import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Mention, MentionsInput } from "react-mentions";
import EmojiPicker from "emoji-picker-react";
import { IGif } from "@giphy/js-types";
import Dropzone from "react-dropzone";
import {
  FileMosaic,
  FullScreen,
  ImagePreview,
  VideoPreview,
} from "@files-ui/react";

import {
  Box,
  Avatar,
  Text,
  IconButton,
  HStack,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  // Icon,
  useOutsideClick,
} from "@chakra-ui/react";
import {
  FaceSmileIcon,
  GifIcon,
  PaperClipIcon,
  // CheckCircleIcon,
  PaperAirplaneIcon,
  // DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";

// import { Toast } from "@/Widgets";

import defaultStyle from "./defaultStyle";
import defaultMentionStyle from "./defaultMentionStyle";
import Giphy from "../Giphy";
import { Toast } from "@/Widgets";
import { useTranslation } from "react-multi-lang";
// import Utils from "@/Utils";

interface UuidInfo {
  name: string;
  id: string;
}

interface IDataStructure {
  id: string;
  display: string;
  avatar?: string;
}

interface ICallbackData {
  content?: string;
  fileAttachments?: any[];
  tagMember?: string[];
}
interface ISectionProps {
  value?: string;
  onSubmit(data: ICallbackData): void;
  onGifSelect?(gif: IGif): void;
  payload?: IDataStructure[];
  sx?: object;
  isShowBottomBar?: boolean;
  isClear?: boolean;
  getFor?: "chat" | "board";
  isDisabled?: boolean;
  placeholder?: string;
}

const fileItemStyles = {
  ".files-ui-file-mosaic-main-container,.files-ui-file-mosaic-main-layer.files-ui-layer,.files-ui-file-mosaic-main-container .files-ui-file-mosaic-icon-layer-container":
    {
      // width: "135px",
      // height: "135px",
    },
  ".files-ui-file-mosaic-file-name, .filesui-file-item-size": {
    display: "none",
  },
  ".file-mosaic-main-layer-header > .files-ui-file-icon": {
    minWidth: "10px!important",
    minHeight: "10px!important",
  },
  ".files-ui-file-mosaic-main-container": {
    "&:hover": {
      ".files-ui-tooltip": {
        display: "none",
      },
    },
  },
};

const MAX_FILES = 10;
// const MAX_SIZE = 10 * 1024 * 1024;

const ATTACHMENT_ACCEPT = {
  // "image/*": [],
  // "video/*": [],
  // "application/pdf": [".pdf"],
  // "application/msword": [".doc"],
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
  //   ".docx",
  // ],
  // "application/vnd.ms-excel": [".xls"],
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
  //   ".xlsx",
  // ],
  // "text/csv": [".csv"],
  // "application/vnd.ms-powerpoint": [".ppt"],
  // "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
  //   ".pptx",
  // ],
};

// const UPLOAD_ACCEPT = {
//   // "image/*": [],
// };

const CommentBox: React.FC<ISectionProps> = ({
  payload = [],
  value,
  onSubmit,
  sx,
  isShowBottomBar,
  isClear,
  onGifSelect,
  getFor = "board",
  isDisabled = false,
  placeholder,
}) => {
  const dropzoneRef = useRef<any>(null);
  const emojiRef = useRef<any>(null);

  useOutsideClick({
    ref: emojiRef,
    handler: () => {
      if (isEmojiPopoverOpen) {
        setIsEmojiPopoverOpen(false);
      }
    },
  });
  const t = useTranslation();

  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isEmojiPopoverOpen, setIsEmojiPopoverOpen] = useState<boolean>(false);
  const [isGifPopoverOpen, setIsGifPopoverOpen] = useState<boolean>(false);

  const [accept, setAccept] = useState<any>(ATTACHMENT_ACCEPT);
  const [currentValue, setCurrentValue] = useState<string>("");
  const [selectedSuggestions, setSelectedSuggestions] = useState<UuidInfo[]>(
    []
  );
  const [extFiles, setExtFiles] = useState<any[]>([]);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const [selectedCopyFile, setSelectedCopyFile] = useState<any>(null);

  useEffect(() => {
    setCurrentValue(value || "");
  }, [value]);

  useEffect(() => {
    if (isClear) handleReset();
  }, [isClear]);

  useEffect(() => {
    setIsError(_.some(extFiles, (file) => !file.valid));
  }, [extFiles]);

  useEffect(() => {
    if (selectedCopyFile !== null) {
      const timeoutId = setTimeout(() => {
        setSelectedCopyFile(null);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [selectedCopyFile]);

  const onDelete = (id: string) => {
    const newFiles = extFiles.filter((x) => x.id !== id);
    setExtFiles(newFiles);
  };

  const handleReset = () => {
    setIsError(false);
    setExtFiles([]);
    setCurrentValue("");
    setSelectedSuggestions([]);
    setIsEmojiPopoverOpen(false);
    setIsGifPopoverOpen(false);
  };

  const handleAttachmentClick = useCallback(() => {
    setAccept(ATTACHMENT_ACCEPT);
    dropzoneRef?.current && dropzoneRef?.current?.open();
  }, [dropzoneRef]);

  // const handleUploadClick = useCallback(() => {
  //   setAccept(UPLOAD_ACCEPT);
  //   dropzoneRef?.current && dropzoneRef?.current?.open();
  // }, [dropzoneRef]);

  const handleDownload = async (fileId: string, downloadUrl: string) => {
    // Check if downloadUrl is provided
    if (!downloadUrl) return;

    try {
      // Fetch the file content from the provided downloadUrl
      const image = await fetch(downloadUrl);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      // Create an anchor element for initiating the download
      const anchor = document.createElement("a");

      // Retrieve the fileName based on fileId from the 'files' array
      const fileName =
        _.filter(extFiles, (file) => file.id === fileId)[0]?.file
          ?.nameOriginal || "New file";

      // Set the fileName as the download attribute for the anchor element
      anchor.download = fileName;

      // Set the URL.createObjectURL result as the href for the anchor element
      anchor.href = imageURL;

      // Append the anchor to the document body, trigger the click event, and remove the anchor
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Revoke the object URL to free up resources
      URL.revokeObjectURL(imageURL);
    } catch (error: any) {
      // Handle any download errors
      Toast({ status: "error", title: error?.message });
      console.error(error);
    }
  };

  const handleSee = (imageSource: string) => {
    setImageSrc(imageSource);
  };

  const handleWatch = (videoSource: string) => {
    setVideoSrc(videoSource);
  };

  const handleAbort = (id: string) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };

  const handleCancel = (id: string) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  // const handleCopy = (id: string) => {
  //   const findSelectedFile = _.find(extFiles, (file) => file.id === id);
  //   if (findSelectedFile)
  //     Utils.copyImageToClipboard(findSelectedFile?.downloadUrl);
  // };

  const handlePaste = (e: any) => {
    const clipboardData = e.clipboardData || window.Clipboard;

    if (clipboardData && clipboardData.items) {
      for (let i = 0; i < clipboardData.items.length; i++) {
        const item = clipboardData.items[i];

        // Kiểm tra xem mục clipboard có phải là một hình ảnh không
        if (item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();

          // Xử lý blob và thêm vào extFiles
          handleImageBlob(blob);
        }
      }
    }
  };

  const handleImageBlob = (blob: Blob) => {
    const newFile = {
      id: uuidv4(),
      errors: undefined,
      file: new File([blob], "pasted-image.png", { type: blob.type }),
      name: "pasted-image.png",
      size: blob.size,
      valid: true,
      type: blob.type,
    };
    setExtFiles((prevFiles) => [...prevFiles, newFile]);
  };

  const handleSubmit = () => {
    const resolvedData = {};
    if (!_.isEmpty(currentValue))
      _.assign(resolvedData, {
        content: currentValue,
      });

    if (!_.isEmpty(selectedSuggestions))
      _.assign(resolvedData, {
        tagMember: selectedSuggestions,
      });
    if (!_.isEmpty(extFiles))
      _.assign(resolvedData, {
        fileAttachments: _.map(extFiles, (extFile) => extFile?.file),
      });

    if (!_.isEmpty(resolvedData)) {
      onSubmit(resolvedData);
      handleReset();
    }
  };

  const _renderSuggestions = (suggestion: any) => {
    const isAlreadySelected = selectedSuggestions.some(
      (selected) => selected.id === suggestion.id
    );
    return (
      <Box
        onClick={() => {
          if (
            !selectedSuggestions.some(
              (selected) => selected.id === suggestion.id
            ) &&
            !isAlreadySelected
          ) {
            setSelectedSuggestions((prevSelected) => [
              ...prevSelected,
              suggestion,
            ]);
          }
        }}
        style={{
          display: "flex",
          alignItems: "center",
          gap: 5,
          width: "100%",
        }}
      >
        <Avatar size="xs" src={suggestion.avatar} name={suggestion?.display} />
        <Text style={{ marginLeft: "8px" }}>{suggestion.display}</Text>
      </Box>
    );
  };

  const _renderFileAttachments = () => {
    return (
      <HStack
        sx={{
          maxW: "100%",
          overflow: "auto",
          flexWrap: "wrap",
        }}
      >
        {!_.isEmpty(extFiles) &&
          _.map(extFiles, (extFile, index: number) => (
            <Box
              key={`${extFile?.index}-${index}`}
              sx={
                {
                  // position: "relative",
                  // zIndex: 999,
                  // rounded: 5,
                }
              }
            >
              {/* <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: "45px",
              p: 0,
              zIndex: 999,
              lineHeight: 1,
              w: "21px",
              h: "21px",
              rounded: "full",
              bg: "rgba(32, 33, 36, 0.65)",
              "&:hover": {
                bg: "rgba(32, 33, 36, 0.85)",
              },
              cursor: "pointer",
              pointerEvents: "auto",
              overflow: "hidden",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleCopy(extFile?.id);
              setSelectedCopyFile(extFile);
            }}
          >
            <Icon
              as={DocumentDuplicateIcon}
              w="18px"
              h="18px"
              color="#ddd"
            />
          </Box> */}

              {/* Copied */}
              {/* {false && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 999,
                bg: "rgba(28, 29, 32, 0.65)",
                inset: 0,
                w: "100%",
                h: "100%",
                overflow: "hidden",
                rounded: 5,
                pointerEvents: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  w: "full",
                  h: "full",
                }}
              >
                <Icon
                  as={CheckCircleIcon}
                  w="18px"
                  h="18px"
                  color="green.200"
                />
                <Text fontSize="sm" color="#ddd">
                  Copied
                </Text>
              </Box>
            </Box>
          )} */}
              <FileMosaic
                {...extFile}
                onDelete={onDelete}
                onSee={handleSee}
                onWatch={handleWatch}
                onAbort={handleAbort}
                onCancel={handleCancel}
                onDownload={handleDownload}
                resultOnTooltip
                alwaysActive
                preview
              />
            </Box>
          ))}
      </HStack>
    );
  };

  const _renderMentionInput = () => (
    <MentionsInput
      value={currentValue}
      onChange={(e) => {
        const newValue = e.target.value;
        const regex = /@\[([^\]]+)\]\(([^)]+)\)|@\b([^\s,@]+)\b/g;
        const mentions = newValue.match(regex);
        if (!mentions) setSelectedSuggestions([]);
        setCurrentValue(newValue);
      }}
      placeholder={placeholder ? placeholder : t("label.mentionPeopleUsing")}
      a11ySuggestionsListLabel={"Suggested mentions"}
      style={defaultStyle}
      forceSuggestionsAboveCursor={true}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      onKeyDown={(e) => {
        // Prevent default behavior of Enter key
        // if (e.key === "Enter" && currentValue.trim() !== "") {
        //   e.preventDefault();
        //   handleSubmit();
        // }
        if (e.key === "Enter") {
          if (e.ctrlKey || e.shiftKey) {
            e.preventDefault();
            setCurrentValue(currentValue + "\n");
          } else if (currentValue.trim() !== "") {
            e.preventDefault();
            handleSubmit();
          }
        }
      }}
      onPaste={handlePaste}
      disabled={isDisabled}
    >
      <Mention
        trigger="@"
        data={payload}
        style={defaultMentionStyle}
        renderSuggestion={_renderSuggestions as any}
        displayTransform={(_id, display) => `@${display}`}
      />
    </MentionsInput>
  );

  const _renderDropzone = () => (
    <Dropzone
      disabled={isDisabled}
      maxFiles={MAX_FILES}
      // maxSize={MAX_SIZE}
      accept={accept}
      ref={dropzoneRef}
      onDrop={(acceptedFiles, rejectedFiles) => {
        const resolvedFiles = _.map(acceptedFiles, (result) => ({
          id: uuidv4(),
          errors: undefined,
          file: result,
          name: result?.name,
          size: result?.size,
          valid: true,
          type: result?.type,
        }));

        const rejectedFilesWithErrors = _.map(
          rejectedFiles,
          (rejectedFile) => ({
            id: uuidv4(),
            errors: ["File not accepted."],
            file: rejectedFile.file,
            size: rejectedFile?.file?.size,
            valid: false,
            type: rejectedFile?.file?.type,
          })
        );

        const allFiles = [...resolvedFiles, ...rejectedFilesWithErrors];
        setExtFiles((prevState) => [...prevState, ...allFiles]);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Box {...getRootProps()}>
          <Box as="input" {...getInputProps()} />
        </Box>
      )}
    </Dropzone>
  );

  const _renderBottomBar = () =>
    isShowBottomBar && (
      <HStack
        sx={{
          justifyContent: "space-between",
        }}
      >
        <HStack>
          <Popover placement="top-start" isOpen={isEmojiPopoverOpen}>
            <PopoverTrigger>
              <IconButton
                size="xs"
                aria-label="Emoji"
                icon={<FaceSmileIcon />}
                sx={{
                  bg: "none",
                  "&:hover": {
                    bg: "none",
                  },
                }}
                onClick={() => setIsEmojiPopoverOpen(true)}
                isDisabled={isDisabled}
              />
            </PopoverTrigger>
            <Portal>
              <Box
                ref={emojiRef}
                {...(getFor === "board"
                  ? { zIndex: "popover", position: "relative" }
                  : {})}
              >
                <PopoverContent>
                  <EmojiPicker
                    onEmojiClick={(emoji) => {
                      setCurrentValue(
                        (prevValue) => `${prevValue}${emoji?.emoji}`
                      );
                    }}
                  />
                </PopoverContent>
              </Box>
            </Portal>
          </Popover>
          <Popover
            placement="top-start"
            isOpen={isGifPopoverOpen}
            onClose={() => setIsGifPopoverOpen(false)}
          >
            <PopoverTrigger>
              <IconButton
                size="xs"
                aria-label="Gif"
                icon={<GifIcon />}
                sx={{
                  bg: "none",
                  "&:hover": {
                    bg: "none",
                  },
                }}
                onClick={() => setIsGifPopoverOpen(true)}
                isDisabled={isDisabled}
              />
            </PopoverTrigger>
            <Portal>
              <Box
                {...(getFor === "board"
                  ? { zIndex: "popover", position: "relative" }
                  : {})}
              >
                <PopoverContent
                  sx={{
                    w: "max-content",
                    maxH: 500,
                    overflowY: "auto",
                  }}
                >
                  <HStack>
                    <Giphy
                      onGifClick={(gif: IGif, e: any) => {
                        e.preventDefault();
                        if (onGifSelect) {
                          onGifSelect(gif);
                          handleReset();
                        }
                      }}
                    />
                  </HStack>
                </PopoverContent>
              </Box>
            </Portal>
          </Popover>
          <IconButton
            size="xs"
            aria-label="Attachment"
            icon={<PaperClipIcon />}
            sx={{
              bg: "none",
              "&:hover": {
                bg: "none",
              },
            }}
            onClick={handleAttachmentClick}
            isDisabled={isDisabled}
          />
          {/* <IconButton
          size="xs"
          aria-label="Upload"
          icon={<PhotoIcon />}
          sx={{
            bg: "none",
            "&:hover": {
              bg: "none",
            },
          }}
          onClick={handleUploadClick}
        /> */}
        </HStack>
        {getFor === "board" ? (
          <Button
            size="sm"
            //colorScheme="messenger"
            onClick={handleSubmit}
            isDisabled={
              (!currentValue && _.isEmpty(extFiles)) || isError || isDisabled
            }
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af!important",
              },
            }}
          >
            {t("button.comment")}
          </Button>
        ) : (
          <IconButton
            size="xs"
            aria-label="Send"
            icon={<PaperAirplaneIcon />}
            sx={{
              bg: "none",
              "&:hover": {
                bg: "none",
              },
            }}
            disabled={
              ((!currentValue || _.trim(currentValue) === "") &&
                _.isEmpty(extFiles)) ||
              isError ||
              isDisabled
            }
            color={
              (!currentValue && _.isEmpty(extFiles)) || isError
                ? "gray.400"
                : "blue.500"
            }
            onClick={handleSubmit}
          />
        )}
      </HStack>
    );

  const _renderPreview = () => (
    <Portal>
      {imageSrc && (
        <FullScreen
          open={imageSrc !== undefined}
          onClose={() => {
            setImageSrc(undefined);
          }}
        >
          <ImagePreview src={imageSrc} />
        </FullScreen>
      )}
      {videoSrc && (
        <FullScreen
          open={videoSrc !== undefined}
          onClose={() => {
            setVideoSrc(undefined);
          }}
        >
          <VideoPreview src={videoSrc} autoPlay controls />
        </FullScreen>
      )}
    </Portal>
  );

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: 3,
        ...fileItemStyles,
        ...sx,
      }}
      draggable
      onDragStart={() => !isDragging && setIsDragging(true)}
      onDragOver={() => !isDragging && setIsDragging(true)}
      onDragEnd={() => isDragging && setIsDragging(false)}
      onMouseLeave={() => isDragging && setIsDragging(false)}
      onDrop={(e) => {
        setIsDragging(false);
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length === 0) return;
        let tempFiles = [...extFiles];
        _.forEach(droppedFiles, (file) => {
          const isValidFileNumber = _.size(tempFiles) <= MAX_FILES - 1;
          tempFiles.push({
            id: uuidv4(),
            errors: [
              `${
                !isValidFileNumber
                  ? `Max amount of files (${MAX_FILES}) has been reached.`
                  : undefined
              }`,
            ],
            file: file,
            name: file.name,
            size: file.size,
            valid: isValidFileNumber,
            type: file.type,
          });
        });
        setExtFiles(tempFiles);
      }}
    >
      <Box
        sx={{
          p: 2,
          borderRadius: getFor === "chat" ? 0 : 5,
          border: `1px solid ${
            isFocus || isDragging ? "#3182ce" : isError ? "#E53E3E" : "silver"
          }`,
          borderStyle: isDragging ? "dashed" : "solid",
        }}
      >
        {_renderFileAttachments()}
        <Box
          sx={{
            position: "relative",
            ".files-ui-footer, .files-ui-header": {
              display: "none",
            },
            zIndex: 999,
          }}
        >
          {_renderMentionInput()}
          {_renderDropzone()}
        </Box>
        {_renderBottomBar()}
        {_renderPreview()}
      </Box>
    </Box>
  );
};
export default CommentBox;
