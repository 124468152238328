import React, { useRef, useEffect, useState, useMemo, memo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { EventContentArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import {
  Box,
  Heading,
  Text,
  Avatar,
  Tooltip,
  Stack,
  Fade,
  HStack,
  CircularProgress,
  // CircularProgress,
} from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import CommonColors from "@/Themes/CommonColors";
import dayjs from "dayjs";
import { RoundedContainer } from "@/Components/Common";
import {
  BoardActions,
  LogtimeActions,
  ProjectExteriorActions,
  UserActions,
  SocketActions,
} from "@/Actions";
import ProjectAction from "@/Actions/Project.action";
import Utils from "@/Utils";
import { useWindowWidth } from "@/Helpers";
import { CustomHeaderToolbar } from "@/Components/LayoutPart/FullCalendar";
import { ENUMS } from "@/Constants";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";
import "dayjs/locale/vi";

const { performAction, fetchLogTimeDashboard } = LogtimeActions;
const { getProjectExteriorById } = ProjectExteriorActions;
const { fetchProjects } = ProjectAction;
const { getBoardById } = BoardActions;
const { fetchUserForDashboard } = UserActions;
const { saveCurrentDateInScroller } = SocketActions;

interface ISectionProps {}

const TimeSheet: React.FC<ISectionProps> = ({}) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const userData = Utils.getSavedUserData();
  const currentUserRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isFetchLoading")
  );
  const isDeleteLogSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isDeleteSuccess")
  );
  const isUpdateLogSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );
  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.SOCKET, "currentDateInScroller")
  );

  const users: any[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );

  const logTimes: any[] = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logTimeListDashboard")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );
  const dayOffRequestList: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "dayOffRequestList")
  );

  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const DEFAULT_TIME_RANGE = useMemo(() => {
    const DEFAULT_DURATION = isMobile ? 3 : 7;
    return Utils.generateDefaultTimeline(DEFAULT_DURATION);
  }, [isMobile]);

  const [events, setEvents] = useState<any[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [slotLabelDisplayType, setSlotLabelDisplayType] = useState<string>("");
  const [resouceAreaWidth, setResourceAreaWidth] = useState<number>(0);
  const [isTooltipOpen, setIsTooltipOpen] = useState("");

  const [activeDates, setActiveDates] = useState<string[]>(DEFAULT_TIME_RANGE);
  const [timeline, setTimeline] = useState(Utils.generateDateRangesByMonths(1));

  const [activeView, setActiveView] = useState<"week" | "month">("week");
  const [projectColors, setProjectColors] = useState<any>({});
  const [minSlotWidthDate, setMinSlotWidthDate] = useState(50);
  const [calendarWidth, setCalendarWidth] = useState(0);
  const [calendarMounted, setCalendarMounted] = useState(false);

  const calendarRef = useRef<FullCalendar | any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const calendarStyles = useMemo(
    () => ({
      // "& .fc *": {
      //   transition: "all ease 0.5s",
      // },
      // "& .fc-timeline-events.fc-scrollgrid-sync-inner *": {
      //   transition: "none",
      // },
      // "& .fc-timeline-events.fc-scrollgrid-sync-inner > *": {
      //   transition: "none",
      // },
      display: "flex",
      flexDirection: "column",
      gap: 3,
      position: "relative",
      ".fc .fc-scrollgrid-section-header > *": {
        borderBottomWidth: "1px!important",
      },
      ".fc .fc-datagrid-cell-cushion": {
        p: "2px",
      },
      //fixed height
      ".fc-datagrid-cell-frame, .fc-timeline-lane-frame": {
        minHeight: "28px",
      },
      ".fc.fc-media-screen.fc-direction-ltr.fc-theme-standard": {
        overflow: "auto",
        minWidth: "800px",
        maxWidth: "100%",
        //minHeight: "calc(100vh - 500px)", // header size
        // maxHeight: "calc(100vh - 180px)",
      },
      ".fc .fc-view-harness ": {
        //overflow: "auto",
      },
      ".fc-button": {
        background: "transparent",
        outline: "none",
        boxShadow: "none !important",
        border: `2px solid ${CommonColors.burntSienna}`,
        color: CommonColors.burntSienna,
        fontSize: "14px",
        "&:hover": {
          background: "#E87844",
          border: `2px solid ${CommonColors.burntSienna}`,
          color: "#ffffff",
        },
      },
      ".fc-toolbar-title": {
        fontSize: 20,
      },
      ".fc-toolbar-title, .fc-button": {
        padding: `${isMobile ? "2px 5px" : "4px 10px"}`,
        textTransform: "capitalize",
      },

      ".fc-today-button, .fc-button.fc-button-primary": {
        // background: `${CommonColors.LightSkyBlue3}!important`,
        "&.fc-button-active": {
          background: `#E87844 !important`,
          border: `2px solid ${CommonColors.burntSienna}`,
          color: "#ffffff",
          fontWeight: 700,
        },
        "&.fc-next-button, &.fc-button[title='month view']": {
          borderLeft: `1px solid ${CommonColors.burntSienna}`,
        },
        "&.fc-prev-button, &.fc-button[title='week view']": {
          borderRight: `1px solid ${CommonColors.burntSienna}`,
        },
      },
      //"& h2.chakra-heading.css-1r8joen": {
      //  background: "#d38d87 !important",
      //},
      "& a.fc-event": {
        borderColor: "#fff!important",
        border: "1px solid #fff",
      },
      ".fc-button-primary:not(:disabled)": {
        background: "unset !important",
        borderColor: "#e87844 !important",
        color: "#e87844",
      },
      ".fc-button:hover": {
        color: "#ffffff!important",
        background: "#E87844 !important",
      },
      "button.fc-today-button.fc-button.fc-button-primary": {
        color: "#ffffff!important",
        background: "#E87844 !important",
        borderColor: "#e87844 !important",
      },
      "td.fc-timeline-slot.fc-timeline-slot-lane.fc-day,th.fc-timeline-slot.fc-timeline-slot-label.fc-day":
        {
          //  width: "200px!important",
        },
      width: "100%",
      overflow: "auto",
      "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-today,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-today":
        {
          background: CommonColors.loblolly,
        },
      ".custom-header .fc-datagrid-table tbody tr": {
        marginBottom: "30px",
      },
      ".custom-header  .fc-resource": {
        paddingBottom: "50px!important",
      },
      ".fc .fc-scroller-harness": {
        //overflow: "visible",
      },
      //  ".fc .fc-scroller": {
      //    overflow: "visible!important",
      //  },import CustomHeaderToolbar from './../../../Components/LayoutPart/FullCalendar/CustomHeaderToolbar';
      "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.holiday, td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.holiday":
        {
          backgroundColor: CommonColors.edward,
        },
      "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-sun,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-sun":
        {
          background: "#E2E8F0",
        },
    }),
    [isMobile]
  );

  const createInternalLogArtist = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LogTime",
    "createInternalLogArtist"
  );

  const fetchProject = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Project",
    "fetchProject"
  );

  const slotLabelFormats = useMemo(() => {
    const formats: any[] = [];
    formats.push({
      weekday:
        slotLabelDisplayType === "full" || minSlotWidthDate >= 30
          ? "short"
          : "narrow",
    });
    formats.push({ day: "2-digit" });
    return formats;
  }, [slotLabelDisplayType, minSlotWidthDate]);

  const visibleRange = useMemo(
    () => ({
      start: _.first(activeDates) || "",
      end: _.last(activeDates) || "",
    }),
    [activeDates]
  );

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  useEffect(() => {
    const closeTooltip = () => {
      setIsTooltipOpen("");
    };
    const calendarContainer = document.querySelector(".container");
    const calendarCalendar = document.querySelector(".fc");
    document.addEventListener("click", closeTooltip);
    if (calendarContainer) {
      calendarContainer.addEventListener("scroll", closeTooltip);
    }
    if (calendarCalendar) {
      calendarCalendar.addEventListener("scroll", closeTooltip);
    }
    return () => {
      document.removeEventListener("click", closeTooltip);
      if (calendarContainer) {
        calendarContainer.removeEventListener("scroll", closeTooltip);
      }
      if (calendarCalendar) {
        calendarCalendar.addEventListener("scroll", closeTooltip);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchUserForDashboard({
        page: 0,
        limit: 0,
        status: ENUMS.USER_STATUS.ACTIVE,
      })
    );
  }, []);

  useEffect(() => {
    let userColors: any = {};
    const colors = ["#5C6E6C", "#A6B7AA", "#D2A96A", "#D39D87", "#BB7154"];
    const sortedUsers = _.sortBy([...users, userData], (user) =>
      user?.userData?.fullName.toLowerCase()
    );

    _.forEach(sortedUsers, (user, index) => {
      const userId = user?.id;
      if (!userColors[userId]) {
        const randomColor = colors[index % colors.length];

        userColors[userId] = {
          color: randomColor,
        };
        setProjectColors(userColors);
      }
    });
  }, [users]);

  useEffect(() => {
    let eventData: any[] = [];
    let resourceData: any[] = [];
    const sortedUsers = _.sortBy([...users, userData], (user) =>
      user?.userData?.fullName.toLowerCase()
    );

    _.forEach(sortedUsers, (user) => {
      resourceData.push({
        id: user?.id,
        title: user?.userData?.fullName,
        user,
      });
    });
    _.forEach(logTimes, (log: any) => {
      const startDate = new Date(
        `${log?.workTimeStartDate}T${log?.workTimeStartTime}`
      );
      const endDate =
        dayjs(startDate).format("DD/MM/YYYY") ===
        dayjs(
          new Date(`${log?.workTimeEndDate}T${log?.workTimeEndTime}`)
        ).format("DD/MM/YYYY")
          ? new Date(`${log?.workTimeEndDate}T${log?.workTimeEndTime}`)
          : dayjs(new Date(`${log?.workTimeEndDate}T${log?.workTimeEndTime}`))
              .add(1, "day")
              .toDate();

      if (log?.task?.kanbanBoard?.project?.id) {
        let event = {
          id: log?.id,
          start: startDate,
          end: endDate,
          resourceId: log?.user?.id,
          extendedProps: {
            ...log,
            resourceId: log?.user?.id,
            //backgroundColor: colors[index % colors.length],
            backgroundColor:
              projectColors[log?.user?.id]?.color || "transparent",
            borderColor: log?.isDone ? "#00ff00" : "inherit",
            type: "log",
          },
          //  backgroundColor: colors[index % colors.length],
          backgroundColor: projectColors[log?.user?.id]?.color || "transparent",
          borderColor: "transparent",
          allDay: true,
        };
        eventData.push(event);
      }
    });

    _.forEach(dayOffRequestList, (dayOffRequest: any) => {
      const startDate = dayjs(dayjs(dayOffRequest.startDate))
        .startOf("day")
        .toDate();
      const endDate =
        dayOffRequest.startDate !== dayOffRequest.endDate
          ? dayjs(dayjs(dayOffRequest.endDate).add(1, "day"))
              .startOf("day")
              .toDate()
          : dayjs(dayjs(dayOffRequest.endDate)).startOf("day").toDate();

      let event = {
        id: dayOffRequest?.id,
        start: startDate,
        end: endDate,
        resourceId: dayOffRequest?.userCreated?.id,
        extendedProps: {
          resourceId: dayOffRequest?.userCreated?.id,
          //backgroundColor: colors[index % colors.length],
          backgroundColor: "transparent",
          type: "dayOffRequest",
          payload: dayOffRequest,
        },
        backgroundColor: CommonColors.lemonGrass,
        borderColor: "transparent",
        allDay: true,
      };
      eventData.push(event);
    });

    setEvents(eventData);
    setResources(resourceData);
  }, [logTimes, users, projectColors, dayOffRequestList]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    setTimeout(() => {
      calendarApi?.refetchEvents();
      calendarApi?.refetchResources();
    }, 500);
  }, [isSidebarExpanded, minSlotWidthDate]);

  useEffect(() => {
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) - (resouceAreaWidth || 150);
    if (containerWidth > 0) setCalendarWidth(containerWidth);
  }, [resouceAreaWidth]);

  useEffect(() => {
    if (!_.isEmpty(timeline)) {
      if (
        dayjs(_.first(timeline)).isValid() &&
        dayjs(_.last(timeline)).isValid()
      ) {
        dispatch(
          fetchLogTimeDashboard({
            page: 0,
            limit: 0,
            forDashboard: true,
            startDate: _.first(timeline),
            endDate: _.last(timeline),
          })
        );
        const calendarApi = calendarRef?.current?.getApi();
        if (!_.isEmpty(timeline) && calendarApi) {
          requestAnimationFrame(() => {
            calendarApi.gotoDate(_.first(timeline));
          });
        }
      }
    }
  }, [timeline]);

  useEffect(() => {
    if (containerRef?.current) {
      const type = calculateDisplayType();
      setSlotLabelDisplayType(type);
    }
  }, [resouceAreaWidth, visibleRange, minSlotWidthDate]);

  useEffect(() => {
    const calendarEl = calendarRef?.current?.calendar?.el;
    const scroller: any = calendarEl.querySelectorAll("tfoot .fc-scroller")[1];

    const handleCalendarScroll = _.debounce(() => {
      const { scrollLeft } = scroller;
      const currentDay = Math.round(scrollLeft / minSlotWidthDate);
      if (
        timeline[currentDay] &&
        timeline[currentDay] !== currentDateInScroller
      ) {
        dispatch(saveCurrentDateInScroller(timeline[currentDay]));
      }
    }, 300);

    if (scroller) {
      scroller.addEventListener("scroll", handleCalendarScroll);
    }

    return () => {
      scroller?.removeEventListener("scroll", handleCalendarScroll);
    };
  }, [minSlotWidthDate, timeline]);

  useEffect(() => {
    if (isDeleteLogSuccess || isUpdateLogSuccess) {
      dispatch(
        fetchLogTimeDashboard({
          page: 0,
          limit: 0,
          startDate: _.first(timeline),
          endDate: _.last(timeline),
          forDashboard: true,
        })
      );
    }
  }, [isDeleteLogSuccess, isUpdateLogSuccess, timeline]);

  useEffect(() => {
    dispatch(fetchProjects({ page: 0, limit: 0 }));
    return () => {};
  }, [createInternalLogArtist, fetchProject]);

  useEffect(() => {
    if (calendarMounted) scrollToTime();
  }, [calendarMounted, visibleRange, timeline]);

  const scrollToTime = () => {
    const calendarApi = calendarRef?.current?.getApi();
    if (!_.isEmpty(visibleRange) && calendarApi) {
      const midIndex = _.findIndex(
        timeline,
        (date) => date === currentDateInScroller || date === activeDates[0]
      );

      if (midIndex !== -1) {
        _.delay(() => {
          calendarApi.scrollToTime({
            days: midIndex,
          });
        }, 100);
      }
    }
  };

  const handleReset = () => {
    setActiveDates(DEFAULT_TIME_RANGE);
    const calendarApi = calendarRef?.current?.getApi();
    const midIndex = _.findIndex(
      timeline,
      (date) => date === DEFAULT_TIME_RANGE[0]
    );
    if (midIndex !== -1) {
      _.delay(() => {
        calendarApi.scrollToTime({
          days: midIndex,
        });
      }, 100);
    }
  };

  const calculateDisplayType = () => {
    const numberOfDays =
      dayjs(_.last(timeline)).diff(dayjs(_.first(timeline)), "day") + 1;
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) - (resouceAreaWidth || 150);
    return Utils.calculateDisplayType(numberOfDays, containerWidth);
  };

  const _renderElementTooltip = (arg: any) => {
    const log = arg?.extendedProps;
    const eventType = arg?.extendedProps?.type;

    if (eventType === "dayOffRequest") {
      const dayOffDetails = _.get(arg?.extendedProps, "payload");
      return (
        <Stack
          sx={{
            w: "max-content",
            maxW: 400,
          }}
        >
          <Stack direction="column">
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Text fontSize="xs" sx={{ fontWeight: 600 }}>
                {t("label.startTimeOffDate")}:
              </Text>
              <Text fontSize="xs">
                {dayjs(dayOffDetails?.startDate).format("DD/MM/YYYY") || "--"}
              </Text>
            </Box>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Text fontSize="xs" sx={{ fontWeight: 600 }}>
                {t("label.endTimeOffDate")}:
              </Text>
              <Text fontSize="xs">
                {dayjs(dayOffDetails?.endDate).format("DD/MM/YYYY") || "--"}
              </Text>
            </Box>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Text fontSize="xs" sx={{ fontWeight: 600 }}>
                {t("label.duration")}:
              </Text>
              <Text fontSize="xs">{`${dayOffDetails?.duration} ${t(
                "label.days"
              )}`}</Text>
            </Box>
            <Box
              sx={{
                color: "#fff",
              }}
            >
              <Text fontSize="xs" sx={{ fontWeight: 600 }}>
                {t("label.timeOffReason")}:
              </Text>
              <Text fontSize="xs">{dayOffDetails?.reason || "--"}</Text>
            </Box>
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack
        sx={{
          w: "max-content",
        }}
      >
        <Stack direction="column">
          {log?.task && (
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Text fontSize="xs" sx={{ fontWeight: 600 }}>
                {t("label.project")}:
              </Text>
              <Text fontSize="xs">
                {log?.task?.kanbanBoard?.project?.name || "--"}
              </Text>
            </Box>
          )}
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.process")} :
            </Text>
            <Text fontSize="xs">{log?.task?.title || "--"}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.status")} :
            </Text>
            <Text fontSize="xs">
              {log?.isDone ? t("status.done") : t("status.slacking")}
            </Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.workingTime")} :
            </Text>
            <Text fontSize="xs">{log?.workingTime}h</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.description")}:
            </Text>
            <Text
              sx={{
                wordWrap: "break-word",
                maxWidth: 200,
              }}
              fontSize="xs"
              dangerouslySetInnerHTML={{
                __html: log?.description ? log?.description : "--",
              }}
            />
          </Box>
        </Stack>
      </Stack>
    );
  };

  const handleLongPress = (id: any) => () => {
    //setTimeout(() => {
    setIsTooltipOpen(id);
    //}, 50);
  };

  const handleTouchEnd = () => {
    setIsTooltipOpen("");
  };

  const _renderEventContent = (eventInfo: EventContentArg) => {
    const { event } = eventInfo;
    const log = event?.extendedProps;
    const eventType = event?.extendedProps?.type;
    const isVirtualEvent = _.includes(event?.id, "virtual_event");

    if (eventType === "dayOffRequest") {
      return (
        <Tooltip
          label={_renderElementTooltip(event)}
          hasArrow
          maxW="400px"
          minW={200}
          aria-label="tooltip"
          isOpen={isTooltipOpen === log?.payload?.id}
          placement="auto"
        >
          <Box
            sx={{
              // cursor: "pointer",
              overflow: "hidden",
              visibility: isVirtualEvent ? "hidden" : "visible",
              background: log?.backgroundColor,
            }}
            // onClick={(e) => {}}
            onTouchStart={handleLongPress(log?.payload?.id)}
            onMouseEnter={handleLongPress(log?.payload?.id)}
            onMouseLeave={handleTouchEnd}
          >
            <Heading
              sx={{
                color: "white",
                fontSize: "12px",
                p: 0,
                boxShadow: "10px 10px",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Text>{t("label.off")}</Text>
              </Box>
            </Heading>
          </Box>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        label={_renderElementTooltip(event)}
        hasArrow
        maxW="500px"
        minW={200}
        isOpen={isTooltipOpen === log?.id}
        placement="auto"
      >
        <Box
          sx={{
            cursor: "pointer",
            overflow: "hidden",
            visibility: isVirtualEvent ? "hidden" : "visible",
            background: log?.backgroundColor,
          }}
          onTouchStart={handleLongPress(log?.id)}
          onMouseEnter={handleLongPress(log?.id)}
          onMouseLeave={handleTouchEnd}
          onClick={(e) => {
            e.stopPropagation();
            const targetUserRoles = _.map(
              log?.user?.userRole,
              (userRole) => userRole?.role?.roleCode
            );
            const projectType = log?.task?.kanbanBoard?.project?.type;
            const canUpdateLog = Utils.handleCheckLogTimePermissions(
              currentUserRoles,
              targetUserRoles,
              projectType
            );

            if (canUpdateLog || userData?.id === log?.resourceId) {
              setIsTooltipOpen("");
              if (log?.projectExterior?.id)
                dispatch(getProjectExteriorById(log?.projectExterior?.id));
              else dispatch(getBoardById(log?.task?.kanbanBoard?.id));
              dispatch(
                performAction("editLog", log?.id, {
                  userId: log?.resourceId,
                })
              );
            }
          }}
        >
          <Heading
            sx={{
              color: "white",
              fontSize: "12px",
              p: 0,
              boxShadow: "10px 10px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Text> {`${log?.workingTime}H`}</Text>
            </Box>
          </Heading>
        </Box>
      </Tooltip>
    );
  };

  const _renderResourceContent = (resourceInfo: any) => {
    const { _resource } = resourceInfo.resource;
    const { user } = _resource?.extendedProps;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Avatar
          src={user?.userData?.avatar?.path}
          name={user?.userData?.fullName}
          size={isMobile ? "xs" : "xs"}
        />
        <Tooltip title={user?.userData?.fullName}>
          <Text
            fontSize={isMobile ? "xs" : "sm"}
            sx={{
              fontWeight: 500,
            }}
          >
            {user?.userData?.fullName}
          </Text>
        </Tooltip>
      </Box>
    );
  };

  const _renderLoading = () =>
    isFetchLoading && (
      <Box
        sx={{
          position: "absolute",
          background: "#fff",
          inset: 0,
          zIndex: 99,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "all .3s ease-in-out",
          width: "full",
          height: "full",
        }}
      >
        <HStack>
          <CircularProgress size="20px" isIndeterminate color="gray" />
          <Text>Loading...</Text>
        </HStack>
      </Box>
    );

  const _renderCalendar = () => {
    return (
      <RoundedContainer>
        <CustomHeaderToolbar
          isLoading={isFetchLoading}
          activeView={activeView}
          onReset={handleReset}
          onMinSlotWidthDateChange={(width) => setMinSlotWidthDate(width)}
          onActiveViewChange={(view) => setActiveView(view)}
          activeDates={activeDates}
          onActiveDatesChange={(dates) => {
            if (!_.isEmpty(dates)) setActiveDates(dates);
          }}
          timeline={timeline}
          onTimelineChange={(timeline) => setTimeline(timeline)}
          calendarWidth={calendarWidth}
        />
        <Box
          ref={containerRef}
          sx={{
            ...calendarStyles,
            height: _.size(users) > 10 ? "500px" : "auto",
            "td.fc-resource-timeline-divider.fc-cell-shaded": {
              width: isMobile ? "7px" : "3px",
            },
            //The commented code you provided is used to hide unnecessary days when the container area is small.
            ".fc-timeline-header-row:last-of-type .fc-timeline-slot.fc-timeline-slot-label.fc-day:not(.fc-day-mon) a":
              {
                visibility:
                  (slotLabelDisplayType === "firstDay" ||
                    slotLabelDisplayType === "hide") &&
                  minSlotWidthDate <= 20
                    ? "hidden"
                    : "visible",
              },
            ".fc .fc-timeline-slot-cushion": {
              fontSize:
                slotLabelDisplayType === "firstDay" ||
                slotLabelDisplayType === "short" ||
                slotLabelDisplayType === "hide"
                  ? "10px"
                  : "inherit",
            },
          }}
        >
          <Fade in>
            <FullCalendar
              ref={calendarRef}
              rerenderDelay={2}
              scrollTimeReset={false}
              plugins={[interactionPlugin, resourceTimelinePlugin]}
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              resourceAreaHeaderClassNames="custom-header"
              eventContent={_renderEventContent}
              events={events}
              resources={resources}
              resourceOrder={[
                "overall",
                ...resources?.map((resource) => resource.order),
              ]}
              resourceLabelContent={_renderResourceContent}
              stickyHeaderDates
              stickyFooterScrollbar
              firstDay={1}
              headerToolbar={false}
              dayMaxEvents
              // editable
              // eventResizableFromStart
              //  eventResize={handleResize}
              aspectRatio={1}
              initialView={"resourceTimelineMonth"}
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
              }}
              eventDisplay="block"
              eventOrder="priority"
              views={{
                resourceTimelineWeek: {
                  slotMinWidth: minSlotWidthDate,
                  eventMinWidth: minSlotWidthDate,
                  slotDuration: { day: 1 },
                  // slotMinTime: "08:00",
                  // slotMaxTime: "17:30",
                  // slotDuration: { hours: 1 },
                  // slotLabelFormat: [
                  //   { weekday: "short", day: "2-digit" }, // lower level of text
                  // ],
                  // slotLabelFormat: [
                  //   { week: "short" },
                  //   { weekday: "short" },
                  //   { day: "2-digit" },
                  // ],
                  slotLabelFormat: slotLabelFormats,
                  // hiddenDays: [0, 6] // Hide Sunday and Saturday?
                  visibleRange: {
                    start: _.first(timeline),
                    end: _.last(timeline),
                  },
                  duration: {
                    days: !_.isEmpty(timeline)
                      ? dayjs(_.last(timeline)).diff(
                          dayjs(_.first(timeline)),
                          "day"
                        ) + 1
                      : 7,
                  },
                },
                resourceTimelineMonth: {
                  // slotMinTime: "08:00",
                  // slotMaxTime: "17:30",
                  // slotDuration: { hours: 1 },
                  // slotLabelFormat: [
                  //   { weekday: "short", day: "2-digit" },
                  //   { hour: "numeric", minute: "2-digit" },
                  // ],
                  slotMinWidth: minSlotWidthDate,
                  eventMinWidth: minSlotWidthDate,
                  slotDuration: { day: 1 },
                  // slotLabelFormat: [
                  //   { week: "short" },
                  //   { weekday: "short" },
                  //   { day: "2-digit" },
                  // ],
                  slotLabelFormat: slotLabelFormats,
                  visibleRange: {
                    start: _.first(timeline),
                    end: _.last(timeline),
                  },
                  duration: {
                    days: !_.isEmpty(timeline)
                      ? dayjs(_.last(timeline)).diff(
                          dayjs(_.first(timeline)),
                          "day"
                        ) + 1
                      : 30,
                  },
                },
              }}
              resourceAreaWidth={isMobile ? 190 : 225}
              resourceAreaHeaderContent={t("table.employees")}
              height={_.size(users) >= 10 ? 500 : "auto"}
              slotLabelClassNames={(slotContent) => {
                const classNames = [];
                const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
                const isHoliday = _.some(
                  holidaysList,
                  (holiday) => holiday?.day === slotDate
                );
                if (isHoliday) classNames.push("holiday");
                return classNames;
              }}
              slotLaneClassNames={(slotContent) => {
                const classNames = [];
                const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
                const isHoliday = _.some(
                  holidaysList,
                  (holiday) => holiday?.day === slotDate
                );
                if (isHoliday) classNames.push("holiday");
                return classNames;
              }}
              viewDidMount={(info) => {
                const resourcesEl: Element | any = info.el.querySelector(
                  ".fc-scrollgrid > colgroup > col"
                );
                new ResizeObserver(() => {
                  if (resourcesEl.clientWidth > 0)
                    setResourceAreaWidth(resourcesEl.clientWidth);
                }).observe(resourcesEl);
              }}
              eventDidMount={() => {
                setCalendarMounted(true);
                scrollToTime();
              }}
              locale={language === "en" ? "en" : "vi"}
            />
          </Fade>
          {_renderLoading()}
        </Box>
      </RoundedContainer>
    );
  };

  return _renderCalendar();
};

export default memo(TimeSheet);
