import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Text,
  Switch,
  Tooltip,
  Icon,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import CreateInternalDialog from "./CreateInternalDialog";
import UpdateInternalDilog from "./UpdateInternalDialog";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import InternalIpAction from "@/Actions/InternalIp.action";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { IInternalStructure } from "@/Interfaces/InternalIp.interface";
import _ from "lodash";
import ConfirmDialog from "../Confirm";
import { useTranslation } from "react-multi-lang";

const { fetchInternalIp, changeStatusInternalIp } = InternalIpAction;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const InternalIpDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const [filterDialog, setFilterDialog] = useState<string>("LIST");
  const [confirmStatus, setConfirmStatus] = useState<{
    open: boolean;
    status: string;
    type: string;
    id: string;
  }>({ open: false, status: "", type: "", id: "" });

  const [internalIpDetail, setInternalIpDetail] = useState<IInternalStructure>({
    id: "",
    ipAddress: "",
    description: "",
    status: "",
  });

  const internalList: IInternalStructure[] = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "internalList")
  );

  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isFetchLoading")
  );

  const isChangeStatusSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.INTERNAL_IP, "isChangeStatusSuccess")
  );

  const onRowAction = (type: boolean, id: string) => {
    if (type)
      setConfirmStatus({
        open: true,
        status: "active",
        type: t("message.doYouWantToActiveInternalIpAddress"),
        id: id,
      });
    else
      setConfirmStatus({
        open: true,
        status: "inactive",
        type: t("message.doYouWantToInactiveInternalAddress"),
        id: id,
      });
  };

  const onChangeStatus = () => {
    if (confirmStatus?.id) {
      if (confirmStatus?.status === "inactive")
        dispatch(
          changeStatusInternalIp(confirmStatus.id, { status: "inactive" })
        );
      else
        dispatch(
          changeStatusInternalIp(confirmStatus.id, { status: "active" })
        );
    }
  };

  useEffect(() => {
    if (filterDialog === "LIST" && open) {
      dispatch(fetchInternalIp());
    }
  }, [filterDialog, open]);

  useEffect(() => {
    if (!open) {
      onClose();
      setInternalIpDetail({
        id: "",
        ipAddress: "",
        description: "",
        status: "",
      });
    }
  }, [open]);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      setConfirmStatus({ open: false, status: "", type: "", id: "" });
    }
  }, [isChangeStatusSuccess]);

  const _renderBodyInternal = (value: string) => {
    switch (value) {
      case "LIST":
        return _renderInternalList();
      case "CREATE":
        return (
          <CreateInternalDialog
            onClose={() => setFilterDialog("LIST")}
            open={filterDialog === "CREATE"}
          />
        );
      case "UPDATE":
        return (
          <UpdateInternalDilog
            onClose={() => setFilterDialog("LIST")}
            open
            payload={internalIpDetail}
          />
        );
      default:
        return "";
    }
  };

  const _renderInternalList = () => {
    return (
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        size="sm"
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
            borderRadius: 0,
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 2px 1px -1px gray",
            }}
          >
            <Text> Internal IP Addresses</Text>
            <Box
              sx={{ cursor: "pointer", position: "absolute", top: 1, right: 2 }}
              onClick={() => setFilterDialog("CREATE")}
            >
              <Tooltip label="Create Internal IP">
                <Icon
                  as={PlusCircleIcon}
                  boxSize={8}
                  sx={{ color: "#22c35e" }}
                />
              </Tooltip>
            </Box>
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              overflowY: "auto",
              minH: 210,
            }}
          >
            {!isFetchLoading ? (
              <Box>
                {!_.isEmpty(internalList) ? (
                  <Box>
                    {_.map(
                      internalList,
                      (item: IInternalStructure, index: number) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Text size={"sm"} sx={{ fontWeight: 600 }}>
                                IP{index + 1}:
                              </Text>
                              <Text sx={{ ml: 2, fontWeight: 600 }}>
                                {item.ipAddress}
                              </Text>
                            </Box>
                            <Box>
                              <Tooltip label="Change status">
                                <Switch
                                  isChecked={item?.status === "active"}
                                  size={"sm"}
                                  id="internalIp"
                                  onChange={(e) =>
                                    onRowAction(e.target.checked, item.id)
                                  }
                                />
                              </Tooltip>
                              <Tooltip label="Edit" ml={1}>
                                <IconButton
                                  sx={{
                                    bg: "transparent",
                                  }}
                                  size="md"
                                  isRound
                                  icon={<Icon as={PencilSquareIcon} />}
                                  aria-label={""}
                                  onClick={() => {
                                    setInternalIpDetail(item);
                                    setFilterDialog("UPDATE");
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  </Box>
                ) : (
                  <Box>{t("message.noDataWereFound")}</Box>
                )}
              </Box>
            ) : (
              <Box textAlign={"center"}>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            )}
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              boxShadow: "0px -1px 1px gray",
              justifyContent: "left",
            }}
          >
            <Button
              size={"sm"}
              ref={cancelRef}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };

  const _renderMain = () => {
    return (
      <Box>
        {_renderBodyInternal(filterDialog)}
        <ConfirmDialog
          isOpen={confirmStatus.open}
          onClose={() => {
            setConfirmStatus({ ...confirmStatus, open: false });
          }}
          body={confirmStatus.type}
          onAction={() => onChangeStatus()}
          actionType={confirmStatus.status === "active" ? "active" : "inactive"}
        />
      </Box>
    );
  };

  return <Box>{_renderMain()}</Box>;
};

export default InternalIpDialog;
