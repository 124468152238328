import React, { useRef, useEffect } from "react";
import _ from "lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Stack,
  Heading,
  Image,
  Checkbox,
} from "@chakra-ui/react";

import { TextField, UploadAvatar } from "@/Components/Common";
import EmojiPicker from "emoji-picker-react";
import { WorkspaceActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

//import { RootState, useTypedDispatch } from "@/Store";

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: any;
}

const DEFAULT_FORM_DATA = {
  name: "",
  link: "",
  icon: "",
  isUserIconCustom: false,
};
const { updateWorkSpaceShorCut } = WorkspaceActions;
const UpdateWorkspaceShortCutDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const cancelRef = useRef<any>(null);
  const [files, setFiles] = React.useState<any[]>([]);

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      name: yup.string().trim().required(t("message.titleIsRequired")),
      icon: yup.string().trim().notRequired(),
      isUserIconCustom: yup.boolean().notRequired(),
      link: yup
        .string()
        .url(t("message.linkInvalid"))
        .required(t("message.linkIsRequired")),
    })
    .required();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });
  const iconUrl: any = watch("icon");

  const onSubmit = (data: any) => {
    const formData = new FormData();
    if (!_.isEmpty(files)) {
      const fileData = files[0];
      if (fileData) formData.append("iconCustom", fileData);
    }
    formData.append("name", data?.name);
    formData.append("icon", data?.icon);
    formData.append("isUserIconCustom", data?.isUserIconCustom);
    formData.append("link", data?.link);

    if (payload?.id) dispatch(updateWorkSpaceShorCut(payload.id, formData));
  };
  console.log(payload);

  const generateDetails = async () => {
    if (payload) {
      setValue("icon", payload.icon);
      setValue("name", payload.name);
      setValue("isUserIconCustom", payload.isUserIconCustom);
      setValue("link", payload.link);
      if (payload?.iconCustom) {
        const isInternal = payload?.iconCustom?.storageService === "Internal";
        const avatarSrc = isInternal
          ? await Utils.getFileFromURL(
              _.get(payload, "iconCustom.path") || "",
              _.get(payload, "iconCustom.nameOriginal") || ""
            )
          : await Utils.getAWSFileAsBlob(
              _.get(payload, "iconCustom.path") || "",
              _.get(payload, "iconCustom.nameOriginal") || ""
            );
        if (avatarSrc) setFiles([avatarSrc as File]);
      }
    }
  };
  useEffect(() => {
    generateDetails();
  }, [payload]);

  useEffect(() => {
    if (!open) {
      reset();
      setFiles([]);
    }
  }, [open]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          ".epr-skin-tone-select.epr_mw4zr1.epr_-2hq8qm.epr_-l1995p.epr_-bbny0t":
            {
              display: "none",
            },
        }}
        as={"form"}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("label.title")}
              placeholder={t("label.title")}
              size={"sm"}
              value={field.value || ""}
              onChange={(e: any) => {
                field.onChange(e.target.value);
              }}
              isError={!_.isEmpty(errors?.name?.message)}
              errorMessage={errors?.name?.message}
              isRequired
            />
          )}
        />
        <Box>
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <Stack mb={3}>
                <TextField
                  value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e.target.value);
                  }}
                  label={t("label.link")}
                  placeholder={t("label.link")}
                  size="sm"
                  isError={!_.isEmpty(errors.link?.message)}
                  errorMessage={errors?.link?.message}
                  isRequired
                />
                <Controller
                  name="isUserIconCustom"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Checkbox
                        isChecked={!!field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        size="lg"
                        colorScheme="teal"
                      />
                      {t("label.openLinkInNewWindow")}
                    </Box>
                  )}
                />
              </Stack>
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Heading fontSize={"14px"} mb={2}>
              {t("label.customIcon")}
            </Heading>
            <UploadAvatar
              direction="row"
              labelSx={{ minWidth: "250px" }}
              containerSx={{ mb: 2 }}
              files={files}
              onFileChange={(newFile) => setFiles(newFile)}
            />
          </Box>
        </Box>
        <Box>
          <Heading fontSize={"14px"}>{t("label.icon")}</Heading>
          <Image src={iconUrl} />
          <Controller
            name="icon"
            control={control}
            render={({ field }) => (
              <Stack>
                <EmojiPicker
                  onEmojiClick={(emoji) => {
                    field.onChange(emoji.imageUrl);
                  }}
                />
              </Stack>
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.updateShortcut")}
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 800, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 10px 10px ",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
              reset();
            }}
            size="sm"
            isDisabled={isActionLoading}
          >
            {t("button.cancel")}
          </Button>
          <Button
            isDisabled={isActionLoading}
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            size="sm"
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateWorkspaceShortCutDialog;
