import React from "react";
import _ from "lodash";
import {
  Box,
  Icon,
  IconButton,
  Text,
  Tooltip,
  //  Avatar,
} from "@chakra-ui/react";

import { Table, Pagination } from "rsuite";
import { type IPaginate, type TActions } from "@/Types/Common.types";

import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ENUMS } from "@/Constants";
import Utils from "@/Utils";
import dayjs from "dayjs";
import { NoDataFound, TextComponent } from "@/Components/Common";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";
const { Column, HeaderCell, Cell } = Table;

const { TIMECARD_STATUS_COLOR } = ENUMS;

interface IProps {
  payload: any[];
  paginate: IPaginate;
  onPageChange?: (page: number) => void;
  onRowAction: (action: TActions, item: any) => void;
  actions?: TActions[];
  showTotal?: boolean;
  isLoading?: boolean;
  onChangeLimit?: (limint: number) => void;
}
const HEADER_STYLE = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#bb7154",
};

const OvertimeDataTable: React.FC<IProps> = ({
  payload,
  paginate,
  onPageChange,
  onRowAction,
  isLoading,
  onChangeLimit,
}) => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 700;
  const getAccountId = Utils.getAccountId();
  const userData = Utils.getSavedUserData();

  const approveOvertime = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "approved"
  );

  const deleteOvertime = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "deleteOvertime"
  );

  const checkTableUser = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "checkTable"
  );

  const viewTable = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "viewTable"
  );

  const filteredData = payload.filter((item: any) => {
    const hasArtistRole = _.some(
      item?.user?.userRole,
      (userRole) =>
        userRole?.role?.roleCode === ENUMS.ROLES.ARTIST ||
        userRole?.role?.roleCode === ENUMS.ROLES.LEADER ||
        userRole?.role?.roleCode === ENUMS.ROLES.MARKETING
    );

    const hasManagerRole = _.some(
      item?.user?.userRole,
      (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
    );

    const hasAdminRole = _.some(
      item?.user?.userRole,
      (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ADMIN
    );

    const hasHumanRole = _.some(
      item?.user?.userRole,
      (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.HUMAN_RESOURCES
    );

    return hasArtistRole && !hasManagerRole && !hasAdminRole && !hasHumanRole;
  });

  const locale = {
    limit: `{0} ${t("label.page")}`,
    total: `${t("label.totalRows")}: {0}`,
    skip: `${t("label.goTo")} {0}`,
  };

  return (
    <Box
      sx={{
        w: "100%",
        ".rs-table-scrollbar-handle": {
          background: "#E87844",
          w: "4px",
          height: "4px",
        },
        ".rs-table-scrollbar.rs-table-scrollbar-horizontal": {
          height: "4px",
        },
      }}
    >
      <Table
        data={viewTable ? payload : filteredData}
        autoHeight={payload?.length <= 10}
        affixHeader
        affixHorizontalScrollbar
        loading={isLoading}
        renderEmpty={() => <NoDataFound />}
        headerHeight={50}
        height={isMobile ? 400 : 550}
      >
        <Column width={180} fixed={!isMobile} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.fullName")}
          </HeaderCell>
          <Cell dataKey="user.userData.fullName" />
        </Column>
        <Column align="center" width={200}>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.role")}
          </HeaderCell>
          <Cell dataKey="role">
            {(rowData) => {
              const roleNames = _.map(rowData?.user?.userRole, (userRole) =>
                userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
                  ? `Staff`
                  : _.startCase(userRole?.role?.name)
              );
              const concatenatedRoleNames = roleNames.join(", ");
              return (
                <TextComponent content={concatenatedRoleNames} maxW="100%" />
              );
            }}
          </Cell>
        </Column>
        {(approveOvertime || checkTableUser) && (
          <Column flexGrow={1} minWidth={150} resizable>
            <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
              {t("table.projects")}
            </HeaderCell>
            <Cell dataKey="kanbanBoard.project.name">
              {(rowData) => (
                <TextComponent
                  content={
                    rowData?.kanbanBoard
                      ? rowData.kanbanBoard.project.name
                      : "--"
                  }
                  maxW="100%"
                />
              )}
            </Cell>
          </Column>
        )}
        {(approveOvertime || checkTableUser) && (
          <Column align="center" width={100} resizable>
            <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
              {t("table.process")}
            </HeaderCell>
            <Cell>
              {(rowData) => (
                <TextComponent content={rowData?.task?.title} maxW="100%" />
              )}
            </Cell>
          </Column>
        )}
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.type")}
          </HeaderCell>
          <Cell dataKey="date" style={{ textTransform: "capitalize" }}>
            {(rowData) =>
              rowData?.kanbanBoard?.project?.type ===
              ENUMS.PROJECT_TYPE.INTERIOR
                ? t("label.internal")
                : t("label.external")
            }
          </Cell>
        </Column>
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.date")}
          </HeaderCell>
          <Cell dataKey="date">
            {(rowData) => dayjs(rowData?.date).format("DD/MM/YYYY")}
          </Cell>
        </Column>
        <Column align="center" width={120} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.dateType")}
          </HeaderCell>
          <Cell style={{ textTransform: "capitalize" }}>
            {(rowData) => {
              const dateObject = new Date(rowData?.date);
              const dayOfWeek = dateObject.getDay();
              return rowData?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND
                ? dayOfWeek === 6
                  ? t(`label.weekend`)
                  : t(`label.sunday`)
                : t(`label.${rowData?.dateType}`);
            }}
          </Cell>
        </Column>
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.workingTime")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return Math.round(rowData.workingTime * 1000) / 1000;
            }}
          </Cell>
          {/*<Cell dataKey="workingTime" />*/}
        </Column>
        <Column align="center" width={100} resizable>
          <HeaderCell verticalAlign="center" style={HEADER_STYLE}>
            {t("table.status")}
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return (
                <Text
                  color={
                    TIMECARD_STATUS_COLOR[
                      rowData?.overTimeStatus as keyof typeof TIMECARD_STATUS_COLOR
                    ]?.text
                  }
                  bgColor={
                    TIMECARD_STATUS_COLOR[
                      rowData?.overTimeStatus as keyof typeof TIMECARD_STATUS_COLOR
                    ]?.background
                  }
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 700,
                    borderRadius: "20px",
                    px: 2,
                    py: 1,
                    fontSize: "12px",
                    fontFamily: "Inter",
                  }}
                >
                  {t(`status.${rowData?.overTimeStatus}`)}
                </Text>
              );
            }}
          </Cell>
        </Column>
        <Column align="center" width={120} fixed="right">
          <HeaderCell
            verticalAlign="center"
            align="center"
            style={HEADER_STYLE}
          >
            {t("table.actions")}
          </HeaderCell>
          <Cell style={{ padding: "5px" }}>
            {(rowData: any) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    // justifyContent: "center",
                  }}
                >
                  {approveOvertime && (
                    <Tooltip label={t("tooltip.view")}>
                      <IconButton
                        sx={{
                          bg: "transparent",
                        }}
                        size="md"
                        isRound
                        icon={<Icon as={EyeIcon} />}
                        aria-label={""}
                        onClick={() => onRowAction("details", rowData)}
                      />
                    </Tooltip>
                  )}
                  {rowData?.overTimeStatus !== "pending" &&
                    !approveOvertime && (
                      <Tooltip label={t("tooltip.view")}>
                        <IconButton
                          sx={{
                            bg: "transparent",
                          }}
                          size="md"
                          isRound
                          icon={<Icon as={EyeIcon} />}
                          aria-label={""}
                          onClick={() => onRowAction("details", rowData)}
                        />
                      </Tooltip>
                    )}
                  {rowData?.overTimeStatus === "pending" && (
                    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                      {(getAccountId === rowData?.user?.id ||
                        approveOvertime) && (
                        <Tooltip label={t("tooltip.edit")}>
                          <IconButton
                            sx={{
                              bg: "transparent",
                            }}
                            size="md"
                            isRound
                            icon={<Icon as={PencilSquareIcon} />}
                            aria-label={""}
                            onClick={() => onRowAction("edit", rowData)}
                          />
                        </Tooltip>
                      )}
                      {(deleteOvertime ||
                        getAccountId === rowData?.user?.id) && (
                        <Tooltip label={t("tooltip.delete")}>
                          <IconButton
                            sx={{
                              bg: "transparent",
                            }}
                            size="md"
                            isRound
                            icon={
                              <Icon as={TrashIcon} sx={{ color: "#bb7154" }} />
                            }
                            aria-label={""}
                            color="error"
                            onClick={() => onRowAction("delete", rowData)}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  )}
                </Box>
              );
            }}
          </Cell>
        </Column>
      </Table>
      <Box
        sx={{
          p: "20px",
          bg: "white",
          "& .rs-pagination-btn-active": {
            border: "1px solid #bb7154",
            color: "#bb7154",
          },
          "& .rs-pagination-btn-active:hover": {
            color: "#bb7154",
            border: "1px solid #bb7154",
            boxShadow: "0 0 0 1px #bb7154",
          },
          "& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value":
            {
              color: "#bb7154!important",
            },
          "&.rs-pagination-btn:disabled": {
            color: "#c5c6c7!important",
          },
          "& .rs-picker-toggle:hover": {
            borderColor: "#bb7154!important",
          },
        }}
      >
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          layout={
            !isMobile ? ["total", "-", "limit", "pager", "skip"] : undefined
          }
          total={paginate.totalItems}
          limitOptions={[10, 30, 50]}
          limit={paginate.itemsPerPage}
          activePage={paginate.currentPage}
          onChangePage={onPageChange}
          onChangeLimit={onChangeLimit}
          style={{ color: "#bb7154" }}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default OvertimeDataTable;
