import React, { useEffect, useMemo, useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ENUMS } from "@/Constants";
import * as yup from "yup";
import {
  Button,
  DatePicker,
  Select,
  TextField,
  Textarea,
  TimePicker,
} from "@/Components/Common";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions, LogtimeActions } from "@/Actions";
import Utils from "@/Utils";
import { ITaskStructure } from "@/Interfaces/Board.interface";
import dayjs from "dayjs";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import { useTranslation } from "react-multi-lang";

const { getBoardById } = BoardActions;
const { updateLogtime } = LogtimeActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: any;
}

const DEFAULT_OVER_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME,
  workingTime: 0,
  startTime: "",
  endTime: "",
  date: new Date(),
  dateType: ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY,
  description: "",
};

const dateTypeOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsSunday = [
  { label: "Sunday", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeWeekendOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeWeekendOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsSundayVI = [
  { label: "Ngày chủ nhật", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const UpdateOvertimeDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const userRoles = React.useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const [message, setMessage] = React.useState<string>("");

  const projectType = useMemo(
    () => _.get(payload, "kanbanBoard.project.type"),
    [payload]
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );
  const projects: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );
  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );
  const pagination: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "pagination")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );
  const projectsExterior: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "payload")
  );

  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const checkSendOvertime = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Overtime",
    "sendOvertime"
  );

  //check time
  const checkCurrentimeMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const checkCurrentimeAfternoonEnd =
    workingTimeList[0]?.afternoonEnd &&
    Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

  useEffect(() => {
    if (!open) {
      //  reset();
    }
  }, [open]);

  const overTimeSchema = yup
    .object()
    .shape({
      project: yup
        .string()
        .trim()
        .when("type", ([type], sch) => {
          return type === ENUMS.LOG_TIME_TYPE.EXTERNAL
            ? sch.required(t("message.projectIsRequired"))
            : sch.required(t("message.projectIsRequired"));
        }),
      task: yup.string().when("type", ([type], sch) => {
        return type === ENUMS.LOG_TIME_TYPE.INTERNAL
          ? sch.trim().required(t("message.processIsRequired"))
          : sch.trim().required(t("message.processIsRequired"));
      }),
      date: yup.date().required(t("message.dateIsRequired")),
      timeType: yup.string().trim().required(t("message.timeTypeIsRequired")),
      dateType: yup.string().trim().required(t("message.dateTypeIsRequired")),
      startTime: yup.string().trim().notRequired(),
      endTime: yup.string().trim().notRequired(),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeAvalidNumber"))
        .max(24, t("message.workedHoursCannotExceed")),
      description: yup.string().notRequired(),
      type: yup.string().notRequired(),
    })
    .required();

  const {
    control: overTimeControl,
    handleSubmit,
    formState: { errors: overTimeErrors },
    setValue: setOverTimeValue,
    reset,
    clearErrors: clearOverTimeErrors,
    watch: watchOverTime,
  } = useForm({
    resolver: yupResolver(overTimeSchema),
    defaultValues: DEFAULT_OVER_TIME,
  });

  const watchOverStartTime: any = watchOverTime("startTime");
  const watchOverEndTime: any = watchOverTime("endTime");
  const watchOverTimeProject = watchOverTime("project") || "";
  const watchDate = watchOverTime("date") || "";

  const defaultTimeLine = useMemo(
    () => Utils.extractTimeValues(payload?.startTime, payload?.endTime),
    [payload]
  );

  const projectOptions = useMemo(
    () =>
      _.map(projects, (project) => ({
        label: `${project?.name} - ${project?.code}`,
        value: project?.id,
      })),
    [projects]
  );

  const projectExteriorOptions = useMemo(
    () =>
      _.map(projectsExterior, (project) => ({
        label: `${project?.name}`,
        value: project?.id,
      })),
    [projectsExterior]
  );

  useEffect(() => {
    if (watchOverStartTime || watchOverEndTime) {
      if (watchOverStartTime && watchOverEndTime) {
        const newValue = Utils.calculateWorkingTime(
          watchOverStartTime,
          watchOverEndTime
        );
        setOverTimeValue("workingTime", newValue);
        if (newValue > 0) clearOverTimeErrors();
      }
    }
  }, [watchOverStartTime, watchOverEndTime]);

  const onSubmit = (data: any) => {
    const resolvedData = Utils.removeEmptyFields({
      ...data,
      date: dayjs(data?.date).format("YYYY-MM-DD"),
    });
    const dateObject = new Date(data?.date);
    const dayOfWeek = dateObject.getDay();
    if (dayOfWeek === 6 && data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
      if (data?.endTime < data?.startTime && data?.endTime !== "00:00:00") {
        setMessage(t("message.theStartTimeCannotBeGreaterThanTheEndTime"));
        return;
      }
      dispatch(
        updateLogtime(
          payload?.id,
          {
            ...resolvedData,
            date: dayjs(data?.date).format("YYYY-MM-DD"),
            description: data?.description,
          },
          "",
          pagination,
          true
        )
      );
    } else {
      if (data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
        if (
          data?.startTime < checkCurrentimeMorning ||
          data?.startTime >= checkCurrentimeAfternoonEnd
        ) {
          if (
            (data?.startTime < checkCurrentimeMorning &&
              data?.endTime > checkCurrentimeMorning) ||
            (data?.startTime < checkCurrentimeMorning &&
              data?.endTime < data?.startTime)
          ) {
            setMessage(t("message.endTimeInvalid"));
            return;
          }
          if (data?.endTime < data?.startTime && data?.endTime !== "00:00:00") {
            setMessage(t("message.theStartTimeCannotBeGreaterThanTheEndTime"));
            return;
          }
          dispatch(
            updateLogtime(
              payload?.id,
              {
                ...resolvedData,
                date: dayjs(data?.date).format("YYYY-MM-DD"),
                description: data?.description,
              },
              "",
              pagination,
              true
            )
          );
        } else setMessage(t("message.startTimeInvalid"));
      }
      if (
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY ||
        data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND
      ) {
        if (data?.endTime < data?.startTime && data?.endTime !== "00:00:00") {
          setMessage(t("message.theStartTimeCannotBeGreaterThanTheEndTime"));
          return;
        }
        dispatch(
          updateLogtime(
            payload?.id,
            {
              ...resolvedData,
              date: dayjs(data?.date).format("YYYY-MM-DD"),
              description: data?.description,
            },
            "",
            pagination,
            true
          )
        );
      }
    }
  };

  const findOverTimeProccessOptions = useMemo(() => {
    const targetProjects =
      projectType === ENUMS.PROJECT_TYPE.EXTERIOR ? projectsExterior : projects;
    const foundProject: any = _.find(
      targetProjects,
      (project) => project.id === watchOverTimeProject
    );

    if (foundProject) {
      const kanbanBoard = foundProject.kanbanBoard || {};
      const tasks = kanbanBoard.task || [];
      return _.map(
        tasks,
        (task: ITaskStructure) =>
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
      );
    }

    return [];
  }, [watchOverTimeProject, projects, projectsExterior, projectType]);

  useEffect(() => {
    if (isUpdateSuccess) {
      onClose();
      reset();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (checkSendOvertime) {
      setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.INTERNAL);
    } else setOverTimeValue("type", ENUMS.LOG_TIME_TYPE.EXTERNAL);
  }, [checkSendOvertime]);

  useEffect(() => {
    if (!_.isEmpty(payload)) {
      if (payload?.kanbanBoard?.project?.id)
        setOverTimeValue("project", payload.kanbanBoard?.project?.id);
      if (payload?.task?.id) setOverTimeValue("task", payload?.task?.id);
      setOverTimeValue("date", payload?.date);
      setOverTimeValue("type", payload?.type);
      setOverTimeValue("timeType", payload?.timeType);
      setOverTimeValue("dateType", payload?.dateType);
      if (payload?.startTime && payload?.endTime) {
        setOverTimeValue("startTime", payload?.startTime);
        setOverTimeValue("endTime", payload?.endTime);
      }

      setOverTimeValue("workingTime", payload?.workingTime);
      setOverTimeValue("description", payload?.description);
    }
  }, [payload, payload?.projectExterior]);

  //  useEffect(() => {
  //    if (watchDate) {
  //      console.log(watchDate);
  //      if (new Date(watchDate).getDay() === 0) {
  //        setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKEND);
  //      } else setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
  //    }
  //  }, [watchDate]);

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <Box>
        {userData?.id === payload?.user?.id ||
        _.includes(userRoles, ENUMS.ROLES.ADMIN) ? (
          <Box>
            <Controller
              name="project"
              control={overTimeControl}
              render={({ field }) => (
                <Select
                  displayChoose
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setOverTimeValue("task", "");
                    dispatch(getBoardById(e.target.value));
                    setMessage("");
                  }}
                  label={`${
                    timeLogPerformAction?.actionType === "logInternal"
                      ? t("label.internalProject")
                      : t("label.externalProject")
                  } `}
                  size="sm"
                  options={
                    timeLogPerformAction?.actionType === "logInternal"
                      ? projectOptions
                      : projectExteriorOptions
                  }
                  isRequired
                  isError={!_.isEmpty(overTimeErrors.project?.message)}
                  errorMessage={overTimeErrors?.project?.message}
                />
              )}
            />
            <Box>
              <Controller
                name="task"
                control={overTimeControl}
                render={({ field }) => (
                  <Select
                    displayChoose
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setMessage("");
                    }}
                    label={t("label.process")}
                    size="sm"
                    options={findOverTimeProccessOptions}
                    isRequired
                    isError={!_.isEmpty(overTimeErrors.task?.message)}
                    errorMessage={overTimeErrors?.task?.message}
                  />
                )}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <TextField
              value={payload?.kanbanBoard?.name}
              label={`${
                timeLogPerformAction?.actionType === "logInternal"
                  ? t("label.internalProject")
                  : t("label.externalProject")
              } `}
              placeholder={`${
                timeLogPerformAction?.actionType === "logInternal"
                  ? t("label.internalProject")
                  : t("label.externalProject")
              } `}
              size="sm"
              disabled
            />
            <TextField
              value={payload?.task?.title}
              label={t("label.process")}
              placeholder={t("label.process")}
              size="sm"
              disabled
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <Controller
          name="date"
          control={overTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label={t("label.date")}
              value={field.value as any}
              onDateChange={(date) => {
                field.onChange(date);
                setMessage("");
              }}
              isError={!_.isEmpty(overTimeErrors.date?.message)}
              errorMessage={overTimeErrors.date?.message}
              isRequired
            />
          )}
        />
        <Controller
          name="dateType"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
                setMessage("");
              }}
              label={t("label.dateType")}
              size="sm"
              options={
                language === "en"
                  ? new Date(watchDate).getDay() === 0
                    ? dateTypeOptionsSunday
                    : new Date(watchDate).getDay() === 6
                    ? dateTypeWeekendOptions
                    : dateTypeOptions
                  : new Date(watchDate).getDay() === 0
                  ? dateTypeOptionsSundayVI
                  : new Date(watchDate).getDay() === 6
                  ? dateTypeWeekendOptionsVI
                  : dateTypeOptionsVI
              }
              isRequired
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <TimePicker
          isRequired
          label={t("label.timeline")}
          defaultValue={defaultTimeLine}
          sx={{
            flex: 1,
          }}
          onTimeChange={(newTime: string[]) => {
            setMessage("");
            setOverTimeValue("startTime", newTime[0]);
            setOverTimeValue("endTime", newTime[1]);
          }}
        />
        <Controller
          name="workingTime"
          control={overTimeControl}
          render={({ field }) => (
            <TextField
              label={t("label.workedHours")}
              value={field.value}
              placeholder="0"
              size="sm"
              isReadOnly
              isError={!_.isEmpty(overTimeErrors.workingTime?.message)}
              errorMessage={overTimeErrors.workingTime?.message}
              moreInfo={t(
                "message.workingHoursWillBeCalculatedBasedOnfilledTimelineValue"
              )}
            />
          )}
        />
      </Box>
      <Box>
        {message && (
          <Text fontSize={"14px"} color={"error"}>
            {message}
          </Text>
        )}
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>

        <Controller
          name="description"
          control={overTimeControl}
          render={({ field }) => (
            <Textarea
              size="sm"
              value={field?.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t("label.description")}
            />
          )}
        />
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.updateRequestOvertime")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
          }}
        >
          <Button ref={cancelRef} onClick={onClose} size={"sm"}>
            {t("button.cancel")}
          </Button>
          <Button
            ml={3}
            //colorScheme="twitter"
            onClick={handleSubmit(onSubmit)}
            size={"sm"}
            isLoading={isActionLoading}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateOvertimeDialog;
