import { Routers, ENUMS } from "@/Constants";

import { Icon } from "@chakra-ui/react";
import {
  HomeIcon,
  UserIcon,
  UserGroupIcon,
  ClipboardDocumentCheckIcon,
  ChatBubbleBottomCenterIcon,
  ListBulletIcon,
  PresentationChartBarIcon,
  ClipboardDocumentListIcon,
  ArrowPathIcon,
  ClockIcon,
  CalendarDaysIcon,
  CalendarIcon,
  Cog6ToothIcon,
  GlobeAltIcon,
  CreditCardIcon,
  UsersIcon,
  GlobeAsiaAustraliaIcon,
  RectangleStackIcon,
  DocumentIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
// import TimeSheetscon from "@/Components/Common/Icon/TimeSheetIcon";

interface IMenu {
  name: string;
  path: string;
  icon?: JSX.Element;
}

const dashboardItem = {
  name: "dashboard",
  path: Routers.DASHBOARD,
  icon: <Icon as={HomeIcon} boxSize={5} />,
};

const userItem = {
  name: "users",
  path: Routers.USER,
  icon: <Icon as={UserIcon} boxSize={5} />,
};

const projectItem = {
  name: "projects",
  path: Routers.PROJECT,
  icon: <Icon as={ListBulletIcon} boxSize={5} />,
};

const projectPerformanceItem = {
  name: "performanceEvaluation",
  path: Routers.PERFORMANCE,
  icon: <Icon as={PresentationChartBarIcon} boxSize={5} />,
};

const boardItem = {
  name: "board",
  path: Routers.BOARD,
  icon: <Icon as={ClipboardDocumentCheckIcon} boxSize={5} />,
};

const clientItem = {
  name: "clients",
  path: Routers.CLIEINT,
  icon: <Icon as={UsersIcon} boxSize={5} />,
};

const timeCardItem = {
  name: "timeCard",
  path: Routers.TIME_CARD,
  icon: <Icon as={CreditCardIcon} boxSize={5} />,
  childs: [
    {
      name: "attendanceSheets",
      path: Routers.TIME_SHEET,
      icon: <Icon as={UserGroupIcon} boxSize={5} />,
    },
    {
      name: "dayOffRequest",
      path: Routers.DAY_OFF_REQUEST,
      icon: <Icon as={CalendarIcon} boxSize={5} />,
    },
    {
      name: "overtime",
      path: Routers.OVER_TIME,
      icon: <Icon as={ClockIcon} boxSize={5} />,
    },
    {
      name: "leaveDays",
      path: Routers.LEAVE_DAY,
      icon: <Icon as={CalendarDaysIcon} boxSize={5} />,
    },
    {
      name: "timeSheets",
      path: Routers.TIME_KEEPPING,
      icon: <Icon as={ClipboardDocumentListIcon} boxSize={5} />,
    },
    {
      name: "requestUpdateTimeCard",
      path: Routers.REQUEST_UPDATE_TIME_CARD,
      icon: <Icon as={ArrowPathIcon} boxSize={5} />,
    },
  ],
};

const chatItem = {
  name: "chat",
  path: Routers.CHAT,
  icon: <Icon as={ChatBubbleBottomCenterIcon} boxSize={5} />,
};

const settingItem = {
  name: "settings",
  path: Routers.SETTING,
  icon: <Icon as={Cog6ToothIcon} boxSize={5} />,
  childs: [
    {
      name: "internalIPAddress",
      path: Routers.INTERNAL_IP,
      icon: <Icon as={GlobeAltIcon} boxSize={5} />,
    },
    {
      name: "myInternalIPAddress",
      path: Routers.GET_MY_INTERNAL_IP,
      icon: <Icon as={GlobeAsiaAustraliaIcon} boxSize={5} />,
    },
  ],
};

const settingItemArtist = {
  name: "settings",
  path: Routers.SETTING,
  icon: <Icon as={Cog6ToothIcon} boxSize={5} />,
  childs: [
    {
      name: "myIPAddress",
      path: Routers.GET_MY_INTERNAL_IP,
      icon: <Icon as={GlobeAsiaAustraliaIcon} boxSize={5} />,
    },
  ],
};

const settingItemAdmin = {
  name: "settings",
  path: Routers.SETTING,
  icon: <Icon as={Cog6ToothIcon} boxSize={5} />,
  childs: [
    {
      name: "internalIPAddress",
      path: Routers.INTERNAL_IP,
      icon: <Icon as={GlobeAltIcon} boxSize={5} />,
    },
    {
      name: "myIPAddress",
      path: Routers.GET_MY_INTERNAL_IP,
      icon: <Icon as={GlobeAsiaAustraliaIcon} boxSize={5} />,
    },
    {
      name: "workingTime",
      path: Routers.WORKING_TIME,
      icon: <Icon as={ClockIcon} boxSize={5} />,
    },
    {
      name: "fileSystem",
      path: Routers.FILE_SYSTEM,
      icon: <Icon as={DocumentIcon} boxSize={5} />,
    },
    {
      name: "backupRestore",
      path: Routers.BACKUP_RESTORE,
      icon: <Icon as={RectangleStackIcon} boxSize={5} />,
    },
    {
      name: "workspace",
      path: Routers.WORKSPACE,
      icon: <Icon as={RectangleGroupIcon} boxSize={5} />,
    },
  ],
};

const ADMIN_SIDEBAR_MENU = [
  dashboardItem,
  userItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  projectPerformanceItem,
  clientItem,
  settingItemAdmin,
];

const MANAGER_SIDEBAR_MENU = [
  dashboardItem,
  userItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  projectPerformanceItem,
  clientItem,
  settingItem,
];

const LEADER_SIDEBAR_MENU = [
  dashboardItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  projectPerformanceItem,
  settingItemArtist,
];

const ARTIST_SIDEBAR_MENU = [
  dashboardItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  settingItemArtist,
];

const HR_SIDEBAR_MENU = [
  dashboardItem,
  userItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  projectPerformanceItem,
  clientItem,
  settingItem,
];

const MKT_SIDEBAR_MENU = [
  dashboardItem,
  projectItem,
  boardItem,
  timeCardItem,
  chatItem,
  settingItemArtist,
];

const MenuOptionsBasedRole: { [key: string]: IMenu[] } = {
  [ENUMS.ROLES.ADMIN]: ADMIN_SIDEBAR_MENU,
  [ENUMS.ROLES.MANAGER]: MANAGER_SIDEBAR_MENU,
  [ENUMS.ROLES.LEADER]: LEADER_SIDEBAR_MENU,
  [ENUMS.ROLES.ARTIST]: ARTIST_SIDEBAR_MENU,
  [ENUMS.ROLES.HUMAN_RESOURCES]: HR_SIDEBAR_MENU,
  [ENUMS.ROLES.MARKETING]: MKT_SIDEBAR_MENU,
};

export default MenuOptionsBasedRole;
