import React, { useRef, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  Icon,
  Box,
  Heading,
  Text,
  CloseButton,
  Avatar,
  IconButton,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Collapse,
  Stack,
  Image,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  CircularProgress,
} from "@chakra-ui/react";

import {
  CalendarIcon,
  EllipsisHorizontalIcon,
  ClockIcon,
  ClipboardIcon,
  PaperClipIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  PencilSquareIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions } from "@/Actions";
import Utils from "@/Utils";
import {
  IBoardStructure,
  IPerformAction,
  ITaskStructure,
} from "@/Interfaces/Board.interface";
import {
  CommentBox,
  UploadFileInput,
  HighlightedText,
  FileItem,
  Button,
} from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";

import { ENUMS } from "@/Constants";
import { useTranslation } from "react-multi-lang";

const {
  updateColumnInBoard,
  createCommentInColumn,
  deleteCommentInColumn,
  resetBoardStatus,
} = BoardActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const TaskDetailDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const currentUserRoles = _.map(
    userData?.userRole,
    (userRole) => userRole?.role?.roleCode
  );

  const isCreateCommentSuccess = useSelector((state: RootState) =>
    _.get(state.BOARD, "isCreateCommentSuccess")
  );
  const isUpdateColumnSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isUpdateColumnSuccess")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );
  const performAction: IPerformAction = useSelector((state: RootState) =>
    _.get(state.BOARD, "performAction")
  );
  const boardDetails: IBoardStructure = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const memberOptions = useMemo(() => {
    const projectMember = _.get(boardDetails, "project.projectMember");
    // Map projectMember array to extract necessary information
    const options = _.map(projectMember, (member) => ({
      id: member?.user?.id,
      display: member?.user?.userData?.fullName,
      avatar: member?.user?.userData?.avatar?.path,
    }));

    // Filter out elements with ids matching userLogged's id
    const filteredOptions = _.filter(
      options,
      (member: any) => member?.user?.id !== userData?.id
    );

    return filteredOptions;
  }, [boardDetails]);

  const taskDetails: ITaskStructure = useMemo(() => {
    return _.first(
      _.filter(boardDetails?.task, (task) => task.id === performAction?.itemId)
    );
  }, [boardDetails, performAction]);

  const sortCommentsByDate = useMemo(
    () =>
      Utils.sortByProperty(taskDetails?.taskComment || [], "createdAt", true),
    [taskDetails]
  );

  const [isShowComments, setIsShowComments] = useState(false);
  const [isEditAttachments, setIsEditAttachments] = useState(false);
  const [isAcceptEditAttachments, setIsAcceptEditAttachments] = useState(false);
  const [isCancelEditAttachments, setIsCancelEditAttachments] = useState(false);
  const [selectedDeleteComment, setSelectedDeleteComment] = useState<
    string | null
  >(null);
  const [files, setFiles] = useState<any>([]);
  const [selectedCopyFile, setSelectedCopyFile] = useState<any>(null);

  const cancelRef = useRef<HTMLDivElement | null>(null);
  const commentRef = useRef<HTMLDivElement | null>(null);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      setIsShowComments(false);
      setIsCancelEditAttachments(false);
      setIsEditAttachments(false);
      setSelectedDeleteComment(null);
    };
  }, []);

  useEffect(() => {
    if (isCancelEditAttachments) setFiles(taskDetails?.fileAttachment);
  }, [isCancelEditAttachments]);

  useEffect(() => {
    if (isCreateCommentSuccess || isUpdateColumnSuccess) {
      dispatch(resetBoardStatus());
      setIsEditAttachments(false);
    }
  }, [isCreateCommentSuccess, isUpdateColumnSuccess]);

  useEffect(() => {
    if (
      !_.isEmpty(sortCommentsByDate) ||
      isShowComments ||
      isCreateCommentSuccess
    ) {
      bottomRef?.current?.scrollTo({
        top: bottomRef?.current.scrollHeight,
        behavior: "smooth",
      });
      commentRef?.current?.scrollTo({
        top: commentRef?.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [
    sortCommentsByDate,
    isShowComments,
    commentRef?.current,
    bottomRef?.current,
  ]);

  useEffect(() => {
    if (taskDetails) setFiles(taskDetails?.fileAttachment);
  }, [taskDetails]);

  useEffect(() => {
    if (selectedCopyFile !== null) {
      const timeoutId = setTimeout(() => {
        setSelectedCopyFile(null);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [selectedCopyFile]);

  const handleCheckPermissionDeleteComment = (
    commentAuthorRoles: string[]
  ): boolean => {
    const isCurrentUserAdmin = currentUserRoles.includes(ENUMS.ROLES.ADMIN);
    const isCurrentUserManager = currentUserRoles.includes(ENUMS.ROLES.MANAGER);
    const isAdminOrManager =
      isCurrentUserAdmin ||
      (isCurrentUserManager &&
        !commentAuthorRoles.includes(ENUMS.ROLES.MANAGER));

    const canDeleteComment = isAdminOrManager;

    const isCommentCreatedByAdminOrManager =
      commentAuthorRoles.includes(ENUMS.ROLES.ADMIN) ||
      commentAuthorRoles.includes(ENUMS.ROLES.MANAGER);

    if (isCommentCreatedByAdminOrManager && !isAdminOrManager) {
      return false;
    }

    return canDeleteComment;
  };

  const findDeletedAndNewFiles = (beforeUpload: any, afterUpload: any) => {
    // Find deleted files
    const deletedFiles = _.filter(
      beforeUpload,
      (beforeFile) =>
        !afterUpload.some((afterFile: any) => afterFile.id === beforeFile.id)
    );

    // Find newly added files
    const newFiles = _.filter(
      afterUpload,
      (afterFile) =>
        !beforeUpload.some((beforeFile: any) => beforeFile.id === afterFile.id)
    );
    return { deletedFiles, newFiles };
  };

  const handleUploadFile = () => {
    const { deletedFiles, newFiles } = findDeletedAndNewFiles(
      taskDetails?.fileAttachment,
      files
    );
    if (!deletedFiles || !newFiles || !isAcceptEditAttachments) return;

    const formData = new FormData();
    if (!_.isEmpty(deletedFiles)) {
      _.forEach(deletedFiles, (file) =>
        formData.append("removeFileAttachments[]", file.id)
      );
    }

    if (newFiles) {
      _.forEach(newFiles, (item) => {
        formData.append("fileAttachment", item.file);
      });
    }
    if (taskDetails?.id)
      dispatch(updateColumnInBoard(taskDetails?.id, formData));
  };

  const handleComment = async (data: any) => {
    if (!_.isEmpty(data)) {
      if (data?.content) {
        const formData = new FormData();
        formData.append("content", data?.content);
        if (!_.isEmpty(data?.tagMember))
          _.forEach(
            data?.tagMember,
            (member) => member?.id && formData.append("tagMember[]", member?.id)
          );
        dispatch(
          createCommentInColumn(taskDetails.id, formData, boardDetails?.id)
        );
      }

      if (data?.fileAttachments) {
        const promises = _.map(
          data?.fileAttachments,
          async (fileAttachment) => {
            const fileFormData = new FormData();
            fileFormData.append("fileAttachment", fileAttachment);
            try {
              const response = await dispatch(
                createCommentInColumn(
                  taskDetails.id,
                  fileFormData,
                  boardDetails?.id
                )
              );
              return response;
            } catch (error) {
              throw error;
            }
          }
        );

        try {
          const promiseAll = await Promise.all(promises);
          if (promiseAll) {
          }
        } catch (error) {}
      }
    }
  };

  const _renderHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Icon boxSize={7} as={CalendarIcon} />
          <Box>
            <Heading size="sm">{taskDetails?.title || "--"}</Heading>
            <Text fontSize="sm">{boardDetails?.project?.name || "--"}</Text>
          </Box>
        </Box>
        <CloseButton onClick={onClose} rounded="full" />
      </Box>
    );
  };

  const _renderMembers = () => {
    return (
      <Box
        sx={{
          display: "none",
          gap: 1,
          mb: 3,
          maxHeight: 300,
        }}
      >
        <Icon boxSize={7} as={UserGroupIcon} />
        <Box>
          <Heading
            size="sm"
            sx={{
              mb: 3,
            }}
          >
            {t("label.members")}
          </Heading>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar size="sm" />
            <Avatar size="sm" />
            <Avatar
              size="sm"
              src="https://static-00.iconduck.com/assets.00/plus-circle-icon-2048x2048-r9uk7t9z.png"
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderTimeline = () => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          mb: 3,
          maxHeight: 300,
        }}
      >
        <Icon boxSize={7} as={ClockIcon} />
        <Box>
          <Heading
            size="sm"
            sx={{
              mb: 3,
            }}
          >
            {t("label.timeline")}
          </Heading>
          <Text fontSize="sm">{`${
            dayjs(taskDetails?.timeLineStart).format("DD/MM/YYYY HH:mm") || "--"
          } - ${
            dayjs(taskDetails?.timeLineEnd).format("DD/MM/YYYY HH:mm") || "--"
          }`}</Text>
        </Box>
      </Box>
    );
  };

  const _renderDescription = () => {
    return (
      <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
        <Icon boxSize={7} as={ClipboardIcon} />
        <Box>
          <Heading
            size="sm"
            sx={{
              mb: 3,
            }}
          >
            {t("label.description")}
          </Heading>
          <Text fontSize="sm">{taskDetails?.description || "--"}</Text>
        </Box>
      </Box>
    );
  };

  const _renderAttachments = () => {
    return (
      <Accordion allowToggle>
        <AccordionItem
          sx={{
            border: "none",
          }}
        >
          <AccordionButton
            sx={{
              p: 0,
              bg: "none",
              border: "none",
            }}
          >
            <Icon boxSize={7} as={PaperClipIcon} />
            <Box as="span" flex="1" textAlign="left">
              <Heading size="sm">
                {t("label.attachments")} ({_.size(taskDetails?.fileAttachment)})
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel mt={3} p={0}>
            <HStack
              sx={{
                justifyContent: "space-between",
              }}
            >
              {_.isEmpty(taskDetails?.fileAttachment) && !isEditAttachments && (
                <Text fontSize="sm">--</Text>
              )}
              {!isEditAttachments && (
                <Tooltip title={t("tooltip.edit")}>
                  <IconButton
                    sx={{
                      background: "none",
                      ":hover": { background: "none" },
                      ml: "auto",
                    }}
                    size="xs"
                    icon={<PencilSquareIcon />}
                    aria-label={""}
                    onClick={() => setIsEditAttachments(true)}
                  />
                </Tooltip>
              )}
            </HStack>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <UploadFileInput
                disabled={isActionLoading}
                onErrors={(valid) => setIsAcceptEditAttachments(valid)}
                maxFiles={10}
                accept="image/*, video/*, application/pdf, .doc, .docx, .xls, .xlsx, .csv, .tsv, .ppt, .pptx"
                files={taskDetails?.fileAttachment}
                isEditing={isEditAttachments}
                isCancel={isCancelEditAttachments}
                sx={{ mb: 3 }}
                onFilesChange={(files) => {
                  setFiles(files);
                }}
              />
              <Collapse in={isEditAttachments} animateOpacity>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    size="sm"
                    onClick={() => {
                      setIsEditAttachments(false);
                      setIsCancelEditAttachments(!isCancelEditAttachments);
                    }}
                    children={t("button.cancel")}
                  />
                  <Button
                    size="sm"
                    //colorScheme="messenger"
                    onClick={() => handleUploadFile()}
                    isDisabled={!isAcceptEditAttachments}
                    children={t("button.save")}
                    sx={{
                      color: "#fff",
                      background: "#5c6e6c",
                      minW: 65,
                      "&:hover": {
                        background: "#a6b7af!important",
                      },
                    }}
                  />
                </Box>
              </Collapse>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const _renderMoreOptions = (commentId: string) => (
    <Popover placement="left-end">
      <PopoverTrigger>
        <IconButton
          isRound
          size="sm"
          icon={<EllipsisHorizontalIcon />}
          aria-label={""}
          sx={{
            bg: "none",
          }}
        />
      </PopoverTrigger>
      <PopoverContent
        sx={{
          maxW: "max-content",
        }}
      >
        <Button
          size="sm"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSelectedDeleteComment(commentId);
          }}
          colorScheme="red"
          variant="outline"
          children={t("button.delete")}
        />
      </PopoverContent>
    </Popover>
  );

  const _renderListComments = () => {
    return !_.isEmpty(sortCommentsByDate) ? (
      _.map(sortCommentsByDate, (taskComment, index) => {
        const allowedDeteteComment = handleCheckPermissionDeleteComment(
          _.map(
            taskComment?.userCreated?.userRole,
            (userRole) => userRole?.role?.roleCode
          )
        );

        return (
          <Box key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Avatar
                  size="sm"
                  name={taskComment?.userCreated?.userData?.fullName}
                  src={taskComment?.userCreated?.userData?.avatar?.path}
                />
                <Box>
                  <Heading fontSize="sm">
                    {taskComment?.userCreated?.userData?.fullName}
                  </Heading>
                  <Tooltip
                    label={dayjs(taskComment?.updatedAt).format(
                      "YYYY-MM-DD, HH:mm:ss"
                    )}
                    hasArrow
                  >
                    <Text fontSize="xs" cursor="pointer">
                      {Utils.calculateTimeAgo(taskComment?.createdAt)}
                    </Text>
                  </Tooltip>
                </Box>
              </Box>
              {(userData?.id === taskComment?.userCreated?.id ||
                allowedDeteteComment) &&
                _renderMoreOptions(taskComment?.id)}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mt: 1,
                whiteSpace: "pre-line",
              }}
            >
              {Utils.isJsonString(taskComment?.content) ? (
                <Box>
                  <Image
                    src={
                      JSON.parse(taskComment?.content)?.images?.fixed_height
                        ?.url
                    }
                    sx={{
                      rounded: 5,
                      minW: JSON.parse(taskComment?.content)?.images
                        ?.fixed_height?.width,
                      minH: JSON.parse(taskComment?.content)?.images
                        ?.fixed_height?.height,
                    }}
                  />
                </Box>
              ) : (
                <HighlightedText
                  textContent={taskComment?.content}
                  users={[
                    {
                      id: "everyone",
                      display: "Everyone",
                      avatar:
                        "https://cdn-icons-png.flaticon.com/512/69/69589.png",
                    },
                    ...memberOptions,
                  ]}
                />
              )}
              {!_.isEmpty(taskComment?.fileAttachment) && (
                <FileItem
                  files={_.map(
                    taskComment?.fileAttachment,
                    (fileAttachment) => {
                      const fileType = Utils.getMimeTypeFromFile(
                        fileAttachment?.file?.nameOriginal
                      );
                      return {
                        id: fileAttachment?.id,
                        name: fileAttachment?.file?.nameOriginal,
                        type: fileType,
                        imageUrl: _.includes(fileType, "image")
                          ? fileAttachment?.file?.path
                          : "",
                        videoUrl: _.includes(fileType, "video")
                          ? fileAttachment?.file?.path
                          : "",
                        downloadUrl: fileAttachment?.file?.path,
                      };
                    }
                  )}
                />
              )}
            </Box>
          </Box>
        );
      })
    ) : (
      <Text fontSize="sm">{t("message.thereAreNoCommentsYet")}</Text>
    );
  };

  const _renderComments = () => {
    return (
      <Accordion allowToggle>
        <AccordionItem
          sx={{
            border: "none",
            ".chakra-collapse": {
              overflow: "visible!important",
            },
          }}
        >
          {(state) => {
            setIsShowComments(state?.isExpanded);
            return (
              <>
                <AccordionButton
                  sx={{
                    p: 0,
                    bg: "none",
                    border: "none",
                  }}
                >
                  <Icon boxSize={7} as={ChatBubbleOvalLeftEllipsisIcon} />
                  <Box>
                    <Heading size="sm">
                      {t("label.comments")} ({_.size(taskDetails?.taskComment)})
                    </Heading>
                  </Box>
                  <Box sx={{ ml: "auto", display: "flex" }}>
                    <AccordionIcon />
                  </Box>
                </AccordionButton>
                <AccordionPanel mt={3} p={0}>
                  <Collapse in={true} animateOpacity>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 3,
                        mb: 3,
                        maxHeight: 250,
                        overflowY: "auto",
                        position: "relative",
                      }}
                      ref={commentRef}
                    >
                      {isActionLoading ? (
                        <CircularProgress size="50px" isIndeterminate />
                      ) : (
                        <>{_renderListComments()}</>
                      )}
                    </Stack>
                    <CommentBox
                      payload={[
                        {
                          id: "everyone",
                          display: "Everyone",
                          avatar:
                            "https://cdn-icons-png.flaticon.com/512/69/69589.png",
                        },
                        ...memberOptions,
                      ]}
                      isShowBottomBar
                      onSubmit={(data) => handleComment(data)}
                      isClear={isCreateCommentSuccess}
                      onGifSelect={(gif) => {
                        const formData = new FormData();
                        formData.append("content", JSON.stringify(gif));
                        dispatch(
                          createCommentInColumn(
                            taskDetails.id,
                            formData,
                            boardDetails?.id
                          )
                        );
                      }}
                    />
                  </Collapse>
                </AccordionPanel>
              </>
            );
          }}
        </AccordionItem>
      </Accordion>
    );
  };

  const _renderContent = () => (
    <>
      {_renderMembers()}
      {_renderTimeline()}
      {_renderDescription()}
      {_renderAttachments()}
      {_renderComments()}
    </>
  );

  const _renderPopup = () => (
    <ConfirmDialog
      body={t("message.areYouSureYouWantToDeleteThisComment")}
      actionType="delete"
      isOpen={!!selectedDeleteComment}
      onClose={() => setSelectedDeleteComment(null)}
      onAction={() => {
        if (selectedDeleteComment)
          dispatch(
            deleteCommentInColumn(selectedDeleteComment, boardDetails?.id)
          );
        setSelectedDeleteComment("");
      }}
    />
  );

  return (
    <AlertDialog
      isOpen={open && performAction.actionType === "view"}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="6xl"
      motionPreset="slideInBottom"
      closeOnEsc={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent ref={bottomRef}>
        <AlertDialogHeader
          sx={{
            p: 3,
            position: "sticky",
            zIndex: 10000,
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {_renderHeader()}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflow: "auto",
            zIndex: 9999,
            p: 3,
            cursor: "default",
          }}
        >
          {_renderContent()}
          {_renderPopup()}
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default TaskDetailDialog;
