import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Textarea,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Button,
  Checkbox,
  Switch,
} from "@chakra-ui/react";

import { HolidayActions, LogtimeActions, WorkingTimeActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { ENUMS, Routers } from "@/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DatePicker,
  TimePicker,
  TextField,
  Select,
  DateTimeRangePicker,
} from "@/Components/Common";
import { ConfirmDialog } from "@/Components/Popup";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import Utils from "@/Utils";
import { ITaskStructure } from "@/Interfaces/Board.interface";
import { LOG_OVER_TIME_STATUS_COLOR } from "@/Constants/Enums.contant";
import TextEditor from "@/Components/Common/TextEditor";
import TextareaComponent from "@/Components/Common/Textarea";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";

const {
  getByIdLogTime,
  updateLogtime,
  resetLogTimeDetail,
  deleteLogTime,
  deleteLogTimeInMyTime,
  deleteLogTimeInDashboard,
} = LogtimeActions;
const { fetchWorkingTime } = WorkingTimeActions;
const { fetchHoliday } = HolidayActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_WORK_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME,
  date: "",
  timeline: ["", ""],
  workingTime: 0,
  description: "",
  user: "",
  isDone: false,
  note: "",
};

const DEFAULT_OVER_TIME = {
  project: "",
  task: "",
  type: ENUMS.LOG_TIME_TYPE.INTERNAL,
  timeType: ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME,
  workingTime: 0,
  startTime: "",
  endTime: "",
  date: new Date(),
  dateType: ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY,
  description: "",
  user: "",
  isDone: false,
  note: "",
};

const dateTypeOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsSunday = [
  { label: "Sunday", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeWeekendOptions = [
  { label: "Weekday", value: "weekday" },
  { label: "Weekend", value: "weekend" },
  { label: "Holiday", value: "holiday" },
];

const dateTypeOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeWeekendOptionsVI = [
  { label: "Ngày trong tuần", value: "weekday" },
  { label: "Ngày cuối tuần", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const dateTypeOptionsSundayVI = [
  { label: "Ngày chủ nhật", value: "weekend" },
  { label: "Ngày lễ", value: "holiday" },
];

const UpdateTimelogDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const workTimeSchema = yup
    .object()
    .shape({
      project: yup.string().trim().required(t("message.projectIsRequired")),
      task: yup.string().required(t("message.processIsRequired")),
      date: yup.string().notRequired(),
      timeline: yup
        .array()
        .of(yup.string().trim().required(t("message.timelineIsRequired")))
        .test(
          t("message.timeline-valid"),
          t("message.startTimeMustBeBeforeEndTime"),
          function (value) {
            const [startTime, endTime] = value as [string, string];

            if (!startTime || !endTime) {
              return true; // Allow null values, as they are handled by the individual date validations
            }

            // Check if start and end dates are the same
            const sameDate = dayjs(startTime).isSame(dayjs(endTime), "day");

            // If the dates are the same and the start time is 12:00 PM or later, return false (invalid)

            if (sameDate) {
              const formatStartTime = dayjs(startTime).format("HH");
              const formatEndTime = dayjs(endTime).format("HH");
              if (
                _.toNumber(formatEndTime) >= 12 &&
                _.toNumber(formatStartTime) === 12
              )
                return false;
            }

            // Otherwise, compare UTC Date objects
            return dayjs(startTime).isBefore(dayjs(endTime));
          }
        ),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeaValidNumber")),
      description: yup.string().notRequired(),
      type: yup.string().notRequired(),
      note: yup.string().notRequired(),
      user: yup.string().required(t("message.assigneeIsRequired")),
      isDone: yup.boolean().default(false),
    })
    .required();

  const overTimeSchema = yup
    .object()
    .shape({
      project: yup.string().trim().required(t("message.projectIsRequired")),
      task: yup.string().required(t("message.processIsRequired")),
      date: yup.date().required(t("message.dateIsRequired")),
      timeType: yup.string().trim().required(t("message.timeTypeIsRequired")),
      dateType: yup.string().trim().required(t("message.dateTypeIsRequired")),
      startTime: yup.string().trim().notRequired(),
      endTime: yup.string().trim().notRequired(),
      workingTime: yup
        .number()
        .transform((originalValue) => {
          const parsedValue = parseFloat(originalValue);
          return isNaN(parsedValue) ? undefined : parsedValue;
        })
        .moreThan(0, t("message.workedHoursMustBeGreaterThan"))
        .required(t("message.workedHoursIsRequired"))
        .typeError(t("message.workedHoursMustBeAvalidNumber"))
        .max(24, t("message.workedHoursCannotExceed")),
      description: yup.string().notRequired(),
      type: yup.string().notRequired(),
      note: yup.string().notRequired(),
      user: yup.string().required(t("message.assigneeIsRequired")),
      isDone: yup.boolean().default(false),
    })
    .required();

  type IWorkTime = yup.InferType<typeof workTimeSchema>;
  //  type IOverTime = yup.InferType<typeof overTimeSchema>;

  const {
    control: workTimeControl,
    handleSubmit: handleWorkTimeSubmit,
    formState: { errors: workTimeErrors },
    setValue: setWorkTimeValue,
    reset: resetWorkTime,
    watch: watchWorkTime,
  } = useForm({
    resolver: yupResolver(workTimeSchema),
    defaultValues: DEFAULT_WORK_TIME,
  });

  const {
    control: overTimeControl,
    handleSubmit: handleOverTimeSubmit,
    formState: { errors: overTimeErrors },
    setValue: setOverTimeValue,
    clearErrors: clearOverTimeErrors,
    reset: resetOverTime,
    watch: watchOverTime,
    setError,
  } = useForm({
    resolver: yupResolver(overTimeSchema),
    defaultValues: DEFAULT_OVER_TIME,
  });

  const watchOverStartTime = watchOverTime("startTime");
  const watchOverEndTime = watchOverTime("endTime");
  const watchWorkTimeProject = watchWorkTime("project") || "";
  const watchOverTimeProject = watchOverTime("project") || "";
  const watchWorkTimeWorkingTime = watchWorkTime("workingTime");
  const watchOverTimeWorkingTime = watchOverTime("workingTime");

  const watchDate = watchOverTime("date") || "";
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );
  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  // const workingTimeOffice = useMemo(() => {
  //   const firstItem = workingTimeList[0];
  //   return {
  //     morningStart: Number(firstItem?.morningStart || 8),
  //     morningEnd: Number(firstItem?.morningEnd || 12),
  //     afternoonStart: Number(firstItem?.afternoonStart || 13.5),
  //     afternoonEnd: Number(firstItem?.afternoonEnd || 17.5),
  //   };
  // }, [workingTimeList]);

  const ishasArtistRole = useMemo(
    () =>
      _.includes(userRoles, ENUMS.ROLES.ARTIST) &&
      !_.includes(userRoles, ENUMS.ROLES.LEADER) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER),
    [userData]
  );

  const projects: any = useSelector((state: RootState) =>
    _.get(state.PROJECT, "payload")
  );

  const projectsExterior: any = useSelector((state: RootState) =>
    _.get(state.PROJECT_EXTERIOR, "payload")
  );

  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );

  const logDetails: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "logtimeDetail")
  );
  const myTimePagination = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "myTimePagination")
  );

  const defaultTimeLine = useMemo(
    () => Utils.extractTimeValues(logDetails?.startTime, logDetails?.endTime),
    [logDetails]
  );

  const isUpdateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isActionLoading")
  );

  const performAction: ITimeLogPerformAction = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "performAction")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const checkCurrentimeMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const checkCurrentimeAfternoonEnd =
    workingTimeList[0]?.afternoonEnd &&
    Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

  const projectOptions = useMemo(
    () =>
      _.map(projects, (project) => ({
        label: `${project?.name} - ${project?.code}`,
        value: project?.id,
      })),
    [projects]
  );

  const projectExteriorOptions = useMemo(
    () =>
      _.map(projectsExterior, (project) => ({
        label: `${project?.name}-${project?.code}`,
        value: project?.id,
      })),
    [projectsExterior]
  );

  const projectType = useMemo(
    () => logDetails?.kanbanBoard?.project?.type,
    [logDetails]
  );

  const findWorkTimeProccessOptions = useMemo(() => {
    if (_.isEmpty(boardDetails)) {
      const targetProjects =
        projectType === ENUMS.PROJECT_TYPE.EXTERIOR
          ? projectsExterior
          : projects;
      const foundProject: any = _.find(
        targetProjects,
        (project) => project.id === watchWorkTimeProject
      );

      if (foundProject) {
        const kanbanBoard = foundProject.kanbanBoard || {};
        const tasks = kanbanBoard.task || [];
        return _.map(
          tasks,
          (task: ITaskStructure) =>
            task.status === "active" && {
              value: task.id,
              label: task.title,
            }
        );
      }
    } else {
      return _.map(
        boardDetails?.task,
        (task: ITaskStructure) =>
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
      );
    }
    return [];
  }, [
    watchWorkTimeProject,
    projects,
    projectsExterior,
    projectType,
    boardDetails,
  ]);

  const findOverTimeProccessOptions = useMemo(() => {
    if (_.isEmpty(boardDetails)) {
      const targetProjects =
        projectType === ENUMS.PROJECT_TYPE.EXTERIOR
          ? projectsExterior
          : projects;
      const foundProject: any = _.find(
        targetProjects,
        (project) => project.id === watchOverTimeProject
      );

      if (foundProject) {
        const kanbanBoard = foundProject.kanbanBoard || {};
        const tasks = kanbanBoard.task || [];
        return tasks.map(
          (task: ITaskStructure) =>
            task.status === "active" && {
              value: task.id,
              label: task.title,
            }
        );
      }
    } else {
      return _.map(
        boardDetails?.task,
        (task: ITaskStructure) =>
          task.status === "active" && {
            value: task.id,
            label: task.title,
          }
      );
    }

    return [];
  }, [
    watchOverTimeProject,
    projects,
    projectsExterior,
    projectType,
    boardDetails,
  ]);

  const userOptions = useMemo(() => {
    const hasLeaderRole =
      _.includes(userRoles, ENUMS.ROLES.LEADER) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER);
    const hasManagerRole = _.includes(userRoles, ENUMS.ROLES.MANAGER);
    const projectMembers = boardDetails?.project?.projectMember;

    const managers = _.filter(projectMembers, (projectMember) => {
      const userRoles = projectMember?.user?.userRole || [];
      return _.some(
        userRoles,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );
    });

    const leaders = _.filter(projectMembers, (projectMember) => {
      const userRoles = projectMember?.user?.userRole || [];
      return _.some(
        userRoles,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.LEADER
      );
    });

    const acceptUsers = _.map(boardDetails?.project?.projectMember, (user) => {
      const allowedRoles = [
        ENUMS.ROLES.ARTIST,
        ENUMS.ROLES.LEADER,
        ENUMS.ROLES.MANAGER,
      ];
      // Check if the user has any of the allowed roles
      const hasAllowedRole = _.some(user?.user?.userRole, (userRole) =>
        allowedRoles.includes(userRole?.role?.roleCode)
      );
      const userHasManagerRole = _.find(
        user?.user?.userRole,
        (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
      );

      if (hasLeaderRole && !hasManagerRole && userHasManagerRole) {
        return null;
      }
      // If the user has at least one allowed role, include them in the result
      if (hasAllowedRole) {
        // return {
        //   id: user.id,
        //   avatar: user?.userData?.avatar?.path,
        //   name: user?.userData?.fullName,
        //   email: user?.email,
        //   roles: user?.userRole,
        //   user,
        // };
        return {
          label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
          value: user?.user.id,
        };
      }
      // If the user doesn't have any allowed roles, exclude them
      return null;
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    if (hasManagerRole) {
      const removeUser = _.filter(projectMembers, (member) => {
        return !managers.find((mnMember) => mnMember === member);
      });
      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    if (hasLeaderRole) {
      const removeUser = _.filter(projectMembers, (member) => {
        return !managers.includes(member) && !leaders.includes(member);
      });

      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    return acceptUsers;
  }, [boardDetails, userRoles, userData]);

  const userOptionsExterior = useMemo(() => {
    const hasMktRole =
      _.includes(userRoles, ENUMS.ROLES.MARKETING) &&
      (!_.includes(userRoles, ENUMS.ROLES.MANAGER) ||
        !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES));
    const hasLeaderRole =
      _.includes(userRoles, ENUMS.ROLES.LEADER) &&
      (!_.includes(userRoles, ENUMS.ROLES.MANAGER) ||
        !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES));
    const hasManagerRole =
      _.includes(userRoles, ENUMS.ROLES.MANAGER) &&
      !_.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES);
    const hasHrRole =
      _.includes(userRoles, ENUMS.ROLES.HUMAN_RESOURCES) &&
      !_.includes(userRoles, ENUMS.ROLES.MANAGER);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      const acceptUsers = _.map(
        boardDetails?.project?.projectMember,
        (user) => {
          return {
            label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
            value: user?.user.id,
          };
        }
      );
      acceptUsers.unshift({
        label: `${userData?.userData?.fullName} - ${userData?.email}`,
        value: userData?.id,
      });
      return acceptUsers;
    }
    const projectMembers = boardDetails?.project?.projectMember;

    if (hasManagerRole || hasHrRole) {
      const managers = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.MANAGER
        );
      });
      const hr = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.HUMAN_RESOURCES
        );
      });
      const removeUser = _.filter(projectMembers, (member) => {
        return (
          !managers.find((mnMember) => mnMember === member) &&
          !hr.find((hrMember) => hrMember === member)
        );
      });
      return [
        ..._.map(removeUser, (userInfo) => ({
          label: `${userInfo?.user?.userData?.fullName} - ${userInfo?.user?.email}`,
          value: userInfo?.user?.id,
        })),
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
      ];
    }

    if (hasMktRole) {
      const artists = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        );
      });

      return [
        {
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        },
        ..._.map(artists, (artist) => ({
          label: `${artist?.user?.userData?.fullName} - ${artist?.user?.email}`,
          value: artist?.user?.id,
        })),
      ];
    }

    if (hasLeaderRole) {
      const artists = _.filter(projectMembers, (projectMember) => {
        const userRoles = projectMember?.user?.userRole || [];
        return _.some(
          userRoles,
          (userRole) => userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
        );
      });

      const result: any[] = [];
      _.forEach(artists, (artist) => {
        result.push({
          label: `${artist?.user?.userData?.fullName} - ${artist?.user?.email}`,
          value: artist?.user?.id,
        });
      });
      if (!_.some(artists, (artist) => artist?.user?.id === userData?.id)) {
        result.unshift({
          label: `${userData?.userData?.fullName} - ${userData?.email}`,
          value: userData?.id,
        });
      }
      return result;
    }

    const acceptUsers = _.map(boardDetails?.project?.projectMember, (user) => {
      return {
        label: `${user?.user?.userData?.fullName} - ${user?.user?.email}`,
        value: user?.user.id,
      };
    }).filter(Boolean);

    if (_.includes(userRoles, ENUMS.ROLES.ADMIN)) {
      acceptUsers.unshift({
        label: userData?.userData?.fullName,
        value: userData?.id,
      });
    }

    return acceptUsers;
  }, [boardDetails, userRoles, userData]);

  // useEffect(() => {
  //   if (watchDate) {
  //     if (new Date(watchDate).getDay() === 0) {
  //       setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKEND);
  //     } else setOverTimeValue("dateType", ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY);
  //   }
  // }, [watchDate]);

  const timeType = _.get(logDetails, "timeType");

  const [tabIndex, setTabIndex] = useState(0);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [validTimeRangeMsg, setValidTimeRangeMsg] = useState<string>("");
  const [isShowDeleteLog, setIsShowDeleteLog] = useState(false);
  useState<string>("");

  const cancelRef = useRef<any>(null);
  const workTimeSubmitButtonRef = useRef<any>(null);
  const overTimeSubmitButtonRef = useRef<any>(null);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (_.isEmpty(workingTimeList)) dispatch(fetchWorkingTime());
  }, []);
  useEffect(() => {
    if (!open && !_.isEmpty(logDetails)) {
      dispatch(resetLogTimeDetail());
      onReset();
    }
  }, [open, logDetails]);

  useEffect(() => {
    if (performAction?.actionType === "editLog") {
      dispatch(getByIdLogTime(performAction.id));
    }
  }, [performAction]);

  useEffect(() => {
    if (!_.isEmpty(logDetails)) {
      const timeType = _.get(logDetails, "timeType");
      if (timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
        setWorkTimeValue("project", logDetails.kanbanBoard?.project?.id);
        setWorkTimeValue("task", logDetails?.task?.id);
        setWorkTimeValue("date", logDetails.date);
        setWorkTimeValue("type", logDetails?.type);
        setWorkTimeValue("workingTime", logDetails?.workingTime);
        setWorkTimeValue("description", logDetails?.description);
        setWorkTimeValue("note", logDetails?.note);
        setWorkTimeValue("user", logDetails?.user?.id);
        setWorkTimeValue("isDone", logDetails?.isDone || false);

        if (
          logDetails?.workTimeStartDate &&
          logDetails?.workTimeStartTime &&
          logDetails?.workTimeEndDate &&
          logDetails?.workTimeEndTime
        ) {
          const startDate = new Date(
            logDetails?.workTimeStartDate + "T" + logDetails?.workTimeStartTime
          ).toISOString();
          const endDate = new Date(
            logDetails?.workTimeEndDate + "T" + logDetails?.workTimeEndTime
          ).toISOString();
          setWorkTimeValue("timeline", [startDate, endDate]);
        }
      }
      if (timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME) {
        setOverTimeValue("project", logDetails.kanbanBoard?.project?.id);
        setOverTimeValue("task", logDetails?.task?.id);
        setOverTimeValue("date", logDetails.date);
        setOverTimeValue("type", logDetails?.type);
        setOverTimeValue("timeType", logDetails?.timeType);
        setOverTimeValue("dateType", logDetails?.dateType);
        if (logDetails?.startTime && logDetails?.endTime) {
          setOverTimeValue("startTime", logDetails?.startTime);
          setOverTimeValue("endTime", logDetails?.endTime);
        }

        setOverTimeValue("workingTime", logDetails?.workingTime);
        setOverTimeValue("description", logDetails?.description);
        setOverTimeValue("note", logDetails?.note);
        setOverTimeValue("user", logDetails?.user?.id);
        setOverTimeValue("isDone", logDetails?.isDone || false);
      }
      setTabIndex(timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME ? 1 : 0);
    }
  }, [logDetails]);

  useEffect(() => {
    if (isUpdateSuccess) onReset();
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (watchOverStartTime || watchOverEndTime) {
      if (watchOverStartTime && watchOverEndTime) {
        const newValue = Utils.calculateWorkingTime(
          watchOverStartTime,
          watchOverEndTime
        );
        setOverTimeValue("workingTime", newValue);
        if (newValue > 0) clearOverTimeErrors();
      }
    }
  }, [watchOverStartTime, watchOverEndTime]);

  const onReset = () => {
    resetWorkTime();
    resetOverTime();
    onClose();
    setValidTimeRangeMsg("");
    setIsShowConfirm(false);
    setIsShowDeleteLog(false);
  };

  const handleCombinedSubmit = () => {
    if (timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME)
      workTimeSubmitButtonRef?.current?.click();
    if (timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME)
      overTimeSubmitButtonRef?.current?.click();
  };

  const onWorkTimeSubmit = (data: IWorkTime) => {
    const formatTime = (date: Date) => {
      let hours: any = date.getHours();
      let minutes: any = date.getMinutes();
      let seconds: any = date.getSeconds();
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return `${hours}:${minutes}:${seconds}`;
    };
    const { timeline } = data;
    const workTimeStartDate = dayjs(timeline?.[0]).format("YYYY-MM-DD");
    const workTimeEndDate = dayjs(timeline?.[1]).format("YYYY-MM-DD");
    const workTimeStartTime = formatTime(new Date(timeline?.[0] as any));
    const workTimeEndTime = formatTime(new Date(timeline?.[1] as any));
    // const isValid = Utils.isValidTimeRange(
    //   timeline?.[0] || "",
    //   timeline?.[1] || "",
    //   workingTimeOffice
    // );

    if (
      _.isEmpty(workTimeErrors) &&
      data.workingTime > 0 &&
      // isValid &&
      !validTimeRangeMsg
    ) {
      const resolvedData = Utils.removeEmptyFields({
        ...data,
        date: workTimeStartDate,
        workTimeStartDate,
        workTimeEndDate,
        workTimeStartTime,
        workTimeEndTime,
      });
      dispatch(
        updateLogtime(
          logDetails?.id,
          {
            ...resolvedData,
            description: data?.description,
            note: data?.note,
            workingTime: watchWorkTimeWorkingTime,
          },
          boardDetails?.id || ""
        )
      );
    }
    // else {
    //   if (!isValid) {
    //     setValidTimeRangeMsg("Timeline invalid");
    //   }
    // }
  };

  const onOverTimeSubmit = (data: any) => {
    const resolvedData = Utils.removeEmptyFields({
      ...data,
      date: dayjs(data?.date).format("YYYY-MM-DD"),
      workingTime: watchOverTimeWorkingTime,
    });

    const dateObject = new Date(data?.date);
    const dayOfWeek = dateObject.getDay();
    if (dayOfWeek === 6 && data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
      if (data?.endTime < data?.startTime && data?.endTime !== "00:00:00") {
        setError("endTime", {
          type: "endTime",
          message: t("message.theStartTimeCannotBeGreaterThanTheEndTime"),
        });
        return;
      }
      if (_.isEmpty(workTimeErrors))
        dispatch(
          updateLogtime(logDetails?.id, resolvedData, boardDetails?.id || "")
        );
    } else {
      if (_.isEmpty(overTimeErrors) && data.workingTime > 0) {
        if (data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY) {
          if (
            data?.startTime < checkCurrentimeMorning ||
            data?.startTime >= checkCurrentimeAfternoonEnd
          ) {
            if (
              (data?.startTime < checkCurrentimeMorning &&
                data?.endTime > checkCurrentimeMorning) ||
              (data?.startTime < checkCurrentimeMorning &&
                data?.endTime < data?.startTime)
            ) {
              setError("endTime", {
                type: "endTime",
                message: t("message.endTimeInvalid"),
              });
              return;
            }
            if (
              data?.endTime < data?.startTime &&
              data?.endTime !== "00:00:00"
            ) {
              setError("endTime", {
                type: "endTime",
                message: t("message.theStartTimeCannotBeGreaterThanTheEndTime"),
              });
              return;
            }
            if (_.isEmpty(workTimeErrors))
              dispatch(
                updateLogtime(
                  logDetails?.id,
                  {
                    ...resolvedData,
                    description: data?.description,
                  },
                  boardDetails?.id || ""
                )
              );
            else {
              setIsShowConfirm(true);
            }
          } else
            setError("endTime", {
              type: "endTime",
              message: t("message.endTimeInvalid"),
            });
        }
        if (
          data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY ||
          data?.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND
        ) {
          if (data?.endTime < data?.startTime && data?.endTime !== "00:00:00") {
            setError("endTime", {
              type: "endTime",
              message: t("message.theStartTimeCannotBeGreaterThanTheEndTime"),
            });
            return;
          }
          if (_.isEmpty(workTimeErrors))
            dispatch(
              updateLogtime(
                logDetails?.id,
                resolvedData,
                boardDetails?.id || ""
              )
            );
          else {
            setIsShowConfirm(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (_.isEmpty(holidaysList)) {
      dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    }
  }, []);

  const _renderWorktime = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {boardDetails?.id ? (
        <TextField
          label={`${
            projectType === ENUMS.PROJECT_TYPE.INTERIOR
              ? t("label.internalProject")
              : t("label.externalProject")
          } `}
          placeholder={`${
            projectType === ENUMS.PROJECT_TYPE.INTERIOR
              ? t("label.internalProject")
              : t("label.externalProject")
          } `}
          value={`${boardDetails?.project?.name} - ${boardDetails?.project?.code}`}
          size="sm"
          isReadOnly
        />
      ) : (
        <Controller
          name="project"
          control={workTimeControl}
          render={({ field }) => (
            <Select
              displayChoose
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e.target.value);
                setWorkTimeValue("task", "");
              }}
              label={`${
                projectType === ENUMS.PROJECT_TYPE.INTERIOR
                  ? t("label.internalProject")
                  : t("label.externalProject")
              } `}
              size="sm"
              options={
                projectType === ENUMS.PROJECT_TYPE.INTERIOR
                  ? projectOptions
                  : projectExteriorOptions
              }
              isRequired
              isError={!_.isEmpty(workTimeErrors.project?.message)}
              errorMessage={workTimeErrors?.project?.message}
            />
          )}
        />
      )}
      <Controller
        name="task"
        control={workTimeControl}
        render={({ field }) => (
          <Select
            displayChoose
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            label={t("label.process")}
            size="sm"
            options={findWorkTimeProccessOptions}
            isRequired
            isError={!_.isEmpty(workTimeErrors.task?.message)}
            errorMessage={workTimeErrors?.task?.message}
          />
        )}
      />
      <Controller
        name="timeline"
        control={workTimeControl}
        render={({ field }) => (
          <DateTimeRangePicker
            isRequired
            label={t("label.timeline")}
            onDateChange={(newDate) => {
              field.onChange(newDate);
              // const isValid = Utils.isValidTimeRange(
              //   newDate[0],
              //   newDate[1],
              //   workingTimeOffice
              // );

              // const calculateWorkingTime = Utils.calculateHourDifference(
              //   newDate[0],
              //   newDate[1],
              //   workingTimeOffice,
              //   holidaysList
              // );
              // if (isValid)
              //   setWorkTimeValue("workingTime", calculateWorkingTime);
              // setValidTimeRangeMsg(isValid ? "" : "Timeline invalid");
            }}
            value={field.value}
            isError={
              !_.isEmpty(workTimeErrors.timeline) ||
              !_.isEmpty(validTimeRangeMsg)
            }
            errorMessage={
              workTimeErrors.timeline?.message ||
              workTimeErrors.timeline?.[0]?.message ||
              validTimeRangeMsg
            }
          />
        )}
      />
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        {/* <Controller
          name="date"
          control={workTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label="Date"
              value={field.value as any}
              onDateChange={(date) => field.onChange(date)}
              isError={!_.isEmpty(workTimeErrors.date?.message)}
              errorMessage={workTimeErrors.date?.message}
              isRequired
              disabledTitle
            />
          )}
        /> */}
        <Controller
          name="workingTime"
          control={workTimeControl}
          render={({ field }) => (
            <TextField
              sx={{
                flex: 1,
              }}
              label={t("label.workedHours")}
              placeholder={t("label.workedHours")}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              // moreInfo="Working hours will be calculated based on
              // filled timeline value"
              isError={!_.isEmpty(workTimeErrors.workingTime?.message)}
              errorMessage={workTimeErrors.workingTime?.message}
              size="sm"
              // isReadOnly
              isRequired
            />
          )}
        />
      </Box>
      <Box>
        {pathname !== Routers.MY_TIME && !ishasArtistRole && (
          <Controller
            name="user"
            control={workTimeControl}
            render={({ field }) => (
              <>
                <Select
                  options={
                    performAction?.actionType === "logExternal" ||
                    logDetails?.type === ENUMS.LOG_TIME_TYPE.EXTERNAL
                      ? userOptionsExterior
                      : userOptions
                  }
                  size="sm"
                  label={t("label.assignee")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  displayChoose
                  isRequired
                  isError={!_.isEmpty(workTimeErrors.user?.message)}
                  errorMessage={workTimeErrors.user?.message}
                />
                <Checkbox
                  isChecked={field.value === userData?.id}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? userData?.id : "");
                  }}
                >
                  <Text fontSize="sm">{t("label.assigneeToMe")}</Text>
                </Checkbox>
              </>
            )}
          />
        )}
      </Box>
      <Box>
        <Controller
          name="description"
          control={workTimeControl}
          render={({ field }) => (
            <TextEditor
              value={field?.value || ""}
              label={t("label.description")}
              containerSx={{ mb: 2 }}
              onChange={(e: any) => field.onChange(e)}
              height={80}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="note"
          control={workTimeControl}
          render={({ field }) => (
            <TextareaComponent
              placeholder={t("label.note")}
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.note")}
            />
          )}
        />
      </Box>
      <Controller
        name="isDone"
        control={workTimeControl}
        render={({ field }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Switch
              isChecked={field.value}
              id="toggle-status-task"
              onChange={(e) => field.onChange(e.target.checked)}
            />
            <Text fontSize="sm">
              {field.value
                ? t("label.taskCompleted")
                : t("label.taskNotCompleted")}
            </Text>
          </Box>
        )}
      />
    </Box>
  );

  const _renderOvertime = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <>
        {boardDetails?.id ? (
          <TextField
            label={t("label.project")}
            placeholder="project"
            value={boardDetails?.project?.name}
            size="sm"
            isReadOnly
          />
        ) : (
          <Controller
            name="project"
            control={overTimeControl}
            render={({ field }) => (
              <Select
                displayChoose
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setOverTimeValue("task", "");
                }}
                label={t("label.project")}
                size="sm"
                options={projectOptions}
                isRequired
                isError={!_.isEmpty(overTimeErrors?.project?.message)}
                errorMessage={overTimeErrors?.project?.message}
                isDisabled={
                  logDetails?.overTimeStatus &&
                  logDetails?.overTimeStatus !==
                    ENUMS.LOG_OVER_TIME_STATUS.PENDING
                }
              />
            )}
          />
        )}
        <Controller
          name="task"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.process")}
              size="sm"
              options={findOverTimeProccessOptions}
              isRequired
              isDisabled={
                logDetails?.overTimeStatus &&
                logDetails?.overTimeStatus !==
                  ENUMS.LOG_OVER_TIME_STATUS.PENDING
              }
            />
          )}
        />
      </>

      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <Controller
          name="date"
          control={overTimeControl}
          render={({ field }) => (
            <DatePicker
              size="sm"
              label={t("label.date")}
              value={field.value as any}
              onDateChange={(date) => field.onChange(date)}
              isError={!_.isEmpty(overTimeErrors.date?.message)}
              errorMessage={overTimeErrors.date?.message}
              isRequired
              disabled={
                logDetails?.overTimeStatus &&
                logDetails?.overTimeStatus !==
                  ENUMS.LOG_OVER_TIME_STATUS.PENDING
              }
            />
          )}
        />
        <Controller
          name="dateType"
          control={overTimeControl}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.dateType")}
              size="sm"
              options={
                language === "en"
                  ? new Date(watchDate).getDay() === 0
                    ? dateTypeOptionsSunday
                    : new Date(watchDate).getDay() === 6
                    ? dateTypeWeekendOptions
                    : dateTypeOptions
                  : new Date(watchDate).getDay() === 0
                  ? dateTypeOptionsSundayVI
                  : new Date(watchDate).getDay() === 6
                  ? dateTypeWeekendOptionsVI
                  : dateTypeOptionsVI
              }
              isRequired
              isDisabled={
                logDetails?.overTimeStatus &&
                logDetails?.overTimeStatus !==
                  ENUMS.LOG_OVER_TIME_STATUS.PENDING
              }
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 5,
        }}
      >
        <TimePicker
          label={t("label.timeline")}
          sx={{
            flex: 1,
          }}
          defaultValue={defaultTimeLine}
          onTimeChange={(newTime: string[]) => {
            setOverTimeValue("startTime", newTime[0]);
            setOverTimeValue("endTime", newTime[1]);
          }}
          isRequired
          isDisabled={
            logDetails?.overTimeStatus &&
            logDetails?.overTimeStatus !== ENUMS.LOG_OVER_TIME_STATUS.PENDING
          }
        />
        <Controller
          name="workingTime"
          control={overTimeControl}
          render={({ field }) => (
            <TextField
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              label={t("label.workedHours")}
              placeholder="0"
              size="sm"
              isReadOnly
              moreInfo={t(
                "message.workingHoursWillBeCalculatedBasedOnFilledTimelineValue"
              )}
              isError={!_.isEmpty(overTimeErrors.workingTime?.message)}
              errorMessage={overTimeErrors.workingTime?.message}
              isDisabled={
                logDetails?.overTimeStatus &&
                logDetails?.overTimeStatus !==
                  ENUMS.LOG_OVER_TIME_STATUS.PENDING
              }
            />
          )}
        />
      </Box>
      <Box>
        {overTimeErrors.endTime?.message && (
          <Text fontSize={"14px"} color={"error"}>
            {overTimeErrors.endTime?.message}
          </Text>
        )}
      </Box>
      <Box
        sx={{
          display: "none",
        }}
      >
        {pathname !== Routers.MY_TIME && (
          <Controller
            name="user"
            control={overTimeControl}
            render={({ field }) => (
              <>
                <Select
                  options={
                    performAction?.actionType === "logExternal" ||
                    logDetails?.type === ENUMS.LOG_TIME_TYPE.EXTERNAL
                      ? userOptionsExterior
                      : userOptions
                  }
                  size="sm"
                  label={t("label.assigneeToMe")}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  displayChoose
                  isRequired
                  isError={!_.isEmpty(overTimeErrors.user?.message)}
                  errorMessage={overTimeErrors.user?.message}
                />
                {/* <Checkbox
                  isChecked={field.value === userData?.id}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? userData?.id : "");
                  }}
                >
                  <Text fontSize="sm">{t("label.assigneeToMe")}</Text>
                </Checkbox> */}
              </>
            )}
          />
        )}
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>
        <Controller
          name="description"
          control={overTimeControl}
          render={({ field }) => (
            <Textarea
              size="sm"
              value={field?.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              placeholder={t("label.description")}
              isDisabled={
                logDetails?.overTimeStatus &&
                logDetails?.overTimeStatus !==
                  ENUMS.LOG_OVER_TIME_STATUS.PENDING
              }
            />
          )}
        />
      </Box>
      {/* <Box>
        <Controller
          name="note"
          control={overTimeControl}
          render={({ field }) => (
            <TextareaComponent
              placeholder="Note"
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              label="Note"
            />
          )}
        />
      </Box> */}
      {/* <Controller
        name="isDone"
        control={overTimeControl}
        render={({ field }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Switch
              isChecked={field.value}
              id="toggle-status-task"
              onChange={(e) => field.onChange(e.target.checked)}
            />
            <Text fontSize="sm">
              {field.value
                ? t("label.taskCompleted")
                : t("label.taskNotCompleted")}
            </Text>
          </Box>
        )}
      /> */}
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size="lg"
    >
      <Tabs
        size="sm"
        variant="line"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxHeight: "100%",
          }}
        >
          <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
            {`${
              timeType === "work time"
                ? t("label.workTimeDetail")
                : t("label.overtimeDetail")
            } `}
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {logDetails?.overTimeStatus && (
                <Text
                  color={
                    LOG_OVER_TIME_STATUS_COLOR[
                      logDetails?.overTimeStatus as keyof typeof LOG_OVER_TIME_STATUS_COLOR
                    ].text
                  }
                  bgColor={
                    LOG_OVER_TIME_STATUS_COLOR[
                      logDetails?.overTimeStatus as keyof typeof LOG_OVER_TIME_STATUS_COLOR
                    ].background
                  }
                  sx={{
                    fontSize: 16,
                    textTransform: "capitalize",
                    borderRadius: 15,
                    px: "15px",
                    textAlign: "center",
                  }}
                >
                  {t(`status.${logDetails?.overTimeStatus}`)}
                </Text>
              )}
            </Box>
          </AlertDialogHeader>
          <AlertDialogBody
            sx={{
              p: 0,
              overflow: "auto",
            }}
          >
            <TabPanels>
              <TabPanel>{_renderWorktime()}</TabPanel>
              <TabPanel>{_renderOvertime()}</TabPanel>
            </TabPanels>
          </AlertDialogBody>
          <AlertDialogFooter
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              <Button
                size="sm"
                //colorScheme="red"
                isDisabled={
                  logDetails?.overTimeStatus &&
                  logDetails?.overTimeStatus !==
                    ENUMS.LOG_OVER_TIME_STATUS.PENDING
                }
                onClick={() => setIsShowDeleteLog(true)}
                sx={{
                  color: "#fff",
                  background: "rgba( 187, 113, 84,0.9)",
                  "&:hover": {
                    background: "rgba( 187, 113, 84,1)",
                  },
                }}
              >
                {t("button.delete")}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box>
                {timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME && (
                  <Button
                    ref={workTimeSubmitButtonRef}
                    sx={{ display: "none" }}
                    onClick={handleWorkTimeSubmit(onWorkTimeSubmit)}
                  />
                )}
                {timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME && (
                  <Button
                    ref={overTimeSubmitButtonRef}
                    sx={{ display: "none" }}
                    onClick={handleOverTimeSubmit(onOverTimeSubmit)}
                  />
                )}
              </Box>
              <Button size="sm" ref={cancelRef} onClick={onClose}>
                {t("button.cancel")}
              </Button>
              <Button
                size="sm"
                onClick={handleCombinedSubmit}
                ml={3}
                //colorScheme="twitter"
                isLoading={isActionLoading}
                isDisabled={
                  logDetails?.overTimeStatus &&
                  logDetails?.overTimeStatus !==
                    ENUMS.LOG_OVER_TIME_STATUS.PENDING
                }
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
              >
                {t("button.save")}
              </Button>
            </Box>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Tabs>
      <ConfirmDialog
        title={t("message.areYouSureYouWantToProceed")}
        isOpen={isShowConfirm}
        onClose={() => setIsShowConfirm(false)}
        body={t("message.youAreAboutToSubmit")}
        onAction={() => {}}
        actionType="confirm"
      />
      <ConfirmDialog
        isOpen={isShowDeleteLog}
        onClose={() => setIsShowDeleteLog(false)}
        onAction={() => {
          if (pathname === Routers.MY_TIME || pathname === Routers.DASHBOARD) {
            if (pathname === Routers.MY_TIME)
              dispatch(
                deleteLogTimeInMyTime(performAction.id, myTimePagination)
              );
            if (pathname === Routers.DASHBOARD) {
              dispatch(
                deleteLogTimeInDashboard(performAction.id, myTimePagination)
              );
            }
          } else {
            dispatch(deleteLogTime(performAction.id, boardDetails?.id));
          }
        }}
        body={t("message.doYouWantRemoveThisLog")}
        actionType="delete"
        isLoading={isActionLoading}
      />
    </AlertDialog>
  );
};

export default UpdateTimelogDialog;
