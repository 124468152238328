import React, { useRef, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Textarea,
} from "@chakra-ui/react";
import { Button } from "@/Components/Common";
import { RootState, useTypedDispatch } from "@/Store";
import { BoardActions } from "@/Actions";

import {
  IBoardStructure,
  IPerformAction,
  ITaskStructure,
} from "@/Interfaces/Board.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, DateTimeRangePicker } from "@/Components/Common";
import { useTranslation } from "react-multi-lang";

const { updateColumnInBoard } = BoardActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_DATA = {
  title: "",
  description: "",
  timeline: ["", ""],
};

const EditTaskDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const performAction: IPerformAction = useSelector((state: RootState) =>
    _.get(state.BOARD, "performAction")
  );

  const boardDetails: IBoardStructure = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isActionLoading")
  );
  const isUpdateColumnSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.BOARD, "isUpdateColumnSuccess")
  );

  const taskDetails: ITaskStructure = useMemo(() => {
    return _.first(
      _.filter(boardDetails?.task, (task) => task.id === performAction?.itemId)
    );
  }, [boardDetails, performAction]);

  const schema = yup
    .object()
    .shape({
      title: yup.string().trim().required(t("message.processIsRequired")),
      description: yup.string().notRequired(),
      timeline: yup
        .array()
        .of(yup.string().trim().required(t("message.timelineIsRequired")))
        .test(
          t("message.timeline-valid"),
          t("message.startTimeMustBeBeforeEndTime"),
          function (value) {
            const [startTime, endTime] = value as [string, string];

            if (!startTime || !endTime) {
              return true; // Allow null values, as they are handled by the individual date validations
            }

            // Check if start and end dates are the same
            const sameDate = dayjs(startTime).isSame(dayjs(endTime), "day");

            // If the dates are the same and the start time is 12:00 PM or later, return false (invalid)

            if (sameDate) {
              const formatStartTime = dayjs(startTime).format("HH");
              const formatEndTime = dayjs(endTime).format("HH");
              if (
                _.toNumber(formatEndTime) >= 12 &&
                _.toNumber(formatStartTime) === 12
              )
                return false;
            }

            // Otherwise, compare UTC Date objects
            return dayjs(startTime).isBefore(dayjs(endTime));
          }
        ),
    })
    .required();

  type FormData = yup.InferType<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_DATA,
  });

  const [timeline, setTimeline] = useState<any>([]);
  const cancelRef = useRef<any>(null);

  useEffect(() => {
    if (isUpdateColumnSuccess) onClose();
  }, [isUpdateColumnSuccess]);

  useEffect(() => {
    if (!_.isEmpty(taskDetails)) {
      setValue("title", taskDetails.title);
      setValue("description", taskDetails?.description || "");
      setValue("timeline", [
        taskDetails.timeLineStart || "",
        taskDetails.timeLineEnd || "",
      ]);
      setTimeline([taskDetails.timeLineStart, taskDetails.timeLineEnd]);
    }
  }, [taskDetails]);

  const onSubmit = (data: FormData) => {
    dispatch(
      updateColumnInBoard(
        performAction.itemId,
        {
          ..._.omit(data, ["timeline"]),
          timeLineStart: timeline[0] || "",
          timeLineEnd: timeline[1] || "",
        },
        () => onClose()
      )
    );
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("label.process")}
            placeholder={t("label.process")}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            isError={!_.isEmpty(errors.title?.message)}
            errorMessage={errors.title?.message}
            size="sm"
            isRequired
          />
        )}
      />
      <Box
        sx={{
          width: "max-content",
        }}
      >
        <Controller
          name="timeline"
          control={control}
          render={({ field }) => (
            <DateTimeRangePicker
              isRequired
              label={t("label.timeline")}
              onDateChange={(newDate) => {
                setTimeline(newDate);
                field.onChange(newDate);
              }}
              value={field.value}
              isError={!_.isEmpty(errors.timeline)}
              errorMessage={
                errors.timeline?.message || errors.timeline?.[0]?.message
              }
            />
          )}
        />
      </Box>
      <Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
          }}
        >
          {t("label.description")}
        </Heading>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <Textarea
              placeholder={t("label.description")}
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              size="sm"
              isRequired
            />
          )}
        />
      </Box>
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open && performAction.actionType === "edit"}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader fontSize="lg" textAlign="center" fontWeight="bold">
          {t("title.editProcess")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter>
          <Button size="sm" ref={cancelRef} onClick={onClose}>
            {t("button.cancel")}
          </Button>
          <Button
            size="sm"
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            sx={{
              minW: 65,
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
            isDisabled={isActionLoading}
          >
            {t("button.save")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default EditTaskDialog;
