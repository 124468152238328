import React, { useEffect, useState } from "react";
import Fingerprint2 from "fingerprintjs2";
import {
  Stack,
  Heading,
  Box,
  HStack,
  Text,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";
import { RoundedContainer } from "@/Components/Common";
import { publicIpv4 } from "public-ip";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useCopyToClipboard, useWindowWidth } from "@/Helpers";
import CommonColors from "@/Themes/CommonColors";

import Utils from "@/Utils";
import _ from "lodash";
import { useTranslation } from "react-multi-lang";

const GetInternalIP: React.FC = () => {
  const [, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isCopiedID, setIsCopiedID] = useState<boolean>(false);
  const [addressIp, setAddressIp] = useState<any>();
  const [addressId, setAddressId] = useState<any>();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const t = useTranslation();

  const userData = Utils.getSavedUserData();

  const internal = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "setting",
    "internal"
  );

  const internalStaff = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "setting",
    "internalStaff"
  );

  const handleCopyText = (value: string) => {
    if (value)
      copy(value)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 500);
        })
        .catch((err) => console.log(err));
  };

  const handleCopyTextId = (value: string) => {
    if (value)
      copy(value)
        .then(() => {
          setIsCopiedID(true);
          setTimeout(() => {
            setIsCopiedID(false);
          }, 500);
        })
        .catch((err) => console.log(err));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await publicIpv4();
        setAddressIp(result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    Fingerprint2.get((components: any) => {
      const values = components.map((component: any) => {
        return component.value;
      });
      const fingerprint = Fingerprint2.x64hash128(values.join(""), 31);
      setAddressId(fingerprint);
    });
  }, []);

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box>
          <HStack
            sx={{
              display: "flex",
              justifyContent: "space-between",
              w: "full",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.myIPAddress.")}
            </Heading>
          </HStack>
        </Box>
        <RoundedContainer>
          {internal && (
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Text sx={{ fontWeight: 600, fontSize: 18 }}>
                  {t("title.myIPAddress.")}:
                </Text>
                {addressIp && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        ml: "10px!important",
                      }}
                    >
                      {addressIp}
                    </Text>
                    <Tooltip
                      hasArrow
                      isOpen={isCopied}
                      label={t("label.copied")}
                    >
                      <Icon
                        boxSize={5}
                        sx={{
                          ml: 2,
                          color: "rgba(211, 157, 135,0.8)",
                          "&:hover": {
                            color: CommonColors.dustyCoral,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleCopyText(`${addressIp}`)}
                      >
                        <DocumentDuplicateIcon />
                      </Icon>
                    </Tooltip>
                  </Box>
                )}
              </Box>
              {internal && (
                <Box mt={2}>
                  <Text
                    sx={{
                      fontWeight: 550,
                      minW: "80px",
                      // fontStyle: "italic",
                      // fontFamily: " sans-serif",
                    }}
                  >
                    {t("label.forAdminHRusers")}: <br />{" "}
                    {t("message.copyTheMyIPAddressCode")}.
                  </Text>
                  <Box>
                    <Text
                      sx={{
                        fontWeight: 700,
                        fontSize: 18,
                        minW: "120px",
                        color: "#BB7154",
                        // fontStyle: "italic",
                        // fontFamily: " sans-serif",
                      }}
                    >
                      * {t("label.instructions")}:
                    </Text>
                    <Box ml={"10px"}>
                      <Text
                        sx={{
                          fontWeight: 550,
                          // fontStyle: "italic",
                          // fontFamily: " sans-serif",
                        }}
                      >
                        1. {t("message.copyTheMyIPAddressCodeDisplayedAbove")}
                      </Text>
                      <Text
                        sx={{
                          fontWeight: 550,

                          // fontStyle: "italic",
                          // fontFamily: " sans-serif",
                        }}
                      >
                        2.{" "}
                        {t(
                          "message.switchToTheInternalIPAddressTabOnTheLeftColumn"
                        )}
                      </Text>
                      <Text
                        sx={{
                          fontWeight: 550,

                          // fontStyle: "italic",
                          // fontFamily: " sans-serif",
                        }}
                      >
                        3. {t("message.inputTheCopiedCodeInto")}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {internalStaff && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: isMobile ? "baseline" : "center",
                }}
              >
                <Text sx={{ fontWeight: 600, fontSize: 18, minW: "125px" }}>
                  {t("title.myIPAddress")}:
                </Text>
                {addressId && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      w: isMobile ? "45%" : "70%",
                    }}
                  >
                    <Text
                      sx={{
                        fontWeight: 600,
                        fontSize: 18,
                        ml: "10px!important",
                        wordWrap: "break-word", // hoặc overflowWrap: "break-word",
                        maxWidth: isMobile ? "150px" : "100%",
                      }}
                    >
                      {addressId}
                    </Text>
                    <Tooltip
                      hasArrow
                      isOpen={isCopiedID}
                      label={t("label.copied")}
                    >
                      <Icon
                        boxSize={5}
                        sx={{
                          ml: 2,
                          color: "rgba(211, 157, 135,0.8)",
                          "&:hover": {
                            color: CommonColors.dustyCoral,
                            cursor: "pointer",
                          },
                        }}
                        onClick={() => handleCopyTextId(`${addressId}`)}
                      >
                        <DocumentDuplicateIcon />
                      </Icon>
                    </Tooltip>
                  </Box>
                )}
              </Box>
              {internalStaff && (
                <Box mt={2}>
                  <Text
                    sx={{
                      fontWeight: 550,
                      minW: "80px",
                      // fontStyle: "italic",
                      // fontFamily: " sans-serif",
                    }}
                  >
                    {t("message.for")} Staff - Leader - Marketing - Manager:{" "}
                    <br />
                    {t(
                      "message.copyTheMyIDAddressCodeSnippetAboveAndSendItToYourSystemAdministrator"
                    )}{" "}
                    (Admin, Manager, HR).
                  </Text>
                  <Box>
                    <Text
                      sx={{
                        fontWeight: 700,
                        fontSize: 18,
                        minW: "80px",
                        color: "#BB7154",
                        // fontStyle: "italic",
                        // fontFamily: " sans-serif",
                      }}
                    >
                      * {t("label.note")}:
                    </Text>
                    <Box ml={"10px"}>
                      <Text
                        sx={{
                          fontWeight: 550,
                          // fontStyle: "italic",
                          // fontFamily: " sans-serif",
                        }}
                      >
                        -{" "}
                        {t(
                          "message.Always ensure you send the correct and unique ID Code information to the admin"
                        )}
                        .
                      </Text>
                      <Text
                        sx={{
                          fontWeight: 550,
                          // fontStyle: "italic",
                          // fontFamily: " sans-serif",
                        }}
                      >
                        -{" "}
                        {t(
                          "message.Wait for confirmation from the admin before proceeding with the first check-in or check-out process to ensure the accuracy of the data"
                        )}
                        .
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </RoundedContainer>
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default GetInternalIP;
