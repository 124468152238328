import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  Stack,
  Box,
  Button,
  Heading,
  Icon,
  // IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import { AdminLayout } from "@/Components/DefaultLayout";
// import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  ConfirmDialog,
  OvertimeDialog,
  TimeLogDialog,
} from "@/Components/Popup";

// import Board from "./Board";

import { RootState, useTypedDispatch } from "@/Store";
import {
  LogtimeActions,
  ProjectActions,
  ProjectExteriorActions,
  // TaskNoteActions,
} from "@/Actions";
import { ITimeLogPerformAction } from "@/Interfaces/LogTime.interface";
import Utils from "@/Utils";
import { RoundedContainer } from "@/Components/Common";
import { PlusIcon } from "@heroicons/react/24/outline";
import MyTimeV3 from "./MyTimeV3";
import { ENUMS } from "@/Constants";
import { CustomHeaderToolbar } from "@/Components/LayoutPart/FullCalendar";
import { useWindowWidth } from "@/Helpers";
import { useTranslation } from "react-multi-lang";

// const { fetchTaskNote, deleteTaskNote, resetTaskNoteRecucer } = TaskNoteActions;
const { fetchProjects, resetProjectReducer } = ProjectActions;
const { fetchProjectsExterior, resetProjectExteriorReducer } =
  ProjectExteriorActions;

const {
  deleteLogTimeInMyTime,
  performAction,
  getByIdLogTime,
  getMyTime,
  resetLogTimePerformAction,
  resetLogTimeReducer,
  setMyTimePagination,
} = LogtimeActions;

// interface CardStructure {
//   id: string;
//   name: string;
//   desc: string;
//   columnId: string;
//   placeholderCard?: boolean;
//   workingTime?: number;
//   createdAt?: string;
// }

// interface ColumnStructure {
//   id: string;
//   name: string;
//   desc?: string;
//   cardOrderIds: string[];
//   status?: string;
//   cards: CardStructure[];
//   totalHours?: number;
//   project?: {
//     id: string;
//     name: string;
//     code: string;
//   };
// }

const MyTime: React.FC = () => {
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const [searchParams, setSearchParams] = useSearchParams();

  const DEFAULT_TIME_RANGE = useMemo(() => {
    const DEFAULT_DURATION = isMobile ? 3 : 7;
    return Utils.generateDefaultTimeline(DEFAULT_DURATION);
  }, [isMobile]);

  const [action, setAction] = useState<string>("");
  const [dateClick, setDateClick] = useState<string>("");
  const [timeline, setTimeline] = useState(Utils.generateDateRangesByMonths(1));
  const [activeDates, setActiveDates] = useState<string[]>(DEFAULT_TIME_RANGE);
  const [activeView, setActiveView] = useState<"week" | "month">("week");
  const [minSlotWidthDate, setMinSlotWidthDate] = useState<number>(50);
  const [calendarWidth, setCalendarWidth] = useState<number>(0);
  const [isReset, setIsReset] = useState(false);
  // const isMobile = width <= 768;

  // const [currentDate, setCurrentDate] = useState(new Date());
  // const [currentView, setCurrentView] = useState<"overview" | "timeline">(
  //   "overview"
  // );
  // const [daysOfWeek, setDaysOfWeek] = useState<any[]>([]);
  // const [resolvedData, setResolvedData] = useState<ColumnStructure[]>([]);
  const isFetchLoading = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isFetchLoading")
  );

  const isUpdateLogSuccess = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isUpdateSuccess")
  );

  const timeLogPerformAction: ITimeLogPerformAction = useSelector(
    (state: RootState) => _.get(state.LOG_TIME, "performAction")
  );

  const canCreateInternalLog = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "LogTime",
    "createInternalLog"
  );

  useEffect(() => {
    // Access the 'id' parameter from searchParams
    const logId = searchParams.get("id");
    if (logId) dispatch(getByIdLogTime(logId));
  }, [searchParams]);

  useEffect(() => {
    if (canCreateInternalLog)
      dispatch(
        fetchProjects({
          page: 0,
          limit: 0,
          type: ENUMS.PROJECT_TYPE.INTERIOR,
        })
      );
    dispatch(
      fetchProjectsExterior({
        page: 0,
        limit: 0,
      })
    );
    return () => {
      dispatch(resetProjectReducer());
      dispatch(resetLogTimeReducer());
      dispatch(resetProjectExteriorReducer());
      // dispatch(resetTaskNoteRecucer());
      setDateClick("");
      dispatch(setMyTimePagination({}));
    };
  }, []);

  // useEffect(() => {
  //   if (currentDate) {
  //     const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
  //     setDaysOfWeek(weekdays);
  //     dispatch(setMyTimeWeekdays(weekdays));
  //     dispatch(
  //       getMyTime({
  //         startDate: _.first(weekdays),
  //         endDate: _.last(weekdays),
  //         userId: [userData?.id],
  //         forSelf: true,
  //         page: 0,
  //         limit: 0,
  //       })
  //     );
  //     // dispatch(
  //     //   fetchTaskNote({
  //     //     startDate: _.first(weekdays),
  //     //     endDate: _.last(weekdays),
  //     //   })
  //     // );
  //   }
  // }, [currentDate]);

  useEffect(() => {
    if (isUpdateLogSuccess) {
      dispatch(resetLogTimePerformAction());
      dispatch(
        getMyTime({
          startDate: _.first(timeline),
          endDate: _.last(timeline),
          userId: [userData?.id],
          forSelf: true,
          page: 0,
          limit: 0,
        })
      );
    }
  }, [isUpdateLogSuccess, timeline]);

  useEffect(() => {
    if (!_.isEmpty(timeline))
      dispatch(
        getMyTime({
          startDate: _.first(timeline),
          endDate: _.last(timeline),
          userId: [userData?.id],
          forSelf: true,
          page: 0,
          limit: 0,
        })
      );
  }, [timeline]);

  // useEffect(() => {
  //   if (daysOfWeek) {
  //     // Group data by date
  //     const groupedMyTime = _.groupBy(myTime, "date");
  //     // Create a new array based on daysOfWeek
  //     const resultMyTime = _.map(daysOfWeek, (date) => {
  //       const resolvedData = (groupedMyTime[date] || []).map((item: any) => {
  //         const columnId = item.task ? item.task.id : "defaultColumnId"; // Provide a default value if item.task.id is not available
  //         return {
  //           id: item.id,
  //           name: item.title || item?.task?.title || "",
  //           desc: item.description || "",
  //           columnId: columnId,
  //           overTimeStatus: item?.overTimeStatus || "",
  //           placeholderCard: false,
  //           workingTime: item.workingTime,
  //           createdAt: item.createdAt,
  //           project: {
  //             id: item?.kanbanBoard?.project?.id,
  //             name: item?.kanbanBoard?.project?.name,
  //             code: item?.kanbanBoard?.project?.code,
  //           },
  //         };
  //       });
  //       return {
  //         id: date,
  //         name: date,
  //         cardOrderIds: [],
  //         status: "active",
  //         cards: resolvedData,
  //         totalHours: calculateTotalHours(resolvedData || []),
  //       };
  //     });
  //     setResolvedData(resultMyTime);
  //   }
  // }, [daysOfWeek, myTime]);

  // const myTimev2 = useMemo(() => {
  //   const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //   const events: any[] = [];
  //   _.forEach(myTime, (timeLog: any) => {
  //     const dateStr = timeLog?.date;
  //     const date = new Date(dateStr);
  //     const dayIndex = date.getDay();
  //     const dayOfWeek = daysOfWeek[dayIndex];

  //     const startTimeParts = timeLog?.startTime?.split(":");
  //     const startEndParts = timeLog?.endTime?.split(":");
  //     // const start = _.clone(date);
  //     const start = _.clone(currentDate);
  //     start.setHours(parseInt(startTimeParts?.[0] || "00"));
  //     start.setMinutes(parseInt(startTimeParts?.[1] || "00"));
  //     const end = _.clone(currentDate);
  //     // const end = _.clone(date);
  //     start.setHours(parseInt(startEndParts?.[0] || "00"));
  //     start.setMinutes(parseInt(startEndParts?.[1] || "00"));

  //     events.push({
  //       id: timeLog?.id,
  //       resourceId: dayOfWeek,
  //       start,
  //       end,
  //       title: timeLog?.title || timeLog?.task?.title,
  //       backgroundColor:
  //         timeLog?.timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
  //           ? "#3498DB"
  //           : "#ff3232",
  //     });
  //   });
  //   return events;
  // }, [currentDate, daysOfWeek, myTime]);

  // Function to calculate total hours
  // const calculateTotalHours = (cards: any[]) => {
  //   return cards.reduce(
  //     (total: number, card: any) => total + parseFloat(card.workingTime),
  //     0
  //   );
  // };

  // const getDaysOfWeek = (date: Date, dateFormat: string) => {
  //   const daysOfWeek = [];
  //   const firstDayOfWeek = new Date(date);
  //   firstDayOfWeek.setDate(
  //     date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
  //   );

  //   for (let i = 0; i < 7; i++) {
  //     const currentDate = new Date(firstDayOfWeek);
  //     currentDate.setDate(firstDayOfWeek.getDate() + i);
  //     daysOfWeek.push(dayjs(currentDate).format(dateFormat));
  //   }

  //   return daysOfWeek;
  // };

  // const formattedDateRange = React.useMemo(() => {
  //   const startDate = dayjs(_.first(daysOfWeek));
  //   const endDate = dayjs(_.last(daysOfWeek));

  //   const isValidStartDate = dayjs(startDate).isValid();
  //   const isValidEndDate = endDate.isValid();

  //   const formattedStartDate = isValidStartDate
  //     ? startDate.format("MMM D")
  //     : "";
  //   const formattedEndDate = isValidEndDate
  //     ? endDate.format("MMM D, YYYY")
  //     : "";

  //   return `${formattedStartDate} - ${formattedEndDate}`;
  // }, [daysOfWeek, currentDate]);

  // const handlePrevClick = () => {
  //   const newDate = new Date(currentDate);
  //   newDate.setDate(currentDate.getDate() - 7);
  //   setCurrentDate(newDate);
  // };

  // const handleNextClick = () => {
  //   const newDate = new Date(currentDate);
  //   newDate.setDate(currentDate.getDate() + 7);
  //   setCurrentDate(newDate);
  // };

  const _renderMoreOptions = () => (
    <Popover>
      <PopoverTrigger>
        <Button
          size="sm"
          //  colorScheme="twitter"
          leftIcon={<Icon as={PlusIcon} boxSize={5} />}
          sx={{
            color: "#fff",
            background: "#5c6e6c",
            "&:hover": {
              background: "#a6b7af",
            },
          }}
        >
          {t("button.create")}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          sx={{
            maxW: 200,
          }}
        >
          <PopoverArrow />
          <PopoverHeader>
            <Heading size="sm"> {t("label.moreOptions")}</Heading>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(
                    performAction("logInternal", "", { date: dateClick })
                  );
                  setAction("worktime-overtime");
                }}
              >
                {t("button.internalProject")}
              </Button>
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(
                    performAction("logExternal", "", { date: dateClick })
                  );
                }}
              >
                {t("button.externalProject")}
              </Button>
              {/* <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(performAction("createTaskNote", ""));
                }}
              >
                Task Note
              </Button> */}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );

  // const _renderTopSection = () => (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       flexWrap: "wrap",
  //       justifyContent: "space-between",
  //       alignItems: "center",
  //       position: "relative",
  //     }}
  //   >
  //     <Box>
  //       <Button
  //         size="sm"
  //         isDisabled={_.includes(daysOfWeek, dayjs().format("YYYY-MM-DD"))}
  //         onClick={() => setCurrentDate(new Date())}
  //       >
  //         Today
  //       </Button>
  //     </Box>
  //     <Box
  //       sx={{
  //         display: "flex",
  //         gap: 2,
  //         alignItems: "center",
  //         ml: isMobile ? "auto" : 0,
  //       }}
  //     >
  //       <IconButton
  //         isRound
  //         size="sm"
  //         background="transparent"
  //         aria-label={""}
  //         onClick={handlePrevClick}
  //       >
  //         <ChevronLeftIcon boxSize={5} />
  //       </IconButton>
  //       <Heading size="sm">{formattedDateRange}</Heading>
  //       <IconButton
  //         isRound
  //         size="sm"
  //         background="transparent"
  //         aria-label={""}
  //         onClick={handleNextClick}
  //       >
  //         <ChevronRightIcon boxSize={5} />
  //       </IconButton>
  //     </Box>
  //     <Box
  //       sx={{ opacity: isMobile ? 1 : 0, w: isMobile ? "100%" : "max-content" }}
  //     >
  //       {/* {isMobile && (
  //         <Flex alignItems="center" justifyContent="center" gap={2}>
  //           <Flex alignItems="center" gap={1}>
  //             <Box
  //               sx={{
  //                 w: 2.5,
  //                 h: 2.5,
  //                 borderRadius: "50%",
  //                 background: "#3498DB",
  //               }}
  //             />
  //             <Text fontSize="14px" fontWeight={400}>
  //               Worktime
  //             </Text>
  //           </Flex>
  //           <Flex alignItems="center" gap={1}>
  //             <Box
  //               sx={{
  //                 w: 2.5,
  //                 h: 2.5,
  //                 borderRadius: "50%",
  //                 background: "#ff3232",
  //               }}
  //             />
  //             <Text fontSize="14px" fontWeight={400}>
  //               Overtime
  //             </Text>
  //           </Flex>
  //         </Flex>
  //       )} */}
  //     </Box>
  //     {/* {!isMobile && (
  //       <Box sx={{ position: "absolute", right: 0 }}>
  //         <Flex alignItems="center" justifyContent="end" gap={2}>
  //           <Flex alignItems="center" gap={1}>
  //             <Box
  //               sx={{
  //                 w: 2.5,
  //                 h: 2.5,
  //                 borderRadius: "50%",
  //                 background: "#3498DB",
  //               }}
  //             />
  //             <Text fontSize="14px" fontWeight={400}>
  //               Worktime
  //             </Text>
  //           </Flex>
  //           <Flex alignItems="center" gap={1}>
  //             <Box
  //               sx={{
  //                 w: 2.5,
  //                 h: 2.5,
  //                 borderRadius: "50%",
  //                 background: "#ff3232",
  //               }}
  //             />
  //             <Text fontSize="14px" fontWeight={400}>
  //               Overtime
  //             </Text>
  //           </Flex>
  //         </Flex>
  //       </Box>
  //     )} */}
  //   </Box>
  // );

  const _renderPopup = () => (
    <>
      {action === "worktime-overtime" && (
        <TimeLogDialog.CreateTimelogDialog
          open={
            timeLogPerformAction?.actionType === "logExternal" ||
            timeLogPerformAction?.actionType === "logInternal"
          }
          onClose={() => dispatch(resetLogTimePerformAction())}
        />
      )}
      {action === "worktime" && (
        <TimeLogDialog.CreateWorkingDialog
          open={
            timeLogPerformAction?.actionType === "logExternal" ||
            timeLogPerformAction?.actionType === "logInternal"
          }
          onClose={() => dispatch(resetLogTimePerformAction())}
        />
      )}
      {action === "overtime" && (
        <OvertimeDialog.SendOvertimeDialog
          title={t("title.createOvertime")}
          open={
            timeLogPerformAction?.actionType === "logExternal" ||
            timeLogPerformAction?.actionType === "logInternal"
          }
          onClose={() => dispatch(resetLogTimePerformAction())}
        />
      )}
      <TimeLogDialog.UpdateTimelogDialog
        open={timeLogPerformAction?.actionType === "editLog"}
        onClose={() => {
          if (searchParams) {
            searchParams.delete("id");
            setSearchParams(searchParams);
          }
          dispatch(resetLogTimePerformAction());
        }}
      />
      <ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteLog"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(
            deleteLogTimeInMyTime(timeLogPerformAction.id, {
              startDate: _.first(activeDates),
              endDate: _.last(activeDates),
              userId: [userData?.id],
              forSelf: true,
              page: 0,
              limit: 0,
            })
          );
        }}
        body={t("message.doYouWantRemoveThisLog")}
        actionType="delete"
      />
      {/* <TimeLogDialog.CreateTaskNoteDialog
        open={timeLogPerformAction?.actionType === "createTaskNote"}
        onClose={() => dispatch(resetLogTimePerformAction())}
      />
      <TimeLogDialog.UpdateTaskNoteDialog
        open={timeLogPerformAction?.actionType === "editTaskNote"}
        onClose={() => dispatch(resetLogTimePerformAction())}
      /> */}
      {/* <ConfirmDialog
        isOpen={timeLogPerformAction?.actionType === "deleteTaskNote"}
        onClose={() => dispatch(resetLogTimePerformAction())}
        onAction={() => {
          dispatch(
            deleteTaskNote(timeLogPerformAction.id, () => {
              dispatch(resetLogTimePerformAction());
              dispatch(
                fetchTaskNote({
                  startDate: _.first(daysOfWeek),
                  endDate: _.last(daysOfWeek),
                })
              );
            })
          );
        }}
        body="Do you want remove this note"
        actionType="delete"
      /> */}
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Heading size="lg" color={"#5c6e6c"}>
            {t("label.myTime")}
          </Heading>
          <Box
            sx={{
              display: "flex",
              gap: 3,
            }}
          >
            {canCreateInternalLog ? (
              <Box>{_renderMoreOptions()}</Box>
            ) : (
              <Box>
                <Button
                  colorScheme="twitter"
                  size="sm"
                  onClick={() => {
                    setAction("worktime-overtime");
                    dispatch(
                      performAction("logExternal", "", { date: dateClick })
                    );
                  }}
                  leftIcon={<Icon as={PlusIcon} boxSize={5} />}
                  sx={{
                    color: "#fff",
                    background: "#5c6e6c",
                    "&:hover": {
                      background: "#a6b7af",
                    },
                  }}
                >
                  {t("button.create")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>

        <RoundedContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* {_renderTopSection()} */}
          {_renderPopup()}
          {/* <Board data={resolvedData} /> */}
          <CustomHeaderToolbar
            isLoading={isFetchLoading}
            activeView={activeView}
            onReset={() => {
              setIsReset(true);
              setActiveDates(DEFAULT_TIME_RANGE);
            }}
            onMinSlotWidthDateChange={(width) => {
              setMinSlotWidthDate(width);
            }}
            onActiveViewChange={(view) => setActiveView(view)}
            activeDates={activeDates}
            onActiveDatesChange={(dates) => {
              if (!_.isEmpty(dates)) setActiveDates(dates);
            }}
            timeline={timeline}
            onTimelineChange={(timeline) => setTimeline(timeline)}
            calendarWidth={calendarWidth}
          />
          <MyTimeV3
            minSlotWidthDate={minSlotWidthDate}
            activeDates={activeDates}
            activeView={activeView}
            onRowAction={(e) => setAction(e)}
            onDateClick={(date) => setDateClick(date)}
            onContainerWidth={(width) => setCalendarWidth(width)}
            isReset={isReset}
            onReset={() => setIsReset(false)}
            timeline={timeline}
            defaultTimeRange={DEFAULT_TIME_RANGE}
          />
        </RoundedContainer>
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default MyTime;
