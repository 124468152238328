import { useWindowWidth } from "@/Helpers";
import { SmallCloseIcon, WarningIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  Text,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-multi-lang";

interface SectionProps {
  open: boolean;
  close?(): void;
  onClose(): void;
}

const WarningCheckInAfternoonDialog: React.FC<SectionProps> = ({
  open,
  onClose,
}) => {
  const cancelRef = useRef<any>(null);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 450;
  const t = useTranslation();

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      motionPreset="none"
    >
      <AlertDialogOverlay
        sx={{
          "& .css-1u2cvaz": {
            top: "50%",
            transform: "translateY(-20%)",
          },
        }}
      >
        <AlertDialogContent sx={{ mt: 50 }}>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            textAlign={"center"}
            sx={{ position: "relative" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: " 50%",
                transform: "translate(-50%, 0)",
              }}
            >
              <WarningIcon color={"rgba(210, 169, 106,1)"} fontSize={35} />
            </Box>
            <Box
              sx={{ position: "absolute", top: 0, right: !isMobile ? 1 : 3 }}
            >
              <SmallCloseIcon onClick={() => onClose()} />
            </Box>
          </AlertDialogHeader>
          <AlertDialogBody
            textAlign={"center"}
            mt={5}
            sx={{ borderBottom: "5px solid rgba(210, 169, 106,0.7)" }}
          >
            <Box>
              <Text
                sx={{
                  fontSize: 22,
                  fontWeight: 500,
                  color: "rgba(210, 169, 106,1)",
                }}
              >
                {t("message.warning")}
              </Text>
              <Text sx={{ fontSize: 18, fontFamily: "sans-serif" }}>
                {t("message.youhavenCheckedInThisAfternoon")}
              </Text>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default WarningCheckInAfternoonDialog;
