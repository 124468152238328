import CommonColors from "@/Themes/CommonColors";

export const calendarStyles = {
  // "& .fc *": {
  //   transition: "all ease 0.25s",
  // },
  // "& .fc-timeline-events.fc-scrollgrid-sync-inner *": {
  //   transition: "none",
  // },
  // "& .fc-timeline-events.fc-scrollgrid-sync-inner > *": {
  //   transition: "none",
  // },
  width: "100%",
  height: "100%",
  overflow: "auto",
  ".fc.fc-media-screen.fc-direction-ltr.fc-theme-standard": {
    overflow: "auto",
    // maxWidth: "100%",
    height: "100%",
  },

  ".fc-button": {
    background: "transparent",
    outline: "none",
    boxShadow: "none !important",
    border: `2px solid ${CommonColors.burntSienna}`,
    color: CommonColors.burntSienna,
    fontSize: "14px",
    "&:hover": {
      background: "#E87844",
      border: `2px solid ${CommonColors.burntSienna}`,
      color: "#ffffff",
    },
  },
  ".fc-toolbar-title": {
    fontSize: 20,
  },
  ".fc-toolbar-title, .fc-button": {
    padding: "4px 10px",
    textTransform: "capitalize",
  },

  ".fc-today-button, .fc-button.fc-button-primary": {
    // background: `${CommonColors.LightSkyBlue3}!important`,
    "&.fc-button-active": {
      background: `#E87844 !important`,
      border: `2px solid ${CommonColors.burntSienna}`,
      color: "#ffffff",
      fontWeight: 700,
    },
    "&.fc-next-button, &.fc-button[title='month view']": {
      borderLeft: `1px solid ${CommonColors.burntSienna}`,
    },
    "&.fc-prev-button, &.fc-button[title='week view']": {
      borderRight: `1px solid ${CommonColors.burntSienna}`,
    },
  },
  //"& h2.chakra-heading.css-1r8joen": {
  //  background: "#d38d87 !important",
  //},
  "& a.fc-event": {
    //  borderColor: "#fff",
    border: "2px solid #fff",
  },
  ".fc-button-primary:not(:disabled)": {
    background: "unset !important",
    borderColor: "#e87844 !important",
    color: "#e87844",
  },
  ".fc-button:hover": {
    color: "#ffffff!important",
    background: "#E87844 !important",
  },
  "button.fc-today-button.fc-button.fc-button-primary": {
    color: "#ffffff!important",
    background: "#E87844 !important",
    borderColor: "#e87844 !important",
  },

  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-today,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-today":
    {
      background: CommonColors.loblolly,
    },

  ".custom-header": {
    ".fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner": {
      display: "none",
    },
  },
  ".custom-header .fc-datagrid-table tbody tr": {
    marginBottom: "30px",
  },
  ".custom-header  .fc-resource": {
    paddingBottom: "50px!important",
  },
  // hidden expland icon
  ".fc-direction-ltr .fc-datagrid-expander": {
    display: "none",
  },
  // "span.fc-datagrid-cell-main": {
  //   display: "inline-block",
  // },
  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.holiday, td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.holiday":
    {
      backgroundColor: CommonColors.aquatone,
    },
  "th.fc-timeline-slot.fc-timeline-slot-label.fc-day.fc-day-sun,td.fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-sun":
    {
      background: "#E2E8F0",
    },
  "td.fc-datagrid-cell.fc-resource": {
    border: "none",
    "&.has-border": {
      borderTop: "1px solid #E2E8F0",
    },
  },
  "td.fc-timeline-lane.fc-resource": {
    border: "transparent !important",
  },
  ".fc-timeline-body td.fc-timeline-lane.fc-resource.has-border": {
    borderTop: "1px solid #E2E8F0 !important",
  },
};

export const sharedStyles = {
  [`td.fc-datagrid-cell.fc-resource:not(.overall):not(.last-resource-id) > .fc-datagrid-cell-frame`]:
    {
      marginBottom: "-10px",
    },
  [`td.fc-timeline-lane.fc-resource:not(.overall):not(.last-resource-id) > .fc-timeline-lane-frame`]:
    {
      marginBottom: "-10px",
    },
  [`td.fc-datagrid-cell.fc-resource:has(.has-border) > .fc-datagrid-cell-frame`]:
    {
      marginBottom: "-10px",
    },
  [`td.fc-timeline-lane.fc-resource:has(.has-border) > .fc-timeline-lane-frame`]:
    {
      marginBottom: "-10px",
    },
  [`td.fc-datagrid-cell.fc-resource.is-last-task.fixed-height > .fc-datagrid-cell-frame`]:
    {
      marginBottom: "10px",
    },
  [`td.fc-timeline-lane.fc-resource.is-last-task.fixed-height > .fc-timeline-lane-frame`]:
    {
      marginBottom: "10px",
    },
};
