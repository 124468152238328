import _ from "lodash";
import { useEffect, useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { DatePicker, Textarea } from "@/Components/Common";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { HolidayActions } from "@/Actions";
import { IFilterHoliday } from "@/Interfaces/Holiday.interface";
import dayjs from "dayjs";
import { useTranslation } from "react-multi-lang";

const { createHoliday } = HolidayActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_FORM_DATA = {
  day: "",
  description: "",
  year: "",
};

const CreateHolidaysDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();

  const isCreateSuccess = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "isCreateSuccess")
  );

  const pagination: IFilterHoliday = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "pagination")
  );

  const schema = yup
    .object()
    .shape({
      year: yup.string(),
      day: yup.string().required(t("message.Dateisrequired")),
      description: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    dispatch(
      createHoliday({ ...data, year: data.day.slice(0, 4) }, pagination)
    );
  };

  useEffect(() => {
    if (isCreateSuccess) {
      reset();
      onClose();
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const _renderContent = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Controller
            name="day"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onDateChange={(newDate) =>
                  field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                }
                label={t("label.date")}
                placeholder={t("label.date")}
                isError={!_.isEmpty(errors.day?.message)}
                errorMessage={errors?.day?.message}
                isRequired
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Textarea
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.description")}
                placeholder={t("label.description")}
                size="sm"
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <Box as={"form"}>
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "0",
              top: 0,
              backgroundColor: "#fff",
              boxShadow: "0 1px 2px -1px gray",
              borderRadius: "10px 10px 0 0",
            }}
          >
            {t("title.createHoliday")}
          </AlertDialogHeader>
          <AlertDialogBody>{_renderContent()}</AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              borderRadius: "0 0 10px 10px",
              boxShadow: "0px 0px 2px gray",
            }}
          >
            <Button ref={cancelRef} onClick={onClose} size={"sm"} w={75}>
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              //  colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              w={75}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.create")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Box>
    </AlertDialog>
  );
};

export default CreateHolidaysDialog;
