import React, { useRef, useState } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
} from "@chakra-ui/react";
import { TextField } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import TimeField from "react-simple-timefield";

import TimekeepingAction from "@/Actions/Timekeeping.action";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

const { createTimekeepingAdmin, updateTimekeeping } = TimekeepingAction;

interface IDayData {
  id: string;
  date: string;
  time: string;
  type: string;
  note: string;
}

interface IDataStructure {
  id: string;
  name: string;
  avatar?: string;
  timekeeping: IDayData[];
  date?: any;
}
interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: IDataStructure;
}

const UpdateTimekeepingDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [valueCheckIn, setValueCheckIn] = useState<{
    time: string;
    note: string;
  }>({ time: "", note: "" });
  const [valueCheckOut, setValueCheckOut] = useState<{
    time: string;
    note: string;
  }>({ time: "", note: "" });

  const [valueCheckInAfternoon, setValueCheckInAfternoon] = useState<{
    time: string;
    note: string;
  }>({ time: "", note: "" });

  const [valueCheckOutAfternoon, setValueCheckOutAfternoon] = useState<{
    time: string;
    note: string;
  }>({ time: "", note: "" });

  const [isMessage, setIsMessage] = useState<string>("");

  const isCreateTimekeepingSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isCreateTimekeepingSuccess")
  );

  const isUpdateTimekeepingSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isUpdateTimekeepingSuccess")
  );

  const userData = Utils.getSavedUserData();
  const checkUpdateTimekeeping = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timekeeping",
    "checkUpdate"
  );

  const startTime = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-in"
    ),
    "time"
  )}`;

  const endTime = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-out"
    ),
    "time"
  )}`;

  const startTimeAfternoon = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-in-afternoon"
    ),
    "time"
  )}`;

  const endTimeAfternoon = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-out-afternoon"
    ),
    "time"
  )}`;

  const startNote = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-in"
    ),
    "note"
  )}`;

  const endNote = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-out"
    ),
    "note"
  )}`;
  const startNoteAfternoon = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-in-afternoon"
    ),
    "note"
  )}`;

  const endNoteAfternoon = `${_.get(
    _.find(
      payload?.timekeeping,
      (timekeeping) => timekeeping.type === "check-out-afternoon"
    ),
    "note"
  )}`;

  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "pagination")
  );

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isActionLoading")
  );

  const handleSubmit = () => {
    const idCheckIn = `${_.get(
      _.find(
        payload?.timekeeping,
        (timekeeping) => timekeeping.type === "check-in"
      ),
      "id"
    )}`;

    const idCheckOut = `${_.get(
      _.find(
        payload?.timekeeping,
        (timekeeping) => timekeeping.type === "check-out"
      ),
      "id"
    )}`;

    const idCheckInAfternoon = `${_.get(
      _.find(
        payload?.timekeeping,
        (timekeeping) => timekeeping.type === "check-in-afternoon"
      ),
      "id"
    )}`;

    const idCheckOutAfternoon = `${_.get(
      _.find(
        payload?.timekeeping,
        (timekeeping) => timekeeping.type === "check-out-afternoon"
      ),
      "id"
    )}`;

    if (!_.isEmpty(payload) && !_.isEmpty(payload.timekeeping)) {
      if (valueCheckIn.time)
        if (idCheckIn && idCheckIn !== "undefined") {
          dispatch(
            updateTimekeeping(
              idCheckIn,
              { ...valueCheckIn, date: payload.date },
              pagination
            )
          );
        }
      if (!idCheckIn || idCheckIn === "undefined") {
        if (valueCheckIn.time)
          dispatch(
            createTimekeepingAdmin(
              {
                user: payload.id,
                listTimeKeeping: [
                  {
                    ...valueCheckIn,
                    type: "check-in",
                    date: payload.date,
                  },
                ],
              },
              pagination
            )
          );
      }
      if (idCheckOut && idCheckOut !== "undefined") {
        if (valueCheckOut.time)
          dispatch(
            updateTimekeeping(
              idCheckOut,
              { ...valueCheckOut, date: payload.date },
              pagination
            )
          );
      }
      if (!idCheckOut || idCheckOut === "undefined") {
        if (valueCheckOut.time)
          dispatch(
            createTimekeepingAdmin(
              {
                user: payload.id,
                listTimeKeeping: [
                  {
                    ...valueCheckOut,
                    type: "check-out",
                    date: payload.date,
                  },
                ],
              },
              pagination
            )
          );
      }
      if (idCheckOutAfternoon && idCheckOutAfternoon !== "undefined") {
        if (valueCheckOutAfternoon.time)
          dispatch(
            updateTimekeeping(
              idCheckOutAfternoon,
              { ...valueCheckOutAfternoon, date: payload.date },
              pagination
            )
          );
      }
      if (!idCheckOutAfternoon || idCheckOutAfternoon === "undefined") {
        if (valueCheckOutAfternoon.time)
          dispatch(
            createTimekeepingAdmin(
              {
                user: payload.id,
                listTimeKeeping: [
                  {
                    ...valueCheckOutAfternoon,
                    type: "check-out-afternoon",
                    date: payload.date,
                  },
                ],
              },
              pagination
            )
          );
      }
      if (idCheckInAfternoon && idCheckInAfternoon !== "undefined") {
        if (valueCheckInAfternoon.time)
          dispatch(
            updateTimekeeping(
              idCheckInAfternoon,
              { ...valueCheckInAfternoon, date: payload.date },
              pagination
            )
          );
      }
      if (!idCheckInAfternoon || idCheckInAfternoon === "undefined") {
        if (valueCheckInAfternoon.time)
          dispatch(
            createTimekeepingAdmin(
              {
                user: payload.id,
                listTimeKeeping: [
                  {
                    ...valueCheckInAfternoon,
                    type: "check-in-afternoon",
                    date: payload.date,
                  },
                ],
              },
              pagination
            )
          );
      }
    } else {
      if (
        !valueCheckInAfternoon.time &&
        !valueCheckOutAfternoon.time &&
        !valueCheckIn.time &&
        !valueCheckOut.time
      ) {
        setIsMessage(t("message.pleaseAtLeastOfTheCases"));
      }
      if (valueCheckInAfternoon.time) {
        dispatch(
          createTimekeepingAdmin(
            {
              user: payload.id,
              listTimeKeeping: [
                {
                  ...valueCheckInAfternoon,
                  type: "check-in-afternoon",
                  date: payload.date,
                },
              ],
            },
            pagination
          )
        );
      }
      if (valueCheckOutAfternoon.time) {
        dispatch(
          createTimekeepingAdmin(
            {
              user: payload.id,
              listTimeKeeping: [
                {
                  ...valueCheckOutAfternoon,
                  type: "check-out-afternoon",
                  date: payload.date,
                },
              ],
            },
            pagination
          )
        );
      }
      if (valueCheckIn.time) {
        dispatch(
          createTimekeepingAdmin(
            {
              user: payload.id,
              listTimeKeeping: [
                {
                  ...valueCheckIn,
                  type: "check-in",
                  date: payload.date,
                },
              ],
            },
            pagination
          )
        );
      }
      if (valueCheckOut.time) {
        dispatch(
          createTimekeepingAdmin(
            {
              user: payload.id,
              listTimeKeeping: [
                {
                  ...valueCheckOut,
                  type: "check-out",
                  date: payload.date,
                },
              ],
            },
            pagination
          )
        );
      }
      //  dispatch(
      //    createTimekeepingAdmin(
      //      {
      //        user: payload.id,
      //        listTimeKeeping: [
      //          {
      //            ...valueCheckInAfternoon,
      //            type: "check-in-afternoon",
      //            date: payload.date,
      //          },
      //          {
      //            ...valueCheckOutAfternoon,
      //            type: "check-out-afternoon",
      //            date: payload.date,
      //          },
      //          {
      //            ...valueCheckIn,
      //            type: "check-in",
      //            date: payload.date,
      //          },
      //          {
      //            ...valueCheckOut,
      //            type: "check-out",
      //            date: payload.date,
      //          },
      //        ],
      //      },
      //      pagination
      //    )
      //  );
    }
  };

  React.useEffect(() => {
    if (payload) {
      if (!_.isEmpty(payload.timekeeping)) {
        if (startTime && startTime !== "undefined") {
          setValueCheckIn({ ...valueCheckIn, time: startTime });
          if (startNote && startNote !== "null")
            setValueCheckIn({ time: startTime, note: startNote });
        }
        if (endTime && endTime !== "undefined") {
          setValueCheckOut({ ...valueCheckOut, time: endTime });
          if (endNote && endNote !== "null")
            setValueCheckOut({ ...valueCheckOut, note: endNote });
        }
        if (startTimeAfternoon && startTimeAfternoon !== "undefined") {
          setValueCheckInAfternoon({
            ...valueCheckInAfternoon,
            time: startTimeAfternoon,
          });
          if (startNoteAfternoon && startNoteAfternoon !== "null")
            setValueCheckInAfternoon({
              time: startTimeAfternoon,
              note: startNoteAfternoon,
            });
        }
        if (endTimeAfternoon && endTimeAfternoon !== "undefined") {
          setValueCheckOutAfternoon({
            ...valueCheckOutAfternoon,
            time: endTimeAfternoon,
          });
          if (endNoteAfternoon && endNoteAfternoon !== "null")
            setValueCheckOutAfternoon({
              time: endTimeAfternoon,
              note: endNoteAfternoon,
            });
        }
      }
    }
  }, [payload, open]);

  React.useEffect(() => {
    if (!open) {
      setValueCheckIn({ time: "", note: "" });
      setValueCheckOut({ time: "", note: "" });
      setValueCheckInAfternoon({ time: "", note: "" });
      setValueCheckOutAfternoon({ time: "", note: "" });
      setIsMessage("");
    }
  }, [open]);

  React.useEffect(() => {
    if (
      isActionLoading ||
      isCreateTimekeepingSuccess ||
      isUpdateTimekeepingSuccess
    ) {
      onClose();
    }
  }, [isActionLoading, isCreateTimekeepingSuccess, isUpdateTimekeepingSuccess]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box>
          <TextField
            size="sm"
            value={payload.name}
            label={t("label.fullName")}
            disabled
          />
        </Box>
        <Box>
          <TextField
            size="sm"
            value={payload.date}
            label={t("label.date")}
            disabled
          />
        </Box>
        {!_.isEmpty(isMessage) && (
          <Text size="sm" sx={{ color: "error" }}>
            {isMessage}
          </Text>
        )}
        <Box mb={2} sx={{ display: "flex" }} gap={2}>
          <Box>
            {checkUpdateTimekeeping ? (
              <Box gap={2} mb={2}>
                <Text size={"sm"} mr={1} mb={2} fontWeight={700}>
                  {t("label.morningStart")}
                </Text>
                <TimeField
                  value={valueCheckIn.time}
                  onChange={(_event, e) => {
                    setValueCheckIn({ ...valueCheckIn, time: e });
                    setIsMessage("");
                  }}
                  showSeconds
                  input={<TextField size="sm" />}
                />
              </Box>
            ) : (
              <TextField
                size="sm"
                value={valueCheckIn.time}
                disabled
                label={t("label.morningStart")}
              />
            )}
          </Box>
          {checkUpdateTimekeeping ? (
            <Box gap={2}>
              <Text size={"sm"} mr={1} mb={1} fontWeight={700}>
                {t("label.morningEnd")}
              </Text>
              <Box>
                <TimeField
                  value={valueCheckOut.time}
                  onChange={(_event, e) => {
                    setValueCheckOut({ ...valueCheckOut, time: e });
                    setIsMessage("");
                  }}
                  showSeconds
                  input={<TextField size="sm" />}
                />
              </Box>
            </Box>
          ) : (
            <TextField
              size="sm"
              value={valueCheckOut.time}
              disabled
              label={t("label.morningEnd")}
            />
          )}
        </Box>
        <Box>
          <TextareaComponent
            rows={2}
            label={t("label.morningStartNote")}
            value={valueCheckIn.note}
            onChange={(e) => {
              setValueCheckIn({ ...valueCheckIn, note: e.target.value });
              setIsMessage("");
            }}
            disabled={!checkUpdateTimekeeping}
          />
          <TextareaComponent
            rows={2}
            label={t("label.morningEndNote")}
            value={valueCheckOut.note}
            onChange={(e) => {
              setValueCheckOut({ ...valueCheckOut, note: e.target.value });
              setIsMessage("");
            }}
            disabled={!checkUpdateTimekeeping}
          />
        </Box>
        <Box mb={2} sx={{ display: "flex" }} gap={2}>
          <Box>
            {checkUpdateTimekeeping ? (
              <Box gap={2} mb={2}>
                <Text size={"sm"} mr={1} mb={2} fontWeight={700}>
                  {t("label.afternoonStart")}
                </Text>
                <TimeField
                  value={valueCheckInAfternoon.time}
                  onChange={(_event, e) => {
                    setValueCheckInAfternoon({
                      ...valueCheckOutAfternoon,
                      time: e,
                    });
                    setIsMessage("");
                  }}
                  showSeconds
                  input={<TextField size="sm" />}
                />
              </Box>
            ) : (
              <TextField
                size="sm"
                value={valueCheckInAfternoon.time}
                disabled
                label={t("label.afternoonStart")}
              />
            )}
          </Box>
          <Box>
            {checkUpdateTimekeeping ? (
              <Box gap={2} mb={2}>
                <Text size={"sm"} mr={1} mb={2} fontWeight={700}>
                  {t("label.afternoonEnd")}
                </Text>
                <TimeField
                  value={valueCheckOutAfternoon.time}
                  onChange={(_event, e) => {
                    setValueCheckOutAfternoon({
                      ...valueCheckOutAfternoon,
                      time: e,
                    });
                    setIsMessage("");
                  }}
                  showSeconds
                  input={<TextField size="sm" />}
                />
              </Box>
            ) : (
              <TextField
                size="sm"
                value={valueCheckOutAfternoon.time}
                disabled
                label={t("label.afternoonEnd")}
              />
            )}
          </Box>
        </Box>
        <Box>
          <TextareaComponent
            rows={2}
            label={t("label.affternoonStartNote")}
            value={valueCheckInAfternoon.note}
            onChange={(e) => {
              setValueCheckInAfternoon({
                ...valueCheckInAfternoon,
                note: e.target.value,
              });
              setIsMessage("");
            }}
            disabled={!checkUpdateTimekeeping}
          />
          <TextareaComponent
            rows={2}
            label={t("label.afternoonEndNote")}
            value={valueCheckOutAfternoon.note}
            onChange={(e) => {
              setValueCheckOutAfternoon({
                ...valueCheckOutAfternoon,
                note: e.target.value,
              });
              setIsMessage("");
            }}
            disabled={!checkUpdateTimekeeping}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="sm"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 1px -1px gray",
          }}
        >
          {checkUpdateTimekeeping
            ? t("title.updateTimesheets")
            : t("title.detailTimesheets")}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflow: "auto",
          }}
        >
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px -1px 1px gray",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          {checkUpdateTimekeeping && (
            <Button
              onClick={() => handleSubmit()}
              ml={3}
              colorScheme="twitter"
              size={"sm"}
              isLoading={isActionLoading}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateTimekeepingDialog;
