import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import { IUser } from "@/Interfaces/User.interface";
import { RootState } from "@/Store";
import Utils from "@/Utils";
import { Icon } from "@chakra-ui/icon";
import { DownloadIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "../Common";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { useTranslation } from "react-multi-lang";

interface IDayData {
  id: string;
  date: string;
  time: string;
  type: string;
  note: string;
}

interface IDataStructure {
  id: string;
  name: string;
  avatar?: string;
  timekeeping: IDayData[];
  date?: any;
}

interface Props {
  action?: string;
}

const headerExceljs = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
];

const ExportExelTimeSheets: React.FC<Props> = ({ action }) => {
  const userData = Utils.getSavedUserData();
  const t = useTranslation();

  const timekeeping = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "timekeepingList")
  );
  const users: IUser[] = useSelector((state: RootState) =>
    _.get(state.USER, "userList")
  );
  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "pagination")
  );

  const workingTimeList: any = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const tableTimekeeping = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timekeeping",
    "tableTimekeeping"
  );

  const startDate = dayjs(pagination?.startDate);
  const endDate = dayjs(pagination?.endDate);

  const daysInMonth = Array.from(
    { length: startDate.daysInMonth() },
    (_, index) =>
      startDate.startOf("month").add(index, "day").format("YYYY-MM-DD")
  );

  const generateTableData = (startDate: any, endDate: any) => {
    const header = [];
    let currentDate = dayjs(startDate);

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      header.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }

    return header;
  };
  const headerDates = generateTableData(startDate, endDate);
  const transformData = (
    timekeeping: any[],
    users: IUser[]
  ): IDataStructure[] => {
    const data: IDataStructure[] = [];
    if (users?.length > 0 && tableTimekeeping) {
      _.forEach(users, (user) => {
        const userId = user.id;
        if (!_.isEmpty(pagination?.roleCode)) {
          const userRoles = _.map(
            user?.userRole,
            (userRole) => userRole?.role?.roleCode
          );
          if (
            !_.some(pagination?.roleCode, (roleCode) =>
              _.includes(userRoles, roleCode)
            )
          )
            return;
        }

        if (
          !_.isEmpty(pagination?.userId) &&
          !_.includes(pagination?.userId, userId)
        ) {
          return;
        }

        if (
          !_.isEmpty(pagination?.keyword) &&
          !_.includes(user?.userData?.fullName, pagination?.keyword)
        ) {
          return;
        }

        const employee = _.find(timekeeping, { id: userId });
        const userItem: IUser = employee ? { ...user, ...employee } : user;

        const employeeData: IDataStructure = {
          id: userItem?.id,
          name: userItem?.userData?.fullName,
          avatar: userItem?.userData?.avatar?.path,
          timekeeping: [],
        };

        _.forEach(userItem?.timekeeping, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            time: entry.time,
            type: entry.type,
            note: entry.note,
          };
          employeeData.timekeeping.push(dayData);
        });
        data.push(employeeData);
      });
    } else {
      _.forEach(timekeeping, (employee) => {
        const employeeData: IDataStructure = {
          id: employee.id,
          name: employee.userData.fullName,
          avatar: employee.userData?.avatar?.path,
          timekeeping: [],
        };
        _.forEach(employee.timekeeping, (entry) => {
          const dayData: IDayData = {
            id: entry.id,
            date: dayjs(entry.date).format("YYYY-MM-DD"),
            time: entry.time,
            type: entry.type,
            note: entry.note,
          };
          employeeData.timekeeping.push(dayData);
        });
        data.push(employeeData);
      });
    }

    const groupTimekeepingByDate: any = _.map(data, (employee) => {
      return {
        ...employee,
        timekeeping: _.chain(employee?.timekeeping)
          .groupBy("date")
          .map((value, key) => ({ date: key, timekeeping: value }))
          .value(),
      };
    });
    return groupTimekeepingByDate;
  };

  const transformedData = useMemo(() => {
    return transformData(timekeeping, users);
  }, [timekeeping, users]);

  const transformedHeader = useMemo(() => {
    const data: any[] = [];
    if (action === "month")
      daysInMonth.forEach((day) => {
        data.push(
          `${dayjs(day).get("date")}  ${Utils.getDayOfWeek(day, "short")}`
        );
      });
    else
      headerDates.forEach((day) => {
        data.push(
          `${dayjs(day).get("date")}  ${Utils.getDayOfWeek(day, "short")}`
        );
      });

    return data;
  }, [daysInMonth, headerDates]);

  const dataHeader = [`${t("table.fullName")}`, ...transformedHeader];

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    worksheet.getColumn(1).width = 25;
    for (let i = 2; i < dataHeader?.length + 1; i++) {
      worksheet.getColumn(i).width = 25;
    }

    //title
    if (action === "month") {
      worksheet.getCell("A1").value = `${t("title.timesheets")} ${
        dayjs(pagination?.startDate).get("month") + 1
      }/${dayjs(pagination?.endDate).get("year")}`;

      if (transformedHeader?.length === 31) worksheet.mergeCells("A1:AF1");
      if (transformedHeader?.length === 30) worksheet.mergeCells("A1:AE1");
      if (transformedHeader?.length === 29) worksheet.mergeCells("A1:AD1");
      if (transformedHeader?.length === 7) worksheet.mergeCells("A1:H1");

      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.getCell("A1").font = {
        size: 20,
        bold: true,
        color: { argb: "FFFFFF" },
      };

      worksheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4F81BD" },
      };
      worksheet.getRow(1).height = 30;
    }
    // row 2
    if (action === "weeks") {
      worksheet.getCell("A1").value = t("title.timesheets");
      worksheet.mergeCells("A1:H1");
      worksheet.getCell("A1").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.getCell("A1").font = {
        size: 20,
        bold: true,
        color: { argb: "FFFFFF" },
      };

      worksheet.getCell("A1").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4F81BD" },
      };
      worksheet.getRow(1).height = 30;
      //
      worksheet.getCell("A2").value = `${dayjs(pagination?.startDate).format(
        "DD/MM/YYYY"
      )} - ${dayjs(pagination?.endDate).format("DD/MM/YYYY")}`;
      worksheet.mergeCells("A2:H2");
      worksheet.getCell("A2").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      worksheet.getCell("A2").font = {
        size: 13,
        bold: false,
        color: { argb: "FFFFFF" },
      };
      worksheet.getCell("A2").fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4F81BD" },
      };
      worksheet.getRow(2).height = 20;
    }

    //header
    worksheet.addRow(dataHeader);

    _.forEach(headerExceljs, (i, index) => {
      if (transformedHeader?.length === 31) {
        worksheet.getCell(`${i}2`).border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
      if (transformedHeader?.length === 30) {
        if (index < 31) {
          worksheet.getCell(`${i}2`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        }
      }
      if (transformedHeader?.length === 29) {
        if (index < 30) {
          worksheet.getCell(`${i}2`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        }
      }
      if (transformedHeader?.length === 7) {
        if (index < 8) {
          worksheet.getCell(`${i}3`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        }
      }
    });

    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).eachCell((cell: any) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data

    transformedData.forEach((item, index) => {
      const data: any[] = [];
      if (action === "month") {
        _.map(daysInMonth, (day) => {
          const matchingDay: any = _.find(item?.timekeeping, (dayData: any) =>
            dayjs(dayjs(dayData.date).format("YYYY-MM-DD")).isSame(
              dayjs(day).format("YYYY-MM-DD")
            )
          );

          let timeCheckIn: number = 0;
          let timeCheckInAffternoon: number = 0;

          const currentDate = dayjs();
          const formatCurrentDate = currentDate.format("YYYY-MM-DD");

          const renderCheckIn = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-in"
            ),
            "time"
          )}`;

          const renderCheckOut = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-out"
            ),
            "time"
          )}`;

          const checkInAfternoon = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-in-afternoon"
            ),
            "time"
          )}`;

          const checkOutAfternoon = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-out-afternoon"
            ),
            "time"
          )}`;

          const resetSeconds = (timeString: any) => {
            const timeParts = timeString.split(":");

            const hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1]);

            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setSeconds(0);

            return `${date.getHours().toString().padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}:00`;
          };

          const currentTime = new Date();
          const formatTime =
            currentTime.getHours() +
            ":" +
            currentTime.getMinutes() +
            ":" +
            currentTime.getSeconds();

          const checkCurrentimeAfternoonEnd =
            workingTimeList[0]?.afternoonEnd &&
            formatTime > Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

          if (
            renderCheckIn &&
            renderCheckOut &&
            renderCheckIn !== "undefined" &&
            renderCheckOut !== "undefined"
          ) {
            const TotalTimeCheckIn = Utils.calculateWorkingTime(
              resetSeconds(renderCheckIn),
              resetSeconds(renderCheckOut)
            );
            timeCheckIn = _.toNumber(TotalTimeCheckIn);
          }

          if (
            checkInAfternoon &&
            checkOutAfternoon &&
            checkInAfternoon !== "undefined" &&
            checkOutAfternoon !== "undefined"
          ) {
            const TotalTimeCheckIn = Utils.calculateWorkingTime(
              resetSeconds(checkInAfternoon),
              resetSeconds(checkOutAfternoon)
            );
            timeCheckInAffternoon = _.toNumber(TotalTimeCheckIn);
          }
          const hours = Math.floor(timeCheckInAffternoon + timeCheckIn);
          const minutes = Math.round(
            (timeCheckInAffternoon + timeCheckIn - hours) * 60
          );

          data?.push(
            `${
              renderCheckIn === "undefined" ? "_" : renderCheckIn.slice(0, 5)
            } - ${
              renderCheckOut === "undefined" ? "_" : renderCheckOut.slice(0, 5)
            } \r\n ${
              checkInAfternoon === "undefined"
                ? "_"
                : checkInAfternoon.slice(0, 5)
            } - ${
              checkOutAfternoon === "undefined"
                ? "_"
                : checkOutAfternoon.slice(0, 5)
            } \r\n ${
              day === formatCurrentDate
                ? checkCurrentimeAfternoonEnd
                  ? renderCheckIn === "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                    ? t("message.absent")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn === "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn == "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined" &&
                      t("message.error")
                  : ""
                : day < formatCurrentDate
                ? renderCheckIn === "undefined" &&
                  renderCheckOut === "undefined" &&
                  checkInAfternoon === "undefined" &&
                  checkOutAfternoon === "undefined"
                  ? t("message.absent")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn === "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn == "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined" &&
                    t("message.error")
                : ""
            }`
          );
        });
      } else {
        _.map(headerDates, (day) => {
          const matchingDay: any = _.find(item?.timekeeping, (dayData: any) =>
            dayjs(dayjs(dayData.date).format("YYYY-MM-DD")).isSame(
              dayjs(day).format("YYYY-MM-DD")
            )
          );

          let timeCheckIn: number = 0;
          let timeCheckInAffternoon: number = 0;

          const resetSeconds = (timeString: any) => {
            const timeParts = timeString.split(":");

            const hours = parseInt(timeParts[0]);
            const minutes = parseInt(timeParts[1]);

            const date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setSeconds(0);

            return `${date.getHours().toString().padStart(2, "0")}:${date
              .getMinutes()
              .toString()
              .padStart(2, "0")}:00`;
          };

          const currentDate = dayjs();
          const formatCurrentDate = currentDate.format("YYYY-MM-DD");

          const currentTime = new Date();
          const formatTime =
            currentTime.getHours() +
            ":" +
            currentTime.getMinutes() +
            ":" +
            currentTime.getSeconds();

          const checkCurrentimeAfternoonEnd =
            workingTimeList[0]?.afternoonEnd &&
            formatTime > Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

          const renderCheckIn = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-in"
            ),
            "time"
          )}`;

          const renderCheckOut = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-out"
            ),
            "time"
          )}`;

          const checkInAfternoon = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-in-afternoon"
            ),
            "time"
          )}`;

          const checkOutAfternoon = `${_.get(
            _.find(
              matchingDay?.timekeeping,
              (timekeeping) => timekeeping.type === "check-out-afternoon"
            ),
            "time"
          )}`;

          if (
            checkInAfternoon &&
            checkOutAfternoon &&
            checkInAfternoon !== "undefined" &&
            checkOutAfternoon !== "undefined"
          ) {
            const TotalTimeCheckIn = Utils.calculateWorkingTime(
              resetSeconds(checkInAfternoon),
              resetSeconds(checkOutAfternoon)
            );
            timeCheckInAffternoon = _.toNumber(TotalTimeCheckIn);
          }

          if (
            renderCheckIn &&
            renderCheckOut &&
            renderCheckIn !== "undefined" &&
            renderCheckOut !== "undefined"
          ) {
            const TotalTimeCheckIn = Utils.calculateWorkingTime(
              resetSeconds(renderCheckIn),
              resetSeconds(renderCheckOut)
            );
            timeCheckIn = _.toNumber(TotalTimeCheckIn);
          }

          const hours = Math.floor(timeCheckInAffternoon + timeCheckIn);
          const minutes = Math.round(
            (timeCheckInAffternoon + timeCheckIn - hours) * 60
          );

          data?.push(
            `${
              renderCheckIn === "undefined" ? "_" : renderCheckIn.slice(0, 5)
            } - ${
              renderCheckOut === "undefined" ? "_" : renderCheckOut.slice(0, 5)
            } \r\n  ${
              checkInAfternoon === "undefined"
                ? "_"
                : checkInAfternoon.slice(0, 5)
            } - ${
              checkOutAfternoon === "undefined"
                ? "_"
                : checkOutAfternoon.slice(0, 5)
            }\r\n ${
              day === formatCurrentDate
                ? checkCurrentimeAfternoonEnd
                  ? renderCheckIn === "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                    ? t("message.absent")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn === "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut !== "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon !== "undefined"
                    ? hours + t("label.HourG") + minutes + t("label.minutes")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon === "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn !== "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined"
                    ? t("message.error")
                    : renderCheckIn == "undefined" &&
                      renderCheckOut === "undefined" &&
                      checkInAfternoon !== "undefined" &&
                      checkOutAfternoon === "undefined" &&
                      t("message.error")
                  : ""
                : day < formatCurrentDate
                ? renderCheckIn === "undefined" &&
                  renderCheckOut === "undefined" &&
                  checkInAfternoon === "undefined" &&
                  checkOutAfternoon === "undefined"
                  ? t("message.absent")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn === "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut !== "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon !== "undefined"
                  ? hours + t("label.HourG") + minutes + t("label.minutes")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon === "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn !== "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined"
                  ? t("message.error")
                  : renderCheckIn == "undefined" &&
                    renderCheckOut === "undefined" &&
                    checkInAfternoon !== "undefined" &&
                    checkOutAfternoon === "undefined" &&
                    t("message.error")
                : ""
            }`
          );
        });
      }
      const newData = [item?.name, ...data];

      worksheet.addRow(newData);

      _.forEach(headerExceljs, (i, key) => {
        if (transformedHeader?.length === 31)
          worksheet.getCell(`${i}${index + 3}`).border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
          };
        if (transformedHeader?.length === 30)
          if (key < 31)
            worksheet.getCell(`${i}${index + 3}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
        if (transformedHeader?.length === 29)
          if (key < 30)
            worksheet.getCell(`${i}${index + 3}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
        if (transformedHeader?.length === 7)
          if (key < 8)
            worksheet.getCell(`${i}${index + 4}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
      });

      worksheet.getColumn(1).alignment = {
        vertical: "middle",
        horizontal: "left",
      };

      worksheet.getRow(worksheet.rowCount).eachCell((cell: any) => {
        cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
      });
      worksheet.getRow(worksheet.rowCount).height = 60;
      for (let i = 2; i < dataHeader?.length + 1; i++) {
        worksheet.getColumn(i).alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "timesheets.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(transformedData)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};
export default ExportExelTimeSheets;
