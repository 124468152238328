import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Stack,
  Heading,
  Icon,
  Box,
  Button,
  Tooltip,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import { FunnelIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { SearchIcon } from "@chakra-ui/icons";

import { AdminLayout } from "@/Components/DefaultLayout";
import { PerformanceDialog } from "@/Components/Popup";
import {
  DateRangePicker,
  RoundedContainer,
  TextField,
} from "@/Components/Common";

import Utils from "@/Utils";
import { RootState, useTypedDispatch } from "@/Store";
import { PerformanceActions } from "@/Actions";

import PerformanceDataTable from "./DataTable";
import { ExportExcelPerformance } from "@/Components/Export";
import { useWindowWidth } from "@/Helpers";

import {
  IPerformAction,
  IPerformanceFilter,
} from "@/Interfaces/Performance.interface";
import { useTranslation } from "react-multi-lang";

const { fetchPerformances, resetPerformancePerformAction } = PerformanceActions;

const DEFAULT_FILTERS = {
  page: 1,
  limit: 10,
  startDate: "",
  endDate: "",
  keyword: "",
};

const Performance: React.FC = () => {
  const t = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();

  const performAction: IPerformAction = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "performAction")
  );

  const pagination: IPerformanceFilter = useSelector((state: RootState) =>
    _.get(state.PERFORMANCE, "pagination")
  );

  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => userRole?.role?.roleCode),
    [userData]
  );

  const checkExports = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Performance",
    "export"
  );

  const [filters, setFilters] = useState<IPerformanceFilter>(DEFAULT_FILTERS);
  const [dateRange, setDateRange] = useState<any>([null, null]);

  useEffect(() => {
    const accessRouter = Utils.checkRouterAccess(pathname, userRoles, true);
    if (accessRouter) {
      dispatch(fetchPerformances(DEFAULT_FILTERS));
    }
  }, []);

  const handleFilterChange = (name: string, value: string) =>
    setFilters({ ...filters, [name]: value });

  const handleResetField = (fieldNames: string | string[]) => {
    const resetFields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];

    const updatedPagination: any = { ...pagination };
    _.forEach(resetFields, (fieldName) => {
      updatedPagination[fieldName] = _.isArray(
        pagination[fieldName as keyof typeof pagination]
      )
        ? []
        : "";
    });
    setFilters(updatedPagination);

    if (_.isArray(fieldNames)) setDateRange([null, null]);

    dispatch(fetchPerformances(updatedPagination));
  };

  const handleSubmit = () => {
    const resolvedFilters = Utils.removeEmptyFields({
      ...filters,
      keyword: _.trim(filters.keyword),
    });
    if (
      !_.isEmpty(dateRange) &&
      dayjs(_.first(dateRange)).isValid() &&
      dayjs(_.last(dateRange)).isValid()
    ) {
      _.assign(resolvedFilters, {
        startDate: dayjs(_.first(dateRange)).format("YYYY-MM-DD"),
        endDate: dayjs(_.last(dateRange)).format("YYYY-MM-DD"),
      });
    }
    if (resolvedFilters) dispatch(fetchPerformances(resolvedFilters));
  };

  const handleReset = () => {
    setFilters(DEFAULT_FILTERS);
    setDateRange([null, null]);
    dispatch(fetchPerformances(DEFAULT_FILTERS));
  };

  const renderTag = (label: string, field: string | string[]) => {
    return (
      <Tag
        size="md"
        borderRadius="full"
        h="max-content"
        variant="solid"
        key={label}
      >
        <TagLabel>{label}</TagLabel>
        <TagCloseButton onClick={() => handleResetField(field)} />
      </Tag>
    );
  };

  const _renderTags = () => {
    const renderedTags: JSX.Element[] = [];
    if (!pagination) return;

    if (pagination.keyword) {
      renderedTags.push(renderTag(pagination.keyword, "keyword"));
    }

    if (pagination.startDate || pagination.endDate) {
      renderedTags.push(
        renderTag(
          `${dayjs(pagination.startDate).format("DD/MM/YYYY")} - ${dayjs(
            pagination.endDate
          ).format("DD/MM/YYYY")}`,
          ["startDate", "endDate"]
        )
      );
    }

    return (
      <HStack spacing={4}>
        <HStack spacing={1} alignItems="flex-start">
          <Text
            fontSize="sm"
            fontWeight={500}
            sx={{
              minW: "max-content",
            }}
            color={"#5C6e6c"}
          >
            {t("label.filterBy")}:
          </Text>
          <HStack spacing={1} flexWrap="wrap">
            {renderedTags}
          </HStack>
        </HStack>
      </HStack>
    );
  };

  const _renderFilterSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        <Box
          sx={{
            w: isMobile ? "full" : "275px",
          }}
        >
          <TextField
            size="sm"
            placeholder={t("label.search")}
            value={filters.keyword}
            onChange={(e) => handleFilterChange("keyword", e.target.value)}
            onEnter={() => filters.keyword && handleSubmit()}
            rightElement={
              <Tooltip label={t("label.search")} hasArrow>
                <Icon
                  as={SearchIcon}
                  boxSize={5}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                />
              </Tooltip>
            }
          />
        </Box>
        <Box>
          <DateRangePicker
            value={dateRange}
            onDateChange={(date) => setDateRange(date)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <Button
            size="sm"
            //colorScheme="whatsapp"
            leftIcon={<Icon as={FunnelIcon} />}
            onClick={handleSubmit}
            sx={{
              color: "#fff",
              background: "#D2A96A",
              "&:hover": {
                background: "#D2BD6A",
              },
            }}
          >
            {t("button.filter")}
          </Button>
          <Button
            leftIcon={<Icon as={ArrowPathIcon} />}
            size="sm"
            onClick={handleReset}
            sx={{
              color: "#fff",
              background: "#bb7154",
              "&:hover": {
                background: "#Db9d97",
              },
            }}
          >
            {t("button.reset")}
          </Button>
        </Box>
      </Box>
    );
  };

  const _renderPopupSection = () => (
    <>
      <PerformanceDialog
        open={!_.isEmpty(performAction)}
        onClose={() => {
          dispatch(resetPerformancePerformAction());
        }}
      />
    </>
  );

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.performanceEvaluation")}
          </Heading>
          <Box>{checkExports && <ExportExcelPerformance />}</Box>
        </Box>
        <RoundedContainer
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {_renderFilterSection()}
          {_renderTags()}
          <PerformanceDataTable />
        </RoundedContainer>

        {_renderPopupSection()}
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Performance;
