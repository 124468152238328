import React, { useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Icon,
  Stack,
  Select,
  Button,
  Checkbox,
} from "@chakra-ui/react";
import { CalendarDaysIcon, NewspaperIcon } from "@heroicons/react/24/outline";
import { Controller, useForm } from "react-hook-form";
import TextareaComponent from "@/Components/Common/Textarea";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import * as yup from "yup";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@/Components/Common";
import { DayOffRequestActions, HolidayActions } from "@/Actions";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";

const { updateDayOffRequest } = DayOffRequestActions;
const { fetchHoliday, resetHolidayReducer } = HolidayActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: any;
}

const UpdateSendDateRequestDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [duration, setDuration] = React.useState<any>(0);
  const [isMessage, setIsMessage] = React.useState<string>("");
  const [countHolidays, setCountHolidays] = React.useState<any>(0);

  const pagination: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "pagination")
  );
  const isUpdateDayOffRequestSuccess: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "isUpdateDayOffRequestSuccess")
  );
  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.DAY_OFF_REQUEST, "isActionLoading")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );

  const schema = yup
    .object()
    .shape({
      startDate: yup
        .string()
        .trim()
        .required(t("message.startTimeOffDateIsRequired")),
      endDate: yup
        .string()
        .trim()
        .required(t("message.endTimeOffDateIsRequired")),
      reason: yup.string().trim().required(t("message.reasonIsRequired")),
      startDateType: yup.string().trim(),
      endDateType: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const startDate: any = watch("startDate");
  const startDateType: any = watch("startDateType");
  const endDate: any = watch("endDate");
  const endDateType: any = watch("endDateType");

  const [isClicked, setIsClicked] = React.useState(false);

  const onSubmit = _.throttle((data: any) => {
    if (!isClicked) {
      const formData = new FormData();
      if (duration <= 0) {
        setIsMessage(t("message.leaveDateInvalid"));
        return;
      }
      formData.append(
        "startDate",
        dayjs(data.startDate).isValid()
          ? dayjs(data.startDate).format("YYYY-MM-DD")
          : ""
      );
      formData.append(
        "endDate",
        dayjs(data.endDate).isValid()
          ? dayjs(data.endDate).format("YYYY-MM-DD")
          : ""
      );

      const jsonStringSaturdayHasFull =
        saturdays && JSON.stringify(saturdaysNew);

      formData.append("startDateType", data.startDateType);
      formData.append("endDateType", data.endDateType);
      formData.append("reason", data.reason);
      formData.append("duration", duration);
      formData.append("durationAdd", countHolidays);
      formData.append("saturdayHasFull", jsonStringSaturdayHasFull);
      if (payload?.id)
        dispatch(updateDayOffRequest(payload?.id, formData, pagination));
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  }, 1000);

  //  const onSubmit = (data: any) => {
  //    const formData = new FormData();
  //    if (duration <= 0) {
  //      setIsMessage("Leave date invalid");
  //      return;
  //    }
  //    formData.append(
  //      "startDate",
  //      dayjs(data.startDate).isValid()
  //        ? dayjs(data.startDate).format("YYYY-MM-DD")
  //        : ""
  //    );
  //    formData.append(
  //      "endDate",
  //      dayjs(data.endDate).isValid()
  //        ? dayjs(data.endDate).format("YYYY-MM-DD")
  //        : ""
  //    );
  //    formData.append("startDateType", data.startDateType);
  //    formData.append("endDateType", data.endDateType);
  //    formData.append("reason", data.reason);
  //    formData.append("duration", duration);
  //    if (payload?.id)
  //      dispatch(updateDayOffRequest(payload?.id, formData, pagination));
  //  };

  const onDateChangeRequest = (
    dateStart: any,
    endDate: any,
    startDateType: string,
    endDateType: string
  ) => {
    const newDateStart = dayjs(dateStart).format("YYYY-MM-DD");
    const newDateEnd = dayjs(endDate).format("YYYY-MM-DD");

    // holidays
    const countHolidays = (
      startDate: string,
      endDate: string,
      holidays: any[]
    ) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      let holidayCount = 0;

      let tempDate = new Date(start);
      while (tempDate <= new Date(end)) {
        const currentDate = dayjs(tempDate).format("YYYY-MM-DD");

        if (holidays.some((holiday) => holiday.day === currentDate)) {
          holidayCount++;
        }
        tempDate.setDate(tempDate.getDate() + 1);
      }

      return holidayCount;
    };

    const holidayCount = countHolidays(startDate, endDate, holidaysList);
    setCountHolidays(holidayCount);

    if (
      newDateStart &&
      newDateEnd &&
      newDateStart !== "Invalid Date" &&
      newDateEnd !== "Invalid Date"
    ) {
      let start = new Date(newDateStart),
        end = new Date(newDateEnd),
        holiday: string | any[] = [],
        i = holiday.length,
        n_days = 0;
      while (i--) {
        // loop over holidays
        if (holiday[i] >= start && holiday[i] <= end) n_days = n_days - 1; // day holiday within dates
      }

      while (start <= end) {
        if (start.getUTCDay() === 0) n_days = n_days - 0.5;
        if (start.getUTCDay()) n_days = n_days + 1; // not sunday
        start.setUTCHours(24); // add a day
      }

      if (startDateType === "am" && endDateType === "am") {
        n_days = n_days - 0.5 - holidayCount;
      }
      if (startDateType === "pm" && endDateType === "am") {
        n_days = n_days - 1 - holidayCount;
      }
      if (startDateType === "pm" && endDateType === "pm") {
        n_days = n_days - 0.5 - holidayCount;
      }
      if (startDateType === "am" && endDateType === "pm") {
        n_days = n_days - holidayCount;
      }

      setDuration(n_days);
    } else setDuration(0);
  };

  const [saturdays, setSaturdays] = React.useState<any>([]);
  const [saturdaysNew, setSaturdaysNew] = React.useState<any>({});

  const onChangeSaturday = (
    startDate: any,
    endDate: any,
    startDateType: string,
    endDateType: string
  ) => {
    const newStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const newEndDate = dayjs(endDate).format("YYYY-MM-DD");

    const findSaturdays = (start: any, end: any) => {
      const newStart = new Date(start);
      const nenwEnd = new Date(end);
      let current = new Date(newStart);
      let saturdays = [];
      while (current <= nenwEnd) {
        if (current.getDay() === 6) {
          const newSaturdays = dayjs(current).format("YYYY-MM-DD");
          saturdays.push(newSaturdays);
        }
        current.setDate(current.getDate() + 1);
      }
      return saturdays;
    };
    setSaturdays(findSaturdays(newStartDate, newEndDate));

    const convertedObject =
      findSaturdays(newStartDate, newEndDate) &&
      findSaturdays(newStartDate, newEndDate).reduce((obj: any, date: any) => {
        if (date === newEndDate && endDateType === "pm") {
          obj[date] = true;
        }
        if (date === newEndDate && endDateType === "am") {
          obj[date] = false;
        }
        if (
          newStartDate === newEndDate &&
          startDateType === "am" &&
          endDateType === "pm"
        ) {
          obj[date] = true;
        }
        if (newStartDate !== newEndDate && date !== newEndDate) {
          obj[date] = false;
        }
        return obj;
      }, {});

    setSaturdaysNew(convertedObject);
  };

  React.useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  React.useEffect(() => {
    const saturdayHasFullObject =
      payload?.saturdayHasFull && JSON.parse(payload?.saturdayHasFull);

    const newStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const newEndDate = dayjs(endDate).format("YYYY-MM-DD");

    const convertedObject =
      saturdays &&
      saturdays.reduce((obj: any, date: any) => {
        if (date === newEndDate && endDateType === "pm") {
          obj[date] = true;
        }
        if (date === newEndDate && endDateType === "am") {
          obj[date] = false;
        }
        if (
          newStartDate === newEndDate &&
          startDateType === "am" &&
          endDateType === "pm"
        ) {
          obj[date] = true;
        }
        if (newStartDate !== newEndDate && date !== newEndDate) {
          //  obj[date] = false;
          if (saturdays && saturdayHasFullObject) {
            obj[date] = saturdaysNew?.[date] ? saturdaysNew?.[date] : false;
          } else obj[date] = false;
        }
        return obj;
      }, {});

    setSaturdaysNew(convertedObject);
  }, [payload]);

  React.useEffect(() => {
    const saturdayHasFullObject =
      payload?.saturdayHasFull && JSON.parse(payload?.saturdayHasFull);

    const keysArray =
      saturdayHasFullObject && Object.keys(saturdayHasFullObject);

    setValue("startDate", payload?.startDate);
    setValue("startDateType", payload?.startDateType);
    setValue("endDate", payload?.endDate);
    setValue("endDateType", payload?.endDateType);
    setValue("reason", payload?.reason);
    setDuration(payload?.duration);
    setCountHolidays(payload?.durationAdd);
    setSaturdaysNew(saturdayHasFullObject);
    setSaturdays(keysArray);
  }, [payload]);

  React.useEffect(() => {
    if (isUpdateDayOffRequestSuccess) {
      onClose();
      reset();
      setDuration(0);
    }
  }, [isUpdateDayOffRequestSuccess]);

  React.useEffect(() => {
    if (!open) {
      reset();
      setDuration(0);
      setIsMessage("");
      setSaturdays([]);
      setSaturdaysNew({});
    }
  }, [open]);

  const memoizedCheckbox = () => {
    const newStartDays = dayjs(startDate).format("YYYY-MM-DD");
    const newEndDays = dayjs(endDate).format("YYYY-MM-DD");
    return (
      <Box>
        {_.map(saturdays, (item, index: any) => {
          return (
            <Stack key={index}>
              {(item === newStartDays && startDateType !== "am") ||
              item === newEndDays ? (
                <>
                  <Checkbox
                    size="md"
                    colorScheme="green"
                    isChecked={saturdaysNew?.[item]}
                    disabled
                  >
                    {item}
                  </Checkbox>
                </>
              ) : (
                <Checkbox
                  size="md"
                  colorScheme="green"
                  isChecked={saturdaysNew?.[item]}
                  onChange={(e) => {
                    //const updatedDataArray = [...saturdays];
                    //updatedDataArray[index].value =
                    //  !updatedDataArray[index].value;
                    //setSaturdays(updatedDataArray);
                    setSaturdaysNew({
                      ...saturdaysNew,
                      [item]: e.target.checked,
                    });
                    if (e.target.checked) {
                      setDuration(duration + 0.5);
                    } else setDuration(duration - 0.5);
                  }}
                >
                  {item}
                </Checkbox>
              )}
            </Stack>
          );
        })}
      </Box>
    );
  };

  const _renderContent = () => {
    const startDateNew = dayjs(startDate).format("YYYY");
    const maxEndDate = `${_.toNumber(startDateNew)}-12-31`;
    const endDateNew = dayjs(endDate).format("YYYY");
    const minStartDate = `${_.toNumber(endDateNew)}-01-01`;
    const newStartDays = dayjs(startDate).format("YYYY-MM-DD");
    const newEndDays = dayjs(endDate).format("YYYY-MM-DD");

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        as={"form"}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Heading
                fontSize="sm"
                sx={{
                  mb: 2,
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
                {t("label.startTimeOffDate")}
                <Text color="error" ml={1} size="sm">
                  *
                </Text>
              </Heading>
              <Grid templateColumns="repeat(6, 1fr)" gap={2} gridAutoFlow="row">
                <GridItem colSpan={4}>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <Stack>
                        <DatePicker
                          maxDate={endDate && new Date(endDate)}
                          minDate={minStartDate && new Date(minStartDate)}
                          value={field.value}
                          onDateChange={(e: any) => {
                            field.onChange(e);
                            setIsMessage("");
                            onDateChangeRequest(
                              e,
                              endDate,
                              startDateNew,
                              endDateType
                            );
                            onChangeSaturday(
                              e,
                              endDate,
                              startDateNew,
                              endDateType
                            );
                          }}
                          isError={!_.isEmpty(errors.startDate?.message)}
                          errorMessage={errors.startDate?.message}
                          //label="Birthday"
                          disabledTitle
                          size="sm"
                        />
                      </Stack>
                    )}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Controller
                    name="startDateType"
                    control={control}
                    render={({ field }) => (
                      <Stack>
                        <Select
                          placeholder=""
                          size={"sm"}
                          value={field.value}
                          onChange={(e: any) => {
                            field.onChange(e.target.value);
                            setIsMessage("");
                            onDateChangeRequest(
                              startDate,
                              endDate,
                              e.target.value,
                              endDateType
                            );
                            onChangeSaturday(
                              startDate,
                              endDate,
                              e.target.value,
                              endDateType
                            );
                          }}
                        >
                          <option value="am">AM</option>
                          <option value="pm">PM</option>
                        </Select>
                      </Stack>
                    )}
                  />
                </GridItem>
              </Grid>
            </Box>
            <Box>
              <Box>
                <Heading
                  fontSize="sm"
                  sx={{
                    mb: 2,
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Icon as={CalendarDaysIcon} boxSize={3} mr={1} />
                  {t("label.endTimeOffDate")}
                  <Text color="error" ml={1} size="sm">
                    *
                  </Text>
                </Heading>
              </Box>
              <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                <GridItem colSpan={4}>
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <Stack mb={5}>
                        <DatePicker
                          minDate={startDate && new Date(startDate)}
                          maxDate={new Date(maxEndDate)}
                          value={field.value}
                          onDateChange={(e: any) => {
                            field.onChange(e);
                            setIsMessage("");
                            onDateChangeRequest(
                              startDate,
                              e,
                              startDateType,
                              endDateType
                            );
                            onChangeSaturday(
                              startDate,
                              e,
                              startDateType,
                              endDateType
                            );
                          }}
                          isError={!_.isEmpty(errors.endDate?.message)}
                          errorMessage={errors.endDate?.message}
                          //label="Birthday"
                          size="sm"
                          disabledTitle
                        />
                      </Stack>
                    )}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Controller
                    name="endDateType"
                    control={control}
                    render={({ field }) => (
                      <Stack mb={5}>
                        <Select
                          value={field.value}
                          size={"sm"}
                          placeholder=""
                          onChange={(e: any) => {
                            field.onChange(e.target.value);
                            setIsMessage("");
                            onDateChangeRequest(
                              startDate,
                              endDate,
                              startDateType,
                              e.target.value
                            );
                            onChangeSaturday(
                              startDate,
                              endDate,
                              startDateType,
                              e.target.value
                            );
                          }}
                        >
                          <option value="am">AM</option>
                          <option value="pm">PM</option>
                        </Select>
                      </Stack>
                    )}
                  />
                </GridItem>
              </Grid>
            </Box>
          </Box>
          {!_.isEmpty(isMessage) && (
            <Text size="sm" sx={{ color: "error" }}>
              {isMessage}
            </Text>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            flexWrap: "wrap",
          }}
        >
          {!_.isEmpty(saturdays) && newStartDays !== newEndDays && (
            <Box sx={{ minW: 230, alignItems: "center" }}>
              <Heading
                fontSize="sm"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  mr: 2,
                }}
              >
                {t("label.workAllDayOnSaturday")}:
              </Heading>
              <Box my={2}>{memoizedCheckbox()}</Box>
            </Box>
          )}
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
                alignItems: "center",
              }}
            >
              {t("label.duration")}:
            </Heading>
            <Text sx={{ fontSize: 15, fontWeight: 700, ml: 2 }}>
              {duration}
            </Text>
          </Box>
        </Box>
        <Heading
          fontSize="sm"
          sx={{
            mb: 2,
            alignItems: "center",
            display: "flex",
          }}
        >
          <Icon as={NewspaperIcon} boxSize={3} mr={1} />
          {t("label.timeOffReason")}
          <Text color="error" ml={1} size="sm">
            *
          </Text>
        </Heading>
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Stack mb={5}>
              <TextareaComponent
                size={"sm"}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                  setIsMessage("");
                }}
                isError={!_.isEmpty(errors.reason?.message)}
                errorMessage={errors.reason?.message}
              />
            </Stack>
          )}
        />
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="lg"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.updateDateOffRequest")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Box>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose();
                reset();
                setDuration(0);
              }}
              size={"sm"}
              isLoading={isActionLoading}
              disabled={isActionLoading}
            >
              {t("button.cancel")}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              ml={3}
              //  colorScheme="twitter"
              size={"sm"}
              isLoading={isActionLoading}
              disabled={isActionLoading}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateSendDateRequestDialog;
