import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";

import {
  Stack,
  Heading,
  Box,
  Icon,
  IconButton,
  Tooltip,
  Button,
  Text,
} from "@chakra-ui/react";

import "react-calendar/dist/Calendar.css";
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

import { AdminLayout } from "@/Components/DefaultLayout";
import { MonthPicker, RoundedContainer, TextField } from "@/Components/Common";

import { TimekeepingActions, UserActions } from "@/Actions";
import { useTypedDispatch, RootState } from "@/Store";

import TableComponent from "./TableComponent";
import { useSelector } from "react-redux";
import { ITimesheetPagination } from "@/Interfaces/Timesheet.interface";
import Utils from "@/Utils";
import { ArrowPathIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { ENUMS } from "@/Constants";
import { ExportExelTimeSheets } from "@/Components/Export";
import CommonColors from "@/Themes/CommonColors";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import "dayjs/locale/vi";

const { fetchTimekeeping, resetTimekeepingReducer } = TimekeepingActions;
const { fetchUser, resetUserReducer } = UserActions;

interface IFilters {
  keyword?: string;
  startDate?: string;
  endDate?: string;
  userId?: string[];
}

const DEFAULT_FILTERS = {
  page: 0,
  limit: 0,
  keyword: "",
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
};

const Timekeeping: React.FC = () => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const userData = Utils.getSavedUserData();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState<any[]>([]);
  const [filters, setFilters] = useState<IFilters>(DEFAULT_FILTERS);
  const [openTable, setOpenTable] = useState<string>("weeks");
  const [searchParams, setSearchParams] = useSearchParams();
  const language = Utils.getSavedLanguage();

  const recordAction = searchParams.get("action");
  const recordStartDate = searchParams.get("startDate");
  const recordEndDate = searchParams.get("endDate");

  const [keyword, setKeyWord] = useState<string>("");
  const [valueKeyword, setValueKeyword] = useState<string>("");

  const pagination: ITimesheetPagination = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "pagination")
  );

  const checkSearchTimekeeping = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timekeeping",
    "checkUpdate"
  );
  const canFetchUsers = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Users",
    "fetchUsers"
  );

  const exportExcel = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "Timekeeping",
    "export"
  );

  const fieldGroupSize = useMemo(() => {
    return {
      base: "wrap",
      xl: "nowrap",
    };
  }, []);

  const handlePrevClick = () => {
    const updatedFilters = {
      ...filters,
      startDate: dayjs(filters.startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate)
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    };

    searchParams.set("startDate", updatedFilters?.startDate);
    searchParams.set("endDate", updatedFilters?.endDate);
    searchParams.set("action", "month");
    setSearchParams(searchParams);

    setFilters(updatedFilters);
    dispatch(fetchTimekeeping(updatedFilters));
  };

  const handleNextClick = () => {
    const updatedFilters = {
      ...filters,
      startDate: dayjs(filters.startDate)
        .add(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: dayjs(filters.endDate)
        .add(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    };

    searchParams.set("startDate", updatedFilters?.startDate);
    searchParams.set("endDate", updatedFilters?.endDate);
    searchParams.set("action", "month");
    setSearchParams(searchParams);

    setFilters(updatedFilters);
    dispatch(fetchTimekeeping(updatedFilters));
  };

  //  const handleFiltersChange = (key: string, value: string | string[]) => {
  //    setFilters({ ...filters, [key]: value });
  //  };

  const handleReset = () => {
    if (openTable === "month") {
      setFilters(DEFAULT_FILTERS);
      dispatch(fetchTimekeeping(DEFAULT_FILTERS));
      searchParams.delete("startDate");
      searchParams.delete("endDate");
      setSearchParams(searchParams);
    } else {
      dispatch(fetchTimekeeping(DEFAULT_FILTERS));
      const currentDateFilter = new Date();
      const weekdays = getDaysOfWeek(currentDateFilter, "YYYY-MM-DD");
      setDaysOfWeek(weekdays);
      setFilters({
        ...DEFAULT_FILTERS,
        startDate: _.first(weekdays),
        endDate: _.last(weekdays),
      });
      dispatch(
        fetchTimekeeping({
          ...DEFAULT_FILTERS,
          startDate: _.first(weekdays),
          endDate: _.last(weekdays),
        })
      );
      searchParams.delete("startDate");
      searchParams.delete("endDate");
      setSearchParams(searchParams);
    }
    setKeyWord("");
    setValueKeyword("");
  };

  const handleSubmit = () => {
    const resolvedFilters: any = Utils.removeEmptyFields({
      ...filters,
      keyword: _.trim(filters.keyword),
    });
    if (resolvedFilters) {
      if (openTable === "month") {
        dispatch(fetchTimekeeping(resolvedFilters));
        searchParams.set("startDate", resolvedFilters?.startDate);
        searchParams.set("endDate", resolvedFilters?.endDate);
        searchParams.set("action", "month");
        setSearchParams(searchParams);
      } else {
        const currentDateFilter = new Date();
        const weekdays = getDaysOfWeek(currentDateFilter, "YYYY-MM-DD");
        setDaysOfWeek(weekdays);
        const newStartDate = _.first(weekdays)?.toString();
        const newEndDate: any = _.last(weekdays)?.toString();
        dispatch(
          fetchTimekeeping({
            ...resolvedFilters,
            startDate: _.first(weekdays),
            endDate: _.last(weekdays),
          })
        );

        if (newStartDate && newEndDate) {
          searchParams.set("startDate", newStartDate);
          searchParams.set("endDate", newEndDate);
          setSearchParams(searchParams);
        }
      }
    }
    valueKeyword ? setKeyWord(valueKeyword) : setKeyWord("");
  };

  const getDaysOfWeek = (date: Date, dateFormat: string) => {
    const daysOfWeek = [];
    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
    );

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(firstDayOfWeek);
      currentDate.setDate(firstDayOfWeek.getDate() + i);
      daysOfWeek.push(dayjs(currentDate).format(dateFormat));
    }

    return daysOfWeek;
  };

  useEffect(() => {
    if (recordAction) {
      setOpenTable(recordAction);
      if (recordAction === "weeks")
        if (recordStartDate) setCurrentDate(new Date(recordStartDate));
    }
  }, [recordAction]);

  useEffect(() => {
    if (currentDate) {
      if (recordAction) {
        if (recordStartDate && recordEndDate) {
          if (recordAction === "weeks") {
            const weekdays = getDaysOfWeek(
              new Date(recordStartDate),
              "YYYY-MM-DD"
            );
            setDaysOfWeek(weekdays);
            dispatch(
              fetchTimekeeping({
                page: 0,
                limit: 0,
                startDate: recordStartDate,
                endDate: recordEndDate,
              })
            );
          } else {
            dispatch(
              fetchTimekeeping({
                ...DEFAULT_FILTERS,
                startDate: recordStartDate,
                endDate: recordEndDate,
              })
            );
            setFilters({
              ...filters,
              startDate: recordStartDate,
              endDate: recordEndDate,
            });
          }
        } else {
          if (recordAction === "weeks") {
            const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
            setDaysOfWeek(weekdays);
            dispatch(
              fetchTimekeeping({
                page: 0,
                limit: 0,
                startDate: _.first(weekdays),
                endDate: _.last(weekdays),
              })
            );
          } else {
            dispatch(fetchTimekeeping(DEFAULT_FILTERS));
          }
        }
      } else if (openTable === "weeks") {
        const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
        setDaysOfWeek(weekdays);
        dispatch(
          fetchTimekeeping({
            page: 0,
            limit: 0,
            startDate: _.first(weekdays),
            endDate: _.last(weekdays),
          })
        );
      }
    } else {
      if (openTable === "weeks") {
        const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
        setDaysOfWeek(weekdays);
        dispatch(
          fetchTimekeeping({
            page: 0,
            limit: 0,
            startDate: _.first(weekdays),
            endDate: _.last(weekdays),
          })
        );
      } else dispatch(fetchTimekeeping(DEFAULT_FILTERS));
    }
    //if (canFetchUsers)
    //  dispatch(
    //    fetchUser({
    //      page: 0,
    //      limit: 0,
    //      status: ENUMS.USER_STATUS.ACTIVE,
    //    })
    //  );
    return () => {
      dispatch(resetTimekeepingReducer());
      //  dispatch(resetUserReducer());
    };
  }, [currentDate, openTable]);

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  useEffect(() => {
    if (canFetchUsers)
      dispatch(
        fetchUser({
          page: 0,
          limit: 0,
          status: ENUMS.USER_STATUS.ACTIVE,
        })
      );
    return () => {
      dispatch(resetUserReducer());
    };
  }, []);

  const _renderFilterSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: fieldGroupSize,
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 3,
            flexWrap: `${openTable === "month" && "wrap"}`,
          }}
        >
          <Box>
            {checkSearchTimekeeping && (
              <TextField
                size="sm"
                placeholder={t("label.search")}
                value={valueKeyword}
                onChange={(e) => {
                  setValueKeyword(e.target.value);
                  //handleFiltersChange("keyword", e.target.value);
                }}
                //onKeyDown={(e) => {
                //  const element = e.currentTarget as HTMLInputElement;
                //  const value = element.value;
                //  if (e.key === "Enter")
                //    dispatch(fetchTimekeeping({ ...filters, keyword: value }));
                //}}
                onEnter={() => {
                  //filters?.keyword && dispatch(fetchTimekeeping(filters));
                  valueKeyword ? setKeyWord(valueKeyword) : setKeyWord("");
                }}
                rightElement={
                  <Tooltip label={t("label.search")} hasArrow>
                    <Icon
                      as={SearchIcon}
                      boxSize={5}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        //  dispatch(fetchTimekeeping(filters))
                        valueKeyword
                          ? setKeyWord(valueKeyword)
                          : setKeyWord("");
                      }}
                    />
                  </Tooltip>
                }
              />
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
            {recordAction === "month" && openTable === "month" && (
              <Box width={135}>
                <MonthPicker
                  defaultValue={{
                    startDate: filters?.startDate,
                    endDate: filters?.endDate,
                  }}
                  onDateChange={({ startDate, endDate }) =>
                    setFilters({ ...filters, startDate, endDate })
                  }
                />
              </Box>
            )}
            {recordAction === "month" &&
              (checkSearchTimekeeping || openTable === "month") && (
                <Box gap={3} sx={{ display: "flex" }}>
                  <Button
                    size="sm"
                    onClick={handleSubmit}
                    colorScheme="whatsapp"
                    leftIcon={<Icon as={FunnelIcon} />}
                    sx={{
                      color: "#fff",
                      background: "#D2A96A",
                      "&:hover": {
                        background: "#D2BD6A",
                      },
                    }}
                  >
                    {t("button.filter")}
                  </Button>
                  <Button
                    size="sm"
                    onClick={handleReset}
                    leftIcon={<Icon as={ArrowPathIcon} />}
                    sx={{
                      color: "#fff",
                      background: "#bb7154",
                      "&:hover": {
                        background: "#Db9d97",
                      },
                    }}
                  >
                    {t("button.reset")}
                  </Button>
                </Box>
              )}
          </Box>
        </Box>
        <Box
          height="100%"
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Button
            size={"sm"}
            sx={{
              fontWeight: 500,
              borderRadius: "5px 0 0 5px",
              borderTop: "2px solid",
              borderRight: "1px solid",
              borderBottom: "2px solid",
              borderLeft: "2px solid",
              borderColor: CommonColors.burntSienna,
              color: openTable == "weeks" ? "white" : "#28282A",
              background: `${
                openTable == "weeks" ? CommonColors.burntSienna : "white"
              }`,
              "&:hover": {
                background: CommonColors.burntSienna,
                color: "white",
              },
            }}
            onClick={() => {
              if (openTable !== "weeks") {
                setOpenTable("weeks");
                searchParams.delete("startDate");
                searchParams.delete("endDate");
                searchParams.set("action", "weeks");
                setSearchParams(searchParams);
                const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
                setDaysOfWeek(weekdays);
              }
            }}
          >
            {t("button.week")}
          </Button>
          <Button
            size={"sm"}
            sx={{
              fontWeight: 500,
              borderRadius: "0 5px 5px 0",
              borderTop: "2px solid ",
              borderRight: "2px solid ",
              borderBottom: "2px solid ",
              borderLeft: "1px solid ",
              color: openTable == "month" ? "white" : "#28282A",
              borderColor: CommonColors.burntSienna,
              background: `${
                openTable == "month" ? CommonColors.burntSienna : "white"
              }`,
              "&:hover": {
                background: CommonColors.burntSienna,
                color: "white",
              },
            }}
            onClick={() => {
              if (openTable !== "month") {
                setOpenTable("month");
                setCurrentDate(new Date());
                searchParams.delete("startDate");
                searchParams.delete("endDate");
                searchParams.set("action", "month");
                setSearchParams(searchParams);
                setFilters(DEFAULT_FILTERS);
              }
            }}
          >
            {t("button.month")}
          </Button>
        </Box>
      </Box>
    );
  };

  const formattedDateRange = React.useMemo(() => {
    const startDate = dayjs(_.first(daysOfWeek));
    const endDate = dayjs(_.last(daysOfWeek));

    const isValidStartDate = dayjs(startDate).isValid();
    const isValidEndDate = endDate.isValid();

    const formattedStartDate = isValidStartDate
      ? startDate.format("MMM D")
      : "";
    const formattedEndDate = isValidEndDate
      ? endDate.format("MMM D, YYYY")
      : "";

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [daysOfWeek, currentDate]);

  const handlePrevWeekClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
    const weekdays = getDaysOfWeek(newDate, "YYYY-MM-DD");
    const newStartDate = _.first(weekdays)?.toString();
    const newEndDate = _.last(weekdays)?.toString();
    if (newStartDate && newEndDate) {
      searchParams.set("startDate", newStartDate);
      searchParams.set("endDate", newEndDate);
      searchParams.set("action", "weeks");
      setSearchParams(searchParams);
    }
  };

  const handleNextWeekClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
    const weekdays = getDaysOfWeek(newDate, "YYYY-MM-DD");
    const newStartDate = _.first(weekdays)?.toString();
    const newEndDate = _.last(weekdays)?.toString();
    if (newStartDate && newEndDate) {
      searchParams.set("startDate", newStartDate);
      searchParams.set("endDate", newEndDate);
      searchParams.set("action", "weeks");
      setSearchParams(searchParams);
    }
  };

  const _renderTopSection = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <IconButton
          isRound
          size="sm"
          background="transparent"
          aria-label={""}
          onClick={handlePrevWeekClick}
        >
          <ChevronLeftIcon boxSize={5} />
        </IconButton>
        <Heading size="md">{formattedDateRange}</Heading>
        <IconButton
          isRound
          size="sm"
          background="transparent"
          aria-label={""}
          onClick={handleNextWeekClick}
        >
          <ChevronRightIcon boxSize={5} />
        </IconButton>
      </Box>
      <Box
        sx={{
          opacity: 0,
        }}
      />
    </Box>
  );

  const _renderDataTableSection = () => {
    const startDate = dayjs(pagination?.startDate).format("MMMM");
    const endDate = dayjs(pagination?.endDate).get("year");
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 3,
          mt: "1em",
        }}
      >
        {openTable === "weeks" ? (
          <Box
            sx={{
              mx: "auto",
              display: "flex",
              textAlign: "center",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {_renderTopSection()}
          </Box>
        ) : (
          <Box
            sx={{
              mx: "auto",
              display: "flex",
              textAlign: "center",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton
              isRound
              size="sm"
              background="transparent"
              aria-label={""}
              onClick={handlePrevClick}
              sx={{
                position: "relative",
                zIndex: 1,
              }}
            >
              <ChevronLeftIcon boxSize={5} />
            </IconButton>
            <Text
              fontSize="18px"
              fontWeight={700}
              sx={{ textTransform: "capitalize" }}
            >
              {`${startDate} - ${endDate}`}
            </Text>
            <IconButton
              isRound
              size="sm"
              background="transparent"
              aria-label={""}
              onClick={handleNextClick}
            >
              <ChevronRightIcon boxSize={5} />
            </IconButton>
          </Box>
        )}
        <TableComponent
          action={recordAction ? recordAction : openTable}
          keyword={keyword}
        />
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Heading size="md" color={"#5C6e6c"}>
            {t("title.timesheets")}
          </Heading>
          {exportExcel && <ExportExelTimeSheets action={openTable} />}
        </Box>
        <RoundedContainer>
          {_renderFilterSection()}
          {_renderDataTableSection()}
        </RoundedContainer>
      </Stack>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default Timekeeping;
