export default {
  EXAMPLE: {
    ROOT: "/example",
  },
  AUTHENTICATION: {
    LOGIN: "auth/login",
    REFRESH_TOKEN: "auth/refresh",
    FORGOT_PASSWORD: "auth/forgot-password",
    VERIFY_ACCOUNT: "auth/verify-account",
    RESET_PASSWORD: "auth/reset-password",
  },
  FCM_TOKEN: {
    BASIC: "fcm-tokens",
    GET_BY_ID: "fcm-tokens/get-by-id",
    CHANGE_LOCALE: "fcm-tokens/change-locale",
  },

  ROLE: {
    BASIC: "roles",
  },
  DASHBOARD: {
    BASIC: "dashboard/statistical",
    DATE_DELETION_SCHEDULE: "date-deletion-schedules",
  },
  USER: {
    BASIC: "users",
    CREATE: "users/register-account",
    GET_BY_ID: "users/get-by-id",
    GET_FOR_SELF: "users/get-for-self",
    UPDATE: "users/update",
    UPDATE_FOR_SELF: "users/update-for-self",
    UPDATE_PASSWORD_FOR_SELF: "users/update-password-for-self",
    ACTIVATE: "users/activate",
    DEACTIVATE: "users/deactivate",
    DELETE: "users/terminated",
  },
  PROJECT: {
    BASIC: "projects",
    GET_BY_ID: "projects/get-by-id",
    CHANGE_STATUS: "projects/change-status",
    DELETE: "projects/delete",
    DASHBOARD_OVERVIEW: "projects/dashboard-overview",
  },
  KANBAN_BOARD: {
    BASIC: "kanban-board",
    GET_BY_ID: "kanban-board/get-by-id",
    ACTIVATE: "kanban-board/activate",
    DEACTIVATE: "kanban-board/deactivate",
    DELETE: "kanban-board/delete",
    GET_TIMELINE: "kanban-board/get-by-id-for-timeline",
  },
  TASK: {
    BASIC: "tasks",
    GET_BY_ID: "tasks/get-by-id",
    COMMENT: "tasks/comment",
    DELETE_COMMENT: "tasks/comment/delete",
    DELETE_COLUMN: "tasks/delete",
  },
  LOG_TIME: {
    BASIC: "log-times",
    GET_BY_ID: "log-times/get-by-id",
    CHANGE_STATUS: "log-times/change-status",
    DELETE: "log-times/delete",
    AGGREGATE: "log-times/aggregate-user",
    FOR_DASHBOARD: "log-times/for-dashboard",
    DEADLINE_DASHBOARD: "log-times/get-deadline-for-dashboard",
    MY_WORK_DASHBOARD: "log-times/for-dashboard-my-work",
  },
  TASK_NOTE: {
    BASIC: "task-notes",
    GET_BY_ID: "task-notes/get-by-id",
    DELETE: "task-notes/delete",
  },
  DAY_OFF_REQUEST: {
    BASIC: "day-off-requests",
    GET_BY_ID: "day-off-requests/get-by-id",
    CHANGE_STATUS: "day-off-requests/change-status",
    DELETE: "day-off-requests/delete",
    AGGREGATE: "day-off-requests/aggregate-user",
  },
  LEAVE_DAYS: {
    BASIC: "leave-days",
    GET_BY_ID: "leave-days/get-by-id",
  },
  UPDATE_TIME_CARD: {
    BASIC: "request-update-timecards",
    GET_BY_ID: "request-update-timecards/get-by-id",
    CHANGE_STATUS: "request-update-timecards/change-status",
    DELETE: "request-update-timecards/delete",
  },
  TIMEKEEPING: {
    BASIC: "timekeeping",
    GET_BY_ID: "timekeeping/get-by-id",
    UPDATE: "timekeeping/by-admin",
  },
  TIME_SHEET: {
    BASIC: "timesheet",
    GET_BY_ID: "timesheet/get-by-id",
    DELETE: "timesheet/delete",
  },
  FILE: {},
  INTERNAL_IP: {
    BASIC: "internal-ip-addresses",
    GET_BY_ID: "internal-ip-addresses/get-by-id",
    CHANGE_STATUS: "internal-ip-addresses/change-status",
    INTERNAL_IP_REMOTE: "internal-ip-addresses/allow-access-remote",
    FETCH_INTERNAL_IP_REMOTE: "internal-ip-addresses/work-remote",
  },
  HOLIDAY: {
    BASIC: "holidays",
    GET_BY_ID: "holidays/get-by-id",
    CHANGE_STATUS: "holidays/change-status",
  },
  PERFORMANCE: {
    BASIC: "performance-evaluation",
    GET_BY_ID: "performance-evaluation/get-by-id",
    CHANGE_STATUS: "performance-evaluation/change-status",
  },
  NOTIFICATION: {
    BASIC: "notifications",
    GET_BY_ID: "notifications/get-by-id",
    FETCH_OUTSIDE: "notifications/outside",
    MARK_AS_READ: "notifications/mark-as-read",
    MARK_ALL_AS_READ: "notifications/mark-all-as-read",
    MARK_AS_READ_OUTSIDE: "notifications/mark-as-read-outside",
  },
  CHAT: {
    FETCH_MESSAGE_CHANNEL: "conversations",
    FETCH_MESSAGE_NOTIFICATION: "conversations/notification",
    FETCH_MESSAGE_BY_CHANEL_ID: "conversations/message",
    FETCH_USER_NOT_CONVERSATION: "conversations/user-not-conversation",
    FETCH_ALL_USERS: "conversations/all-user",
    FETCH_MEDIA_BY_CHANNEL_ID: "conversations/media",
    FETCH_MESSAGE_CHANNEL_DETAIL: "conversations/get-by-id",
    CREATE_PRIVATE_MESSAGE: "conversations/private",
    CREATE_GROUP: "conversations/group",
    CREATE_MESSAGE: "conversations/message",
    DELETE_PRIVATE_MESSAGE: "conversations/delete-private",
    RECALLED_MESSAGE: "conversations/recalled-message",
    ADD_MEMBER_GROUP: "conversations/add-member-group",
    REMOVE_MEMBER_GROUP: "conversations/remove-member-group",
    READ_CONVERSATION: "conversations/read-conversation",
    UPDATE_CONVERSATION: "conversations/update",
    LEAVE_GROUP: "conversations/leave-group",
    REMOVE_ALL_FILE: "conversations/remove-file",
  },
  CLIENT: {
    BASIC: "clients",
    GET_BY_ID: "clients/get-by-id",
    CHANGE_STATUS: "clients/change-status",
  },
  WORKING_TIME: {
    BASIC: "working-time",
    GET_BY_ID: "working-time/get-by-id",
  },

  PROJECT_EXTERIOR: {
    BASIC: "project-exterior",
    GET_BY_ID: "project-exterior/get-by-id",
    CHANGE_STATUS: "project-exterior/change-status",
    DELETE: "projects/delete",
    FETCH_USER_CAN_ADD: "project-exterior/get-user-can-add",
  },
  BACKUP: {
    BASIC: "maintenance-mode",
    BACKUP_LIST: "database-managements/list-backup-database",
    BACKUP_DATABASE: "database-managements/backup-database",
    RESTORE: "database-managements/restore-database",
    DELETE: "database-managements/delete",
  },
  FILES: {
    BASIC: "files",
    ALL_MEMORY_CONVERSATION: "files/all-memory-conversation",
  },
  TIME_DELETE_PRIVATE_CHAT: {
    BASIC: "time-delete-private-conversation",
    GET_BY_ID: "time-delete-private-conversation/get-by-id",
  },
  EMAIL_RECEIVE: {
    BASIC: "mail-receive",
    GET_BY_ID: "mail-receive/get-by-id",
  },
  WORKSPACE: {
    BASIC: "workspace-groups",
    GET_BY_ID: "workspace-groups/get-by-id",
    DELETE: "workspace-groups/delete",
  },
  WORKSPACE_SHORT: {
    BASIC: "workspace-shortcuts",
    GET_BY_ID: "workspace-shortcuts/get-by-id",
    DELETE: "workspace-shortcuts/delete",
  },
};
