interface ActionTypes {
  [key: string]: string;
}

const generateSyncActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== "") {
      map[`${name}_SUCCESS`] = `${name}_SUCCESS`;
      map[`${name}_FAILURE`] = `${name}_FAILURE`;
    }
  });
  return map;
};

const generateLoadingActions = (actionList: string[]) => {
  const map: { [key: string]: string } = {};
  actionList.map((action) => {
    const name = action.trim();
    if (name !== "") {
      map[`SET_${name}_FETCH_LOADING`] = `SET_${name}_FETCH_LOADING`;
      map[`SET_${name}_GET_LOADING`] = `SET_${name}_GET_LOADING`;
      map[`SET_${name}_ACTION_LOADING`] = `SET_${name}_ACTION_LOADING`;
    }
  });
  return map;
};

const _loadingActions: ActionTypes = generateLoadingActions([
  "PROJECT",
  "BOARD",
  "USER",
  "ROLE",
  "LOG_TIME",
  "LOGTIME",
  "DAY_OFF_REQUEST",
  "LEAVE_DAYS",
  "REQUEST_UPDATE_TIME_CARD",
  "TIMEKEEPING",
  "CHAT",
  "TIMESHEET",
  "INTERNAL_IP",
  "HOLIDAY",
  "AGGREGATE",
  "PERFORMANCE",
  "NOTIFICATION",
  "TASK_NOTE",
  "CLIENT",
  "WORKING_TIME",
  "PROJECT_EXTERIOR",
  "BACKUP",
  "TIME_DELETE_FILE_PRIVATE_CHAT",
  "MAIL_RECEIVE",
  "WORKSPACE",
]);

const _asyncActions: ActionTypes = generateSyncActions([
  //Authentication
  "LOGIN",
  "LOGOUT",
  "REQUEST_FORGOT_PASSWORD",
  "VERIFY_ACCOUNT",
  "RESET_PASSWORD",
  "CREATE_FCM_TOKEN",
  "REMOVE_FCM_TOKEN",
  // Role
  "FETCH_ROLES",

  // Dashboard action
  "GET_STATISTICAL",
  "GET_DATE_DELETION_SCHEDULE",
  // USer
  "CREATE_USER",
  "FETCH_USER",
  "GET_BY_ID_USER",
  "GET_FOR_SELF",
  "UPDATE_USER",
  "UPDATE_FOR_SELF",
  "UPDATE_PASSWORD_FOR_SELF",
  "ACTIVATE_USER",
  "DEACTIVATE_USER",
  "DELETE_USER",
  "FETCH_USER_DASH_BOARD",

  // Project
  "FETCH_PROJECTS",
  "FETCH_PROJECTS_FOR_DASHBOARD",
  "GET_STATISTICAL_PROJECTS",
  "CREATE_PROJECT",
  "UPDATE_PROJECT",
  "GET_PROJECT_BY_ID",
  "CHANGE_PROJECT_STATUS",
  "DELETE_PROJECT",

  // Board
  "CREATE_BOARD",
  "FETCH_BOARDS",
  "GET_BOARD_BY_ID",
  "CREATE_COLUMN_IN_BOARD",
  "GET_COLUMN_IN_BOARD",
  "UPDATE_COLUMN_IN_BOARD",
  "CREATE_COMMENT_IN_COLUMN",
  "DELETE_COMMENT_IN_COLUMN",
  "DELETE_COLUMN_IN_BOARD",
  "GET_TIMELINE_IN_BOARD",

  //Logtime
  "CREATE_LOG_TIME",
  "FETCH_LOG_TIME",
  "GET_BY_ID_LOG_TIME",
  "UPDATE_LOG_TIME",
  "CHANGE_STATUS_LOG_TIME",
  "DELETE_LOG_TIME",
  "FETCH_LOG_TIME_BY_BOARD_ID",
  "GET_MY_TIME",
  "FETCH_AGGREGATE",
  "FETCH_LOG_TIME_DASH_BOARD",
  "FETCH_DEADLINE_DASH_BOARD",
  "FETCH_MY_WORK_DASH_BOARD",

  // Plan Time
  "FETCH_TASK_NOTE",
  "CREATE_TASK_NOTE",
  "GET_TASK_NOTE_BY_ID",
  "UPDATE_TASK_NOTE",
  "DELETE_TASK_NOTE",

  //day off request
  "CREATE_DAY_OFF_REQUEST",
  "FETCH_DAY_OFF_REQUEST",
  "UPDATE_DAY_OFF_REQUEST",
  "CHANGE_STATUS_DAY_OFF_REQUEST",
  "GET_BY_ID_DAY_OFF_REQUEST",
  "DELETE_DAY_OFF_REQUEST",
  "FETCH_AGGREGATE_DAY_OFF",

  // leave days
  "CREATE_LEAVE_DAYS",
  "FETCH_LEAVE_DAYS",
  "UPDATE_LEAVE_DAYS",
  "GET_BY_ID_LEAVE_DAYS",
  "FETCH_LEAVE_DAYS_FILTER_CREATE",

  // request update timecard
  "CREATE_REQUEST_UPDATE_TIME_CARD",
  "FETCH_REQUEST_UPDATE_TIME_CARD",
  "UPDATE_REQUEST_UPDATE_TIME_CARD",
  "CHANGE_STATUS_REQUEST_UPDATE_TIME_CARD",
  "GET_BY_ID_REQUEST_UPDATE_TIME_CARD",
  "DELETE_REQUEST_UPDATE_TIME_CARD",

  //Timekeeping
  "CREATE_TIMEKEEPING",
  "FETCH_TIMEKEEPING",
  "UPDATE_TIMEKEEPING",
  "GET_BY_ID_TIMEKEEPING",
  "CREATE_CHECK_OUT",
  "UPDATE_CHECK_OUT",
  "FETCH_CHECK_IN",
  "CREATE_TIMEKEEPING_ADMIN",

  //Time sheet
  "FETCH_TIMESHEET",
  "GET_TIMESHEET_BY_ID",
  "CREATE_TIMESHEET",
  "UPDATE_TIMESHEET",
  "DELETE_TIMESHEET",

  // Chat
  "FETCH_CHAT",
  "CREATE_CHAT",
  "DELETE_CHAT",

  //Internal Ip
  "FETCH_INTERNAL_IP",
  "CREATE_INTERNAL_IP",
  "UPDATE_INTERNAL_IP",
  "CHANGE_STATUS_INTERNAL_IP",
  "GET_BY_ID_INTERNAL_IP",
  "FETCH_INTERNAL_REMOTE",
  "CHANGE_INTERNAL_IP_REMOTE",

  //Holidays
  "CREATE_HOLIDAY",
  "FETCH_HOLIDAY",
  "UPDATE_HOLIDAY",
  "GET_BY_ID_HOLIDAY",
  "CHANGE_STATUS_HOLIDAY",

  // Performance
  "FETCH_PERFORMANCES",
  "GET_PERFORMANCE_BY_ID",
  "CREATE_PERFORMANCE",
  "UPDATE_PERFORMANCE",

  // Notification
  "FETCH_NOTIFICATION",
  "FETCH_OUTSIDE_NOTIFICATION",
  "GET_NOTIFICATION_BY_ID",
  "MARK_AS_READ_NOTIFICATION",
  "MARK_ALL_AS_READ_NOTIFICATION",
  "MARK_ALL_AS_READ_OUTSIDE_NOTIFICATION",

  // Chat
  "FETCH_MESSAGE_CHANNEL",
  "FETCH_MESSAGE_NOTIFICATION",
  "FETCH_MESSAGE_BY_CHANNEL_ID",
  "FETCH_USER_NOT_CONVERSATION",
  "FETCH_ALL_USER_FOR_CHAT",
  "FETCH_MEDIA_BY_CHANNEL_ID",
  "CREATE_PRIVATE_CHAT",
  "CREATE_GROUP_CHAT",
  "CREATE_MESSAGE_CHAT",
  "RECALLED_MESSAGE",
  "DELETE_PRIVATE_MESSAGE",
  "ADD_MEMBER_TO_GROUP",
  "REMOVE_MEMBER_TO_GROUP",
  "LEAVE_GROUP_CHAT",
  "READ_CONVERSATION",
  "UPDATE_CONVERSATION",
  "FETCH_CONVERSATION_CHANNEL_DETAIL",
  "REMOVE_ALL_FILE_IN_CHAT",

  //Client
  "FETCH_CLIENT",
  "CREATE_CLIENT",
  "UPDATE_CLIENT",
  "CHANGE_STATUS_CLIENT",
  "GET_BY_ID_CLIENT",
  //WorkingTime
  "FETCH_WORKING_TIME",
  "UPDATE_WORKING_TIME",

  // Project Exterior
  "FETCH_PROJECT_EXTERIORS",
  "CREATE_PROJECT_EXTERIOR",
  "GET_PROJECT_EXTERIOR_BY_ID",
  "DELETE_PROJECT_EXTERIOR",
  "CHANGE_PROJECT_EXTERIOR_STATUS",
  "UPDATE_PROJECT_EXTERIOR",
  "FETCH_USER_CAN_ADD",
  //backup
  "FETCH_BACKUP",
  "CHANGE_STATUS_BACKUP",
  "FETCH_BACKUP_DATABASE",
  "GET_BACKUP_DATABASE",
  "RESTORE_DATABASE",
  "DELETE_BACKUP",
  "GET_ALL_MEMORY_CONVERSATION",
  // time delete file private chat
  "FETCH_TIME_DELETE_FILE_PRIVATE_CHAT",
  "UPDATE_TIME_DELETE_FILE_PRIVATE_CHAT",
  "CREATE_TIME_DELETE_FILE_PRIVATE_CHAT",
  // Mail receive
  "FETCH_MAIL_RECEIVE",
  "CREATE_MAIL_RECEIVE",
  "UPDATE_MAIL_RECEIVE",
  // workspace
  "FETCH_WORKSPACE",
  "UPDATE_WORKSPACE",
  "CREATE_WORKSPACE",
  "GET_BY_ID_WORKSPACE",
  "DELETE_WORKSPACE",
  // workspace shortCut
  "FETCH_WORKSPACE_SHORT_CUT",
  "UPDATE_WORKSPACE_SHORT_CUT",
  "CREATE_WORKSPACE_SHORT_CUT",
  "GET_BY_ID_WORKSPACE_SHORT_CUT",
  "DELETE_WORKSPACE_SHORT_CUT",
  //locale
  "CHANGE_LOCALE",
]);

const _singleActions: ActionTypes = {
  // Dashboard actions
  SET_DASHBOARD_LOADING: "SET_DASHBOARD_LOADING",

  // Authentication action
  LOGOUT: "LOGOUT",
  SET_AUTH_LOADING: "SET_AUTH_LOADING",
  CLEAR_AUTH_MESSAGE: "CLEAR_AUTH_MESSAGE",
  RESET_STEP_FORGOT_PASSWORD: "RESET_STEP_FORGOT_PASSWORD",
  RESET_AUTH_REDUCER: "RESET_AUTH_REDUCER",
  RESET_CHECK_LOGIN: "RESET_CHECK_LOGIN",

  // Role action
  RESET_ROLES_REDUCER: "RESET_ROLES_REDUCER",

  // User action
  USER_PERFORM_ACTION: "USER_PERFORM_ACTION",
  RESET_USER_PERFORM_ACTION: "RESET_USER_PERFORM_ACTION",
  SET_USER_LOADING: "SET_USER_LOADING",
  SET_EXPORT_USER_LOADING: "SET_EXPORT_USER_LOADING",
  RESET_USER_REDUCER: "RESET_USER_REDUCER",
  SET_META_USER: "SET_META_USER",
  SET_PAGINATION_USER: "SET_PAGINATION_USER",
  RESET_ACTION_USER_REDUCER: "RESET_ACTION_USER_REDUCER",
  RESET_USER_MESSAGE: "RESET_USER_MESSAGE",
  RESET_USER_DETAIL: "RESET_USER_DETAIL",

  // Project action
  SAVE_CURRENT_DATE_IN_SCROLLER: "SAVE_CURRENT_DATE_IN_SCROLLER",
  SET_PROJECT_META: "SET_PROJECT_META",
  SET_PROJECT_PAGINATION: "SET_PROJECT_PAGINATION",
  STATUS_PROJECT_STATUS: "RESET_PROJECT_STATUS",
  RESET_PROJECT_REDUCER: "RESET_PROJECT_REDUCER",

  // Board action
  SET_MY_TIME_WEEKDAYS: "SET_MY_TIME_WEEKDAYS",
  SET_DASHBOARD_WEEKDAYS: "SET_DASHBOARD_WEEKDAYS",
  BOARD_PERFORM_ACTION: "BOARD_PERFORM_ACTION",
  RESET_BOARD_PERFORM_ACTION: "RESET_BOARD_PERFORM_ACTION",
  RESET_BOARD_STATUS: "RESET_BOARD_STATUS",
  RESET_WEEKDAYS: "RESET_WEEKDAYS",
  RESET_BOARD_DETAIL: "RESET_BOARD_DETAIL",
  RESET_TIMELINE_IN_BOARD: "RESET_TIMELINE_IN_BOARD",
  SET_TIMELINE_ACTIVE_DATES: "SET_TIMELINE_ACTIVE_DATES",
  RESET_BOARD_REDUCER: "RESET_BOARD_REDUCER",

  //Logtime
  SET_LOG_TIME_META: "SET_LOG_TIME_META",
  SET_LOG_TIME_PAGINATION: "SET_LOG_TIME_PAGINATION",
  LOG_TIME_PERFORM_ACTION: "LOGTIME_PERFORM_ACTION",
  RESET_LOG_TIME_PERFORM_ACTION: "RESET_LOGTIME_PERFORM_ACTION",
  RESET_LOG_TIME_STATUS: "RESET_LOG_TIME_STATUS",
  RESET_LOG_TIME_DETAIL: "RESET_LOG_TIME_DETAIL",
  RESET_LOG_TIME_REDUCER: "RESET_LOG_TIME_REDUCER",
  SET_AGGREGATE_PAGINATION: "SET_AGGREGATE_PAGINATION",
  SET_AGGREGATE_META: "SET_AGGREGATE_META",
  SET_AGGREGATE_DAY_OFF_PAGINATION: "SET_AGGREGATE_DAY_OFF_PAGINATION",
  SET_MY_TIME_PAGINATION: "SET_MY_TIME_PAGINATION",

  // Plan time
  RESET_TASK_NOTE_DETAILS: "RESET_TASK_NOTE_DETAILS",
  RESET_TASK_NOTE_REDUCER: "RESET_TASK_NOTE_REDUCER",

  //Day off request
  RESET_REDUCER_DAY_OFF_REQUEST: "RESET_REDUCER_DAY_OFF_REQUEST",
  SET_META_DAY_OFF_REQUEST: "SET_META_DAY_OFF_REQUEST",
  SET_PAGINATION_DAY_OFF_REQUEST: "SET_PAGINATION_DAY_OFF_REQUEST",
  SET_AGGREGATE_DAY_OFF_META: "SET_AGGREGATE_DAY_OFF_META",
  RESET_DAY_OFF_REQUEST_DETAILS: "RESET_DAY_OFF_REQUEST_DETAILS",

  //Leave days
  RESET_LEAVE_DAYS: "RESET_LEAVE_DAYS",
  SET_META_LEAVE_DAYS: "SET_META_LEAVE_DAYS",
  SET_PAGINATION_LEAVE_DAYS: "SET_PAGINATION_LEAVE_DAYS",
  RESET_LEAVE_DAY_DETAILS: "RESET_LEAVE_DAY_DETAILS",

  // request update timecard
  RESET_REDUCER_REQUEST_UPDATE_TIME_CARD:
    "RESET_REDUCER_REQUEST_UPDATE_TIME_CARD",
  SET_META_REQUEST_UPDATE_TIME_CARD: "SET_META_REQUEST_UPDATE_TIME_CARD",
  SET_PAGINATION_REQUEST_UPDATE_TIME_CARD:
    "SET_PAGINATION_REQUEST_UPDATE_TIME_CARD",
  RESET_REQUEST_UPDATE_TIME_CARD_DETAILS:
    "RESET_REQUEST_UPDATE_TIME_CARD_DETAILS",

  //Timekeeping
  RESET_REDUCER_TIMEKEEPING: "RESET_REDUCER_TIMEKEEPING",
  SET_META_TIMEKEEPING: "SET_META_TIMEKEEPING",
  SET_PAGINATION_TIMEKEEPING: "SET_PAGINATION_TIMEKEEPING",
  RESET_MESSAGE_TIMEKEEPING: "RESET_MESSAGE_TIMEKEEPING",

  // Time sheet
  RESET_TIMESHEET_STATUS: "RESET_TIMESHEET_STATUS",
  RESET_TIMESHEET_REDUCER: "RESET_TIMESHEET_REDUCER",
  SET_TIMESHEET_META: "SET_TIMESHEET_META",
  SET_TIMESHEET_PAGINATION: "SET_TIMESHEET_PAGINATION",
  // Chat
  SET_CHAT_FETCH_CHANNEL_LOADING: "SET_CHAT_FETCH_CHANNEL_LOADING",
  CHAT_PERFORM_ACTION: "CHAT_PERFORM_ACTION",
  RESET_CHAT_PERFORM_ACTION: "RESET_CHAT_PERFORM_ACTION",
  CREATE_NEW_CHAT: "CREATE_NEW_CHAT",
  RESET_NEW_CHAT_DATA: "RESET_NEW_CHAT_DATA",
  SET_POTENTIAL_CHATS: "SET_POTENTIAL_CHATS",
  RESET_CHAT_REDUCER: "RESET_CHAT_REDUCER",
  SET_MESSAGE_CHANNEL_PAGINATION: "SET_MESSAGE_CHANNEL_PAGINATION",
  SET_MESSAGE_CHANNEL_META: "SET_MESSAGE_CHANNEL_META",
  SET_MESSAGE_DETAILS_PAGINATION: "SET_MESSAGE_DETAILS_PAGINATION",
  SET_MESSAGE_DETAILS_META: "SET_MESSAGE_DETAILS_META",
  RESET_MEDIA_IN_CHANNEL: "RESET_MEDIA_IN_CHANNEL",
  SET_LIST_MESSAGES: "SET_LIST_MESSAGES",
  RESET_CONVERSATION_CHANNEL_DETAIL: "RESET_CONVERSATION_CHANNEL_DETAIL",
  SET_NEW_PERFORM_ACTION: "SET_NEW_PERFORM_ACTION",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SET_TYPE_CHAT: "SET_TYPE_CHAT",

  // Internal Ip
  RESET_INTERNAL_IP: "RESET_INTERNAL_IP",
  RESET_STATUS_INTERNAL_IP: "RESET_STATUS_INTERNAL_IP",
  SET_META_INTERNAL_IP: "ET_META_INTERNAL_IP",
  SET_PAGINATION_INTERNAL_IP: "SET_PAGINATION_INTERNAL_IP",

  //Holiday
  RESET_HOLIDAY: "RESET_HOLIDAY",
  SET_META_HOLIDAY: "SET_META_HOLIDAY",
  SET_PAGINATION_HOLIDAY: "SET_PAGINATION_HOLIDAY",

  // Performance
  PERFORMANCE_PERFORM_ACTION: "PERFORMANCE_PERFORM_ACTION",
  SET_PERFORMANCE_PAGINATION: "SET_PERFORMANCE_PAGINATION",
  SET_PERFORMANCE_META: "SET_PERFORMANCE_META",
  RESET_PERFORMANCE_PERFORM_ACTION: "RESET_PERFORMANCE_PERFORM_ACTION",
  RESET_PERFORMANCE_REDUCER: "RESET_PERFORMANCE_REDUCER",

  // Notification
  SET_NOTIFICATION_FETCH_OUTSIDE_LOADING:
    "SET_NOTIFICATION_FETCH_OUTSIDE_LOADING",
  RESET_NOTIFICATION_REDUCER: "RESET_NOTIFICATION_REDUCER",
  SET_PAGINATION_NOTIFICATION: "SET_PAGINATION_NOTIFICATION",
  SET_META_NOTIFICATION: "SET_META_NOTIFICATION",

  SET_SIDEBAR_EXPANDED: "SET_SIDEBAR_EXPANDED",

  // Socket
  USER_CONNECT_SOCKET: "USER_CONNECT_SOCKET",
  RESET_SOCKET_REDUCER: "RESET_SOCKET_REDUCER",
  // Client
  RESET_CLIENT_REDUCER: "RESET_CLIENT_REDUCER",
  SET_META_CLIENT: "SET_META_CLIENT",
  SET_PAGINATION_CLIENT: "SET_PAGINATION_CLIENT",
  RESET_STATUS_CLIENT: "RESET_STATUS_CLIENT",
  //Working time
  RESET_WORKING_TIME: "RESET_WORKING_TIME",

  // Project exteriorr action
  SET_PROJECT_EXTERIOR_META: "SET_PROJECT_EXTERIOR_META",
  SET_PROJECT_EXTERIOR_PAGINATION: "SET_PROJECT_EXTERIOR_PAGINATION",
  RESET_PROJECT_EXTERIOR_STATUS: "RESET_PROJECT_EXTERIOR_STATUS",
  RESET_PROJECT_EXTERIOR_REDUCER: "RESET_PROJECT_EXTERIOR_REDUCER",
  // backup
  RESET_BACKUP: "RESET_BACKUP",
  SET_META_BACKUP: "SET_META_BACKUP",
  SET_PAGINATION_BACKUP: "SET_PAGINATION_BACKUP",

  // Time delete file private chat
  RESET_TIME_DELETE_FILE_PRIVATE_CHAT_REDUCER:
    "RESET_TIME_DELETE_FILE_PRIVATE_CHAT_REDUCER",

  // Mail receive
  RESET_MAIL_RECEIVE_REDUCER: "RESET_MAIL_RECEIVE_REDUCER",

  //WorkSpace
  RESET_WORKSPACE: "RESET_WORKSPACE",
  WORKSPACE_MODAL: "WORKSPACE_MODAL",
  WORKSPACE_BUTTON: "WORKSPACE_BUTTON",
};

const ACTION_TYPES = {
  ..._asyncActions,
  ..._singleActions,
  ..._loadingActions,
};

export default ACTION_TYPES;
