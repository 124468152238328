import React, { useState, memo, useRef, useMemo, useEffect } from "react";
import _ from "lodash";
import dayjs from "dayjs";

import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useOutsideClick,
  IconButton,
  Text,
  Flex,
  Avatar,
  Icon,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";

import {
  ClockIcon,
  MinusCircleIcon,
  ChevronDownIcon,
  ArrowRightStartOnRectangleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { Routers, ENUMS } from "@/Constants";
import Utils from "@/Utils";
import { useWindowWidth } from "@/Helpers";

import SideBar from "../SideBar";
import Notification from "./Notification";
import Chat from "./Chat";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { RootState, useTypedDispatch } from "@/Store";
import { TimekeepingDialog, WarningCheckInDialog } from "@/Components/Popup";
import AuthenticationAction from "@/Actions/Authentication.action";
import TimekeepingAction from "@/Actions/Timekeeping.action";
import {
  NotificationActions,
  TimesheetActions,
  WorkingTimeActions,
} from "@/Actions";
import WarningCheckInAfternoonDialog from "@/Components/Popup/WarningCheckIn/WarningCheckInAfternoon";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-multi-lang";

const DEFAULT_FILTERS = {
  page: 0,
  limit: 0,
  keyword: "",
  startDate: dayjs(dayjs().startOf("month")).format("YYYY-MM-DD"),
  endDate: dayjs(dayjs().endOf("month")).format("YYYY-MM-DD"),
};

const { fetchCheckIn } = TimekeepingAction;
const { fetchWorkingTime } = WorkingTimeActions;
const { logout, resetCheckLogin } = AuthenticationAction;
const { fetchOutsideNotifications } = NotificationActions;
const { fetchTimesheets } = TimesheetActions;
const Appbar: React.FC = () => {
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const t = useTranslation();

  const isMobile = windowWidth <= 767;
  const isTablet = windowWidth >= 768 && windowWidth <= 1279;
  const isDesktop = windowWidth >= 1280;
  const isMobileResponsive = windowWidth <= 480;

  const [isShowNav, setIsShowNav] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenMesageTimekeeping, setIOpenMessageTimekeeping] =
    useState<boolean>(false);
  const [openWarningCheckIn, setOpenWarningCheckIn] = useState<boolean>(false);
  const [openWarningCheckInAfternoon, setOpenWarningCheckInAfternoon] =
    useState<boolean>(false);

  const [typeTimekeeping, setTypeTimekeeping] = React.useState<{
    open: boolean;
    type: string;
  }>({ open: false, type: "" });
  const userData = Utils.getSavedUserData();
  const firebaseToken = Utils.getSavedFirebaseToken() || "";
  const { pathname } = useLocation();

  const currentTime = dayjs();
  const formatTime = currentTime.format("HH:mm:ss");
  //  const checkCurrentime = formatTime < "07:30:00";

  const isCheckedInStored = localStorage.getItem("isCheckedIn");
  const isCheckedInStoredAfternoon = localStorage.getItem(
    "isCheckedInAfternoon"
  );

  const isCreateTimekeepingFail: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isCreaetTimekeepingFail")
  );
  const timekeepingList: any = useSelector((state: RootState) =>
    _.get(state.CHECKIN, "checkInList")
  );

  const isUpdateForSelfSuccess: any = useSelector((state: RootState) =>
    _.get(state.USER, "isUpdateForSelfSuccess")
  );
  const messageTimekeeping: any = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "message")
  );
  //  const isLoggedSuccess: any = useSelector((state: RootState) =>
  //    _.get(state.AUTH, "checkIsLogged")
  //  );
  const CheckIsPopubWarning = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "setting",
    "isCheckWarning"
  );

  const isCreateTimekeepingSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isCreateTimekeepingSuccess")
  );

  const workingTimeList: any = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const checkCurrentimeMorning =
    workingTimeList[0]?.morningStart &&
    formatTime >
      Utils.subtractTime(
        Utils.convertToTime(workingTimeList[0]?.morningStart),
        10
      );
  const checkCurrentimeMorningEnd =
    workingTimeList[0]?.morningEnd &&
    formatTime < Utils.convertToTime(workingTimeList[0]?.morningEnd);

  const checkCurrentimeAfternoonStart =
    workingTimeList[0]?.afternoonStart &&
    formatTime >
      Utils.subtractTime(
        Utils.convertToTime(workingTimeList[0]?.afternoonStart),
        10
      );

  const checkCurrentimeAfternoonEnd =
    workingTimeList[0]?.afternoonEnd &&
    formatTime < Utils.convertToTime(workingTimeList[0]?.afternoonEnd);

  const profileData: any = useMemo(() => {
    const result = Utils.getSavedUserData();
    return result;
  }, [isUpdateForSelfSuccess]);
  const roleNames = _.map(profileData?.userRole, (userRole) =>
    userRole?.role?.roleCode === ENUMS.ROLES.ARTIST
      ? `Staff`
      : userRole?.role?.name
  );
  const concatenatedRoleNames = roleNames.join(", ");

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick({
    ref: sidebarRef,
    handler: () => setIsShowNav(false),
  });
  const [searchParams] = useSearchParams();
  const idActive: any = searchParams.get("id");

  useEffect(() => {
    if (pathname === "/shortcut") {
      if (idActive) {
        setIsShowNav(false);
      }
    }
  }, [idActive]);

  React.useEffect(() => {
    if (isCreateTimekeepingFail) {
      setIOpenMessageTimekeeping(true);
    }
  }, [isCreateTimekeepingFail]);

  const uniqueUsers = _.filter(timekeepingList, (itemX: any) => {
    return itemX?.id === profileData?.id;
  });
  const timeCheck = useMemo(
    () =>
      _.filter(uniqueUsers[0]?.timekeeping, (itemX: any) => {
        return itemX?.date === dayjs(new Date()).format("YYYY-MM-DD");
      }),
    [uniqueUsers]
  );

  const isCheckIn = useMemo(
    () => _.find(timeCheck, (item) => item.type === "check-in"),
    [timeCheck]
  );

  const isCheckInAffternool = _.find(
    timeCheck,
    (item) => item.type === "check-in-afternoon"
  );

  const isCheckOut = useMemo(
    () => _.find(timeCheck, (item) => item.type === "check-out"),
    [timeCheck]
  );

  const isCheckOutAfternoon = _.find(
    timeCheck,
    (item) => item.type === "check-out-afternoon"
  );

  const handleClick = _.throttle(() => {
    if (!isClicked) {
      Utils.redirect(Routers.MY_TIME);
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 1000);
    }
  }, 1000);

  //  React.useEffect(() => {
  //    //if (isLoggedSuccess && !isCheckIn && !checkCurrentime) {
  //    //  setOpenWarningCheckIn(true);
  //    //} else setOpenWarningCheckIn(false);
  //  }, [isLoggedSuccess, isCheckIn]);

  React.useEffect(() => {
    if (
      _.isEmpty(isCheckedInStored) &&
      checkCurrentimeMorning &&
      checkCurrentimeMorningEnd &&
      !CheckIsPopubWarning &&
      _.isEmpty(isCheckIn)
    ) {
      setOpenWarningCheckIn(true);
    } else setOpenWarningCheckIn(false);
  }, [isCheckedInStored, checkCurrentimeMorning, checkCurrentimeMorningEnd]);

  React.useEffect(() => {
    if (
      _.isEmpty(isCheckedInStoredAfternoon) &&
      checkCurrentimeAfternoonStart &&
      checkCurrentimeAfternoonEnd &&
      !CheckIsPopubWarning &&
      _.isEmpty(isCheckInAffternool)
    ) {
      setOpenWarningCheckInAfternoon(true);
    } else setOpenWarningCheckInAfternoon(false);
  }, [
    isCheckedInStoredAfternoon,
    checkCurrentimeAfternoonStart,
    checkCurrentimeAfternoonEnd,
  ]);

  useEffect(() => {
    if (isCheckIn && !isCheckedInStored)
      localStorage.setItem("isCheckedIn", "true");
    if (isCheckInAffternool && !isCheckedInStoredAfternoon)
      localStorage.setItem("isCheckedInAfternoon", "true");
  }, [
    isCheckIn,
    isCheckedInStored,
    isCheckInAffternool,
    isCheckedInStoredAfternoon,
  ]);

  useEffect(() => {
    if (isCreateTimekeepingSuccess) {
      dispatch(fetchTimesheets(DEFAULT_FILTERS));
    }
  }, [isCreateTimekeepingSuccess]);

  const toggleSideBar = () => setIsShowNav(!isShowNav);

  const _renderPopup = useMemo(
    () => (
      <>
        <TimekeepingDialog.CreateTimekeepingDialog
          open={typeTimekeeping.open}
          onClose={() => setTypeTimekeeping({ open: false, type: "" })}
          type={typeTimekeeping.type}
        />
        <TimekeepingDialog.MessageTimekeepingDialog
          onClose={() => setIOpenMessageTimekeeping(false)}
          type={messageTimekeeping}
          open={isOpenMesageTimekeeping}
        />
      </>
    ),
    [typeTimekeeping, isOpenMesageTimekeeping, messageTimekeeping]
  );

  useEffect(() => {
    const currentDate = new Date();
    const weekdays = Utils.getDaysOfWeek(currentDate, "YYYY-MM-DD");
    if (_.isEmpty(timekeepingList)) {
      dispatch(
        fetchCheckIn({
          page: 0,
          limit: 0,
          startDate: _.first(weekdays),
          endDate: _.last(weekdays),
        })
      );
    }
    if (_.isEmpty(workingTimeList)) dispatch(fetchWorkingTime());
    dispatch(fetchOutsideNotifications());
  }, []);

  const _renderAvatar = () => (
    <Flex>
      <Flex flex="1" gap={2} alignItems="center" flexWrap="wrap">
        <Avatar
          src={profileData?.userData?.avatar?.path}
          name={profileData?.userData?.fullName}
          title={profileData?.userData?.fullName}
          boxSize={10}
        />
        {!isMobile && (
          <Box>
            <Text size="15px" fontWeight={600}>
              {profileData?.userData?.fullName || "Anonymous"}
            </Text>
            <Text
              sx={{
                color: "gray",
                fontSize: "12px",
                fontStyle: "normal",
                // fontWeight: 600,
                lineHeight: "normal",
                textTransform: "upperCase",
                WebkitLineClamp: 1,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                maxW: 200,
              }}
              title={concatenatedRoleNames}
            >
              {/* need update */}
              {concatenatedRoleNames}
            </Text>
          </Box>
        )}
      </Flex>
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={ChevronDownIcon} />}
          variant="ghost"
          sx={{
            bg: "none",
            "&:hover": {
              bg: "none",
            },
            "&[data-active]": {
              bg: "none",
            },
          }}
        />
        <MenuList>
          <MenuItem onClick={() => Utils.redirect(Routers.PROFILE)}>
            <Icon as={UserIcon} sx={{ mr: 2 }} />
            <Text fontSize="15px">{t("label.profile")}</Text>
          </MenuItem>
          <MenuItem onClick={handleClick}>
            <Icon as={ClockIcon} sx={{ mr: 2 }} />
            <Text fontSize="15px">{t("label.myTime")}</Text>
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(logout(userData?.id, firebaseToken));
            }}
          >
            <Icon as={ArrowRightStartOnRectangleIcon} sx={{ mr: 2 }} />
            <Text fontSize="15px">{t("label.logout")}</Text>
          </MenuItem>
          <LanguageSwitch />
        </MenuList>
      </Menu>
    </Flex>
  );

  return (
    <Box
      sx={{
        h: "3.2rem",
        background: "white",
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        px: "20px",
        position: "relative",
        zIndex: "sticky",
      }}
    >
      <Box
        sx={{
          zIndex: !isDesktop ? 999 : 9,
          mr: "auto",
        }}
        ref={sidebarRef}
      >
        {(isTablet || isMobile) && (
          <Popover isOpen={isShowNav}>
            <PopoverTrigger>
              <IconButton
                aria-label="Collapse"
                onClick={toggleSideBar}
                sx={{
                  display: isTablet || isMobile ? "block" : "none",
                }}
              >
                <HamburgerIcon />
              </IconButton>
            </PopoverTrigger>
            <PopoverContent
              sx={{
                h: "full",
                transition: "width .3s ease-in-out",
                overflowX: "hidden",
              }}
            >
              <SideBar isOpen={true} onClick={() => {}} />
            </PopoverContent>
          </Popover>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          zIndex: !isDesktop ? 999 : 9,
        }}
      >
        {_.isEmpty(isCheckIn) && !checkCurrentimeAfternoonStart && (
          <Button
            //colorScheme="whatsapp"
            size={isMobileResponsive ? "sm" : "sm"}
            onClick={() =>
              setTypeTimekeeping({
                open: true,
                type: "check-in",
              })
            }
            sx={{
              display: checkCurrentimeMorning ? "flex" : "none",
              transition: ".3s ease-in-out",
              bg: "rgba(210, 169, 106, 0.9)",
              color: "#fff",
              "&:hover": {
                bg: "rgba(210, 169, 106, 1)",
              },
            }}
            leftIcon={<ClockIcon width={20} height={20} />}
          >
            {t("button.checkIn")}
          </Button>
        )}
        {_.isEmpty(isCheckInAffternool) && (
          <Button
            //colorScheme="whatsapp"
            size={isMobileResponsive ? "sm" : "sm"}
            onClick={() =>
              setTypeTimekeeping({
                open: true,
                type: "check-in-afternoon",
              })
            }
            sx={{
              display: checkCurrentimeAfternoonStart ? "flex" : "none",
              transition: ".3s ease-in-out",
              bg: "rgba(210, 169, 106, 0.9)",
              color: "#fff",
              "&:hover": {
                bg: "rgba(210, 169, 106, 1)",
              },
            }}
            leftIcon={<ClockIcon width={20} height={20} />}
          >
            {t("button.checkIn")}
          </Button>
        )}
        {_.isEmpty(isCheckOut) &&
          !_.isEmpty(isCheckIn) &&
          !checkCurrentimeAfternoonStart && (
            <Button
              colorScheme="red"
              size={isMobileResponsive ? "sm" : "sm"}
              onClick={() =>
                setTypeTimekeeping({ open: true, type: "check-out" })
              }
              sx={{
                display: checkCurrentimeMorning ? "flex" : "none",
                transition: ".3s ease-in-out",
                bg: "rgba(187, 113, 84, 0.9)",
                color: "#fff",
                "&:hover": {
                  bg: "rgba(187, 113, 84, 1)",
                },
              }}
              leftIcon={<MinusCircleIcon width={20} height={20} />}
            >
              {t("button.checkOut")}
            </Button>
          )}

        {_.isEmpty(isCheckOutAfternoon) && !_.isEmpty(isCheckInAffternool) && (
          <Button
            colorScheme="red"
            size={isMobileResponsive ? "sm" : "sm"}
            onClick={() =>
              setTypeTimekeeping({ open: true, type: "check-out-afternoon" })
            }
            sx={{
              display: checkCurrentimeAfternoonStart ? "flex" : "none",
              transition: ".3s ease-in-out",
              bg: "rgba(187, 113, 84, 0.9)",
              color: "#fff",
              "&:hover": {
                bg: "rgba(187, 113, 84, 1)",
              },
            }}
            leftIcon={<MinusCircleIcon width={20} height={20} />}
          >
            {t("button.checkOut")}
          </Button>
        )}
        <WarningCheckInDialog
          open={openWarningCheckIn}
          onClose={() => {
            setOpenWarningCheckIn(false);
            dispatch(resetCheckLogin());
            localStorage.setItem("isCheckedIn", "true");
          }}
        />
        <WarningCheckInAfternoonDialog
          open={openWarningCheckInAfternoon}
          onClose={() => {
            setOpenWarningCheckInAfternoon(false);
            dispatch(resetCheckLogin());
            localStorage.setItem("isCheckedInAfternoon", "true");
          }}
        />

        {/* <Button size={isMobileResponsive ? "xs" : "sm"} onClick={handleClick}>
          My Time
        </Button> */}
        <Chat />
        <Notification />
        {_renderAvatar()}
      </Box>
      {_renderPopup}
    </Box>
  );
};

export default memo(Appbar);
