import React, { useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Select as GSelect,
  Heading,
  Text,
  Stack,
  InputGroup,
  Link,
} from "@chakra-ui/react";
import { DatePicker, Select } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import { RootState } from "@/Store";
import { useSelector } from "react-redux";
import { IDataRequestUpdateTimeCard } from "@/Interfaces/RequestUpdateTimeCard.interface";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import dayjs from "dayjs";
import { useTranslation } from "react-multi-lang";

const { TIMECARD_STATUS_COLOR } = ENUMS;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload: IDataRequestUpdateTimeCard;
  onRowAction: (action: any, item: string) => void;
}
interface DataYear {
  label: number;
  value: number;
}

const type = [
  { label: "Timesheet", value: "timesheet" },
  { label: "Attendance", value: "attendance history" },
  { label: "Leaves day", value: "leave day" },
];

const DetailsRequestUpdateTimeCardDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
  onRowAction,
}) => {
  const cancelRef = useRef<any>(null);
  const userData = Utils.getSavedUserData();
  const t = useTranslation();

  const isUpdateRequestUpdateTimeCardSuccess: boolean = useSelector(
    (state: RootState) =>
      _.get(
        state.REQUEST_UPDATE_TIME_CARD,
        "isUpdateRequestUpdateTimeCardSuccess"
      )
  );

  const approveDayOffRequest = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "UpdateTimecard",
    "approve"
  );

  const yearsOptions = React.useMemo(() => {
    let allyears: DataYear[] = [];
    const minYear = -(
      new Date().getFullYear() - new Date("2010-01-01T23:15:30").getFullYear()
    );
    const maxYear = 1;
    const thisYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      allyears.push({ label: thisYear + i, value: thisYear + i });
    }
    return allyears;
  }, []);

  React.useEffect(() => {
    if (isUpdateRequestUpdateTimeCardSuccess) {
      onClose();
    }
  }, [isUpdateRequestUpdateTimeCardSuccess]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box>
          <Box>
            <Heading
              fontSize="sm"
              sx={{
                alignItems: "center",
                display: "flex",
                mb: 1,
              }}
            >
              {t("label.types")}
            </Heading>
            <InputGroup size="sm">
              <GSelect
                placeholder="Types"
                value={payload?.type}
                required
                size={"sm"}
                sx={{ borderRadius: "5px" }}
                disabled
              >
                {_.map(_.values(type), (item, index) => (
                  <option key={index} value={item.value}>
                    {t(`label.${item.value}`)}
                  </option>
                ))}
              </GSelect>
            </InputGroup>
          </Box>
        </Box>
        <Box>
          <Heading
            fontSize="sm"
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 1,
            }}
          >
            {payload.type === "leave day"
              ? t("label.yearToBeEdited")
              : t("label.dateToBeEdited")}
          </Heading>
          <Stack mb={5} maxW={150}>
            {payload.type === "leave day" ? (
              <Select
                value={dayjs(payload.date).format("YYYY")}
                size="sm"
                options={yearsOptions}
                isRequired
                disabled
              />
            ) : (
              <DatePicker
                disabled
                maxDate={new Date()}
                value={payload?.date}
                size="sm"
                onDateChange={() => {}}
              />
            )}
          </Stack>
        </Box>
        <Box mb={2}>
          <Heading
            fontSize="sm"
            sx={{
              mb: 2,
              alignItems: "center",
              display: "flex",
            }}
          >
            {t("label.content")}
          </Heading>
          <Stack>
            <TextareaComponent size={"sm"} value={payload?.content} disabled />
          </Stack>
          <Box mb={5}>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
                alignItems: "center",
                display: "flex",
              }}
            >
              {t("label.link")}
            </Heading>
            <Link href={payload?.link} isExternal sx={{ ml: 2 }}>
              {payload?.link ? payload?.link : "--"}
            </Link>
          </Box>
        </Box>

        {payload?.status === ENUMS.REQUEST_UPDATE_TIMECARD_STATUS.REJECTED && (
          <Box>
            <Heading
              fontSize="sm"
              sx={{
                mb: 2,
                alignItems: "center",
                display: "flex",
              }}
            >
              {t("label.reasonForRefusal")}
            </Heading>
            <Stack mb={5}>
              <TextareaComponent size={"sm"} value={payload?.note} disabled />
            </Stack>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("title.detailRequestUpdateTimecard")}
          </Text>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {payload?.status && (
              <Text
                color={
                  TIMECARD_STATUS_COLOR[
                    payload?.status as keyof typeof TIMECARD_STATUS_COLOR
                  ].text
                }
                bgColor={
                  TIMECARD_STATUS_COLOR[
                    payload?.status as keyof typeof TIMECARD_STATUS_COLOR
                  ].background
                }
                sx={{
                  fontSize: 16,
                  textTransform: "capitalize",
                  borderRadius: 15,
                  px: "15px",
                  textAlign: "center",
                }}
              >
                {t(`status.${payload?.status}`)}
              </Text>
            )}
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ overflow: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          {payload?.status === "pending" && approveDayOffRequest && (
            <Box>
              <Button
                size={"sm"}
                onClick={() => onRowAction("rejected", payload?.id)}
                w={75}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(187, 113, 84,1)",
                  "&:hover": {
                    bgColor: "rgba(187, 113, 84,0.8)",
                  },
                }}
              >
                {t("button.reject")}
              </Button>
              <Button
                onClick={() => onRowAction("approve", payload?.id)}
                ml={3}
                size={"sm"}
                w={75}
                sx={{
                  color: "#fff",
                  bgColor: "rgba(92, 110, 108,1)",
                  "&:hover": {
                    bgColor: "rgba(92, 110, 108,0.8)",
                  },
                }}
              >
                {t("button.approve")}
              </Button>
            </Box>
          )}
          {approveDayOffRequest && payload?.status !== "pending" && (
            <Button
              onClick={() => onRowAction("pending", payload?.id)}
              ml={3}
              size={"sm"}
              sx={{
                color: "#fff",
                bgColor: "rgba( 210, 169, 106,1)",
                "&:hover": {
                  bgColor: "rgba( 210, 169, 106,0.8)",
                },
              }}
            >
              {t("button.pending")}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DetailsRequestUpdateTimeCardDialog;
