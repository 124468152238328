import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  Avatar,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Image,
  Skeleton,
  Stack,
  Heading,
} from "@chakra-ui/react";

import { CommentBox } from "@/Components/Common";
// import { messages } from "./data";
import Bookmark from "./Bookmark";
import MessageBox from "./MessageBox";

import {
  ChevronDoubleDownIcon,
  EllipsisHorizontalIcon,
  TrashIcon,
  ArrowRightStartOnRectangleIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/24/outline";
import { ChatDialog, ConfirmDialog } from "@/Components/Popup";

import { RootState, useTypedDispatch } from "@/Store";
import Utils from "@/Utils";
import { ChatActions } from "@/Actions";
import { getSocket } from "@/Configs/socket.config";
import { ENUMS } from "@/Constants";
import { Toast } from "@/Widgets";
import { useWindowWidth } from "@/Helpers";

import { IChatFilters, IMessageEvent } from "@/Interfaces/Chat.interface";

import bgGroup from "@/Assets/bg-group.jpg";
import UpdateClearFileDialog from "@/Components/Popup/Chat/UpdateClearFile";
import { useTranslation } from "react-multi-lang";

const {
  createMessage,
  getMessageByChannelId,
  createPrivateChat,
  recalledMessage,
  deletePrivateMessage,
  leaveGroup,
  resetNewChatData,
  readConversation,
  setNewPerformAction,
  fetchConversationChannelDetail,
} = ChatActions;

interface IMessageStructure {
  id: string;
  timestamp: string;
  isSender: boolean;
  sender: {
    id: string;
    name: string;
    avatar: string;
  } | null;
  content?: any;
  recalled?: boolean;
  fileAttachments?: any[];
  memberOptions?: any[];
  status?: string;
  isNotification?: boolean;
}

const UserChat: React.FC = () => {
  const t = useTranslation();
  const socket = getSocket();
  const dispatch = useTypedDispatch();
  const userLogged = Utils.getSavedUserData();
  const width = useWindowWidth();
  const isMobile = width <= 768;
  const isGetLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isGetLoading")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isActionLoading")
  );
  const isFetchLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isFetchLoading")
  );
  const isFetchMessageInChannelLoading: boolean = useSelector(
    (state: RootState) => _.get(state.CHAT, "isFetchMessageInChannelLoading")
  );
  const isFetchChannelLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isFetchChannelLoading")
  );
  const isCreateMessageSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isCreateMessageSuccess")
  );
  const performAction: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "performAction")
  );

  const personalChats: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "personalChat")
  );
  const groupChats: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "groupChat")
  );
  // const groupChats: any[] = useSelector((state: RootState) =>
  //   _.get(state.CHAT, "groupChat")
  // );
  const listMessages = useSelector((state: RootState) =>
    _.get(state.CHAT, "listMessages")
  );
  const newChatData: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "newChatData")
  );
  const messageDetailsPagination: IChatFilters = useSelector(
    (state: RootState) => _.get(state.CHAT, "messageDetailsPagination")
  );
  const messageDetailsMeta: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "messageDetailsMeta")
  );
  const userConnectSocket: any[] = useSelector((state: RootState) =>
    _.get(state.SOCKET, "userConnectSocket")
  );

  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

  const [messages, setMessages] = useState<any>([]);
  const [pendingMessages, setPendingMessages] = useState<any>([]);
  const [errorMessages, setErrorMessages] = useState<any>([]);
  const [pinMessages, setPinMessages] = useState<IMessageStructure[]>([]);
  const [selectedMessageId, setSelectedMessageId] = useState<null | string>(
    null
  );

  const [showButtonScrollToBottom, setShowButtonScrollToBottom] =
    useState(false);

  const [isShowPopup, setIsShowPopup] = useState({
    chatDetail: false,
    deleteChat: false,
    leaveChat: false,
    member: false,
    confirmLeaveChat: false,
    selectNewAdmin: false,
    confirmSelectNewAdmin: false,
    confirmRecalledMessage: false,
    confirmDeletePrivateMessage: false,
    editDelelteFile: false,
  });
  const [isShowMore, setIsShowMore] = useState(false);
  const [chatMessageInfo, setChatMessageInfo] = useState<IMessageEvent | null>(
    null
  );
  const [newMessageRecalled, setNewMessageRecalled] = useState<any>(null);
  const [dataMemberLeaveRemove, setDataMemberLeaveRemove] = useState<any>(null);

  const listRef = useRef<any>(null);
  const containerRef = useRef<any>(null);

  const chatInfo = performAction?.extraData;

  const groupedMessages = useMemo(() => {
    const grouped: { [date: string]: IMessageStructure[] } = {};
    messages.forEach((message: IMessageStructure) => {
      const messageDate = dayjs(message.timestamp).format("DD/MM/YYYY");

      if (!grouped[messageDate]) {
        grouped[messageDate] = [];
      }

      grouped[messageDate].push(message);
    });

    return grouped;
  }, [messages]);

  const [memberOptions, setMemberOptions] = useState<any[]>([]);

  const isMemberDeleted = useMemo(() => {
    const channel = chatInfo?.channel;
    if (channel && channel.type === "private" && channel.messageChannelMember) {
      for (const member of channel.messageChannelMember) {
        if (!member.user) {
          return true;
        }
      }
    }
    return false;
  }, [chatInfo]);

  useEffect(() => {
    scrollToBottom();
    // Cleanup function
    return () => {
      dispatch(resetNewChatData());
    };
  }, []);

  useEffect(() => {
    // Retrieve project members from chatInfo
    const projectMember = _.get(chatInfo, "channel.messageChannelMember");

    // Map projectMember array to extract necessary information
    const options = _.map(projectMember, (member) => ({
      id: member?.user?.id,
      display: member?.user?.userData?.fullName,
      avatar: member?.user?.userData?.avatar?.path,
    }));

    // Filter out elements with ids matching userLogged's id
    const filteredOptions = _.filter(
      options,
      (member: any) => member?.user?.id !== userLogged?.id
    );

    // Set the state with the filtered options
    setMemberOptions(filteredOptions);
  }, [chatInfo]);

  useEffect(() => {
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_RECALLED, (data: any) => {
      setNewMessageRecalled(data);
    });

    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_CONVERSATION, (newMessage) => {
      setChatMessageInfo(newMessage);
    });
    socket.on(ENUMS.SOCKET_EVENT.MESSAGE_GROUP_LEAVE_REMOVE, (data) => {
      if (!_.isEmpty(data)) setDataMemberLeaveRemove(data);
    });
  }, []);

  useEffect(() => {
    // Create a Set to store unique message IDs
    const messageIdSet = new Set();
    // Iterate through all messages and add to the result list only when the ID is not duplicated
    const updatedMessages = Utils.sortByProperty(
      [...pendingMessages, ...errorMessages, ...messages].filter((message) => {
        if (messageIdSet.has(message.id)) {
          return false; // Skip messages that already exist
        } else {
          messageIdSet.add(message.id);
          return true; // Keep messages that are not duplicates
        }
      }),
      "timestamp",
      false
    );
    setMessages(updatedMessages);
  }, [pendingMessages, errorMessages]);

  useEffect(() => {
    if (isCreateMessageSuccess) scrollToBottom();
  }, [isCreateMessageSuccess]);

  useEffect(() => {
    if (performAction?.chatId) {
      const channelId = performAction?.chatId;
      if (channelId !== "new-chat-data-placeholder") {
        dispatch(
          getMessageByChannelId(
            channelId,
            {
              page: 1,
              limit: 10,
            },
            true
          )
        );
      }
    }
    return () => {
      setSelectedMessageId(null);
      setMessages([]);
      setPinMessages([]);
      setErrorMessages([]);
      setPendingMessages([]);
      setShowButtonScrollToBottom(false);
    };
  }, [performAction?.chatId]);

  //new message received
  useEffect(() => {
    if (chatMessageInfo) {
      const { channelId, message } = chatMessageInfo;
      if (!_.isEmpty(chatMessageInfo) && chatInfo?.channel?.id === channelId) {
        //handle read message it same channel
        const formData = new FormData();
        formData.append("channelId", channelId);
        dispatch(readConversation(channelId, formData, () => {}));
        const resolvedData = {
          id: message?.id,
          timestamp: dayjs(message?.createdAt).format("YYYY-MM-DD, HH:mm:ss"),
          isSender: message?.userCreated?.id === userLogged?.id,
          sender: !_.isEmpty(message?.userCreated)
            ? {
                id: message?.userCreated?.id,
                name: message?.userCreated?.userData?.fullName,
                avatar: message?.userCreated?.userData?.avatar?.path,
              }
            : null,
          content: message?.message,
          fileAttachments: message?.fileAttachment,
          recalled: message?.recalled,
          members: [
            {
              id: "everyone",
              display: "Everyone",
              avatar: chatInfo?.channel?.avatar?.path,
            },
            ...memberOptions,
          ],
          isNotification: _.isEmpty(message?.userCreated),
        };
        setMessages((prevMessages: any) => {
          const isMessageExist = prevMessages.some(
            (msg: IMessageStructure) => msg.id === message.id
          );
          if (!isMessageExist) {
            return [resolvedData, ...prevMessages];
          }
          return prevMessages;
        });
        if (
          containerRef?.current?.scrollTop +
            containerRef?.current?.clientHeight <
          containerRef?.current?.scrollHeight
        )
          setHasUnreadMessage(true);
      }
      setChatMessageInfo(null);
    }
  }, [chatMessageInfo]);

  useEffect(() => {
    // Check if the socket event matches the current chatId and if data is not empty
    if (!_.isEmpty(newMessageRecalled)) {
      // Find the index of the message in the messages array based on id and channelId
      const recalledMessageIndex = messages.findIndex(
        (message: any) => message.id === newMessageRecalled.messageId
      );
      // If the message is found
      if (recalledMessageIndex !== -1) {
        // Update the recalled value of the corresponding message
        setMessages((prevMessages: any) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[recalledMessageIndex].recalled = true;
          return updatedMessages;
        });
      }
      setNewMessageRecalled(null);
    }
  }, [newMessageRecalled]);

  useEffect(() => {
    if (!_.isEmpty(dataMemberLeaveRemove)) {
      const channelIdToRemove = dataMemberLeaveRemove?.channelId;
      const userIdToRemove = dataMemberLeaveRemove?.userId;
      if (channelIdToRemove === chatInfo?.channel?.id) {
        const filterMembers = _.filter(
          _.get(chatInfo, "channel.messageChannelMember"),
          (filterMember) => filterMember?.user?.id !== userIdToRemove
        );
        const newChatPerformAction = {
          ...performAction,
          extraData: {
            ...chatInfo,
            channel: {
              ...chatInfo?.channel,
              messageChannelMember: filterMembers,
            },
          },
        };
        dispatch(setNewPerformAction(newChatPerformAction));
      }
    }
    setDataMemberLeaveRemove(null);
  }, [dataMemberLeaveRemove]);

  useEffect(() => {
    const resolvedData = resolveMessages(listMessages);
    // setMessages((prevMsg: IMessageStructure[]) => [
    //   ...resolvedData,
    //   ...prevMsg,
    // ]);
    setMessages(resolvedData);
  }, [listMessages]);

  // Define a separate function to resolve messages
  const resolveMessages = (listMessages: any) => {
    return _.map(listMessages, (message) => {
      return {
        id: message?.id,
        timestamp: dayjs(message?.createdAt).format("YYYY-MM-DD, HH:mm:ss"),
        isSender: message?.userCreated?.id === userLogged?.id,
        sender: !_.isEmpty(message?.userCreated)
          ? {
              id: message?.userCreated?.id,
              name: message?.userCreated?.userData?.fullName,
              avatar: message?.userCreated?.userData?.avatar?.path,
            }
          : null,
        content: message?.message,
        fileAttachments: message?.fileAttachment,
        recalled: message?.recalled,
        members: [
          {
            id: "everyone",
            display: "Everyone",
            avatar: chatInfo?.channel?.avatar?.path,
          },
          ...memberOptions,
        ],
        status: "success",
        isNotification: _.isEmpty(message?.userCreated),
      };
    });
  };

  const fetchMoreData = async () => {
    try {
      const channelId = performAction?.chatId;
      if (channelId) {
        // const newMessages = await dispatch(
        //   getMessageByChannelId(chatInfo?.channel?.id, {
        //     ...messageDetailsPagination,
        //     page: messageDetailsPagination?.page + 1,
        //   })
        // );
        // const resolvedNewMessages = resolveMessages(newMessages);
        // setMessages((prevMsg: IMessageStructure[]) => [
        //   ...prevMsg,
        //   ...resolvedNewMessages,
        // ]);
        const newMessages = await dispatch(
          getMessageByChannelId(chatInfo?.channel?.id, {
            ...messageDetailsPagination,
            limit: messageDetailsPagination?.limit + 10,
          })
        );
        const resolvedNewMessages = resolveMessages(newMessages);
        setMessages(resolvedNewMessages);
      }
    } catch (error) {
      Toast({
        status: "error",
        title: "There was a server problem, please try again later!",
      });
    }
  };

  const handleScroll = _.debounce(() => {
    const atBottom = containerRef?.current?.scrollTop >= 0;
    if (atBottom && hasUnreadMessage) setHasUnreadMessage(false);
    setShowButtonScrollToBottom(!atBottom);
  }, 200);

  const scrollToMessage = (messageId: string) => {
    const messageIndex = _.findIndex(
      messages,
      (msg: any) => msg?.id === messageId
    );

    if (messageIndex !== -1 && listRef.current) {
      listRef.current.scrollToRow(messageIndex);
      setSelectedMessageId(messageId);
    }
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      if (hasUnreadMessage) setHasUnreadMessage(false);
    }
  };

  const handleSuccessMessage = async (
    data: any,
    tempMessage: IMessageStructure
  ) => {
    setPendingMessages((prevMsg: IMessageStructure[]) =>
      prevMsg.filter((msg: IMessageStructure) => msg?.id !== tempMessage?.id)
    );
    const resolvedData = {
      id: data?.id,
      timestamp: dayjs(data?.createdAt).format("YYYY-MM-DD, HH:mm:ss"),
      isSender: data?.userCreated?.id === userLogged?.id,
      sender: !_.isEmpty(data?.userCreated)
        ? {
            id: data?.userCreated?.id,
            name: data?.userCreated?.userData?.fullName,
            avatar: data?.userCreated?.userData?.avatar?.path,
          }
        : null,
      content: data?.message,
      fileAttachments: data?.fileAttachment,
      recalled: data?.recalled,
      members: [
        {
          id: "everyone",
          display: "Everyone",
          avatar: chatInfo?.channel?.avatar?.path,
        },
        ...memberOptions,
      ],
      status: "success",
      isNotification: _.isEmpty(data?.userCreated),
    };

    setMessages((prevMessages: IMessageStructure[]) =>
      prevMessages.map((message: IMessageStructure) =>
        message.id === tempMessage?.id ? resolvedData : message
      )
    );
  };

  const handleErrorMessage = (tempMessage: IMessageStructure) => {
    setPendingMessages((prevMsg: IMessageStructure[]) =>
      prevMsg.filter((msg: IMessageStructure) => msg?.id !== tempMessage?.id)
    );
    setErrorMessages((prevMsg: IMessageStructure[]) => [
      { ...tempMessage, status: "error" },
      ...prevMsg,
    ]);
    setMessages((prevMessages: IMessageStructure[]) =>
      prevMessages.filter(
        (message: IMessageStructure) => message.id !== tempMessage?.id
      )
    );
  };

  const handleResendMessage = (msgId: string) => {
    // Find the index of the message in the errorMessages array
    const errorMsgIndex = _.findIndex(
      errorMessages,
      (msg: IMessageStructure) => msg.id === msgId
    );
    if (errorMsgIndex !== -1) {
      // Remove this message from the errorMessages array
      setErrorMessages((prevErrorMessages: IMessageStructure[]) => [
        ...prevErrorMessages.slice(0, errorMsgIndex),
        ...prevErrorMessages.slice(errorMsgIndex + 1),
      ]);
    }
    // If the message is not found in the errorMessages array, check in the messages array
    const messageIndex = _.findIndex(
      messages,
      (msg: IMessageStructure) => msg.id === msgId
    );
    if (messageIndex !== -1) {
      // The message is found in the messages array
      const message = messages[messageIndex];
      // Remove this message from the messages array
      setMessages((prevMessages: IMessageStructure[]) => [
        ...prevMessages.slice(0, messageIndex),
        ...prevMessages.slice(messageIndex + 1),
      ]);
      const tempMessage = {
        ...message,
        status: "pending",
        timestamp: dayjs().format("YYYY-MM-DD, HH:mm:ss"),
      };
      const channelId = chatInfo?.channel?.id;
      if (channelId && tempMessage) {
        const formData = new FormData();
        setPendingMessages((prevMsg: any) => [tempMessage, ...prevMsg]);
        formData.append("channel", channelId);
        if (tempMessage?.content)
          formData.append("message", tempMessage?.content);
        if (tempMessage?.fileAttachments)
          _.map(tempMessage?.fileAttachments, (fileAttachment) =>
            formData.append("fileAttachment", fileAttachment)
          );
        dispatch(
          createMessage(
            formData,
            chatInfo?.channel?.id,
            (data) => handleSuccessMessage(data, tempMessage),
            () => handleErrorMessage(tempMessage)
          )
        );
      }
    }
  };

  const getNextOrPrevChat = (chats: any[], currentIndex: number) => {
    let nextChat;
    let prevChat;
    if (currentIndex === 0) {
      nextChat = chats[1];
    } else if (currentIndex === chats.length - 1) {
      prevChat = chats[currentIndex - 1];
    } else {
      nextChat = chats[currentIndex + 1];
      prevChat = chats[currentIndex - 1];
    }

    return {
      nextChat,
      prevChat,
    };
  };

  const handlePinMessage = (newMessage: IMessageStructure) => {
    setPinMessages((prevPinMessages) => {
      const isAlreadyPinned = prevPinMessages.some(
        (existingMessage) => existingMessage.id === newMessage.id
      );
      return !isAlreadyPinned
        ? [...prevPinMessages, newMessage]
        : prevPinMessages;
    });
  };

  const handleRemovePinMessage = (messageIdToRemove: string) => {
    setPinMessages((prevPinMessages) =>
      prevPinMessages.filter((message) => message.id !== messageIdToRemove)
    );
  };

  const handlePopupChange = (name: string, value: boolean) => {
    if (isShowMore) setIsShowMore(false);
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  const _renderMoreOptions = () => (
    <Popover
      isOpen={isShowMore}
      onClose={() => setIsShowMore(false)}
      placement="auto-start"
    >
      <PopoverTrigger>
        <IconButton
          size="sm"
          aria-label={""}
          isRound
          sx={{ bg: "none", ml: "auto" }}
          onClick={() => setIsShowMore(true)}
        >
          <EllipsisHorizontalIcon />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent
        sx={{
          width: "max-content",
          zIndex: "sticky",
        }}
      >
        {chatInfo?.channel?.type === "group" && (
          <>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              colorScheme="cyan"
              leftIcon={
                <Icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                </Icon>
              }
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("chatDetail", true);
              }}
            >
              {t("button.chatDetailInfo")}
            </Button>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              colorScheme="yellow"
              leftIcon={
                <Icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>
                </Icon>
              }
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("member", true);
              }}
            >
              {t("button.members")}
            </Button>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              colorScheme="red"
              leftIcon={<Icon as={ArrowRightStartOnRectangleIcon} />}
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("leaveChat", true);
              }}
            >
              {t("button.leaveGroup")}
            </Button>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              //  colorScheme="red"
              leftIcon={<Icon as={AdjustmentsHorizontalIcon} />}
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("editDelelteFile", true);
              }}
            >
              {t("label.editTimeDeleteAttachmentfile")}
            </Button>
          </>
        )}
        {chatInfo?.channel?.type === "private" && (
          <>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              colorScheme="cyan"
              leftIcon={
                <Icon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                </Icon>
              }
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("chatDetail", true);
              }}
              isDisabled={isActionLoading || isMemberDeleted}
            >
              {t("button.chatDetailInfo")}
            </Button>
            <Button
              size="sm"
              rounded={0}
              variant="ghost"
              colorScheme="red"
              leftIcon={<Icon as={TrashIcon} />}
              sx={{
                justifyContent: "flex-start",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handlePopupChange("confirmDeletePrivateMessage", true);
              }}
            >
              {t("button.deleteChat")}
            </Button>
          </>
        )}
      </PopoverContent>
    </Popover>
  );

  const _renderPopup = () => (
    <>
      <ChatDialog.ChatDetail
        open={isShowPopup.chatDetail}
        onClose={() => handlePopupChange("chatDetail", false)}
      />
      <ChatDialog.SelectNewAdmin
        open={isShowPopup.selectNewAdmin}
        onClose={() => handlePopupChange("selectNewAdmin", false)}
        onSelect={() => {}}
      />
      <ChatDialog.Member
        open={isShowPopup.member}
        onClose={() => handlePopupChange("member", false)}
      />
      <ConfirmDialog
        title={t("title.deleteGroup")}
        actionType="delete"
        isOpen={isShowPopup.deleteChat}
        onClose={() => handlePopupChange("deleteChat", false)}
        body={t("message.areYouSureToDeleteGroup")}
        onAction={() => {}}
      />
      <ConfirmDialog
        title={t("title.leaveGroup")}
        actionType="confirm"
        isOpen={isShowPopup.leaveChat}
        onClose={() => handlePopupChange("leaveChat", false)}
        body={t("label.areYouSureToLeaveGroup")}
        onAction={() => {
          const groupChatIndex = _.findIndex(
            groupChats,
            (chat: any) => chat?.channel?.id === chatInfo?.channel?.id
          );
          const { nextChat, prevChat } = getNextOrPrevChat(
            groupChats,
            groupChatIndex
          );
          dispatch(leaveGroup(performAction?.chatId, nextChat || prevChat));
          setIsShowPopup({
            ...isShowPopup,
            leaveChat: false,
          });
        }}
      />
      <ConfirmDialog
        title="Leave Group"
        actionType="confirm"
        isOpen={isShowPopup.confirmLeaveChat}
        onClose={() => handlePopupChange("confirmLeaveChat", false)}
        body="You won't be able to see the messages in this conversation again after you leave the group. Please select a new admin or the system will choose automatically"
        onAction={() =>
          setIsShowPopup({
            ...isShowPopup,
            confirmLeaveChat: false,
            selectNewAdmin: true,
          })
        }
      />
      <ConfirmDialog
        title="Leave Group"
        actionType="confirm"
        isOpen={isShowPopup.confirmSelectNewAdmin}
        onClose={() => {
          setIsShowPopup({
            ...isShowPopup,
            confirmLeaveChat: true,
            confirmSelectNewAdmin: false,
          });
        }}
        body="Leave group and select Le Duc Hieu as new admin?"
        onAction={() =>
          setIsShowPopup({
            ...isShowPopup,
            confirmSelectNewAdmin: false,
          })
        }
      />
      <ConfirmDialog
        title={t("label.recallMessage")}
        actionType="confirm"
        isOpen={isShowPopup.confirmRecalledMessage}
        onClose={() => handlePopupChange("confirmRecalledMessage", false)}
        body={t("label.areYouSureYouWantToRecallThisMessage")}
        onAction={() => {
          if (selectedMessageId)
            dispatch(
              recalledMessage(selectedMessageId, () => {
                const updatedMessages = _.map(
                  messages,
                  (message: IMessageStructure) =>
                    message.id === selectedMessageId
                      ? { ...message, recalled: true }
                      : message
                );
                setMessages(updatedMessages);
              })
            );
          handlePopupChange("confirmRecalledMessage", false);
        }}
      />
      <ConfirmDialog
        title={t("button.deleteChat")}
        actionType="confirm"
        isOpen={isShowPopup.confirmDeletePrivateMessage}
        onClose={() => handlePopupChange("confirmDeletePrivateMessage", false)}
        body={t("message.areYouSureYouWantToDeleteThisChat")}
        onAction={() => {
          const chatType = chatInfo?.channel?.type;
          const channelId = chatInfo?.channel?.id;
          if (chatType === "private") {
            // Find index of the chat in personalChats array based on channel ID
            const personalChatIndex = _.findIndex(
              personalChats,
              (chat: any) => chat?.channel?.id === chatInfo?.channel?.id
            );
            const { nextChat, prevChat } = getNextOrPrevChat(
              personalChats,
              personalChatIndex
            );
            // Dispatch deletePrivateMessage action with the nextChat or prevChat
            dispatch(deletePrivateMessage(channelId, nextChat || prevChat));
          }
          handlePopupChange("confirmDeletePrivateMessage", false);
        }}
      />
      <UpdateClearFileDialog
        open={isShowPopup?.editDelelteFile}
        onClose={() => handlePopupChange("editDelelteFile", false)}
      />
    </>
  );

  const _renderTopSection = () => {
    const chatTitle =
      chatInfo?.channel?.type === "private"
        ? _.filter(
            chatInfo?.channel?.messageChannelMember,
            (member) => member?.user?.id !== userLogged?.id
          )[0]?.user?.userData?.fullName
        : chatInfo?.channel?.title;
    const receiver =
      chatInfo?.channel?.type === "private"
        ? _.first(
            _.filter(
              chatInfo?.channel?.messageChannelMember,
              (member) => member?.user?.id !== userLogged?.id
            )
          )
        : null;

    const isReceiverOnline = _.some(
      userConnectSocket,
      (user) => user?.userId === receiver?.user?.id
    );

    const numberOfUsersOnline = _.filter(memberOptions, (user) =>
      userConnectSocket.some((socketUser) => socketUser.userId === user.id)
    ).length;

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid silver",
            borderLeft: "none",
            height: "max-content",
            p: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Avatar
              size="md"
              key={
                receiver?.user?.userData?.avatar?.path ||
                newChatData?.receiver?.avatar ||
                (chatInfo?.channel?.type === "group" &&
                  performAction?.extraData?.channel?.avatar?.path)
              }
              src={
                receiver?.user?.userData?.avatar?.path ||
                newChatData?.receiver?.avatar ||
                (chatInfo?.channel?.type === "group" &&
                  performAction?.extraData?.channel?.avatar?.path)
              }
            />
            <Box>
              <Text fontSize="sm" fontWeight={600}>
                {isMemberDeleted
                  ? t("message.userDoesntExist")
                  : chatTitle || newChatData?.receiver?.name}
              </Text>
              <Text fontSize="sm">
                {chatInfo?.channel?.type === "private" ||
                !_.isEmpty(newChatData)
                  ? isReceiverOnline
                    ? t("label.online")
                    : t("label.offline")
                  : chatInfo?.channel?.type === "group"
                  ? `${numberOfUsersOnline || 1}  ${t("label.online")}`
                  : ""}
              </Text>
            </Box>
          </Box>
          {_.isEmpty(newChatData) && _renderMoreOptions()}
        </Box>
        <Bookmark
          payload={pinMessages}
          onPinClick={(pinId) => scrollToMessage(pinId)}
          onUnPinClick={(pinId) => handleRemovePinMessage(pinId)}
        />
      </Box>
    );
  };

  const _renderChatContainer = () => {
    return (
      <InfiniteScroll
        inverse={true}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        dataLength={messages.length}
        next={fetchMoreData}
        refreshFunction={fetchMoreData}
        hasMore={
          // messageDetailsPagination?.page !== messageDetailsMeta?.totalPages
          messages.length !== messageDetailsMeta?.totalItems
        }
        loader={
          <Text
            fontSize="sm"
            sx={{
              ml: 2,
            }}
          >
            {t("label.loading")}
          </Text>
        }
        scrollableTarget="scrollableDiv"
        onScroll={handleScroll}
        pullDownToRefresh={true}
        endMessage={<span />}
      >
        {_.map(Object.keys(groupedMessages), (messageDate: string) => {
          return (
            <React.Fragment key={messageDate}>
              {groupedMessages[messageDate].map(
                (message: IMessageStructure, messageIndex: number) => {
                  return (
                    <MessageBox
                      key={`${message.id}-${messageIndex}`}
                      payload={message}
                      currentDate={
                        messageIndex === groupedMessages[messageDate].length - 1
                          ? messageDate
                          : null
                      }
                      onPinMsg={() => {
                        handlePinMessage(message);
                      }}
                      onDeleteMsg={(messageId: string) => {
                        setSelectedMessageId(messageId);
                        handlePopupChange("confirmRecalledMessage", true);
                      }}
                      onResendMsg={handleResendMessage}
                      type={chatInfo?.channel?.type}
                    />
                  );
                }
              )}
            </React.Fragment>
          );
        })}
        {showButtonScrollToBottom && (
          <Box
            sx={{
              position: "absolute",
              bottom: 1,
              right: "50%",
              transform: "translateX(50%)",
              zIndex: "sticky",
              cursor: "pointer",
            }}
            onClick={scrollToBottom}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {hasUnreadMessage && (
                <Text color="#3699ff" fontSize="sm">
                  {t("message.newMessageReceived")}
                </Text>
              )}
              <Icon
                color={hasUnreadMessage ? "#3699ff" : "inherit"}
                boxSize={5}
                as={ChevronDoubleDownIcon}
              />
            </Box>
          </Box>
        )}
      </InfiniteScroll>
    );
  };

  const _renderSkeleton = useMemo(() => {
    return (
      <Stack spacing="2px" height="100%" overflow="hidden">
        <Box height="65px">
          <Skeleton height="100%" />
        </Box>
        <Box flex="1">
          <Skeleton height="100%" />
        </Box>
        <Box height="80px">
          <Skeleton height="100%" />
        </Box>
      </Stack>
    );
  }, []);

  const welcomeBanner = useMemo(() => {
    return (
      <Box p={6} textAlign="center" h="full" w="full">
        <Heading as="h2" size="lg" mb={2}>
          {t("label.welcomeToTheCompanysChatApplication")}
        </Heading>
        <Text fontSize="md" mb={4}>
          {t("label.joinUsToInteractAndWorkMoreEffectively")}
        </Text>
        <Image
          src={bgGroup}
          sx={{
            mx: "auto",
            w: "500px",
            height: "280px",
            objectFit: "cover",
          }}
        />
      </Box>
    );
  }, []);

  return isGetLoading ||
    isFetchMessageInChannelLoading ||
    isFetchChannelLoading ? (
    _renderSkeleton
  ) : !_.isEmpty(newChatData) || !_.isEmpty(performAction?.chatId) ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        width: "100%",
      }}
    >
      {_renderTopSection()}
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          overflow: "auto",
          borderRight: "1px solid silver",
        }}
      >
        <Box
          id="scrollableDiv"
          style={{
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          ref={containerRef}
        >
          {_renderChatContainer()}
        </Box>
      </Box>
      <CommentBox
        key={performAction?.chatId}
        isDisabled={isActionLoading}
        payload={
          chatInfo?.channel?.type === "group"
            ? [
                {
                  id: "everyone",
                  display: "Everyone",
                  avatar: chatInfo?.channel?.avatar?.path,
                },
                ...memberOptions,
              ]
            : []
        }
        sx={{
          mt: "auto",
          bg: "#fff",
        }}
        getFor="chat"
        isShowBottomBar
        onSubmit={async (data: any) => {
          const channelId = chatInfo?.channel?.id;
          const formData = new FormData();
          if (channelId) {
            const tempMessage = {
              id: uuidv4(),
              isSender: true,
              sender: {
                id: userLogged?.id,
                name: userLogged?.userData?.fullName,
                avatar: userLogged?.userData?.avatar?.path,
              },
              fileAttachments: [],
              recalled: false,
              content: data?.content,
              members: [
                {
                  id: "everyone",
                  display: "Everyone",
                  avatar: chatInfo?.channel?.avatar?.path,
                },
                ..._.map(chatInfo?.channel?.messageChannelMember, (member) => ({
                  id: member?.user?.id,
                  display: member?.user?.userData?.fullName,
                  avatar: member?.user?.userData?.avatar?.path,
                })),
              ],
              status: "pending",
              timestamp: dayjs().format("YYYY-MM-DD, HH:mm:ss"),
            };
            if (data?.content) {
              const contentMessageData = new FormData();
              contentMessageData.append("channel", channelId);
              contentMessageData.append("message", data?.content);
              if (!_.isEmpty(data?.tagMember)) {
                _.forEach(data?.tagMember, (member) => {
                  member?.id &&
                    contentMessageData.append("tagMember[]", member?.id);
                });
              }
              setPendingMessages((prevMsg: any) => [tempMessage, ...prevMsg]);
              dispatch(
                createMessage(
                  contentMessageData,
                  chatInfo?.channel?.id,
                  (response) => {
                    handleSuccessMessage(response, tempMessage);
                    // setPendingMessages((prevMsg: any) =>
                    //   prevMsg.filter((msg: any) => msg.id !== tempMessage.id)
                    // );
                  },
                  () => {
                    handleErrorMessage(tempMessage);
                    // setPendingMessages((prevMsg: IMessageStructure[]) =>
                    //   prevMsg.filter(
                    //     (msg: IMessageStructure) => msg?.id !== tempMessage?.id
                    //   )
                    // );
                    // setErrorMessages((prevMsg: IMessageStructure[]) => [
                    //   { ...tempMessage, status: "error" },
                    //   ...prevMsg,
                    // ]);
                  }
                )
              );
            }

            if (data?.fileAttachments) {
              const promises = _.map(
                data.fileAttachments,
                async (fileAttachment) => {
                  const fileFormData = new FormData();
                  fileFormData.append("channel", channelId);
                  fileFormData.append("fileAttachment", fileAttachment);
                  const pendingMessage = {
                    id: uuidv4(),
                    isSender: true,
                    sender: {
                      id: userLogged?.id,
                      name: userLogged?.userData?.fullName,
                      avatar: userLogged?.userData?.avatar?.path,
                    },
                    fileAttachments: [fileAttachment],
                    recalled: false,
                    content: null,
                    members: [
                      {
                        id: "everyone",
                        display: "Everyone",
                        avatar: chatInfo?.channel?.avatar?.path,
                      },
                      ..._.map(
                        chatInfo?.channel?.messageChannelMember,
                        (member) => ({
                          id: member?.user?.id,
                          display: member?.user?.userData?.fullName,
                          avatar: member?.user?.userData?.avatar?.path,
                        })
                      ),
                    ],
                    status: "pending",
                    timestamp: dayjs().format("YYYY-MM-DD, HH:mm:ss"),
                  };
                  setPendingMessages((prevState: IMessageStructure[]) => [
                    pendingMessage,
                    ...prevState,
                  ]);
                  try {
                    const response = await dispatch(
                      createMessage(
                        fileFormData,
                        chatInfo?.channel?.id,
                        (response) =>
                          handleSuccessMessage(response, pendingMessage),
                        () => handleErrorMessage(pendingMessage)
                      )
                    );
                    return response;
                  } catch (error) {
                    throw error;
                  }
                }
              );

              try {
                const promiseAll = await Promise.all(promises);
                if (promiseAll)
                  dispatch(fetchConversationChannelDetail(channelId));
              } catch (error) {}
            }
          } else {
            if (newChatData) {
              formData.append("userReceive", newChatData?.receiver?.id);
              if (data?.content) formData.append("message", data?.content);
              if (data?.fileAttachments)
                _.map(data?.fileAttachments, (fileAttachment) =>
                  formData.append("fileAttachment", fileAttachment)
                );
              dispatch(createPrivateChat(formData));
            }
          }
        }}
        onGifSelect={(gif: any) => {
          const channelId = chatInfo?.channel?.id;
          if (channelId) {
            const formData = new FormData();
            formData.append("message", JSON.stringify(gif));
            formData.append("channel", channelId);
            const tempMessage = {
              id: uuidv4(),
              isSender: true,
              sender: {
                id: userLogged?.id,
                name: userLogged?.userData?.fullName,
                avatar: userLogged?.userData?.avatar?.path,
              },
              fileAttachments: [],
              recalled: false,
              content: JSON.stringify(_.pick(gif, "images.fixed_height")),
              members: [
                {
                  id: "everyone",
                  display: "Everyone",
                  avatar: chatInfo?.channel?.avatar?.path,
                },
                ..._.map(chatInfo?.channel?.messageChannelMember, (member) => ({
                  id: member?.user?.id,
                  display: member?.user?.userData?.fullName,
                  avatar: member?.user?.userData?.avatar?.path,
                })),
              ],
              status: "pending",
              timestamp: dayjs().format("YYYY-MM-DD, HH:mm:ss"),
            };
            setPendingMessages((prevMsg: any) => [tempMessage, ...prevMsg]);
            if (chatInfo?.channel?.id)
              dispatch(
                createMessage(
                  formData,
                  chatInfo?.channel?.id,
                  (data) => handleSuccessMessage(data, tempMessage),
                  () => handleErrorMessage(tempMessage)
                )
              );
          } else {
            if (newChatData) {
              const formData = new FormData();
              formData.append("userReceive", newChatData?.receiver?.id);
              formData.append("message", JSON.stringify(gif));
              dispatch(createPrivateChat(formData));
            }
          }
        }}
      />
      {_renderPopup()}
    </Box>
  ) : !isFetchLoading &&
    !isActionLoading &&
    !isFetchMessageInChannelLoading &&
    !isMobile ? (
    welcomeBanner
  ) : (
    _renderSkeleton
  );
};

export default memo(UserChat);
