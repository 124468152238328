import React, { useEffect, useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
} from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import { ChatActions } from "@/Actions";
import { Select, TextField } from "@/Components/Common";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-multi-lang";
import Utils from "@/Utils";
//import { ITaskStructure } from "@/Interfaces/Board.interface";

const { updateConversation } = ChatActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const dataClear = [
  // { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
  { label: "Infinite", value: "infinite" },
];

const dataClearVi = [
  // { label: "Day", value: "day" },
  { label: "Tuần", value: "week" },
  { label: "Tháng", value: "month" },
  { label: "Năm", value: "year" },
  { label: "Vô hạn", value: "infinite" },
];

const DEFAULT_FORM_DATA = {
  timeDeleteAttachment: 1,
  typeDeleteAttachment: "month",
};

const UpdateClearFileDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const language = Utils.getSavedLanguage();

  const chatPerformAction: any = useSelector((state: RootState) =>
    _.get(state.CHAT, "performAction")
  );

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.CHAT, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      timeDeleteAttachment: yup
        .number()
        .default(0)
        .typeError("Time delete attachment file must be a number"),
      typeDeleteAttachment: yup.string(),
    })
    .required();

  const {
    control,
    setValue,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const watchTypeDeleteAttachment = watch("typeDeleteAttachment");

  useEffect(() => {
    if (chatPerformAction?.extraData?.channel) {
      const timeDeleteAttachment =
        chatPerformAction?.extraData?.channel?.timeDeleteAttachment;
      const typeDeleteAttachment =
        chatPerformAction?.extraData?.channel?.typeDeleteAttachment;

      setValue(
        "timeDeleteAttachment",
        timeDeleteAttachment ? _.toNumber(timeDeleteAttachment) : 1
      );
      setValue("typeDeleteAttachment", typeDeleteAttachment || "month");
    }
  }, [chatPerformAction, open]);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const handleReset = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append("timeDeleteAttachment", data.timeDeleteAttachment);
    formData.append("typeDeleteAttachment", data.typeDeleteAttachment);
    dispatch(
      updateConversation(
        chatPerformAction?.chatId,
        formData,
        chatPerformAction,
        () => handleReset()
      )
    );
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <Box mt={2}>
        <Controller
          name="typeDeleteAttachment"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e.target.value);
                if (e.target.value === "infinite")
                  clearErrors("timeDeleteAttachment");
              }}
              label={t("label.TypeDeleteAttachmentFile")}
              size="sm"
              options={language === "en" ? dataClear : dataClearVi}
              //isRequired
              isError={!_.isEmpty(errors.typeDeleteAttachment?.message)}
              errorMessage={errors?.typeDeleteAttachment?.message}
            />
          )}
        />
      </Box>
      {watchTypeDeleteAttachment !== "infinite" && (
        <Box mt={2}>
          <Controller
            name="timeDeleteAttachment"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e.target.value);
                }}
                label={t("label.timeDeleteAttachmentFile")}
                placeholder="0"
                size="sm"
                min={1}
                isError={!_.isEmpty(errors.timeDeleteAttachment?.message)}
                errorMessage={errors?.timeDeleteAttachment?.message}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("label.editTimeDeleteAttachmentfile")}
          </Text>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 450, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            size={"sm"}
            sx={{
              color: "#fff",
              bgColor: "rgba(92, 110, 108,1)",
              "&:hover": {
                bgColor: "rgba(92, 110, 108,0.8)",
              },
            }}
            isDisabled={isActionLoading}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateClearFileDialog;
