import React, { useState, memo, useEffect } from "react";
import _ from "lodash";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  List,
  ListItem,
  Collapse,
  Box,
  Text,
  Tooltip,
  Icon,
  Image,
} from "@chakra-ui/react";
import {
  GlobeAmericasIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import Utils from "@/Utils";
import MenuOptions from "./SideBar.menu";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import { Routers } from "@/Constants";
import { useTranslation } from "react-multi-lang";

interface IMenu {
  name: string;
  path: string;
  icon?: JSX.Element;
}
interface ISectionProps {
  isOpen: boolean;
  onClick(): void;
}

interface IMenuSettings {
  name: string;
  path: string;
  icon: JSX.Element;
  childs?: IMenuSettings[];
  link?: string;
}

const isItemActive = (item: IMenuSettings, pathName: string) => {
  switch (true) {
    case _.isEqual(item.path, pathName):
      return true;

    case _.startsWith(pathName, item.path + "/"):
      return true;

    case item.childs &&
      _.some(item.childs, (child) => {
        return (
          _.isEqual(child.path, pathName) ||
          _.startsWith(pathName, child.path + "/")
        );
      }):
      return true;

    default:
      return false;
  }
};

const NavigationMenu: React.FC<ISectionProps> = ({ isOpen, onClick }) => {
  const { pathname } = useLocation();
  const userData = Utils.getSavedUserData();

  const userRoles = _.map(
    userData?.userRole,
    (userRole) => userRole?.role?.roleCode
  );

  const getUserSidebar = (userRoles: string[]): IMenu[] => {
    // Initialize with an empty array
    let selectedSidebarMenu: IMenu[] = [];

    // Iterate over each role and select the sidebar with the most items
    _.forEach(userRoles, (role) => {
      const currentSidebar = MenuOptions[role];

      // If the current sidebar has more items than the selected one, update it
      if (
        currentSidebar &&
        currentSidebar.length > selectedSidebarMenu.length
      ) {
        selectedSidebarMenu = currentSidebar;
      }
    });

    return selectedSidebarMenu;
  };

  const SIDEBAR_MENU = getUserSidebar(userRoles);

  return (
    <List spacing={0} pt="2em">
      {_.map(SIDEBAR_MENU, (item: IMenuSettings, key: number) => (
        <MenuItem
          key={`${item.path}-${key}`}
          item={item}
          pathname={pathname}
          isOpen={isOpen}
          onClick={onClick}
        />
      ))}
      {MenuGroup(isOpen)}
    </List>
  );
};

const hasChildren = (item: IMenuSettings) => {
  const { childs: childs } = item;
  return Array.isArray(childs) && childs.length > 0;
};

const MenuItem = memo(
  ({
    item,
    pathname,
    isOpen,
    onClick,
  }: {
    item: IMenuSettings;
    pathname: string;
    isOpen: boolean;
    onClick(): void;
  }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return (
      <Component
        item={item}
        pathname={pathname}
        isOpen={isOpen}
        onClick={onClick}
      />
    );
  }
);

const SingleLevel = memo(
  ({
    item,
    pathname,
    isOpen,
    onClick,
  }: {
    item: IMenuSettings;
    pathname: string;
    isOpen: boolean;
    onClick(): void;
  }) => {
    const isActive = pathname === item.path || pathname.startsWith(item.path);
    const t = useTranslation();
    return (
      <Tooltip label={t(`menu.${item.name}`)} placement="right" hasArrow>
        <ListItem
          width="100%"
          textAlign="center"
          _hover={{
            backgroundColor: "rgba(166, 183, 170,0.2)",
            color: "#bb7154",
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            Utils.redirect(item.path);
            onClick();
          }}
          bg={isActive ? "rgba(166, 183, 170,0.2)" : "white"}
          color={isActive ? "#bb7154" : "inherit"}
          borderRight={isActive ? "5px solid #bb7154" : "none"}
          sx={{
            cursor: "pointer",
            py: 2,
            px: 4,
            transition: "all .3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isOpen ? "left" : "center",
              gap: 2,
            }}
          >
            {item.icon}
            {isOpen && (
              <Text
                fontSize="15px"
                fontWeight={isActive ? 700 : 500}
                textTransform="capitalize"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {t(`menu.${item.name}`)}
              </Text>
            )}
          </Box>
        </ListItem>
      </Tooltip>
    );
  }
);

const MultiLevel = memo(
  ({
    item,
    pathname,
    isOpen,
    onClick,
  }: {
    item: IMenuSettings;
    pathname: string;
    isOpen: boolean;
    onClick(): void;
  }) => {
    const t = useTranslation();
    const { childs } = item;
    const isActice = isItemActive(item, pathname);
    const [open, setOpen] = useState<boolean>(isActice);
    const isActive = pathname === item.path || pathname.startsWith(item.path);

    const handleClick = () => setOpen((prev) => !prev);

    return (
      <>
        <Tooltip label={t(`menu.${item.name}`)} placement="right" hasArrow>
          <ListItem
            px={4}
            textAlign="center"
            _hover={{
              backgroundColor: "rgba(166, 183, 170,0.2)",
              color: "#bb7154",
            }}
            onClick={handleClick}
            bg={isActive ? "rgb(234, 236,251,100)" : "white"}
            color={isActive ? "#bb7154" : "inherit"}
            // borderRight={isActive ? "5px solid #2736EB" : "none"}
            sx={{
              display: "flex",
              justifyContent: isOpen ? "space-between" : "center",
              alignitems: "center",
              cursor: "pointer",
              fontWeight: isActive ? 700 : 500,
              py: 2,
              px: 4,
              transition: "all .3s ease-in-out",
              "& .timesheets-icon": {
                w: "16px",
                color: isActive ? "rgb(234, 236,251,100)" : "#bb7154",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignitems: "center",
                gap: 2,
              }}
            >
              {item.icon}
              {isOpen && (
                <Text
                  fontSize="15px"
                  fontWeight={isActive ? 700 : 500}
                  textTransform="capitalize"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {t(`menu.${item.name}`)}
                </Text>
              )}
            </Box>
            <Box
              sx={{
                fontWeight: isActice ? 500 : "normal",
              }}
            >
              {isOpen &&
                (open ? (
                  <Icon as={MinusIcon} boxSize={4} />
                ) : (
                  <Icon as={PlusIcon} boxSize={4} />
                ))}
            </Box>
          </ListItem>
        </Tooltip>
        <Collapse in={open} animateOpacity>
          <Box ml={2}>
            {childs &&
              _.map(childs, (child: IMenuSettings, key) => (
                <MenuItem
                  key={key}
                  item={child}
                  pathname={pathname}
                  isOpen={isOpen}
                  onClick={onClick}
                />
              ))}
          </Box>
        </Collapse>
      </>
    );
  }
);

const MenuGroup = (isOpen: boolean) => {
  const [isActice, setIsActive] = useState<boolean>(false);
  const { pathname } = useLocation();
  const t = useTranslation();

  const workspaceGroupList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceGroupList")
  );

  const workspaceShortList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceShortList")
  );

  useEffect(() => {
    if (pathname === "/shortcut") {
      setIsActive(true);
    }
  }, [pathname]);

  return (
    <Box>
      <Tooltip label={t("menu.quickAccess")} placement="right" hasArrow>
        <ListItem
          width="100%"
          textAlign="center"
          _hover={{
            backgroundColor: "rgba(166, 183, 170,0.2)",
            color: "#bb7154",
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setIsActive(!isActice);
          }}
          //bg={isActive ? "rgba(166, 183, 170,0.2)" : "white"}
          //color={isActive ? "#bb7154" : "inherit"}
          //borderRight={isActive ? "5px solid #bb7154" : "none"}
          sx={{
            cursor: "pointer",
            py: 2,
            px: 4,
            transition: "all .3s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <GlobeAmericasIcon
                style={{ width: 20, marginLeft: !isOpen ? 3 : 0 }}
              />
              {isOpen && (
                <Text
                  fontSize="15px"
                  fontWeight={500}
                  textTransform="capitalize"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {t("menu.quickAccess")}
                </Text>
              )}
            </Box>
            {isOpen && (
              <Box
                sx={{
                  fontWeight: 500,
                }}
              >
                {isActice ? (
                  <Icon as={MinusIcon} boxSize={4} />
                ) : (
                  <Icon as={PlusIcon} boxSize={4} />
                )}
              </Box>
            )}
          </Box>
        </ListItem>
      </Tooltip>
      <Collapse in={isActice} animateOpacity>
        <Box ml={2}>
          {SingleLevelSpacework(workspaceShortList, workspaceGroupList, isOpen)}
          {/*{MultiLevelSpacework(workspaceGroupList)}*/}
        </Box>
      </Collapse>
    </Box>
  );
};

const SingleLevelSpacework = (work: any, group: any, isOpen: boolean) => {
  const [searchParams] = useSearchParams();
  const idActive: any = searchParams.get("id");

  //  const [isActice, setIsActive] = useState<string>("");
  const [isActiceGroup, setIsActiveGroup] = useState<string>("");

  const workspaceAction: any = useSelector((state: RootState) =>
    _.get(state.WORKSPACE, "workspaceAction")
  );

  const handleClick = (id: string) => {
    if (isActiceGroup === id) {
      setIsActiveGroup("");
    } else setIsActiveGroup(id);
  };
  return (
    <>
      {_.map(work, (item, index) => {
        return (
          <Tooltip label={item.name} placement="right" hasArrow key={index}>
            <ListItem
              width="100%"
              textAlign="center"
              _hover={{
                backgroundColor: "rgba(166, 183, 170,0.2)",
                color: "#bb7154",
              }}
              onClick={async (event) => {
                event.stopPropagation();
                event.preventDefault();
                if (idActive === item.id) {
                  Utils.redirect(`${Routers.WORKSPACE_GROUP}`);
                } else {
                  const isOpenLinkInNewTab = item?.isUserIconCustom;
                  if (isOpenLinkInNewTab) {
                    // Get the screen dimensions
                    const screenWidth = window.screen.width;
                    const screenHeight = window.screen.height;

                    // Calculate the size of the pop-up window
                    const popupWidth = screenWidth * 0.7; // Take up 50% of the width
                    const popupHeight = screenHeight * 0.5; // Take up 50% of the height

                    // Calculate the position of the pop-up window to center it on the screen
                    const left = (screenWidth - popupWidth) / 2;
                    const top = (screenHeight - popupHeight) / 2;

                    // Open the pop-up window
                    window.open(
                      item?.link,
                      "popUpWindow",
                      `height=${popupHeight},width=${popupWidth},left=${left},top=${top},scrollbars=yes,menubar=no`
                    );
                  } else
                    Utils.redirect(`${Routers.WORKSPACE_GROUP}?id=${item.id}`);
                }
              }}
              bg={
                idActive === item.id || workspaceAction.id === item.id
                  ? "rgba(166, 183, 170,0.2)"
                  : "white"
              }
              //color={isActive ? "#bb7154" : "inherit"}
              borderRight={idActive === item.id ? "5px solid #bb7154" : "none"}
              sx={{
                cursor: "pointer",
                py: 2,
                px: 4,
                transition: "all .3s ease-in-out",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  //justifyContent: isOpen ? "left" : "center",
                  gap: 2,
                }}
              >
                <Image
                  borderRadius="full"
                  boxSize="20px"
                  src={item.iconCustom?.path || item?.icon}
                />
                {isOpen && (
                  <Text
                    fontSize="15px"
                    fontWeight={500}
                    textTransform="capitalize"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    {item.name}
                  </Text>
                )}
              </Box>
            </ListItem>
          </Tooltip>
        );
      })}
      {_.map(group, (item, index) => {
        return (
          <Box>
            <Tooltip label={item?.name} placement="right" hasArrow key={index}>
              <ListItem
                px={4}
                textAlign="center"
                _hover={{
                  backgroundColor: "rgba(166, 183, 170,0.2)",
                  color: "#bb7154",
                }}
                onClick={() => handleClick(item.id)}
                //  bg={isActive ? "rgb(234, 236,251,100)" : "white"}
                //  color={isActive ? "#bb7154" : "inherit"}
                // borderRight={isActive ? "5px solid #2736EB" : "none"}
                sx={{
                  display: "flex",
                  //justifyContent: isOpen ? "space-between" : "center",
                  justifyContent: "space-between",

                  alignitems: "center",
                  cursor: "pointer",
                  //fontWeight: isActive ? 700 : 500,
                  py: 2,
                  px: 4,
                  transition: "all .3s ease-in-out",
                  "& .timesheets-icon": {
                    w: "16px",
                    //  color: isActive ? "rgb(234, 236,251,100)" : "#bb7154",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    //justifyContent: isOpen ? "left" : "center",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Image
                    borderRadius="full"
                    boxSize="20px"
                    src={item.iconCustom?.path || item?.icon}
                  />
                  {isOpen && (
                    <Text
                      fontSize="15px"
                      fontWeight={500}
                      textTransform="capitalize"
                      whiteSpace="nowrap"
                      overflow="hidden"
                    >
                      {item.name}
                    </Text>
                  )}
                </Box>
                {isOpen && (
                  <Box
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    {isActiceGroup === item.id ? (
                      <Icon as={MinusIcon} boxSize={4} />
                    ) : (
                      <Icon as={PlusIcon} boxSize={4} />
                    )}
                  </Box>
                )}
              </ListItem>
            </Tooltip>
            <Collapse in={isActiceGroup === item.id} animateOpacity>
              <Box ml={2}>
                {item &&
                  _.map(item.workspaceShortcut, (child: any, key) => (
                    <Tooltip
                      label={item.name}
                      placement="right"
                      hasArrow
                      key={key}
                    >
                      <ListItem
                        width="100%"
                        textAlign="center"
                        _hover={{
                          backgroundColor: "rgba(166, 183, 170,0.2)",
                          color: "#bb7154",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (idActive === child.id) {
                            Utils.redirect(`${Routers.WORKSPACE_GROUP}`);
                          } else {
                            const isOpenLinkInNewTab = child?.isUserIconCustom;
                            if (isOpenLinkInNewTab) {
                              // Get the screen dimensions
                              const screenWidth = window.screen.width;
                              const screenHeight = window.screen.height;

                              // Calculate the size of the pop-up window
                              const popupWidth = screenWidth * 0.7; // Take up 50% of the width
                              const popupHeight = screenHeight * 0.5; // Take up 50% of the height

                              // Calculate the position of the pop-up window to center it on the screen
                              const left = (screenWidth - popupWidth) / 2;
                              const top = (screenHeight - popupHeight) / 2;

                              // Open the pop-up window
                              window.open(
                                child?.link,
                                "popUpWindow",
                                `height=${popupHeight},width=${popupWidth},left=${left},top=${top},scrollbars=yes,menubar=no`
                              );
                            } else
                              Utils.redirect(
                                `${Routers.WORKSPACE_GROUP}?id=${child.id}`
                              );
                          }
                        }}
                        bg={
                          idActive === child.id
                            ? "rgba(166, 183, 170,0.2)"
                            : "white"
                        }
                        color={idActive === child.id ? "#bb7154" : "inherit"}
                        borderRight={
                          idActive === child.id ? "5px solid #bb7154" : "none"
                        }
                        sx={{
                          cursor: "pointer",
                          py: 2,
                          px: 4,
                          transition: "all .3s ease-in-out",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Image
                            borderRadius="full"
                            boxSize="20px"
                            src={child.iconCustom?.path || child?.icon}
                          />
                          <Text
                            fontSize="15px"
                            fontWeight={500}
                            textTransform="capitalize"
                            whiteSpace="nowrap"
                            overflow="hidden"
                          >
                            {child.name}
                          </Text>
                        </Box>
                      </ListItem>
                    </Tooltip>
                  ))}
              </Box>
            </Collapse>
          </Box>
        );
      })}
    </>
  );
};

export default NavigationMenu;
