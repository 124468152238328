import React, { useEffect, useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { TextField, Button } from "@/Components/Common";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import { UserActions } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { updatePasswordForSelf, resetActionUserReducer, resetUserMessage } =
  UserActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

const DEFAULT_FORM_DATA = {
  oldPassword: "",
  newPassword: "",
  confirmationNewPassword: "",
};

const ChangePasswordDialog: React.FC<SectionProps> = ({ open, onClose }) => {
  const t = useTranslation();
  const dispatch = useTypedDispatch();
  const [isShowPassword, setIsShowPassword] = React.useState(false);
  const [isShowNewPass, setIsShowNewPass] = React.useState(false);
  const [isShowconfirmNewPass, setIsShowconfirmNewPass] = React.useState(false);
  const [messagePass, setMessPass] = React.useState<string>("");
  const [isMessageServer, setIsMessageServer] = React.useState<string>("");

  const cancelRef = useRef<any>(null);
  type FormData = yup.InferType<typeof schema>;

  const schema = yup
    .object()
    .shape({
      oldPassword: yup
        .string()
        .trim()
        .required(t("message.oldPasswordIsRequired")),
      newPassword: yup
        .string()
        .matches(/^\S*$/, t("message.whitespaceIsNotAllowed"))
        .required(t("message.newPasswordIsRequired"))
        .min(6),
      confirmationNewPassword: yup
        .string()
        .trim()
        .required(t("message.confirmNewPassword")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const isMessage: string = useSelector((state: RootState) =>
    _.get(state.USER, "isMessage")
  );

  const isUpdataPasswordSuccess: string = useSelector((state: RootState) =>
    _.get(state.USER, "isUpdataPasswordSuccess")
  );
  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.USER, "isActionLoading")
  );

  const onSubmit = (data: FormData) => {
    if (_.isEqual(data.oldPassword, data.newPassword)) {
      setMessPass(
        t("message.TheNewPasswordMustNotBeTheSameAsTheLostOldPassword")
      );
    } else if (_.isEqual(data.newPassword, data.confirmationNewPassword)) {
      dispatch(updatePasswordForSelf(data));
    } else
      setMessPass(t("message.passwordAndConfirmationPasswordDoesNotMatch"));
  };

  useEffect(() => {
    if (!open) {
      reset();
      setMessPass("");
      setIsMessageServer("");
      dispatch(resetUserMessage());
    }
  }, [open]);

  useEffect(() => {
    setIsMessageServer(isMessage);
  }, [isMessage]);

  useEffect(() => {
    if (isUpdataPasswordSuccess) {
      onClose();
      dispatch(resetActionUserReducer());
    }
  }, [isUpdataPasswordSuccess]);

  const _renderInformantion = () => {
    return (
      <Box as={"form"}>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => (
            <TextField
              size={"sm"}
              label={t("label.currentPassword")}
              inputSx={{ height: "38px" }}
              type={isShowPassword ? "text" : "password"}
              placeholder={t("label.currentPassword")}
              value={field.value}
              onChange={(e: any) => {
                field.onChange(e);
                setMessPass("");
                setIsMessageServer("");
              }}
              isError={!_.isEmpty(errors.oldPassword?.message)}
              errorMessage={errors.oldPassword?.message}
              isRequired
              autoComplete="new-password"
              rightElement={
                <IconButton
                  size="sm"
                  borderRadius="8px"
                  variant="ghost"
                  _hover={{ bgColor: "none" }}
                  icon={
                    !isShowPassword ? (
                      <EyeSlashIcon style={{ width: "20px", height: "20px" }} />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"
                          fill="#595454"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"
                          fill="#595454"
                        />
                      </svg>
                    )
                  }
                  aria-label={"icon"}
                  onClick={() => setIsShowPassword(!isShowPassword)}
                />
              }
            />
          )}
        />
        {!_.isEmpty(isMessageServer) && (
          <Text sx={{ color: "error", fontSize: 14, mt: 2 }}>
            {isMessageServer}
          </Text>
        )}
        <Box mt={4}>
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("label.newPassword")}
                inputSx={{ height: "38px" }}
                type={isShowNewPass ? "text" : "password"}
                placeholder={t("label.newPassword")}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e);
                  setMessPass("");
                }}
                isError={!_.isEmpty(errors.newPassword?.message)}
                errorMessage={
                  errors.newPassword?.message ===
                  "newPassword must be at least 6 characters"
                    ? t("message.newPasswordMustBeAtLeast6Characters")
                    : errors.newPassword?.message
                }
                isRequired
                autoComplete="new-password"
                rightElement={
                  // <Button h="1.75rem" size="sm" onClick={handleClick}>
                  //   {isShowPassword ? "Hide" : "Show"}
                  // </Button>
                  <IconButton
                    size="xs"
                    borderRadius="8px"
                    variant="ghost"
                    _hover={{ bgColor: "none" }}
                    icon={
                      !isShowNewPass ? (
                        <EyeSlashIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"
                            fill="#595454"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"
                            fill="#595454"
                          />
                        </svg>
                      )
                    }
                    aria-label={"icon"}
                    onClick={() => setIsShowNewPass(!isShowNewPass)}
                  />
                }
              />
            )}
          />
        </Box>
        <Box mt={4}>
          <Controller
            name="confirmationNewPassword"
            control={control}
            render={({ field }) => (
              <TextField
                label={t("label.confirmationNewPassword")}
                inputSx={{ height: "38px" }}
                type={isShowconfirmNewPass ? "text" : "password"}
                placeholder={t("label.confirmationNewPassword")}
                value={field.value}
                onChange={(e: any) => {
                  field.onChange(e);
                  setMessPass("");
                }}
                isError={!_.isEmpty(errors.confirmationNewPassword?.message)}
                errorMessage={errors.confirmationNewPassword?.message}
                isRequired
                autoComplete="new-password"
                rightElement={
                  // <Button h="1.75rem" size="sm" onClick={handleClick}>
                  //   {isShowPassword ? "Hide" : "Show"}
                  // </Button>
                  <IconButton
                    size="sm"
                    borderRadius="8px"
                    variant="ghost"
                    _hover={{ bgColor: "none" }}
                    icon={
                      !isShowconfirmNewPass ? (
                        <EyeSlashIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"
                            fill="#595454"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"
                            fill="#595454"
                          />
                        </svg>
                      )
                    }
                    aria-label={"icon"}
                    onClick={() =>
                      setIsShowconfirmNewPass(!isShowconfirmNewPass)
                    }
                  />
                }
              />
            )}
          />
        </Box>
        {messagePass ? (
          <Text sx={{ color: "error", fontSize: 14, mt: 2 }}>
            {messagePass}!
          </Text>
        ) : null}
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xs"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.changePassword")}
        </AlertDialogHeader>
        <AlertDialogBody
          sx={{
            overflowY: "auto",
          }}
        >
          <Box>{_renderInformantion()}</Box>
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px 0px 2px gray",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Button
            size={"sm"}
            ref={cancelRef}
            onClick={() => {
              onClose();
              reset();
              setMessPass("");
            }}
            isLoading={isActionLoading}
          >
            {t("button.cancel")}
          </Button>
          <Button
            size={"sm"}
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            isLoading={isActionLoading}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ChangePasswordDialog;
