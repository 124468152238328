import React, { useEffect, useRef } from "react";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
} from "@chakra-ui/react";

import { RootState, useTypedDispatch } from "@/Store";
import { MailReceiveActions } from "@/Actions";
import { TextField } from "@/Components/Common";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-multi-lang";
//import { ITaskStructure } from "@/Interfaces/Board.interface";

interface SectionProps {
  open: boolean;
  onClose(): void;
  data: any;
}

const { fetchMailReceive, createMailReceive, updateMailReceive } =
  MailReceiveActions;

const DEFAULT_FORM_DATA = {
  mail: "",
  type: "",
};

const MailReceiveDialog: React.FC<SectionProps> = ({ open, onClose, data }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();

  const isActionLoading: boolean = useSelector((state: RootState) =>
    _.get(state.MAIL_RECEIVE, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      mail: yup
        .string()
        .email(t("message.invalidEmailFormat"))
        .required(t("message.emailIsRequired")),
      type: yup.string().trim().required(t("message.typeIsRequired")),
    })
    .required();

  const {
    control,
    setValue,
    reset,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setValue("mail", data?.mail);
      setValue("type", data?.type);
    }
  }, [data, open]);

  useEffect(() => {
    if (!open) clearErrors();
  }, [open]);

  const handleReset = () => {
    reset();
    onClose();
    dispatch(fetchMailReceive());
  };

  const onSubmit = (data: any) => {
    if (data?.id) {
      dispatch(
        updateMailReceive(
          data?.id,
          {
            type: data?.type,
            mail: data?.mail,
          },
          () => handleReset()
        )
      );
    } else {
      dispatch(createMailReceive(data, () => handleReset()));
    }
  };

  const _renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        py: 2,
      }}
      as={"form"}
    >
      <Controller
        name="mail"
        control={control}
        render={({ field }) => (
          <TextField
            type="email"
            value={field.value}
            onChange={(e: any) => {
              field.onChange(e.target.value);
            }}
            label="Email"
            placeholder="Email"
            size="sm"
            isRequired
            isError={!_.isEmpty(errors.mail?.message)}
            errorMessage={errors?.mail?.message}
            isDisabled={isActionLoading}
          />
        )}
      />
    </Box>
  );

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
            paddingBottom: 1,
          }}
        >
          <Text sx={{ textAlign: "center" }}>
            {t("title.editEmailReceive")}
          </Text>
        </AlertDialogHeader>
        <AlertDialogBody sx={{ maxH: 450, overflowY: "auto" }}>
          {_renderContent()}
        </AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size={"sm"}
            isDisabled={isActionLoading}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            size={"sm"}
            sx={{
              color: "#fff",
              bgColor: "rgba(92, 110, 108,1)",
              "&:hover": {
                bgColor: "rgba(92, 110, 108,0.8)",
              },
            }}
            isDisabled={isActionLoading}
          >
            {t("button.update")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default MailReceiveDialog;
