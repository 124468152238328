import React, { useState, useEffect, memo, useMemo } from "react";
import dayjs from "dayjs";
import _ from "lodash";
import { useSelector } from "react-redux";

import {
  Box,
  Link,
  Icon,
  Tooltip,
  Heading,
  IconButton,
  Fade,
} from "@chakra-ui/react";

import {
  DocumentDuplicateIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";

import { RepeatClockIcon } from "@chakra-ui/icons";

import { Routers } from "@/Constants";
import { RootState, useTypedDispatch } from "@/Store";
import { useCopyToClipboard, useWindowWidth } from "@/Helpers";
import { IProjectStructure } from "@/Interfaces/Project.interface";
import Utils from "@/Utils";

import Calendar from "./Calendar";
import CommonColors from "@/Themes/CommonColors";
import {
  // DateRangeSlider,
  RoundedContainer,
} from "@/Components/Common";
import { HolidayActions, WorkingTimeActions, ProjectActions } from "@/Actions";
import FullHDWarning from "@/Components/Common/FullScreenWarning";
// import CustomBottomBar from "@/Components/Common/DateRangeSlider/CustomBottomBar";
import SingleSlider from "@/Components/Common/DateRangeSlider/SingleRange";
import { useTranslation } from "react-multi-lang";
import "dayjs/locale/vi";
const { fetchHoliday, resetHolidayReducer } = HolidayActions;
const { fetchWorkingTime } = WorkingTimeActions;
const { saveCurrentDateInScroller } = ProjectActions;

interface ISectionProps {}

const Timeline: React.FC<ISectionProps> = ({}) => {
  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const dispatch = useTypedDispatch();
  const [, copy] = useCopyToClipboard();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;
  const projectDetails: IProjectStructure = useSelector((state: RootState) =>
    _.get(state.PROJECT, "details")
  );

  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.PROJECT, "currentDateInScroller")
  );

  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  // const isGetLoading = useSelector((state: RootState) =>
  //   _.get(state.BOARD, "isGetLoading")
  // );

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [activeDates, setActiveDates] = useState<string[]>(
    Utils.generateDefaultTimeline(7)
  );

  const [activeView] = useState<"week" | "month">("week");
  const [isShowFullScreen, setIsShowFullScreen] = useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<any>([]);
  const [minSlotWidthDate, setMinSlotWidthDate] = useState<number>(50);
  const [calendarWidth, setCalendarWidth] = useState<number>(0);
  const [visibleDays, setVisibleDays] = useState<number>(isMobile ? 7 : 14);
  const [isReset, setIsReset] = useState(false);

  const workingTimeList: any = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const timeline = useMemo(() => {
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);
    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);

    return dateRanges;
  }, [boardDetails]);

  useEffect(() => {
    dispatch(fetchHoliday({ page: 0, limit: 0, status: "active" }));
    if (_.isEmpty(workingTimeList)) dispatch(fetchWorkingTime());
    updateDateRanges();
    return () => {
      dispatch(resetHolidayReducer());
    };
  }, []);

  useEffect(() => {
    if (visibleDays > 0 && calendarWidth > 0) handleVisibleDaysChange();
  }, [visibleDays, calendarWidth]);

  useEffect(() => {
    if (!_.isEmpty(boardDetails)) {
      const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);
      setCurrentFilter([minStartDate || "", maxEndDate || ""]);
    }
  }, [boardDetails]);

  useEffect(() => {
    if (language) {
      dayjs.locale(language);
    }
  }, [language]);

  const updateDateRanges = () => {
    const DEFAULT_DURATION = isMobile ? 3 : 7;
    const MAX_RANGE = isMobile ? 7 : 15;
    const defaultRanges = Utils.generateDefaultTimeline(DEFAULT_DURATION);
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);
    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);

    const defaultTimelineStartDate = defaultRanges[0];
    const defaultTimelineEndDate = defaultRanges[defaultRanges.length - 1];
    const timelineStartDate = dateRanges[0];
    const timelineEndDate = dateRanges[dateRanges.length - 1];
    let defaultDate = "";

    if (_.size(dateRanges) <= MAX_RANGE) {
      if (!_.isEmpty(dateRanges)) {
        setActiveDates(dateRanges);
        defaultDate =
          _.first(dateRanges) || Utils.getMiddleValue(dateRanges) || "";
      }
    } else {
      const currentDay = dayjs().format("YYYY-MM-DD");
      if (
        defaultTimelineStartDate >= timelineStartDate &&
        defaultTimelineEndDate <= timelineEndDate
      ) {
        setActiveDates(defaultRanges);
        if (!_.isEmpty(_.first(defaultRanges)))
          defaultDate =
            _.first(defaultRanges) || Utils.getMiddleValue(defaultRanges) || "";
      } else {
        const indexOfCurrentDay = dateRanges.indexOf(currentDay);
        if (indexOfCurrentDay !== -1) {
          const { daysBeforeCurrentDay, daysAfterCurrentDay } =
            Utils.calculateDaysAround(dateRanges, indexOfCurrentDay);
          if (daysBeforeCurrentDay >= daysAfterCurrentDay) {
            const startIndex = Math.max(
              indexOfCurrentDay - (MAX_RANGE - 1 - daysAfterCurrentDay),
              0
            );
            const endIndex = Math.min(
              indexOfCurrentDay + daysAfterCurrentDay + 1,
              dateRanges.length
            );
            const newActiveDates = dateRanges.slice(startIndex, endIndex);
            if (!_.isEmpty(newActiveDates)) {
              setActiveDates(newActiveDates);
              defaultDate = _.first(newActiveDates) || "";
            }
          } else {
            const endIndex = Math.min(
              indexOfCurrentDay + (MAX_RANGE - 1 - daysBeforeCurrentDay) + 1,
              dateRanges.length
            );
            const startIndex = Math.max(
              indexOfCurrentDay - daysBeforeCurrentDay,
              0
            );
            const newActiveDates = dateRanges.slice(startIndex, endIndex);
            if (!_.isEmpty(newActiveDates)) {
              setActiveDates(newActiveDates);
              defaultDate =
                _.first(newActiveDates) ||
                Utils.getMiddleValue(newActiveDates) ||
                "";
            }
          }
        } else {
          const MAX_MID_DATE = isMobile ? 7 : 14;
          const middleDates = Utils.getMiddleDates(dateRanges, MAX_MID_DATE);
          if (!_.isEmpty(middleDates)) {
            setActiveDates(middleDates);
            defaultDate =
              _.first(middleDates) || Utils.getMiddleValue(middleDates) || "";
          }
        }
      }
    }
    dispatch(saveCurrentDateInScroller(defaultDate));
  };

  const handleVisibleDaysChange = () => {
    const newValue = parseInt((calendarWidth / visibleDays).toString());
    setMinSlotWidthDate(newValue);
    const defaultTimelineStartDateIndex = _.findIndex(
      timeline,
      (date) => date === currentDateInScroller
    );

    if (defaultTimelineStartDateIndex !== -1) {
      const startDate = currentDateInScroller;
      const endDate =
        timeline[defaultTimelineStartDateIndex + (visibleDays - 1)] ||
        _.last(timeline);
      const newActiveDates = Utils.generateDateRanges(startDate, endDate);
      if (!_.isEmpty(newActiveDates)) {
        setActiveDates(newActiveDates);
      }
    }
  };

  const handleCopyText = (value: string) => {
    if (value)
      copy(value)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 500);
        })
        .catch((err) => console.log(err, "error"));
  };

  const formattedDateRange = React.useMemo(() => {
    const startDate = dayjs(_.first(currentFilter));
    const endDate = dayjs(_.last(currentFilter));

    const isValidStartDate = dayjs(startDate).isValid();
    const isValidEndDate = endDate.isValid();

    const formattedStartDate = isValidStartDate
      ? startDate.format("MMM D")
      : "";
    const formattedEndDate = isValidEndDate
      ? endDate.format("MMM D, YYYY")
      : "";

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, [currentFilter]);

  // const getDatesInRange = (
  //   startDate: string | Date,
  //   endDate: string | Date
  // ): string[] => {
  //   const datesArray: string[] = [];
  //   let currentDate = dayjs(startDate);
  //   const lastDate = dayjs(endDate);

  //   while (
  //     currentDate.isSame(lastDate, "day") ||
  //     currentDate.isBefore(lastDate, "day")
  //   ) {
  //     const formattedDate = currentDate.format("YYYY-MM-DD");
  //     datesArray.push(formattedDate);
  //     currentDate = currentDate.add(1, "day");
  //   }
  //   return datesArray;
  // };

  // const handleFilterTimelineChange = () => {
  //   const generateDateRanges = getDatesInRange(
  //     currentFilter[0],
  //     currentFilter[1]
  //   );

  //   if (!_.isEmpty(generateDateRanges)) setActiveDates(generateDateRanges);
  //   setIsShowFilter(false);
  // };

  const toggleFullscreen = () => {
    const element: any = document.getElementById("fullscreenElement");
    if (element) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.webkitEnterFullscreen) {
          element.webkitEnterFullscreen();
        }
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsShowFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const _renderToolbar = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Link
              size="sm"
              variant="ghost"
              colorScheme="twitter"
              href={`${Routers.SHARE_TIMELINE}/?id=${projectDetails?.kanbanBoard?.id}`}
              target="_blank"
              sx={{
                "&:hover, &:focus": {
                  color: "#0a5dc2",
                },
              }}
            >
              {t("button.shareToClient")}
            </Link>
            <Tooltip hasArrow isOpen={isCopied} label={t("label.copied")}>
              <Icon
                boxSize={5}
                sx={{
                  color: "rgba(187, 113, 84,0.8)",
                  ml: 2,
                  "&:hover": {
                    color: CommonColors.warmCopper,
                    cursor: "pointer",
                  },
                }}
                onClick={() =>
                  handleCopyText(
                    `${window.location.origin}${Routers.SHARE_TIMELINE}/?id=${projectDetails?.kanbanBoard?.id}`
                  )
                }
              >
                <DocumentDuplicateIcon />
              </Icon>
            </Tooltip>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                p: 2,
              }}
            >
              <Icon
                sx={{
                  w: "32px",
                  height: "32px",
                  color: "#fff",
                  p: 2,
                  rounded: 5,
                  cursor: "pointer",
                  background: CommonColors.aquatone,
                  "&:hover": {
                    background: "#748580",
                  },
                }}
                onClick={() => {
                  setIsShowFullScreen(!isShowFullScreen);
                  toggleFullscreen();
                }}
              >
                {isShowFullScreen ? (
                  <ArrowsPointingInIcon />
                ) : (
                  <ArrowsPointingOutIcon />
                )}
              </Icon>
              <IconButton
                size="sm"
                aria-label={"Reset"}
                sx={{
                  background: CommonColors.dustyCoral,
                  color: "#Fff",
                  "&:hover": {
                    background: "#B57D6E!important",
                  },
                }}
                onClick={() => {
                  updateDateRanges();
                  setVisibleDays(isMobile ? 7 : 14);
                  setIsReset(true);
                }}
              >
                <RepeatClockIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const _renderTopSection = () => {
    return !isMobile ? (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          mb: 5,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          {/* {_renderFilterPopper()} */}
          <Heading size="sm">{formattedDateRange}</Heading>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            width: `calc(50% - ${language === "vi" ? "90" : "80"}px)`,
          }}
        >
          <SingleSlider
            value={visibleDays}
            onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
            calendarWidth={calendarWidth}
            defaultValue={isMobile ? 7 : 14}
            step={7}
            maxDays={_.size(timeline)}
          />
        </Box>
      </Box>
    ) : (
      <Box
        sx={{
          position: "relative",
          mb: 5,
          cursor: "default",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {_renderFilterPopper()} */}
          <Heading size="sm">{formattedDateRange}</Heading>
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <SingleSlider
            value={visibleDays}
            onChange={(value) => _.isNumber(value) && setVisibleDays(value)}
            calendarWidth={calendarWidth}
            defaultValue={isMobile ? 7 : 14}
            step={7}
            maxDays={_.size(timeline)}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box
      background={isShowFullScreen ? "white" : "none"}
      p={isShowFullScreen ? 2 : 0}
      id="fullscreenElement"
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        {_renderToolbar()}
        {_renderTopSection()}
        <Fade in>
          <RoundedContainer>
            <Calendar
              activeView={activeView}
              isShowFullScreen={isShowFullScreen}
              visibleRange={{
                start: _.first(activeDates) || "",
                end: _.last(activeDates) || "",
              }}
              minSlotWidthDate={minSlotWidthDate}
              onContainerWidthChange={(width) => setCalendarWidth(width)}
              isReset={isReset}
              onReset={() => setIsReset(false)}
            />
          </RoundedContainer>
        </Fade>

        {isShowFullScreen && (
          <FullHDWarning
            onAction={(e) => {
              setIsShowFullScreen(e);
              document.exitFullscreen();
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(Timeline);
