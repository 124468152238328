import { PURGE } from "redux-persist";
import {
  ActionTypes,
  DEFAULT_LOADING_STATES,
  DEFAULT_META_STATES,
  generateSuccessStateVariables,
} from "@/Constants";

const initialState = {
  ...DEFAULT_LOADING_STATES,
  isDeleteSuccess: false,
  typeChat: "private",
  isFetchMessageInChannelLoading: false,
  payload: [],
  listMessages: [],
  potentialChats: [],
  groupChat: [],
  personalChat: [],
  performAction: {},
  newChatData: {},
  messageChanelPagination: {},
  messageChanelMeta: {},
  messageDetailsPagination: {
    page: 1,
    limit: 10,
  },
  messageDetailsMeta: DEFAULT_META_STATES,
  attachmentInChannel: [],
  notifications: [],
  userNotConversation: [],
  userList: [],
  conversationChannelDetail: {},
  ...generateSuccessStateVariables([
    "CreateMessage",
    "AddMemberToGroup",
    "RemoveMemberToGroup",
  ]),
};

export default (
  state = initialState,
  action: { type: string; payload: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case PURGE:
      return initialState;
    case ActionTypes.SET_CHAT_FETCH_LOADING:
      return {
        ...state,
        isFetchLoading: true,
      };
    case ActionTypes.SET_CHAT_FETCH_CHANNEL_LOADING:
      return {
        ...state,
        isFetchChannelLoading: true,
      };
    case ActionTypes.SET_CHAT_GET_LOADING:
      return {
        ...state,
        isGetLoading: true,
      };
    case ActionTypes.SET_CHAT_ACTION_LOADING:
      return {
        ...state,
        isActionLoading: true,
      };
    case ActionTypes.SET_LIST_MESSAGES:
      return {
        ...state,
        listMessages: payload,
      };
    case ActionTypes.SET_NEW_PERFORM_ACTION: {
      return {
        ...state,
        performAction: payload,
      };
    }
    case ActionTypes.SET_NOTIFICATION: {
      return {
        ...state,
        notifications: payload,
      };
    }
    case ActionTypes.SET_TYPE_CHAT: {
      return {
        ...state,
        typeChat: payload,
      };
    }
    case ActionTypes.CHAT_PERFORM_ACTION:
      return {
        ...state,
        performAction: payload,
        listMessages: [],
        messageDetailsPagination: {
          page: 1,
          limit: 10,
        },
        isActionLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.RESET_CHAT_PERFORM_ACTION:
      return {
        ...state,
        listMessages: [],
        performAction: {},
        newChatData: {},
        messageChanelPagination: {},
        messageChanelMeta: {},
        messageDetailsPagination: {
          page: 1,
          limit: 10,
        },
        messageDetailsMeta: {
          currentPage: 1,
          itemCount: 0,
          itemsPerPage: 10,
          totalItems: 0,
          totalPages: 1,
        },
        attachmentInChannel: [],
        conversationChannelDetail: {},
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
      };
    case ActionTypes.RESET_CHAT_STATUS:
      return {
        ...state,
        isFetchLoading: false,
        isGetLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
        isCreateMessageSuccess: false,
        isAddMemberToGroupSuccess: false,
        isRemoveemberToGroupSuccess: false,
        isFetchMessageInChannelLoading: false,
      };
    case ActionTypes.RESET_CONVERSATION_CHANNEL_DETAIL: {
      return {
        ...state,
        conversationChannelDetail: {},
        isActionLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
      };
    }
    case ActionTypes.RESET_MEDIA_IN_CHANNEL:
      return {
        ...state,
        attachmentInChannel: [],
        isActionLoading: false,
        isFetchLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.CREATE_NEW_CHAT:
      return {
        ...state,
        newChatData: payload,
      };
    case ActionTypes.RESET_NEW_CHAT_DATA:
      return {
        ...state,
        newChatData: {},
      };
    case ActionTypes.RESET_CHAT_REDUCER:
      return initialState;

    case ActionTypes.SET_POTENTIAL_CHATS:
      return {
        ...state,
        potentialChats: payload,
      };
    case ActionTypes.SET_MESSAGE_CHANNEL_PAGINATION:
      return {
        ...state,
        messageChanelPagination: payload,
      };
    case ActionTypes.SET_MESSAGE_CHANNEL_META:
      return {
        ...state,
        messageChanelMeta: payload,
      };
    case ActionTypes.SET_MESSAGE_DETAILS_PAGINATION:
      return {
        ...state,
        messageDetailsPagination: payload,
      };
    case ActionTypes.SET_MESSAGE_DETAILS_META:
      return {
        ...state,
        messageDetailsMeta: payload,
      };

    case ActionTypes.FETCH_MESSAGE_CHANNEL_SUCCESS:
      return {
        ...state,
        groupChat: payload?.group,
        personalChat: payload?.private,
        potentialChats: [...payload?.group, ...payload?.private],
        isFetchChannelLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isCreateNewMessageSuccess: false,
      };
    case ActionTypes.FETCH_MESSAGE_CHANNEL_FAILURE:
      return {
        ...state,
        isFetchChannelLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        isCreateNewMessageSuccess: false,
        groupChat: [],
        personalChat: [],
        potentialChats: [],
      };
    case ActionTypes.FETCH_MESSAGE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: payload,
        isFetchLoading: false,
      };
    case ActionTypes.FETCH_MESSAGE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
      };
    case ActionTypes.FETCH_MESSAGE_BY_CHANNEL_ID_SUCCESS:
      return {
        ...state,
        listMessages: payload,
        isGetLoading: false,
        isCreateSuccess: false,
        isAddMemberToGroupSuccess: false,
        isRemoveemberToGroupSuccess: false,
      };
    case ActionTypes.FETCH_MESSAGE_BY_CHANNEL_ID_FAILURE:
      return {
        ...state,
        isGetLoading: false,
        isActionLoading: false,
        isCreateSuccess: false,
        isAddMemberToGroupSuccess: false,
        isRemoveMemberToGroupSuccess: false,
      };
    case ActionTypes.FETCH_ALL_USER_FOR_CHAT_SUCCESS:
      return {
        ...state,
        userList: payload,
        isFetchLoading: false,
      };
    case ActionTypes.FETCH_ALL_USER_FOR_CHAT_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
      };
    case ActionTypes.FETCH_CONVERSATION_CHANNEL_DETAIL_SUCCESS:
      return {
        ...state,
        conversationChannelDetail: payload,
        isFetchLoading: false,
        isCreateMessageSuccess: false,
      };
    case ActionTypes.FETCH_CONVERSATION_CHANNEL_DETAIL_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        conversationChannelDetail: {},
        isCreateMessageSuccess: false,
      };
    case ActionTypes.FETCH_USER_NOT_CONVERSATION_SUCCESS:
      return {
        ...state,
        userNotConversation: payload,
        isFetchLoading: false,
      };
    case ActionTypes.FETCH_USER_NOT_CONVERSATION_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
      };
    case ActionTypes.CREATE_PRIVATE_CHAT_SUCCESS:
      return {
        ...state,
        isCreateSuccess: true,
        isActionLoading: false,
        newChatData: {},
      };
    case ActionTypes.CREATE_PRIVATE_CHAT_FAILURE:
      return {
        ...state,
        isCreateSuccess: false,
        isActionLoading: false,
        newChatData: {},
      };
    case ActionTypes.CREATE_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        isCreateSuccess: true,
        isActionLoading: false,
      };
    case ActionTypes.CREATE_GROUP_CHAT_FAILURE:
      return {
        ...state,
        isCreateSuccess: false,
        isActionLoading: false,
      };
    case ActionTypes.RECALLED_MESSAGE_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.RECALLED_MESSAGE_FAILURE:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.CREATE_MESSAGE_CHAT_SUCCESS:
      return {
        ...state,
        isCreateMessageSuccess: true,
        isActionLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.CREATE_MESSAGE_CHAT_FAILURE:
      return {
        ...state,
        isCreateMessageSuccess: false,
        isActionLoading: false,
        isGetLoading: false,
      };
    case ActionTypes.DELETE_PRIVATE_MESSAGE_SUCCESS:
      return {
        ...state,
        isDeleteSuccess: true,
        isActionLoading: false,
        conversationChannelDetail: {},
        performAction: {},
      };
    case ActionTypes.DELETE_PRIVATE_MESSAGE_FAILURE:
      return {
        ...state,
        isDeleteSuccess: false,
        isActionLoading: false,
      };
    case ActionTypes.FETCH_MEDIA_BY_CHANNEL_ID_SUCCESS:
      return {
        ...state,
        isFetchLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        attachmentInChannel: payload,
      };
    case ActionTypes.FETCH_MEDIA_BY_CHANNEL_ID_FAILURE:
      return {
        ...state,
        isFetchLoading: false,
        isActionLoading: false,
        isGetLoading: false,
        attachmentInChannel: [],
      };

    case ActionTypes.ADD_MEMBER_TO_GROUP_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isAddMemberToGroupSuccess: true,
      };
    case ActionTypes.ADD_MEMBER_TO_GROUP_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isAddMemberToGroupSuccess: false,
      };
    case ActionTypes.REMOVE_MEMBER_TO_GROUP_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
        isRemoveMemberToGroupSuccess: true,
      };
    case ActionTypes.REMOVE_MEMBER_TO_GROUP_FAILURE:
      return {
        ...state,
        isActionLoading: false,
        isRemoveMemberToGroupSuccess: false,
      };
    case ActionTypes.LEAVE_GROUP_CHAT_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.LEAVE_GROUP_CHAT_FAILURE:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.READ_CONVERSATION_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.READ_CONVERSATION_FAILURE:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.REMOVE_ALL_FILE_IN_CHAT_SUCCESS:
      return {
        ...state,
        isActionLoading: false,
      };
    case ActionTypes.REMOVE_ALL_FILE_IN_CHAT_FAILURE:
      return {
        ...state,
        isActionLoading: false,
      };

    default:
      return state;
  }
};
