import React, { useRef } from "react";
import _ from "lodash";
import { publicIpv4 } from "public-ip";
import Fingerprint2 from "fingerprintjs2";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
} from "@chakra-ui/react";
import { TextField } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";

import dayjs from "dayjs";
import TimekeepingAction from "@/Actions/Timekeeping.action";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { createTimekeeping } = TimekeepingAction;

interface SectionProps {
  open: boolean;
  onClose(): void;
  type: string;
}

const TimekeepingDialog: React.FC<SectionProps> = ({ open, onClose, type }) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const [time, setTime] = React.useState(new Date().toLocaleTimeString());
  const [note, setNote] = React.useState<string>("");

  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.TIMEKEEPING, "isActionLoading")
  );

  const currentTime = dayjs();
  const formatTime = currentTime.format("HH:mm:ss");

  const getDaysOfWeek = (date: Date, dateFormat: string) => {
    const daysOfWeek = [];
    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)
    );

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(firstDayOfWeek);
      currentDate.setDate(firstDayOfWeek.getDate() + i);
      daysOfWeek.push(dayjs(currentDate).format(dateFormat));
    }

    return daysOfWeek;
  };

  const handleSubmit = async () => {
    const currentDate = new Date();
    const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
    const publicIp = await publicIpv4();

    const fingerprintPromise = new Promise<string>((resolve) => {
      Fingerprint2.get((components: any) => {
        const values = components.map((component: any) => {
          return component.value;
        });
        const data = Fingerprint2.x64hash128(values.join(""), 31);
        resolve(data);
      });
    });

    fingerprintPromise.then((fingerprint: string) => {
      dispatch(
        createTimekeeping(
          {
            time: formatTime,
            note: _.trim(note),
            type: type,
            ipAddress: btoa(publicIp),
            idAddress: btoa(fingerprint),
            date: dayjs(new Date()).format("YYYY-MM-DD"),
          },
          type,
          {
            page: 0,
            limit: 0,
            startDate: _.first(weekdays),
            endDate: _.last(weekdays),
          }
        )
      );
    });
  };

  React.useEffect(() => {
    if (!open) {
      setNote("");
    }
  }, [open]);

  React.useEffect(() => {
    if (isActionLoading) {
      onClose();
    }
  }, [isActionLoading]);

  React.useEffect(() => {
    setInterval(() => setTime(new Date().toLocaleTimeString()), 1000);
  }, []);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box>
          <Box>
            <TextField
              size="sm"
              value={dayjs(new Date()).format("DD/MM/YYYY")}
              label={t("label.date")}
              disabled
            />
          </Box>
          <TextField
            size="sm"
            value={time}
            label={
              type === "check-in" ? t("label.startTime") : t("label.endTime")
            }
            disabled
          />
        </Box>
        <TextareaComponent
          label={t("label.note")}
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
        />
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xs"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
          borderRadius: 0,
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "sticky",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 2px 1px -1px gray",
          }}
        >
          {type === "check-in-afternoon" && t("title.checkInAfternoon")}
          {type === "check-out" && t("title.checkOutMorning")}
          {type === "check-in" && t("title.checkInMorning")}
          {type === "check-out-afternoon" && t("title.checkOutAfternoon")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            boxShadow: "0px -1px 1px gray",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
            }}
            size="sm"
          >
            {t("button.cancel")}
          </Button>
          <Button
            size="sm"
            onClick={() => handleSubmit()}
            ml={3}
            //colorScheme="twitter"
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {type === "check-in" || type === "check-in-afternoon"
              ? t("button.checkIn")
              : t("button.checkOut")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default TimekeepingDialog;
