import _ from "lodash";
import { useEffect, useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
} from "@chakra-ui/react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Controller, useForm } from "react-hook-form";
import { DatePicker, Textarea } from "@/Components/Common";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { HolidayActions } from "@/Actions";
import {
  IFilterHoliday,
  IHolidayStructure,
} from "@/Interfaces/Holiday.interface";
import dayjs from "dayjs";
import { useTranslation } from "react-multi-lang";

const { updateHoliday } = HolidayActions;

interface SectionProps {
  open: boolean;
  onClose(): void;
  payload?: IHolidayStructure;
}

const DEFAULT_FORM_DATA = {
  day: "",
  description: "",
  year: "",
};

const UpdateHolidaysDialog: React.FC<SectionProps> = ({
  open,
  onClose,
  payload,
}) => {
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();
  const t = useTranslation();
  const isUpdateSuccess = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "isUpdateSuccess")
  );

  const pagination: IFilterHoliday = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "pagination")
  );

  const schema = yup
    .object()
    .shape({
      year: yup.string(),
      day: yup.string().required(t("message.Dateisrequired")),
      description: yup.string().trim(),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });

  const onSubmit = (data: any) => {
    if (payload?.id)
      dispatch(
        updateHoliday(
          payload?.id,
          { ...data, year: data.day.slice(0, 4) },
          pagination
        )
      );
  };

  useEffect(() => {
    if (payload) {
      setValue("day", payload.day || "");
      setValue("description", payload.description || "");
    }
  }, [payload]);

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
      onClose();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  const _renderContent = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Controller
            name="day"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value}
                onDateChange={(newDate) =>
                  field.onChange(dayjs(newDate).format("YYYY-MM-DD"))
                }
                label={t("label.date")}
                placeholder={t("label.date")}
                isError={!_.isEmpty(errors.day?.message)}
                errorMessage={errors?.day?.message}
                isRequired
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Textarea
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                label={t("label.description")}
                placeholder={t("label.description")}
                size="sm"
              />
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <Box as={"form"}>
        <AlertDialogOverlay />
        <AlertDialogContent
          sx={{
            maxH: "full",
            borderRadius: 0,
          }}
        >
          <AlertDialogHeader
            fontSize="lg"
            textAlign="center"
            fontWeight="bold"
            sx={{
              position: "sticky",
              zIndex: "sticky",
              top: 0,
              backgroundColor: "#fff",
              //  boxShadow: "0 2px 1px -1px gray",
            }}
          >
            {t("title.updateHolidays")}
          </AlertDialogHeader>
          <AlertDialogBody>{_renderContent()}</AlertDialogBody>
          <AlertDialogFooter
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              //  boxShadow: "0px -1px 1px gray",
            }}
          >
            <Button ref={cancelRef} onClick={onClose} size={"sm"}>
              {t("button.cancel")}
            </Button>
            <Button
              ml={3}
              //  colorScheme="twitter"
              onClick={handleSubmit(onSubmit)}
              size={"sm"}
              sx={{
                color: "#fff",
                background: "#5c6e6c",
                "&:hover": {
                  background: "#a6b7af",
                },
              }}
            >
              {t("button.update")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </Box>
    </AlertDialog>
  );
};

export default UpdateHolidaysDialog;
