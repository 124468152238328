import APIs from "@/APIs";
import { ActionTypes } from "@/Constants";
import { IFilterDayOffRequest } from "@/Interfaces/DayOffRequest";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";
import { IPaginate } from "@/Types/Common.types";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import _ from "lodash";

const setDayOffRequestFetchLoading = () => {
  return {
    type: ActionTypes.SET_DAY_OFF_REQUEST_FETCH_LOADING,
  };
};

const setDayOffRequestGetLoading = () => {
  return {
    type: ActionTypes.SET_DAY_OFF_REQUEST_GET_LOADING,
  };
};

const setDayOffRequestActionLoading = () => {
  return {
    type: ActionTypes.SET_DAY_OFF_REQUEST_ACTION_LOADING,
  };
};

const resetDayOffRequestDetails = () => {
  return {
    type: ActionTypes.RESET_DAY_OFF_REQUEST_DETAILS,
  };
};

const resetDayOffRequestReducer = () => {
  return {
    type: ActionTypes.RESET_REDUCER_DAY_OFF_REQUEST,
  };
};

const setMetaDayOffRequest = (payload: IPaginate) => {
  return {
    type: ActionTypes.SET_META_DAY_OFF_REQUEST,
    payload,
  };
};

const setPaginationDayOffRequest = (payload: any) => {
  return {
    type: ActionTypes.SET_PAGINATION_DAY_OFF_REQUEST,
    payload,
  };
};

const createDayOffRequestSuccess = () => {
  return {
    type: ActionTypes.CREATE_DAY_OFF_REQUEST_SUCCESS,
  };
};

const createDayOffRequestFail = () => {
  return {
    type: ActionTypes.CREATE_DAY_OFF_REQUEST_FAILURE,
  };
};

const createDayOffRequest = (payload: FormData) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestActionLoading());
    await APIs.createDayOffRequest(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          const message = response?.data?.message;
          await dispatch(createDayOffRequestFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(createDayOffRequestSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(
            fetchDayOffRequest({
              page: 1,
              limit: 10,
            })
          );
        }
      })
      .catch(async (error) => {
        await dispatch(createDayOffRequestFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const fetchDayOffRequestSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_DAY_OFF_REQUEST_SUCCESS,
    payload,
  };
};

const fetchDayOffRequestFail = () => {
  return {
    type: ActionTypes.FETCH_DAY_OFF_REQUEST_FAILURE,
  };
};

const fetchDayOffRequest = (payload: IFilterDayOffRequest) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestFetchLoading());
    await APIs.fetchDayOffRequest(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(fetchDayOffRequestFail());
        else {
          const items = _.get(result, "items");
          const meta: any = _.get(result, "meta");
          dispatch(fetchDayOffRequestSuccess(items));
          dispatch(setMetaDayOffRequest(meta));
          dispatch(setPaginationDayOffRequest(payload));
        }
      })
      .catch(async () => {
        await dispatch(fetchDayOffRequestFail());
      });
  };
};

const getByIdDayOffRequestSuccess = (payload: any) => {
  return {
    type: ActionTypes.GET_BY_ID_DAY_OFF_REQUEST_SUCCESS,
    payload,
  };
};

const getByIdDayOffRequestFail = () => {
  return {
    type: ActionTypes.GET_BY_ID_DAY_OFF_REQUEST_FAILURE,
  };
};

const getByIdDayOffRequest = (id: string) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestGetLoading());
    await APIs.getDayOffRequestById(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) await dispatch(getByIdDayOffRequestFail());
        else {
          dispatch(getByIdDayOffRequestSuccess(result));
        }
      })
      .catch(async () => {
        await dispatch(getByIdDayOffRequestFail());
      });
  };
};

const updateDayOffRequestSuccess = (payload: any) => {
  return {
    type: ActionTypes.UPDATE_DAY_OFF_REQUEST_SUCCESS,
    payload,
  };
};

const updateDayOffRequestFail = () => {
  return {
    type: ActionTypes.UPDATE_DAY_OFF_REQUEST_FAILURE,
  };
};

const updateDayOffRequest = (id: string, payload: any, pagination: any) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestActionLoading());
    await APIs.updateDayOffReques(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(updateDayOffRequestFail());
          await Toast({
            title: message,
            description: message,
            status: "error",
          });
        } else {
          dispatch(updateDayOffRequestSuccess(result));
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchDayOffRequest(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(updateDayOffRequestFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const changeStatusDayOffRequestSuccess = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_DAY_OFF_REQUEST_SUCCESS,
  };
};

const changeStatusDayOffRequestFail = () => {
  return {
    type: ActionTypes.CHANGE_STATUS_DAY_OFF_REQUEST_FAILURE,
  };
};

const changeStatusDayOffRequest = (
  id: string,
  payload: any,
  pagination?: any
) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestFetchLoading());
    await APIs.ChangeStatusDayOffRequest(id, payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(changeStatusDayOffRequestFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          const language = Utils.getSavedLanguage();
          dispatch(changeStatusDayOffRequestSuccess());
          if (payload?.status === "rejected")
            Toast({
              title:
                language === "en"
                  ? "Reject day off request successfully"
                  : "Từ chối yêu cầu ngày nghỉ thành công",
              status: "success",
            });
          if (payload?.status === "approved")
            Toast({
              title:
                language === "en"
                  ? "Approve day off request successfully"
                  : "Chấp nhận yêu cầu ngày nghỉ thành công",
              status: "success",
            });
          if (payload?.status === "pending")
            Toast({
              title:
                language === "en"
                  ? "Pending day off request successfully"
                  : "Chuyển trạng thái chờ yêu cầu ngày nghỉ thành công",
              status: "success",
            });
          await dispatch(fetchDayOffRequest(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(changeStatusDayOffRequestFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const deleteDayOffRequestSuccess = () => {
  return {
    type: ActionTypes.DELETE_DAY_OFF_REQUEST_SUCCESS,
  };
};

const deleteDayOffRequestFail = () => {
  return {
    type: ActionTypes.DELETE_DAY_OFF_REQUEST_FAILURE,
  };
};

const deleteDayOffRequest = (id: string, pagination?: any) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestFetchLoading());
    await APIs.deleteDayoffRequest(id)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(deleteDayOffRequestFail());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(deleteDayOffRequestSuccess());
          Toast({
            title: message,
            status: "success",
          });
          await dispatch(fetchDayOffRequest(pagination));
        }
      })
      .catch(async (error) => {
        await dispatch(deleteDayOffRequestFail());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const setMetaAggregate = (payload: any) => {
  return {
    type: ActionTypes.SET_AGGREGATE_DAY_OFF_META,
    payload,
  };
};

const setPaginationAggregate = (payload: IFilterAggregate) => {
  return {
    type: ActionTypes.SET_AGGREGATE_DAY_OFF_PAGINATION,
    payload,
  };
};

const fetchAggregateDayOffSuccess = (payload: any) => {
  return {
    type: ActionTypes.FETCH_AGGREGATE_DAY_OFF_SUCCESS,
    payload,
  };
};

const fetchAggregateFail = () => {
  return {
    type: ActionTypes.FETCH_AGGREGATE_DAY_OFF_FAILURE,
  };
};

const fetchAggregateDayOffRequest = (payload: IFilterAggregate) => {
  return async (dispatch: any) => {
    dispatch(setDayOffRequestFetchLoading());
    await APIs.fetchAggregateDayOffRequest(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          //  const message = response?.data?.message;
          await dispatch(fetchAggregateFail());
        } else {
          const resolveResult: { items: any } = result as {
            items: any;
          };
          await dispatch(setMetaAggregate(_.get(result, "meta")));
          await dispatch(setPaginationAggregate(payload));
          await dispatch(fetchAggregateDayOffSuccess(resolveResult.items));
        }
      })
      .catch(async () => {
        await dispatch(fetchAggregateFail());
      });
  };
};

export default {
  createDayOffRequest,
  fetchDayOffRequest,
  getByIdDayOffRequest,
  updateDayOffRequest,
  changeStatusDayOffRequest,
  resetDayOffRequestReducer,
  deleteDayOffRequest,
  fetchAggregateDayOffRequest,
  resetDayOffRequestDetails,
};
