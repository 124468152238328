import { useRef, useEffect, useState, useMemo, memo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

import { useSelector } from "react-redux";
import { DatesSetArg, EventContentArg, EventDropArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import {
  Box,
  Heading,
  Text,
  Checkbox,
  Stack,
  Icon,
  Tooltip,
} from "@chakra-ui/react";

import { CheckCircleIcon } from "@heroicons/react/24/outline";

import { RootState, useTypedDispatch } from "@/Store";
import { TextComponent } from "@/Components/Common";
import Utils from "@/Utils";
import { ENUMS } from "@/Constants";
import { LogtimeActions, BoardActions, ProjectActions } from "@/Actions";
import CommonColors from "@/Themes/CommonColors";

import { IProjectStructure } from "@/Interfaces/Project.interface";
import { calendarStyles, sharedStyles } from "./Calendar.styles";
import { useWindowWidth } from "@/Helpers";
import { IHolidayStructure } from "@/Interfaces/Holiday.interface";
import { useTranslation } from "react-multi-lang";

const { performAction, updateLogtimeInTimeline } = LogtimeActions;
const { updateProject, saveCurrentDateInScroller } = ProjectActions;
const {
  updateColumnInBoard,
  performAction: boardPerformActions,
  getBoardById,
} = BoardActions;

interface ISectionProps {
  activeView: "week" | "month";
  visibleRange: {
    start: string;
    end: string;
  };
  isShowFullScreen?: boolean;
  minSlotWidthDate: number;
  onContainerWidthChange(width: number): void;
  isReset: boolean;
  onReset(): void;
}

const Calendar: React.FC<ISectionProps> = ({
  visibleRange,
  isShowFullScreen,
  minSlotWidthDate,
  onContainerWidthChange,
  isReset,
  onReset,
}) => {
  const [oldVisibleRange, setOldVisibleRange] = useState<{
    start: string;
    end: string;
  }>({ start: "", end: "" });

  const t = useTranslation();
  const language = Utils.getSavedLanguage();
  const dispatch = useTypedDispatch();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 767;

  const boardDetails: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "details")
  );
  const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);

  const timelineInBoard: any = useSelector((state: RootState) =>
    _.get(state.BOARD, "timelineInBoard")
  );

  const projectDetails: IProjectStructure = useSelector((state: RootState) =>
    _.get(state.PROJECT, "details")
  );

  const currentDateInScroller: string = useSelector((state: RootState) =>
    _.get(state.PROJECT, "currentDateInScroller")
  );

  const userData = Utils.getSavedUserData();
  const userRoles = useMemo(
    () => _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    [userData]
  );

  const [mouseIsEnter, setMouseIsEnter] = useState(false);
  const mouseEnterRef = useRef(false);
  mouseEnterRef.current = mouseIsEnter;

  const canUpdateProcess = Utils.hasPermission(
    userRoles,
    "Board",
    "editProcess"
  );

  const canUpdateProject = Utils.hasPermission(
    userRoles,
    "Project",
    "editProject"
  );

  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const holidaysList: IHolidayStructure[] = useSelector((state: RootState) =>
    _.get(state.HOLIDAY, "holidaysList")
  );
  const isSidebarExpanded = useSelector((state: RootState) =>
    _.get(state.SOCKET, "isSidebarExpanded")
  );

  const workingTimeOffice = useMemo(() => {
    const firstItem = workingTimeList[0];
    return {
      morningStart: Number(firstItem?.morningStart || 8),
      morningEnd: Number(firstItem?.morningEnd || 12),
      afternoonStart: Number(firstItem?.afternoonStart || 13.5),
      afternoonEnd: Number(firstItem?.afternoonEnd || 17.5),
    };
  }, [workingTimeList]);

  const timeline = useMemo(() => {
    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);

    const dateRanges = Utils.generateDateRanges(minStartDate, maxEndDate);

    return dateRanges;
  }, [boardDetails]);

  const addedMembers = new Set();
  const addedProcess = new Set();
  const addedMemberProcess = new Set();

  const [events, setEvents] = useState<any[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [slotLabelDisplayType, setSlotLabelDisplayType] = useState<string>("");
  const [resouceAreaWidth, setResourceAreaWidth] = useState<number>(0);
  const [isResizeOrDragEvent, setIsResizeOrDragEvent] = useState(false);

  const calendarRef = useRef<FullCalendar | any>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const lastResourceId = useMemo(() => {
    const maxOrderResource = resources.reduce((maxOrderItem, currentItem) => {
      return currentItem.order > maxOrderItem.order
        ? currentItem
        : maxOrderItem;
    }, resources[0]);
    const currentProcessId = maxOrderResource?.id;
    const groupProcess = _.filter(resources, (resource) => {
      if (resource?.extendedProps?.type === "task-user") {
        const findTaskUsers = _.filter(resources, (res) =>
          _.includes(res?.id, currentProcessId)
        );

        return (
          _.last(findTaskUsers)?.id ===
          `${resource?.processId}=${resource?.userId}`
        );
      }
      return false;
    });
    return !_.isEmpty(groupProcess)
      ? _.last(groupProcess)?.id
      : maxOrderResource?.id;
  }, [resources]);

  const slotLabelFormats = useMemo(() => {
    const formats: any[] = [];
    formats.push({
      weekday:
        slotLabelDisplayType === "full" || minSlotWidthDate >= 30
          ? "short"
          : "narrow",
    });
    formats.push({ day: "2-digit" });
    return formats;
  }, [slotLabelDisplayType, minSlotWidthDate]);

  const [isTooltipOpen, setIsTooltipOpen] = useState("");

  const handleLongPress = (id: any) => (e: any) => {
    e.stopPropagation();
    setIsTooltipOpen(id);
  };

  const handleTouchEnd = async () => {
    await setIsTooltipOpen("");
  };

  useEffect(() => {
    const closeTooltip = async () => {
      await setIsTooltipOpen("");
    };
    const calendarContainer = document.querySelector(".container");
    const calendarCalendar = document.querySelector(".fc");
    document.addEventListener("click", closeTooltip);
    if (calendarContainer) {
      calendarContainer.addEventListener("scroll", closeTooltip);
    }
    if (calendarCalendar) {
      calendarCalendar.addEventListener("scroll", closeTooltip);
    }
    return () => {
      document.removeEventListener("click", closeTooltip);
      if (calendarContainer) {
        calendarContainer.removeEventListener("scroll", closeTooltip);
      }
      if (calendarCalendar) {
        calendarCalendar.addEventListener("scroll", closeTooltip);
      }
    };
  }, []);

  useEffect(() => {
    if (isResizeOrDragEvent && isTooltipOpen) setIsTooltipOpen("");
  }, [isResizeOrDragEvent, isTooltipOpen]);

  useEffect(() => {
    if (containerRef?.current) {
      const type = calculateDisplayType();
      setSlotLabelDisplayType(type);
    }
  }, [resouceAreaWidth, oldVisibleRange]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    _.delay(() => {
      calendarApi?.refetchEvents();
      calendarApi?.refetchResources();
    }, 500);
  }, [isShowFullScreen, isSidebarExpanded, minSlotWidthDate]);

  useEffect(() => {
    let eventData: any[] = [
      {
        id: projectDetails?.id,
        title: "OVERALL",
        start: projectDetails.startDate
          ? dayjs(dayjs(projectDetails.startDate)).startOf("day").toDate()
          : "",
        end: projectDetails.endDate
          ? dayjs(dayjs(projectDetails.endDate)).endOf("day").toDate()
          : "",
        resourceId: "overall",
        backgroundColor: CommonColors.lightOrange,
        extendedProps: {
          resourceId: "overall",
          type: "overall",
        },
        editable: canUpdateProject,
        droppable: false,
        resourceEditable: false,
        eventStartEditable: canUpdateProject,
      },
    ];
    let resourceData: any[] = [{ id: "overall", title: "OVERALL", order: 0 }];
    const colors = [
      "#5C6E6C", // balsamGreen
      "#A6B7AA", // aquatone
      // "#D2A96A", // artermis
      "#D39D87", // dustyCoral
      // "#BB7154", // warmCopper
    ];

    const { minStartDate, maxEndDate } = Utils.findDateRange(boardDetails);

    const userColors: any = {};
    const processColors: any = {};
    const projectMembers = projectDetails?.projectMember;

    _.forEach(projectMembers, (member, index) => {
      const color = colors[index % colors.length];
      userColors[member?.user?.id as keyof typeof userColors] = color;
    });

    _.forEach(boardDetails?.task, (task, taskIndex: number) => {
      const backgroundColor = colors[taskIndex % colors.length];
      processColors[task?.id as keyof typeof processColors] = backgroundColor;
      let priority = 0;
      // const resourceTimelineStage = {
      //   id: `${task.id}-timeline`,
      //   title: task?.title,
      //   isShow: true,
      //   order: task?.order,
      // };

      if (!addedProcess.has(task?.id)) {
        addedProcess.add(task?.id);
      }

      resourceData.push({
        id: task?.id,
        title: task?.title,
        order: task?.order,
        isShow: true,
        processId: task?.id,
        type: "process",
        initiallyExpanded: true,
        totalLogs: _.size(
          _.filter(
            task?.logTime,
            (log) => log?.log?.timeType !== ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
          )
        ),
      });
      // resourceData.push(resourceTimelineStage);

      const eventTimelineStage = {
        id: task.id,
        title: `OVERALL - ${task?.title}`,
        start: dayjs(dayjs(task?.timeLineStart)).startOf("day").toDate(),
        end: dayjs(dayjs(task?.timeLineEnd)).endOf("day").toDate(),
        resourceId: task.id,
        extendedProps: {
          resourceId: task.id,
          type: "task",
          process: task,
        },
        backgroundColor: CommonColors.deepOrange,
        editable: canUpdateProcess,
        droppable: false,
        resourceEditable: false,
        priority,
        display: "block",
      };

      eventData.push(eventTimelineStage);

      const countLogTime = countLogTimeForEachTask(
        task,
        new Date(minStartDate),
        new Date(maxEndDate)
      );
      _.forEach(task?.logTime, (log) => {
        const logType = log?.timeType;

        if (logType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME) {
          if (!addedMembers.has(log.user?.id)) {
            addedMembers.add(log.user?.id);
          }
          const startDate = new Date(
            `${log?.workTimeStartDate}T${log?.workTimeStartTime}`
          );
          const endDate = new Date(
            `${log?.workTimeEndDate}T${log?.workTimeEndTime}`
          );

          // const isOverlap = isTimeRangeOverlapping(
          //   new Date(log?.workTimeStartDate),
          //   new Date(log?.workTimeEndDate),
          //   new Date(oldVisibleRange.start),
          //   new Date(oldVisibleRange.end)
          // );
          // &&
          //   isOverlap
          if (!addedMemberProcess.has(`${task?.id}=${log.user?.id}`)) {
            const totalLogByUserId =
              countLogTime[`${task?.id}=${log.user?.id}`];
            resourceData.push({
              id: `${task?.id}=${log.user?.id}`,
              title: log.user?.userData?.fullName,
              isShow: false,
              parentId: task.id,
              projectId: projectDetails?.id,
              userId: log.user?.id,
              processId: task?.id,
              resourceEditable: false,
              extendedProps: {
                type: "task-user",
                totalLogByUserId,
              },
            });
            addedMemberProcess.add(`${task?.id}=${log.user?.id}`);
          }
          const currentUserRoles = _.map(
            userData?.userRole,
            (userRole) => userRole?.role?.roleCode
          );

          const targetUserRoles = _.map(
            log?.user?.userRole,
            (userRole) => userRole?.role?.roleCode
          );

          const canUpdateLogStatus = Utils.handleCheckLogTimePermissions(
            currentUserRoles,
            targetUserRoles,
            projectDetails?.type as any
          );

          let event = {
            id: log.id,
            title: log.user?.userData?.fullName,
            start: startDate,
            end: endDate,
            resourceId: `${task?.id}=${log.user?.id}`,
            extendedProps: {
              ...log,
              resourceId: log.user?.id,
              backgroundColor,
              borderColor: log?.isDone ? CommonColors.burntSienna : "inherit",
              type: "log",
              task,
            },
            backgroundColor: userColors[log.user?.id] || backgroundColor,
            borderColor: log?.isDone ? CommonColors.burntSienna : "inherit",
            editable: canUpdateLogStatus || userData?.id === log?.user?.id,
            droppable: canUpdateLogStatus || userData?.id === log?.user?.id,
            resourceEditable:
              canUpdateLogStatus || userData?.id === log?.user?.id,
            eventStartEditable:
              canUpdateLogStatus || userData?.id === log?.user?.id,
            priority: priority++,
            isShowCheck: canUpdateLogStatus || userData?.id === log?.user?.id,
          };
          eventData.push(event);
        }
      });
    });
    // Sort resourceData by the order field before setting the value for resources
    resourceData = _.sortBy(resourceData, "order");
    setEvents(eventData);
    setResources(resourceData);
  }, [timelineInBoard, projectDetails, boardDetails]);

  useEffect(() => {
    const calendarApi = calendarRef?.current?.getApi();
    const { minStartDate } = Utils.findDateRange(boardDetails);
    if (calendarApi) {
      requestAnimationFrame(() => {
        calendarApi.gotoDate(minStartDate);
      });
    }
  }, [boardDetails]);

  useEffect(() => {
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) -
      (resouceAreaWidth || 150) +
      (isMobile ? 5 : 0);
    if (containerWidth > 0) onContainerWidthChange(containerWidth);
  }, [resouceAreaWidth]);

  useEffect(() => {
    scrollToTime();
  }, [oldVisibleRange, timeline]);

  useEffect(() => {
    const calendarEl = calendarRef?.current?.calendar?.el;
    const scroller: any = calendarEl.querySelectorAll("tfoot .fc-scroller")[1];
    const handleCalendarScroll = _.debounce(() => {
      const { scrollLeft } = scroller;
      const currentDay = Math.round(scrollLeft / minSlotWidthDate);
      if (timeline[currentDay]) {
        dispatch(saveCurrentDateInScroller(timeline[currentDay]));
      }
    }, 300);
    if (scroller) {
      scroller.addEventListener("scroll", handleCalendarScroll);
    }

    return () => {
      scroller?.removeEventListener("scroll", handleCalendarScroll);
    };
  }, [minSlotWidthDate, timeline]);

  useEffect(() => {
    const isSameVisibleRange =
      JSON.stringify(visibleRange) === JSON.stringify(oldVisibleRange);
    if (!isSameVisibleRange) {
      setOldVisibleRange(visibleRange);
    }
  }, [visibleRange]);

  useEffect(() => {
    if (isReset) {
      scrollToTime();
      onReset();
    }
  }, [isReset]);

  const scrollToTime = () => {
    const calendarApi = calendarRef?.current?.getApi();
    if (!_.isEmpty(oldVisibleRange) && calendarApi) {
      const midIndex = _.findIndex(
        timeline,
        (date) => date === currentDateInScroller
      );

      if (midIndex !== -1) {
        _.delay(() => {
          calendarApi.scrollToTime({
            days: midIndex,
          });
        }, 100);
      }
    }
  };

  const calculateDisplayType = () => {
    const numberOfDays = dayjs(maxEndDate).diff(dayjs(minStartDate), "day") + 1;
    const containerWidth =
      (containerRef?.current?.offsetWidth || 0) - (resouceAreaWidth || 150);
    return Utils.calculateDisplayType(numberOfDays, containerWidth);
  };

  const isLastTaskUser = (resource: any, resources: any[]) => {
    const currentProcessId = _.split(resource?.id, "=")[0];
    if (resource?.extendedProps?.type === "task-user") {
      const findTaskUsers = _.filter(resources, (res) =>
        _.includes(res?.id, currentProcessId)
      );

      return (
        _.last(findTaskUsers)?.id ===
        `${resource?.extendedProps?.processId}=${resource?.extendedProps?.userId}`
      );
    }
    return false;
  };

  const isDateInRange = (date: Date, startDate: Date, endDate: Date) => {
    return dayjs(date).isBetween(dayjs(startDate), dayjs(endDate), null, "[]");
  };

  const isTimeRangeOverlapping = (
    start: Date,
    end: Date,
    startDate: Date,
    endDate: Date
  ) => {
    const isStartInRange = isDateInRange(start, startDate, endDate);
    const isEndInRange = isDateInRange(end, startDate, endDate);
    const isStartDateInRange = isDateInRange(startDate, start, end);
    const isEndDateInRange = isDateInRange(endDate, start, end);
    return (
      isStartInRange || isEndInRange || isStartDateInRange || isEndDateInRange
    );
  };

  const countLogTimeForEachTask = (
    task: any,
    startDate: Date,
    endDate: Date
  ) => {
    const countLogTimeByDate: any = {};
    const countLogTime: any = {};

    _.forEach(task.logTime, (log) => {
      const taskId = task?.id;
      const workTimeStartDate = new Date(log?.workTimeStartDate);
      const workTimeEndDate = new Date(log?.workTimeEndDate);

      const isOverlap = isTimeRangeOverlapping(
        new Date(startDate),
        new Date(endDate),
        new Date(workTimeStartDate),
        new Date(workTimeEndDate)
      );

      const dateRanges = Utils.generateDateRanges(
        log?.workTimeStartDate,
        log?.workTimeEndDate,
        "YYYY-MM-DD"
      );

      const userId = log?.user ? log?.user?.id : null;
      if (taskId && userId && isOverlap) {
        countLogTimeByDate[`${taskId}=${userId}`] = dateRanges;
        countLogTime[`${taskId}=${userId}`] = 1;
      }
    });
    return countLogTime;
  };

  const calculateDays = (startDate: string, endDate: string) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return end.diff(start, "day");
  };

  const recalculateEstimate = (
    originalStartDate: string,
    originalEndDate: string,
    newStartDate: string,
    newEndDate: string,
    originalEstimate: string
  ) => {
    const originalDays = calculateDays(originalStartDate, originalEndDate);
    const newDays = calculateDays(newStartDate, newEndDate);
    const daysDiff = newDays - originalDays;

    const estimateArr = originalEstimate.split(", ");
    const originalEstimateDays = parseInt(estimateArr[0]);

    let newEstimateDays = originalEstimateDays + daysDiff;
    let newEstimateHours = estimateArr[1]?.trim() || "0 hours";

    if (newEstimateDays < 0) newEstimateDays = 0;

    return `${newEstimateDays} days, ${newEstimateHours}`;
  };

  const handleDatesSet = (dateInfo: DatesSetArg) => {
    const daysInMonth = [];
    const startDate = dayjs(dateInfo.start);
    const endDate = dayjs(dateInfo.end);
    for (
      let date = startDate;
      date.isBefore(endDate);
      date = date.add(1, "day")
    )
      daysInMonth.push(date.format("YYYY-MM-DD"));
  };

  const handleResize = (resizeInfo: any) => {
    const { event } = resizeInfo;
    const start = event?.startStr;
    const end = event?.endStr;
    const log = event?.extendedProps;
    //handle resize log here
    if (log?.type === "overall") {
      const startDate = dayjs(start).format("YYYY-MM-DD");
      const endDate = dayjs(end).format("YYYY-MM-DD");
      const recalculatedEstimate = recalculateEstimate(
        projectDetails?.startDate || "",
        projectDetails?.endDate || "",
        startDate,
        endDate,
        projectDetails?.estimateWorkingTime || ""
      );
      dispatch(
        updateProject(
          projectDetails?.id,
          { startDate, endDate, estimateWorkingTime: recalculatedEstimate },
          t("message.updateTimelineProjectSuccess")
        )
      );
    }

    if (
      log?.type === "log" &&
      log?.timeType === ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
    ) {
      const workingTime = Utils.calculateHourDifference(
        dayjs(start).toDate(),
        dayjs(end).toDate(),
        workingTimeOffice,
        holidaysList
      );

      const workTimeStartDate = dayjs(start).format("YYYY-MM-DD");
      const workTimeEndDate = dayjs(end).format("YYYY-MM-DD");
      // handle change hour here
      // const workTimeStartTime = dayjs(start).format("HH:mm:ss");
      // const workTimeEndTime = dayjs(end).format("HH:mm:ss");

      dispatch(
        updateLogtimeInTimeline(
          log?.id,
          {
            workTimeStartDate,
            workTimeEndDate,
            // workTimeStartTime,
            // workTimeEndTime,
            workingTime,
          },
          boardDetails?.id
        )
      );
    }
    if (log?.type === "task") {
      dispatch(
        updateColumnInBoard(
          event?.id,
          {
            timeLineStart: dayjs(start).toISOString(),
            timeLineEnd: dayjs(end).toISOString(),
          },
          () => dispatch(getBoardById(boardDetails?.id))
        )
      );
    }
  };

  const handleDragEnd = (info: EventDropArg) => {
    const log = info?.event?.extendedProps;
    const start = dayjs(info?.event?.start).format("YYYY-MM-DD");
    const end = dayjs(info?.event?.end).format("YYYY-MM-DD");

    if (info?.event?.extendedProps?.type === "overall") {
      const startDate = dayjs(start).format("YYYY-MM-DD");
      const endDate = dayjs(end).format("YYYY-MM-DD");
      const recalculatedEstimate = recalculateEstimate(
        projectDetails?.startDate || "",
        projectDetails?.endDate || "",
        startDate,
        endDate,
        projectDetails?.estimateWorkingTime || ""
      );
      dispatch(
        updateProject(
          projectDetails?.id,
          { startDate, endDate, estimateWorkingTime: recalculatedEstimate },
          t("message.updateTimelineProjectSuccess")
        )
      );
    }

    if (
      info?.event?.extendedProps?.type === "log" &&
      info?.event?.extendedProps?.timeType ===
        ENUMS.LOG_TIME_WORK_TYPE.WORK_TIME
    ) {
      const newData = {};
      const processId = info?.newResource?.extendedProps?.processId;
      if (processId)
        _.assign(newData, {
          task: processId,
        });

      _.assign(newData, {
        workTimeStartDate: start,
        workTimeEndDate: end,
      });
      dispatch(updateLogtimeInTimeline(log?.id, newData, boardDetails?.id));
    }
    if (info?.event?.extendedProps?.type === "task") {
      dispatch(
        updateColumnInBoard(
          info?.event?.id,
          {
            timeLineStart: dayjs(start).format("YYYY-MM-DD"),
            timeLineEnd: dayjs(end).format("YYYY-MM-DD"),
          },
          () => {}
        )
      );
    }
  };

  const handleDateClick = (arg: DateClickArg) => {
    const isSunday = dayjs(arg?.date).day() === 0;
    const processId = arg?.resource?.extendedProps?.processId;
    const userId = arg?.resource?.extendedProps?.userId;

    if (!isTooltipOpen)
      dispatch(
        performAction(
          boardDetails?.project?.type === ENUMS.PROJECT_TYPE.INTERIOR
            ? "logInternal"
            : "logExternal",
          "",
          {
            date: arg.dateStr,
            userId,
            projectId: boardDetails?.project?.id,
            processId,
            isSunday,
          }
        )
      );
  };

  const handleChangeLogStatus = (logId: string, status: boolean) => {
    dispatch(
      updateLogtimeInTimeline(logId, { isDone: status }, boardDetails?.id)
    );
  };

  const _renderElementTooltip = (event: any, type: "task" | "process") => {
    if (type === "task") {
      const task = event?.extendedProps;
      return (
        <Stack>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.process")}:
            </Text>
            <Text fontSize="xs">{task?.task?.title || "--"}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.startTime")}:{" "}
            </Text>
            <Text fontSize="xs">{`${dayjs(task?.workTimeStartDate).format(
              "DD/MM/YYYY"
            )} ${task?.workTimeStartTime}`}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.endTime")}:{" "}
            </Text>
            <Text fontSize="xs">{`${dayjs(task?.workTimeEndDate).format(
              "DD/MM/YYYY"
            )} ${task?.workTimeEndTime}`}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.workedHours")} :
            </Text>
            <Text fontSize="xs">{`${task?.workingTime || 0}H`}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.assignee")} :
            </Text>
            <Text fontSize="xs">{task?.user?.userData?.fullName || "--"}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.description")}:
            </Text>
            <Text
              fontSize="xs"
              sx={{
                wordWrap: "break-word",
                maxWidth: 160,
                "& a": {
                  color: "rgba(22, 117, 224,1)",
                  "&:hover": {
                    color: "rgba(22, 117, 224,0.8)",
                  },
                },
              }}
              dangerouslySetInnerHTML={{
                __html: event?.extendedProps?.description
                  ? event?.extendedProps?.description
                  : "--",
              }}
            />
          </Box>
          <Box
            sx={{
              color: "#fff",
              width: "100%",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.note")}:
            </Text>
            <Text
              fontSize="xs"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {event?.extendedProps?.note || "--"}
            </Text>
          </Box>
          {task?.isDone && (
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Icon>
                <CheckCircleIcon />
              </Icon>
              <Text fontSize="xs">{t("label.taskCompleted")}</Text>
            </Box>
          )}
        </Stack>
      );
    }
    if (type === "process") {
      const process = event?.extendedProps?.process;
      return (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.process")}:
            </Text>
            <Text fontSize="xs">{process?.title || "--"}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.timeline")}:
            </Text>
            <Text fontSize="xs">{`${dayjs(process?.timeLineStart).format(
              "DD/MM/YYYY HH:mm"
            )} - ${dayjs(process?.timeLineEnd).format(
              "DD/MM/YYYY HH:mm"
            )}`}</Text>
          </Box>
          <Box
            sx={{
              color: "#fff",
            }}
          >
            <Text fontSize="xs" sx={{ fontWeight: 600 }}>
              {t("label.description")} :
            </Text>
            <Box
              sx={{
                fontWeight: 400,
                wordBreak: "break-word",
                "& a": {
                  color: "rgba(22, 117, 224,1)",
                  "&:hover": {
                    color: "rgba(22, 117, 224,0.8)",
                  },
                },
              }}
              dangerouslySetInnerHTML={{
                __html: process?.description ? process?.description : "--",
              }}
            />
          </Box>
        </Stack>
      );
    }

    return <></>;
  };

  const convertTime = (inputString: any) => {
    const updatedString =
      inputString &&
      inputString.replace(/days/g, "ngày").replace(/hours/g, "giờ");

    return updatedString;
  };

  const _renderEventContent = (eventInfo: EventContentArg) => {
    const { event } = eventInfo;

    const log = event?.extendedProps;
    const isVirtualEvent = _.includes(event?.id, "virtual_event");
    const isTimelineProject = log?.resourceId === "overall";
    const process = log?.process;

    if (isTimelineProject) {
      return (
        <TextComponent
          sx={{
            color: "white",
            fontSize: isMobile ? 12 : "18px",
            p: isMobile ? 0 : 1.5,
          }}
          maxW="100%"
          content={
            language === "en"
              ? `${projectDetails?.name} | ${projectDetails?.estimateWorkingTime}`
              : `${projectDetails?.name} | ${
                  projectDetails?.estimateWorkingTime &&
                  convertTime(projectDetails?.estimateWorkingTime)
                }`
          }
        />
      );
    }

    if (!_.isEmpty(process)) {
      const calcHours = Utils.calculateHourDifference(
        dayjs(process?.timeLineStart).toDate(),
        dayjs(process?.timeLineEnd).toDate(),
        workingTimeOffice,
        holidaysList
      );

      // const calcDays =
      //   dayjs(process?.timeLineStart).format("DD/MM/YYYY") ===
      //   dayjs(process?.timeLineEnd).format("DD/MM/YYYY")
      //     ? 1
      //     : dayjs(dayjs(process?.timeLineEnd).toDate()).diff(
      //         dayjs(process?.timeLineStart).toDate(),
      //         "day"
      //       ) + 1;

      return (
        <Tooltip
          hasArrow
          placement="auto"
          label={_renderElementTooltip(event, "process")}
          {...(isMobile && {
            isOpen: isTooltipOpen === event?.id,
          })}
          isDisabled={isResizeOrDragEvent}
          portalProps={{ containerRef: containerRef }}
          closeOnPointerDown
        >
          <Box
            {...(isMobile && {
              onTouchStart: handleLongPress(log?.id),
              onMouseEnter: handleLongPress(log?.id),
              onMouseLeave: handleTouchEnd,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setIsTooltipOpen("");
              dispatch(boardPerformActions("edit", event.id));
            }}
            sx={{
              width: "100%",
            }}
          >
            <TextComponent
              maxW="100%"
              sx={{
                color: "white",
                fontSize: isMobile ? 10 : "16px",
                p: isMobile ? 0 : 1,
                textTransform: "uppercase",
                verticalAlign: "middle",
              }}
              showTooltip={false}
              content={`${process?.title} | ${calcHours}H`}
            />
          </Box>
        </Tooltip>
      );
    }

    const firstTextContent = Utils.getFirstTextContent(log?.description);

    const isShowCheck = log?.isShowCheck;

    return (
      <Tooltip
        hasArrow
        label={isVirtualEvent ? "" : _renderElementTooltip(event, "task")}
        {...(isMobile && {
          isOpen: isTooltipOpen === log?.id,
        })}
        closeOnPointerDown
        placement="auto"
        isDisabled={isResizeOrDragEvent}
        portalProps={{ containerRef: containerRef }}
      >
        <Box
          {...(isMobile && {
            onTouchStart: handleLongPress(log?.id),
            onMouseEnter: handleLongPress(log?.id),
            onMouseLeave: handleTouchEnd,
          })}
          sx={{
            overflow: "hidden",
            opacity: isVirtualEvent ? 0 : 1,
            position: "relative",
            height: "100%",
            width: "100%",
            cursor: "pointer",
            // left: `50%`,
            // width: `calc(100% - ${leftPercentage}%)`,
            // border: `2px solid ${
            //   log?.isDone ? CommonColors.burntSienna : " transparent"
            // }`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsTooltipOpen("");
            isShowCheck && dispatch(performAction("editLog", log?.id));
          }}
        >
          <Heading
            sx={{
              color: "white",
              fontSize: isMobile ? 10 : "12px",
              p: isMobile || slotLabelDisplayType !== "hide" ? 0 : 0.5,
              boxShadow: "10px 10px",
              display: "flex",
              width: "100%",
              position: "relative",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              WebkitLineClamp: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "nowrap",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: `calc(100% - ${log?.isShowCheck ? "25px" : "0"})`,
              }}
            >
              <TextComponent
                fontSize="sm"
                maxW="100%"
                content={`${log?.user?.userData?.fullName} | ${
                  firstTextContent || "--"
                } ${
                  log?.timeType === ENUMS.LOG_TIME_WORK_TYPE.OVER_TIME
                    ? "(OT)"
                    : ""
                } | ${log?.workingTime}H`}
                showTooltip={false}
              />
            </Box>
            {isShowCheck && (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{
                  ml: "auto",
                  position: "absolute",
                  right: 1,
                  top: "50%",
                  zIndex: 2,
                  transform: "translateY(-50%)",
                  "label.chakra-checkbox": {
                    borderColor: "#38A169",
                  },
                }}
              >
                {
                  <Checkbox
                    isChecked={log?.isDone}
                    onChange={(e) => {
                      e.stopPropagation();
                      setIsTooltipOpen("");
                      handleChangeLogStatus(log?.id, e.target.checked);
                    }}
                    colorScheme="green"
                    isReadOnly={
                      !log?.isShowCheck && log?.user?.id !== userData?.id
                    }
                  />
                }
              </Box>
            )}
          </Heading>
        </Box>
      </Tooltip>
    );
  };

  const _renderResourceContent = (resourceInfo: any) => {
    const { _resource } = resourceInfo.resource;

    const isHiddenTitle =
      _resource?.id === "overall" || !_resource?.extendedProps?.isShow;
    let isLastItem = false;
    const currentProcessId = _.split(_resource?.id, "=")[0];
    if (_resource?.extendedProps?.type === "task-user") {
      const findTaskUsers = _.filter(resources, (resource) =>
        _.includes(resource?.id, currentProcessId)
      );
      if (
        _.last(findTaskUsers)?.id ===
        `${_resource?.processId}=${_resource?.userid}`
      )
        isLastItem = true;
    }

    return !isHiddenTitle ? (
      <Text
        fontSize="sm"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
        }}
      >
        {_resource?.title}
      </Text>
    ) : (
      <Box
        sx={{
          mb: isLastItem ? "10px" : 0,
        }}
      ></Box>
    );
  };

  const _renderCalendar = () => {
    return (
      <Box
        ref={containerRef}
        sx={{
          "a.fc-event": {},
          ...calendarStyles,
          ...sharedStyles,
          padding: !isShowFullScreen ? "14px" : "0!important",
          "td.fc-resource-timeline-divider.fc-cell-shaded": {
            width: isMobile ? "7px" : "3px",
          },
          //The commented code you provided is used to hide unnecessary days when the container area is small.
          ".fc-timeline-header-row:last-of-type .fc-timeline-slot.fc-timeline-slot-label.fc-day:not(.fc-day-mon) a":
            {
              visibility:
                (slotLabelDisplayType === "firstDay" ||
                  slotLabelDisplayType === "hide") &&
                minSlotWidthDate <= 20
                  ? "hidden"
                  : "visible",
            },
          ".fc .fc-timeline-slot-cushion": {
            fontSize:
              slotLabelDisplayType === "firstDay" ||
              slotLabelDisplayType === "short" ||
              slotLabelDisplayType === "hide"
                ? "10px"
                : "inherit",
          },
        }}
        onMouseLeave={() => setMouseIsEnter(false)}
        onMouseEnter={() => setMouseIsEnter(true)}
      >
        <FullCalendar
          scrollTimeReset={false}
          locale={language === "en" ? "en" : "vi"}
          height={"auto"}
          ref={calendarRef}
          plugins={[interactionPlugin, resourceTimelinePlugin]}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          resourceAreaHeaderClassNames="custom-header"
          eventContent={_renderEventContent}
          events={events}
          resources={resources}
          rerenderDelay={2}
          resourceOrder={[
            "overall",
            ...resources?.map((resource) => resource.order),
          ]}
          resourcesInitiallyExpanded
          resourceLabelContent={_renderResourceContent}
          stickyHeaderDates
          stickyFooterScrollbar
          firstDay={1}
          headerToolbar={false}
          eventResizableFromStart
          eventResize={handleResize}
          eventDrop={handleDragEnd}
          aspectRatio={1}
          initialView={"resourceTimelineMonth"}
          eventDisplay="block"
          eventOrder="priority"
          views={{
            resourceTimelineWeek: {
              slotDuration: { day: 1 },
              // slotMinTime: "08:00",
              // slotMaxTime: "17:30",
              // slotDuration: { hours: 1 },
              // slotLabelFormat: [
              //   { weekday: "short", day: "2-digit" }, // lower level of text
              // ],
              slotMinWidth: minSlotWidthDate,
              eventMinWidth: minSlotWidthDate,
              slotLabelFormat: slotLabelFormats,
              // hiddenDays: [0, 6] // Hide Sunday and Saturday?
              // visibleRange,
              visibleRange: {
                start: minStartDate,
                end: maxEndDate,
              },
              // duration: {
              //   days: !_.isEmpty(visibleRange)
              //     ? dayjs(visibleRange.end).diff(
              //         dayjs(visibleRange.start),
              //         "day"
              //       ) + 1
              //     : 7,
              // },
              duration: {
                days:
                  dayjs(minStartDate).isValid() && dayjs(maxEndDate).isValid()
                    ? dayjs(maxEndDate).diff(dayjs(minStartDate), "day") + 1
                    : 7,
              },
            },
            resourceTimelineMonth: {
              slotDuration: { day: 1 },
              slotMinWidth: minSlotWidthDate,
              eventMinWidth: minSlotWidthDate,
              slotLabelFormat: slotLabelFormats,
              visibleRange: {
                start: minStartDate,
                end: maxEndDate,
              },
              duration: {
                days:
                  dayjs(minStartDate).isValid() && dayjs(maxEndDate).isValid()
                    ? dayjs(maxEndDate).diff(dayjs(minStartDate), "day") + 1
                    : 30,
              },
            },
          }}
          viewDidMount={(info) => {
            const resourcesEl: Element | any = info.el.querySelector(
              ".fc-scrollgrid > colgroup > col"
            );
            new ResizeObserver(() => {
              if (resourcesEl.clientWidth > 0) {
                setResourceAreaWidth(resourcesEl.clientWidth);
              }
            }).observe(resourcesEl);
          }}
          viewWillUnmount={() => {}}
          resourceAreaWidth={isMobile ? 50 : 150}
          datesSet={handleDatesSet}
          dayMaxEvents
          dateClick={handleDateClick}
          lazyFetching
          eventDragStart={() => setIsResizeOrDragEvent(true)}
          eventDragStop={() => setIsResizeOrDragEvent(false)}
          eventResizeStart={() => setIsResizeOrDragEvent(true)}
          eventResizeStop={() => setIsResizeOrDragEvent(false)}
          resourceLabelClassNames={(resourceInfo) => {
            const classNames = [];
            if (
              resourceInfo?.resource?._resource?.extendedProps?.type ===
              "process"
            ) {
              classNames.push("has-border");
            }

            if (
              resourceInfo?.resource?._resource?.id === lastResourceId ||
              resourceInfo?.resource?._resource?.extendedProps?.totalLogs === 0
            )
              classNames.push("last-resource-id");

            if (resourceInfo?.resource?._resource?.id === "overall") {
              classNames.push("overall");
            }

            const isLastItem = isLastTaskUser(
              resourceInfo?.resource?._resource,
              resources
            );

            if (isLastItem) {
              classNames.push("is-last-task");
            }

            if (
              resourceInfo?.resource?._resource?.extendedProps?.totalLogByUserId
            ) {
              classNames.push("fixed-height");
            }
            return classNames;
          }}
          resourceLaneClassNames={(slotContent) => {
            const classNames: string[] = [];
            if (
              slotContent?.resource?._resource?.extendedProps?.isShow ||
              slotContent?.resource?._resource?.id === "overall"
            ) {
              classNames.push("has-border");
            }

            if (slotContent?.resource?._resource?.id === "overall") {
              classNames.push("overall");
            }

            if (
              slotContent?.resource?._resource?.id === lastResourceId ||
              slotContent?.resource?._resource?.extendedProps?.totalLogs === 0
            ) {
              classNames.push("last-resource-id");
            }
            const isLastItem = isLastTaskUser(
              slotContent?.resource?._resource,
              resources
            );

            if (isLastItem) {
              classNames.push("is-last-task");
            }

            if (
              slotContent?.resource?._resource?.extendedProps?.totalLogByUserId
            ) {
              classNames.push("fixed-height");
            }
            return classNames;
          }}
          slotLabelClassNames={(slotContent) => {
            const classNames = [];
            const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
            const isHoliday = _.some(
              holidaysList,
              (holiday) => holiday?.day === slotDate
            );
            if (isHoliday) classNames.push("holiday");

            return classNames;
          }}
          slotLaneClassNames={(slotContent) => {
            const classNames = [];
            const slotDate = dayjs(slotContent?.date).format("YYYY-MM-DD");
            const isHoliday = _.some(
              holidaysList,
              (holiday) => holiday?.day === slotDate
            );
            if (isHoliday) classNames.push("holiday");
            return classNames;
          }}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: !isShowFullScreen
          ? "calc(100vh - 275px)"
          : "calc(100vh - 155px)",
        width: "100%",
      }}
    >
      {_renderCalendar()}
    </Box>
  );
};

export default memo(Calendar);
