import _ from "lodash";
import { ActionTypes, Routers } from "@/Constants";
import { Roles } from "@/Enums/Role.enums";
import { ILoginStructure } from "@/Interfaces/Auth.interface";
import APIs from "@/APIs";
import Utils from "@/Utils";
import { Toast } from "@/Widgets";
import { initSocket, disconnectSocket } from "../Configs/socket.config";
// import { persistor } from "../Store";

import { RoleActions, NotificationActions, ChatActions } from "@/Actions";
import { getDaysOfWeek } from "@/Utils/AppServices.utils";
import TimekeepingAction from "./Timekeeping.action";
import WorkingTimeAction from "./WorkingTime.action";

// SINGLE ACTIONS

const { fetchCheckIn } = TimekeepingAction;
const { fetchWorkingTime } = WorkingTimeAction;

const setAuthLoading = () => {
  return {
    type: ActionTypes.SET_AUTH_LOADING,
  };
};

const resetStepForgotPassword = () => {
  return {
    type: ActionTypes.RESET_STEP_FORGOT_PASSWORD,
  };
};

const resetCheckLogin = () => {
  return {
    type: ActionTypes.RESET_CHECK_LOGIN,
  };
};

const logout = (userId?: string, firebaseToken?: string) => {
  return async (dispatch: any) => {
    await dispatch(resetAuthReducer());
    if (userId && firebaseToken) {
      await dispatch(
        removeFCMToken({
          userId,
          token: firebaseToken,
        })
      );
    }
    if (ChatActions?.resetChatRecucer)
      await dispatch(ChatActions.resetChatRecucer());

    await Utils.clearAllSavedData();
    disconnectSocket();
    _.delay(() => {
      Utils.redirect(Routers.SIGN_IN);
    }, 500);
    // await persistor.purge();
    // persistor
    //   .purge()
    //   .then(() => {
    //     Utils.redirect(Routers.SIGN_IN);
    //   })
    //   .catch((error) => {
    //     console.error("Error purging state:", error);
    //   });
  };
};

// ASYNC ACTIONS

const loginSuccess = (payload: any) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload,
  };
};

const loginFailure = (payload: string) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    payload,
  };
};

const login = (
  payload: ILoginStructure,
  redirectPath: string | null,
  onSuccess?: (data: any) => void
) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.login(payload)
      .then(async (response: any) => {
        const result: any = await Utils.resolveResponse(response);
        if (!result) {
          const message = response?.data?.message;
          await dispatch(loginFailure(message));
          await Toast({
            title: "Login fail",
            description: message,
            status: "error",
          });
        } else {
          const resolveResult: {
            accessToken: any;
            user: any;
          } = result as {
            accessToken: any;
            user: any;
          };
          const localeStorage = Utils.getSavedLanguage();
          const token = resolveResult.accessToken.token;
          const refreshToken = resolveResult.accessToken.refreshToken;
          const userPayload = resolveResult.user;
          const firebaseToken = Utils.getSavedFirebaseToken();

          initSocket(token);
          Utils.saveToken(token);
          if (payload.isRemember) Utils.saveRefreshToken(refreshToken);
          Utils.saveUserData(userPayload);
          await dispatch(loginSuccess(result));
          // Set socket and tokeninitSocket(token);
          const currentDate = new Date();
          const weekdays = getDaysOfWeek(currentDate, "YYYY-MM-DD");
          await dispatch(
            fetchCheckIn({
              page: 0,
              limit: 0,
              startDate: _.first(weekdays),
              endDate: _.last(weekdays),
            })
          );
          await dispatch(
            changeLocale({
              userId: userPayload.id,
              locale: localeStorage,
              token: firebaseToken,
            })
          );
          await dispatch(fetchWorkingTime());
          if (RoleActions.fetchRoles) dispatch(RoleActions.fetchRoles());
          if (NotificationActions?.fetchOutsideNotifications)
            dispatch(NotificationActions?.fetchOutsideNotifications());
          if (ChatActions?.fetchMessageNotification)
            dispatch(ChatActions?.fetchMessageNotification());
          onSuccess && onSuccess(userPayload);

          if (redirectPath) Utils.redirect(redirectPath);
          else Utils.redirect(Routers.SIGN_IN);
        }
        return true;
      })
      .catch(async (error) => {
        // await Utils.resolveFailureResponse(error);
        await dispatch(
          loginFailure("The server is having problems, please try again later!")
        );
        await Toast({
          title: "Login Fail",
          description: error?.message,
          status: "error",
        });
      });
  };
};

const refeshTokenSuccess = (
  payload: Roles.ADMIN | Roles.THREEDARTIST | Roles.MANAGER
) => {
  return {
    type: ActionTypes.REFRESH_TOKEN_SUCCESS,
    payload,
  };
};

const refeshTokenFailure = (payload: string) => {
  return {
    type: ActionTypes.REFRESH_TOKEN_FAILURE,
    payload,
  };
};

const refeshToken = (payload: { refreshToken: string }) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.refreshToken(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          const message = response?.data?.message;
          await dispatch(refeshTokenFailure(message));
          await Toast({
            title: "Login fail",
            description: message,
            status: "error",
          });
        } else {
          const resolveResult: {
            accessToken: any;
            user: any;
          } = result as {
            accessToken: any;
            user: any;
          };
          const token = resolveResult.accessToken.token;
          const userPayload = resolveResult.user;
          const role = userPayload?.roleCode;
          token && Utils.saveToken(token);
          userPayload && Utils.saveUserData(userPayload);
          role && dispatch(refeshTokenSuccess(role));
        }
        return true;
      })
      .catch(async (error) => {
        // await Utils.resolveFailureResponse(error);
        await dispatch(
          refeshTokenFailure(
            "The server is having problems, please try again later!"
          )
        );
        await Toast({
          title: "Login Fail",
          description: error?.message,
          status: "error",
        });
      });
  };
};

const requestForgotPasswordSuccess = () => {
  return {
    type: ActionTypes.REQUEST_FORGOT_PASSWORD_SUCCESS,
  };
};

const requestForgotPasswordFailure = () => {
  return {
    type: ActionTypes.REQUEST_FORGOT_PASSWORD_FAILURE,
  };
};

const requestForgotPassword = (payload: { email: string }) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.forgotPassword(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;

        if (!result) {
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          dispatch(requestForgotPasswordSuccess());
        }
        return true;
      })
      .catch(async (error) => {
        dispatch(requestForgotPasswordFailure());
        await Toast({
          title: error?.message,
          status: "error",
        });
      });
  };
};

const verifySuccess = () => {
  return {
    type: ActionTypes.VERIFY_ACCOUNT_SUCCESS,
  };
};

const verifyFailure = () => {
  return {
    type: ActionTypes.VERIFY_ACCOUNT_FAILURE,
  };
};

const verifyAccount = (payload: { email: string; code: string }) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.verifyAccount(payload)
      .then(async (response: any) => {
        if (!response?.data?.status) {
          await dispatch(verifyFailure());
          Utils.sleep(2000).then(() =>
            Utils.redirect(Routers.VERIFY_ACCOUNT_FAIL)
          );
        } else {
          await dispatch(verifySuccess());
          Utils.sleep(2000).then(() =>
            Utils.redirect(Routers.VERIFY_ACCOUNT_SUCCESS)
          );
        }
      })
      .catch(async (error) => {
        await Utils.resolveResponse(error);
        dispatch(verifyFailure());
        Utils.sleep(2000).then(() =>
          Utils.redirect(Routers.VERIFY_ACCOUNT_FAIL)
        );
      });
  };
};

const resetPasswordSuccess = () => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordFailure = () => {
  return {
    type: ActionTypes.RESET_PASSWORD_FAILURE,
  };
};

const resetPassword = (payload: {
  email: string;
  code: string;
  newPassword: string;
  confirmationNewPassword: string;
}) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.resetPassword(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        const message = response?.data?.message;
        if (!result) {
          await dispatch(resetPasswordFailure());
          await Toast({
            title: message,
            status: "error",
          });
        } else {
          await dispatch(resetPasswordSuccess());
          Utils.redirect(Routers.SIGN_IN);
          await Toast({
            title: message,
            status: "success",
          });
        }
      })
      .catch(async (error) => {
        await Utils.resolveResponse(error);
        dispatch(resetPasswordFailure());
      });
  };
};

const createFCMTokenSuccess = () => {
  return {
    type: ActionTypes.CREATE_FCM_TOKEN_SUCCESS,
  };
};

const createFCMTokenFailure = () => {
  return {
    type: ActionTypes.CREATE_FCM_TOKEN_FAILURE,
  };
};

const createFCMToken = (payload: {
  userId: string;
  token: string;
  locale?: string;
}) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.createFCMToken(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(createFCMTokenFailure());
        } else {
          await dispatch(createFCMTokenSuccess());
        }
      })
      .catch(async () => {
        dispatch(createFCMTokenFailure());
      });
  };
};

const removeFCMTokenSuccess = () => {
  return {
    type: ActionTypes.REMOVE_FCM_TOKEN_SUCCESS,
  };
};

const removeFCMTokenFailure = () => {
  return {
    type: ActionTypes.REMOVE_FCM_TOKEN_FAILURE,
  };
};

const removeFCMToken = (payload: { userId: string; token: string }) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.removeFCMToken(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(removeFCMTokenFailure());
        } else {
          await dispatch(removeFCMTokenSuccess());
        }
      })
      .catch(async () => {
        dispatch(createFCMTokenFailure());
      });
  };
};

const resetAuthReducer = () => {
  return {
    type: ActionTypes.RESET_AUTH_REDUCER,
  };
};

const changeLocaleSuccess = () => {
  return {
    type: ActionTypes.CHANGE_LOCALE_SUCCESS,
  };
};

const changeLocaleFailure = () => {
  return {
    type: ActionTypes.CHANGE_LOCALE_FAILURE,
  };
};

const changeLocale = (payload: {
  userId: string;
  locale: string;
  token: any;
}) => {
  return async (dispatch: any) => {
    dispatch(setAuthLoading());
    await APIs.changeLocale(payload)
      .then(async (response: any) => {
        const result = await Utils.resolveResponse(response);
        if (!result) {
          await dispatch(changeLocaleFailure());
        } else {
          await dispatch(changeLocaleSuccess());
        }
      })
      .catch(async () => {
        dispatch(changeLocaleFailure());
      });
  };
};

export default {
  setAuthLoading,
  login,
  logout,
  refeshToken,
  requestForgotPassword,
  verifyAccount,
  resetStepForgotPassword,
  resetPassword,
  resetAuthReducer,
  resetCheckLogin,
  createFCMToken,
  removeFCMToken,
  changeLocale,
};
