import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { AdminLayout } from "@/Components/DefaultLayout";
import { TimeOffRequestDialog } from "@/Components/Popup";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import _ from "lodash";
//import AggregateOvertime from "./AggregateOvertime";
import Utils from "@/Utils";
import DayOffOverview from "./DayOffOverview";
import AggregateDayOff from "./DayoffAggregate";
import { ExportToExcelDayOffRequest } from "@/Components/Export";
import { useTranslation } from "react-multi-lang";

const DayOffRequest = () => {
  const t = useTranslation();
  const userData = Utils.getSavedUserData();
  const [isShowPopup, setIsShowPopup] = useState({
    create: false,
  });
  const isCreateSuccess: boolean = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "isCreateSuccess")
  );
  const [openTable, setOpenTable] = useState<string>("overview");

  const viewTotal = Utils.hasPermission(
    _.map(userData?.userRole, (userRole) => _.get(userRole, "role")),
    "DayOffRequest",
    "fetchAggregate"
  );

  const handleShowPopup = (name: string, value: boolean) => {
    setIsShowPopup({ ...isShowPopup, [name]: value });
  };

  useEffect(() => {
    if (isCreateSuccess) {
      setIsShowPopup({
        create: false,
      });
    }
  }, [isCreateSuccess]);

  const _renderTopSection = () => {
    return (
      <Stack
        sx={{
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading size="md" color={"#5C6e6c"}>
              {t("title.dayOffRequest")}
            </Heading>
            <Box sx={{ display: "flex", gap: 1 }}>
              {openTable === "aggregate" && viewTotal && (
                <Box>
                  <ExportToExcelDayOffRequest />
                </Box>
              )}
              <Button
                size="sm"
                //colorScheme="twitter"
                leftIcon={<Icon as={AddIcon} boxSize={3} />}
                onClick={() => handleShowPopup("create", true)}
                sx={{
                  color: "#fff",
                  background: "#5c6e6c",
                  "&:hover": {
                    background: "#a6b7af",
                  },
                }}
              >
                {t("button.newRequest")}
              </Button>
            </Box>
          </Box>
          <Box>
            <TabList
              sx={{
                width: "100%",
                mt: 2,
              }}
            >
              <Tab
                sx={{
                  w: 200,
                  fontSize: 16,
                }}
                _selected={{
                  color: "#bb7154",
                  fontWeight: 600,
                  borderBottom: "4px solid #bb7154",
                }}
                onClick={() => setOpenTable("overview")}
              >
                {t("button.overview")}
              </Tab>
              <Tab
                sx={{
                  w: 210,
                  fontSize: 16,
                }}
                _selected={{
                  color: "#bb7154",
                  fontWeight: 600,
                  borderBottom: "4px solid #bb7154",
                }}
                onClick={() => setOpenTable("aggregate")}
              >
                {viewTotal ? (
                  <Text>{t("button.aggregateWithUsers")}</Text>
                ) : (
                  <Text>{t("button.aggregate")}</Text>
                )}
              </Tab>
            </TabList>
          </Box>
        </Box>
      </Stack>
    );
  };
  const renderMain = () => {
    return (
      <Tabs size={"sm"}>
        <Box>{_renderTopSection()}</Box>
        <TabPanels>
          <TabPanel pt={0}>
            <DayOffOverview />
          </TabPanel>
          <TabPanel pt={0}>
            <AggregateDayOff action={openTable} />
          </TabPanel>
        </TabPanels>
        <TimeOffRequestDialog.SendDateRequestDialog
          open={isShowPopup.create}
          onClose={() => handleShowPopup("create", false)}
        />
      </Tabs>
    );
  };

  return <AdminLayout content={renderMain()} />;
};

export default DayOffRequest;
