import { request } from "@/Configs";
import { APIs } from "@/Constants";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";

const { DAY_OFF_REQUEST } = APIs;

export const fetchDayOffRequest = async (payload: any) => {
  return request(DAY_OFF_REQUEST.BASIC, "GET", payload);
};

export const getDayOffRequestById = async (id: string) => {
  return request(`${DAY_OFF_REQUEST.GET_BY_ID}/${id}`, "GET");
};

export const createDayOffRequest = async (payload: FormData) => {
  return request(DAY_OFF_REQUEST.BASIC, "POST_FORM_DATA", payload);
};

export const updateDayOffReques = async (id: string, payload: any) => {
  return request(`${DAY_OFF_REQUEST.BASIC}/${id}`, "PUT_FORM_DATA", payload);
};

export const ChangeStatusDayOffRequest = async (id: string, payload: any) => {
  return request(
    `${DAY_OFF_REQUEST.CHANGE_STATUS}/${id}`,
    "PUT_FORM_DATA",
    payload
  );
};

export const deleteDayoffRequest = async (id: string) => {
  return request(`${DAY_OFF_REQUEST.DELETE}/${id}`, "PUT");
};

export const fetchAggregateDayOffRequest = async (payload: IFilterAggregate) => {
  return request(DAY_OFF_REQUEST.AGGREGATE, "GET", payload);
};
