import React, { useRef } from "react";
import _ from "lodash";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Select as GSelect,
  Heading,
  Text,
  Stack,
  InputGroup,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, Button, Select, TextField } from "@/Components/Common";
import TextareaComponent from "@/Components/Common/Textarea";
import * as yup from "yup";
import dayjs from "dayjs";
import { RequestUpdateTimeCardAction } from "@/Actions";
import { RootState, useTypedDispatch } from "@/Store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const { createRequestUpdateTimeCard } = RequestUpdateTimeCardAction;

interface SectionProps {
  open: boolean;
  onClose(): void;
}

interface DataYear {
  label: number;
  value: number;
}

const DEFAULT_FORM_DATA = {
  type: "",
  content: "",
  date: "",
  link: "",
};

const type = [
  { label: "Attendance Sheets", value: "attendance history" },
  { label: "Leaves day", value: "leave day" },
  { label: "Timesheet", value: "timesheet" },
];

const SendRequestUpdateTimeCardDialog: React.FC<SectionProps> = ({
  open,
  onClose,
}) => {
  const t = useTranslation();
  const cancelRef = useRef<any>(null);
  const dispatch = useTypedDispatch();

  const isCreateRequestUpdateTimeCardSuccess: any = useSelector(
    (state: RootState) =>
      _.get(
        state.REQUEST_UPDATE_TIME_CARD,
        "isCreateRequestUpdateTimeCardSuccess"
      )
  );
  const isActionLoading: any = useSelector((state: RootState) =>
    _.get(state.REQUEST_UPDATE_TIME_CARD, "isActionLoading")
  );

  const schema = yup
    .object()
    .shape({
      content: yup.string().trim().required(t("message.contentIsRequired")),
      date: yup.string().trim().required(t("message.dateToBeEditIsRequired")),
      type: yup.string().trim().required(t("message.typeIsRequired")),
      link: yup.string().url(t("message.linkInvalid")),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: DEFAULT_FORM_DATA,
  });
  const newDateType: string = watch("type");

  const onSubmit = (data: any) => {
    const formData = new FormData();
    formData.append(
      "date",
      dayjs(data.date).isValid() ? dayjs(data.date).format("YYYY-MM-DD") : ""
    );
    formData.append("content", data.content);
    formData.append("type", data.type);
    formData.append("link", data.link);

    dispatch(createRequestUpdateTimeCard(formData));
  };

  const yearsOptions = React.useMemo(() => {
    let allyears: DataYear[] = [];
    const minYear = -(
      new Date().getFullYear() - new Date("2010-01-01T23:15:30").getFullYear()
    );
    const maxYear = 1;
    const thisYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      allyears.push({ label: thisYear + i, value: thisYear + i });
    }
    return allyears;
  }, []);

  React.useEffect(() => {
    if (isCreateRequestUpdateTimeCardSuccess) {
      onClose();
      reset();
    }
  }, [isCreateRequestUpdateTimeCardSuccess]);

  React.useEffect(() => {
    if (newDateType === "leave day") {
      setValue("date", new Date().getFullYear().toString());
    }
  }, [newDateType]);

  React.useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const _renderContent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
        as={"form"}
      >
        <Box>
          <Box>
            <Heading
              fontSize="sm"
              sx={{
                alignItems: "center",
                display: "flex",
                mb: 2,
              }}
            >
              {t("label.types")}
              <Text color={"error"} ml={1}>
                *
              </Text>
            </Heading>
            <InputGroup size="sm">
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <GSelect
                    placeholder={t("label.types")}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    required
                    size={"sm"}
                    sx={{ borderRadius: "5px" }}
                  >
                    {_.map(_.values(type), (item, index) => (
                      <option key={index} value={item.value}>
                        {t(`label.${item.value}`)}
                      </option>
                    ))}
                  </GSelect>
                )}
              />
            </InputGroup>
          </Box>
          {!_.isEmpty(errors.type?.message) && (
            <Text sx={{ color: "error", fontSize: 14 }}>
              {errors.type?.message}
            </Text>
          )}
        </Box>
        <Box>
          <Heading
            fontSize="sm"
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 2,
            }}
          >
            {newDateType === "leave day"
              ? t("label.yearToBeEdited")
              : t("label.dateToBeEdited")}
            <Text color={"error"} ml={1}>
              *
            </Text>
          </Heading>
          {newDateType === "leave day" ? (
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <Stack mb={2}>
                  <Select
                    value={field.value}
                    onChange={(e: any) => {
                      field.onChange(e.target.value);
                    }}
                    size="sm"
                    options={yearsOptions}
                    isRequired
                  />
                </Stack>
              )}
            />
          ) : (
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <Stack mb={2}>
                  <DatePicker
                    maxDate={new Date()}
                    value={field.value}
                    onDateChange={(e: any) => field.onChange(e)}
                    isError={!_.isEmpty(errors.date?.message)}
                    errorMessage={errors.date?.message}
                    size="sm"
                  />
                </Stack>
              )}
            />
          )}
        </Box>
        <Box>
          <Heading
            fontSize="sm"
            sx={{
              alignItems: "center",
              display: "flex",
              mb: 2,
            }}
          >
            {t("label.content")}
            <Text color={"error"} ml={1}>
              *
            </Text>
          </Heading>
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <Stack>
                <TextareaComponent
                  size={"sm"}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  isError={!_.isEmpty(errors.content?.message)}
                  errorMessage={errors.content?.message}
                />
              </Stack>
            )}
          />
        </Box>
        <Box>
          <Controller
            name="link"
            control={control}
            render={({ field }) => (
              <Stack mb={5}>
                <TextField
                  value={field.value}
                  onChange={(e: any) => {
                    field.onChange(e.target.value);
                  }}
                  label={t("label.link")}
                  placeholder={t("label.link")}
                  size="sm"
                  isError={!_.isEmpty(errors.link?.message)}
                  errorMessage={errors?.link?.message}
                />
              </Stack>
            )}
          />
        </Box>
      </Box>
    );
  };

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="md"
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay />
      <AlertDialogContent
        sx={{
          maxH: "full",
        }}
      >
        <AlertDialogHeader
          fontSize="lg"
          textAlign="center"
          fontWeight="bold"
          sx={{
            position: "sticky",
            zIndex: "0",
            top: 0,
            backgroundColor: "#fff",
            boxShadow: "0 1px 2px -1px gray",
            borderRadius: "10px 10px 0 0",
          }}
        >
          {t("title.sendRequestUpdateTimecard")}
        </AlertDialogHeader>
        <AlertDialogBody>{_renderContent()}</AlertDialogBody>
        <AlertDialogFooter
          sx={{
            position: "sticky",
            bottom: 0,
            background: "#fff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 0px 2px gray",
          }}
        >
          <Button
            ref={cancelRef}
            onClick={() => {
              onClose();
              reset();
            }}
            size={"sm"}
            isLoading={isActionLoading}
            w={75}
          >
            {t("button.cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            ml={3}
            //colorScheme="twitter"
            size={"sm"}
            isLoading={isActionLoading}
            w={75}
            sx={{
              color: "#fff",
              background: "#5c6e6c",
              "&:hover": {
                background: "#a6b7af",
              },
            }}
          >
            {t("button.send")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SendRequestUpdateTimeCardDialog;
